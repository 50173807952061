import GoalsListContainer from "containers/GoalsList";
import LevelsListContainer from "containers/LevelsList";
import SelectThemeContainer from "containers/SelectTheme";
import { Field } from "formik";
import Input from "global/components/Form/Input";
import { CenterForm } from "global/components/Form/Input/styles";
import { ValidationButton } from "global/components/Form/ValidateButton";
// import HorizontalLangsList from "global/components/Langs/HorizontalLangsList";
import React, { useEffect, useState } from "react";
import Fields from "./Fields";
import { CreatedByText, HeightDiv } from "./styles";
import { FormProps } from "./types";
import ImageCropContainer from "containers/ImageCrop";

const Form: React.FC<FormProps> = ({
  isValid,
  dirty,
  values,
  setFieldValue,
  edit,
  seance,
  loading,
}) => {
  const [lang] = useState("fr");

  // const handleLang = (newLang: string) => {
  //   // if (lang === newLang) return;
  //   // let newLangs = values.langs;
  //   // const indexOf = values.langs.findIndex(
  //   //   (element: Lang) => element.id === lang
  //   // );
  //   // if (
  //   //   values.langs[indexOf].name === "" &&
  //   //   values.langs[indexOf].id === lang
  //   // ) {
  //   //   let newTable = values.langs.filter(
  //   //     (language: any) => language.id !== lang
  //   //   );
  //   //   newLangs = newTable;
  //   //   setFieldValue("langs", newTable);
  //   // }
  //   // if (
  //   //   values.langs.findIndex((element: Lang) => element.id === newLang) === -1
  //   // )
  //   //   setFieldValue("langs", [
  //   //     ...newLangs,
  //   //     { id: newLang, name: "", description: "", keywords: [] },
  //   //   ]);
  //   // setLang(newLang);
  // };

  //console.log(values.goals);

  useEffect(() => {
    try {
      if (edit && Object.getOwnPropertyNames(seance).length) {
        const fields = [
          "croppedImage",
          "blobImage",
          "estimatedDuration",
          "theme",
        ];
        fields.forEach((field) => setFieldValue(field, seance[field]));
        //(seance);
        let keywords: any[] = [];
        let i: number = 1;
        seance.langs.fr.keywords.forEach((keyword: string) => {
          keywords.push({ id: i, text: keyword });
          i++;
        });

        setFieldValue(`langs.${lang}].name`, seance.langs.fr.name);
        setFieldValue(
          `langs.${lang}].description`,
          seance.langs.fr.description
        );
        setFieldValue(`langs.${lang}].keywords`, keywords);
        setFieldValue("themes", seance.themes);
        setFieldValue("muscleGroups", seance.muscleGroups);
        setFieldValue("jointInjuries", seance.jointInjuries);
        setFieldValue("equipments", seance.equipments);
        setFieldValue("goals", seance.goals);
        setFieldValue("positions", seance.positions);
        setFieldValue("level", seance.level);
        setFieldValue("educative", seance.educative);
        setFieldValue("free", seance.free);
        setFieldValue("inLibrary", seance.inLibrary);
        setFieldValue("preparation", seance.preparation);
      }
    } catch (error) {
      //console.log(error);
    }
  }, [edit, setFieldValue, seance, lang]);
  return (
    <CenterForm>
      <HeightDiv />
      {edit ? (
        <CreatedByText>Created by {seance.createdBy}</CreatedByText>
      ) : null}
      <HeightDiv />

      <ImageCropContainer
        croppedImage={values.croppedImage}
        croppedImageValue="croppedImage"
        blobImageValue="blobImage"
        setFieldValue={setFieldValue}
        label="Image (obligatoire)"
        aspect={4 / 1}
      />
      <SelectThemeContainer
        theme={values.themes}
        setTheme={(e: any) => setFieldValue("themes", e)}
      />

      <LevelsListContainer
        selected={values.level}
        setSelected={(e: any) => setFieldValue("level", e.target.value)}
      />

      {
        /* <MuscleGroupsListContainer
        selected={values.muscleGroups}
        setSelected={(e: any) => setFieldValue("muscleGroups", e)}
      />

      <JointInjuriesListContainer
        selected={values.jointInjuries}
        setSelected={(e: any) => setFieldValue("jointInjuries", e)}
      />

      <EquipmentsListContainer
        selected={values.equipments}
        setSelected={(e: any) => setFieldValue("equipments", e)}
      />

      <PositionsListContainer
        selected={values.positions}
        setSelected={(e: any) => setFieldValue("positions", e)}
      />
*/
        <GoalsListContainer
          selected={values.goals}
          setSelected={(e: any) => setFieldValue("goals", e)}
          themes={values.themes}
        />
      }
      <div className="cgu">
        <div className="cgu-checkbox">
          <Field type="checkbox" name="educative" />
        </div>

        <label>Éducatif</label>
      </div>
      <div className="cgu">
        <div className="cgu-checkbox">
          <Field type="checkbox" name="preparation" />
        </div>

        <label>Prépa</label>
      </div>
      {/* <HorizontalLangsList onClick={handleLang} lang={lang} /> */}
      <Fields lng="fr" />
      {/* <KeywordsInput
        keywords={values.langs.fr.keywords}
        setFieldValue={setFieldValue}
        lng="fr"
      /> */}
      <HeightDiv />
      <Input
        name="estimatedDuration"
        type="number"
        placeholder="ex : 60"
        label="Estimated Duration (minutes)"
      />

      <div className="cgu">
        <div className="cgu-checkbox">
          <Field type="checkbox" name="inLibrary" />
        </div>

        <label>Dans la librarie ?</label>
      </div>

      <div className="cgu">
        <div className="cgu-checkbox">
          <Field type="checkbox" name="free" />
        </div>

        <label>Gratuit ?</label>
      </div>

      <ValidationButton
        dirty={dirty}
        isValid={isValid}
        edit={edit}
        loading={loading}
      />
    </CenterForm>
  );
};

export default Form;
