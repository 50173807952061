import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { FormValues } from "../utils/initialValues";
import firebase from "firebase";
import { _activityHourToDate } from "global/functions/activityHourToDate";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import { useState } from "react";

export const useAddBalanceSheet = (athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const firestore = useFirestore();
  const alert = useAlert();
  const uploadOnStorage = useUploadOnStorage();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const pdfUrl = await uploadOnStorage(
        values.pdfUrl,
        values.type === "bodyCheckup"
          ? "balanceSheetBody"
          : "balanceSheetNutrition"
      );
      //bonjour :)
      let videoUrl: any = "";
      if (values.videoUrl !== "")
        videoUrl = await uploadOnStorage(
          values.videoUrl,
          values.type === "bodyCheckup"
            ? "balanceSheetBody"
            : "balanceSheetNutrition"
        );

      await firestore.collection("balanceSheets").add({
        owner: firebase.firestore().doc(`/users/${athleteId}`),
        type: values.type,
        date: _activityHourToDate(values.date, "10:00"),
        name: values.name,
        pdfUrl: pdfUrl,
        videoUrl: videoUrl,
      });
      alert.success("Balance Sheet added");
      history.push({
        pathname: "athlete-balance-sheets",
        state: { athleteId: athleteId },
      });
    } catch (error) {
      //console.log(error);
      alert.error("Erreur");
    }
    setLoading(false);
  };

  return { handleSubmit, loading };
};
