import * as Yup from "yup";

export const validationSchema = Yup.object({
  password: Yup.string()
    .required("Mot de passe requis")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
    ),
  passwordConfirmation: Yup.string()
    .required("Requis")
    .oneOf([Yup.ref("password")], "Les mots de passes doivent être identique"),
});
