import AddDropdownButton from "components/CreateSeance/FormExercise/AddDropdownButton";
import DeleteButton from "global/components/Button/DeleteRoundedButton";
import DuplicateButton from "global/components/Button/DuplicateButton";
import { Work } from "schemes/exercise";
import { DivBeetweenButton } from "../Exercises/styles";
import { ButtonsDiv } from "./styles";

interface Props {
  number: number;
  deleteSerie: any;
  duplicateSerie: any;
  work: Work;
  addItemToSerie: any;
  deleteItemToSerie: any;
}

const Buttons: React.FC<Props> = ({
  number,
  deleteSerie,
  duplicateSerie,
  work,
  addItemToSerie,
  deleteItemToSerie,
}) => {
  return (
    <ButtonsDiv>
      <AddDropdownButton
        addItemToSerie={addItemToSerie}
        deleteItemToSerie={deleteItemToSerie}
        number={number}
      />
      <DivBeetweenButton />
      {/* {number < 6 ? ( */}
      <DuplicateButton onClick={() => duplicateSerie(work)} color="#B6B6B6" />
      {/* // ) : (
      //   <DivWidth40 />
      // )} */}

      <DivBeetweenButton />
      <DeleteButton onClick={() => deleteSerie(number - 1)} />
    </ButtonsDiv>
  );
};

export default Buttons;
