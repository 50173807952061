import React from "react";
import { Props } from "./types";
import Cropper from "react-easy-crop";
import { CropContainer, Div, StyledPopup } from "./styles";
import { Button } from "./styles";
import InputFile from "./InputFile";

const ImageCrop: React.FC<Props> = ({
  imageSrc,
  crop,
  zoom,
  setCrop,
  onCropComplete,
  setZoom,
  onFileChange,
  showCroppedImage,
  label,
  aspect,
}) => {
  return (
    <Div>
      {imageSrc ? (
        <React.Fragment>
          <StyledPopup open={true} closeOnDocumentClick={false}>
            <div>
              <CropContainer>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </CropContainer>
              <Button type="button" onClick={showCroppedImage}>
                Validate
              </Button>
            </div>
          </StyledPopup>
        </React.Fragment>
      ) : (
        <InputFile onFileChange={onFileChange} label={label} />
      )}
    </Div>
  );
};

export default ImageCrop;
