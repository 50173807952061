import styled from "styled-components";

export const Div = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.p`
  font-weight: bold;
`;

export const Separation = styled.div`
  height: 15px;
`;

export const SeeSeanceDiv = styled.div`
  :hover {
    cursor: pointer;
  }
`;
