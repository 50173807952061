import AthletePlanning from "components/AthletePlanning";
import { useAthletePlanning } from "./hooks/useAthletePlanning";

interface Props {
  athleteId: string;
}

const AthletePlanningContainer: React.FC<Props> = ({ athleteId }) => {
  const {
    eventsList,
    deleteEvent,
    currentMonth,
    prevMonth,
    nextMonth,
    deleteInjury,
    loading,
  } = useAthletePlanning(athleteId);

  return (
    <AthletePlanning
      eventsList={eventsList}
      currentMonth={currentMonth}
      prevMonth={prevMonth}
      nextMonth={nextMonth}
      deleteEvent={deleteEvent}
      athleteId={athleteId}
      deleteInjury={deleteInjury}
      loading={loading}
    />
  );
};

export default AthletePlanningContainer;
