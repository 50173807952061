import Tag from "global/components/Tag";
import { MyShadows } from "global/styles/box-shadow";
import { ImageUrl } from "global/styles/img";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Div = styled.div`
  width: 250px;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: ${MyShadows.primary};
  padding: 20px;
  margin: 20px;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Title = styled.p`
  //width: 250px;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
`;

const Hit = (props: any) => {
  const history = useHistory();
  return (
    <Div
      onClick={() =>
        history.push({
          pathname: "edit-exercise",
          state: { exerciseRef: props.hit.objectID },
        })
      }
    >
      <ImageUrl src={props.hit.imageUrl} />
      <Title>{props.hit.langs.fr.name.toUpperCase()}</Title>

      <div style={{ width: 250 }}>
        {props.hit.themes !== undefined
          ? props.hit.themes.map((theme: any) => (
              <Tag label={theme.name.fr} color={theme.color} />
            ))
          : null}
        {props.hit.muscleGroups !== undefined
          ? props.hit.muscleGroups.map((muscleGroup: string) => (
              <Tag label={muscleGroup} />
            ))
          : null}
        {props.hit.jointInjuries !== undefined
          ? props.hit.jointInjuries.map((jointInjury: string) => (
              <Tag label={jointInjury} />
            ))
          : null}
        {props.hit.equipments !== undefined
          ? props.hit.equipments.map((equipment: string) => (
              <Tag label={equipment} />
            ))
          : null}
        {props.hit.goals !== undefined
          ? props.hit.goals.map((goal: string) => <Tag label={goal} />)
          : null}
        {props.hit.level !== undefined ? (
          <Tag label={`Niveau ${props.hit.level}`} />
        ) : null}
      </div>
    </Div>
  );
};

export default Hit;
