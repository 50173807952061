import { Loading } from "global/styles";
import "./silver-subscription.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick: any;
  handlePayment: any;
  loading: boolean;
  products: any[];
  productselected: any;
  selectProduct: any;
  trial?: boolean;
}

const SilverSubscription: React.FC<Props> = ({
  onClick,
  handlePayment,
  loading,
  products,
  productselected,
  selectProduct,
  trial,
}) => {
  const { t } = useTranslation();
  const offres = [
    {
      title: t("Abonnement annuel"),
      price: "3,74€",
      discount: "-53%",
      priceDetails: t("PriceDetail1"),
      discoutDetails: t("DiscountDetail1"),
      features: [t("Feature1"), t("Feature2"), t("Feature3")],
      priceUid: "price_1NLhglD4QJDBdYPJPAshjBZe",
    },
    {
      title: t("Abonnement mensuel"),
      price: "4,99€",
      discount: "-38%",
      priceDetails: t("PriceDetail2"),
      discoutDetails: t("DiscountDetail2"),
      features: [t("Feature4"), t("Feature2"), t("Feature3")],
      priceUid: "price_1NLhezD4QJDBdYPJm4YjQRWg",
    },
    {
      title: t("Abonnement à vie"),
      price: "129,99€",
      discount: t("Meilleure offre"),
      priceDetails: t("PriceDetail3"),
      discoutDetails: "",
      features: [t("Feature4"), t("Feature5")],
      priceUid: "price_1NQv4yD4QJDBdYPJlIlmorTa",
    },
  ];

  if (loading) {
    return (
      <div>
        <h1 style={{ padding: 20, color: "#FFF", fontSize: 20 }}>
          Chargement de la page de vente. Veuillez patienter...
        </h1>
      </div>
    );
  }

  return (
    <div>
      <PromoBanner />
      <div
        style={{
          paddingTop: "30px",
          paddingBottom: "30px",
          textAlign: "center",
        }}
      >
        <h1
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            color: "#FFF",
            fontSize: 30,
          }}
        >
          {t("Title1")}
        </h1>
        <h1 style={{ padding: 20, color: "#FFF", fontSize: 20 }}>
          {t("Title2")}
        </h1>
        <div className="carousel-container">
          {offres.map((offer, index) => (
            <Card
              key={index}
              offer={offer}
              onClick={onClick}
              handlePayment={handlePayment}
              loading={loading}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

interface CardProps {
  offer: any;
  onClick: any;
  handlePayment: any;
  loading: boolean;
}

const Card: React.FC<CardProps> = ({
  offer,
  onClick,
  loading,
  handlePayment,
}) => {
  const { t } = useTranslation();
  return (
    <div className="card">
      <div className="discount">{offer.discount}</div>
      <div>
        <h2 className="title">{offer.title}</h2>
        <p className="price">
          {offer.price}
          {offer.price !== "129,99€" && (
            <span style={{ fontSize: 12, marginLeft: 10 }}>{t("Mois")}</span>
          )}
        </p>
        <p className="price-details">{offer.priceDetails}</p>
        <p className="price-details2">{offer.discoutDetails}</p>
      </div>

      <hr className="divider" />
      <ul className="features">
        {offer.features.map((feature: any, index: number) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <button
        className="button"
        onClick={() =>
          offer.title === "Abonnement à vie"
            ? handlePayment()
            : onClick(offer.priceUid)
        }
        disabled={loading}
      >
        {loading ? (
          <div>
            <Loading /> Le chargement peut prendre du temps...
          </div>
        ) : (
          t("subscribe")
        )}
      </button>
    </div>
  );
};

const PromoBanner = () => {
  const [time, setTime] = useState(calculateTimeLeft());

  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    let now = new Date();
    let midnight = new Date();
    midnight.setHours(24, 0, 0, 0); // prochain minuit
    let timeLeft = midnight.getTime() - now.getTime();

    let hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return {
      hours: hours < 10 ? "0" + hours : hours,
      minutes: minutes < 10 ? "0" + minutes : minutes,
      seconds: seconds < 10 ? "0" + seconds : seconds,
    };
  }

  return (
    <div className="promo-banner">
      <p>{t("Promotion d'été")}</p>
      <p>
        {t("Discount1")}{" "}
        <span style={{ fontSize: 20, fontWeight: "bold" }}>
          {t("Discount2")}
        </span>
        {t("Discount3")}
      </p>
      <div
        style={{
          backgroundColor: "black",
          borderRadius: 10,
          color: "#FFF",
          padding: 5,
          fontFamily: "Montserrat",
        }}
      >
        {" "}
        {t("Countdown")}
        {time.hours}:{time.minutes}:{time.seconds}
      </div>
    </div>
  );
};

export default SilverSubscription;
