import React from "react";
import { useAppSelector } from "store/storeTypes";
import Goal from "./Goal";
import { DivCircle } from "./styles";
import { Props } from "./types";
import WeekNumber from "./WeekNumber";

const WeekLine: React.FC<Props> = ({ data }) => {
  const athlete = useAppSelector((state) => state.athlete);
  return (
    <div>
      <DivCircle>
        <WeekNumber
          startDate={data.startDate}
          endDate={data.endDate}
          reliability={data.reliability}
          pdfUrl={data.url !== undefined && data.url !== null ? data.url : null}
        />
        <Goal goal={data.ratio} />
        {athlete?.appRole !== "basic" ? <Goal goal={data.ies} /> : null}

        <Goal goal={data.vidc} />
        <Goal goal={data.issm} />
        <Goal goal={data.dst} />
      </DivCircle>
      <div style={{ height: 3, width: "100%", backgroundColor: "#FFF" }}></div>
    </div>
  );
};

export default WeekLine;
