import Input from "global/components/Form/Input";
import { CenterForm } from "global/components/Form/Input/styles";
import InputPdfFile from "global/components/Form/InputPdfFile";
import InputVideoFile from "global/components/Form/InputVideoFile";
import { ValidationButton } from "global/components/Form/ValidateButton";
import Video from "global/components/Form/Video";
import Select from "global/components/Select";
import { FormProps } from "./types";

const Form: React.FC<FormProps> = ({
  dirty,
  isValid,
  values,
  setFieldValue,
  loading,
}) => {
  //console.log(values);
  return (
    <CenterForm>
      <Select
        options={null}
        optionsList={[
          { name: "Body", value: "bodyCheckup" },
          { name: "Nutrition", value: "nutrition" },
        ]}
        value={values.type}
        onChange={(e: any) => setFieldValue("type", e.target.value)}
        label="Type du bilan"
        name="type"
      />
      <Input name="name" type="text" placeholder="Nom du bilan" label="Name" />
      <Input name="date" type="date" placeholder="Date du bilan" label="Date" />

      {values.videoUrl !== null && values.videoUrl !== "" ? (
        <Video
          videoUrl={values.videoUrl}
          onClick={() => setFieldValue("videoUrl", "")}
        />
      ) : (
        <InputVideoFile
          onChange={(e: any) => setFieldValue("videoUrl", e.target.files[0])}
          label="Video"
        />
      )}

      <InputPdfFile
        onChange={(e: any) => setFieldValue("pdfUrl", e.target.files[0])}
        label="Pdf"
      />

      <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
    </CenterForm>
  );
};

export default Form;
