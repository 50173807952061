export interface FormValues {
  password: string;
  passwordConfirmation: string;
}

export interface Errors {
  "auth/email-already-in-use": string;
  "auth/invalid-email": string;
  "auth/operation-not-allowed": string;
  "auth/weak-password": string;
}

export type ErrorsCodes =
  | "auth/email-already-in-use"
  | "auth/invalid-email"
  | "auth/operation-not-allowed"
  | "auth/weak-password";

export const errors: Errors = {
  "auth/email-already-in-use": "This email is already use",
  "auth/invalid-email": "This email is invalid",
  "auth/operation-not-allowed": "This email/password accounts are not enabled",
  "auth/weak-password": "The password is not strong enough",
};
