import Input from "global/components/Form/Input";
import TextArea from "global/components/Form/Input/TextArea";

interface Props {
  lng: string;
}

const Fields: React.FC<Props> = ({ lng }) => {
  return (
    <div>
      <Input
        name={`langs.${lng}.name`}
        type="text"
        placeholder="Nom de l'article..."
        label="Name"
      />
      <TextArea
        name={`langs.${lng}.description`}
        type="text"
        placeholder="Description..."
        label="Description"
      />
    </div>
  );
};

export default Fields;
