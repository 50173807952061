import Select from "global/components/Select";
import { Loading } from "global/styles";
import { useInjuriesList } from "./hooks/useInjuriesList";

interface Props {
  onChange: any;
  value: any;
}

const InjuriesListContainer: React.FC<Props> = ({ onChange, value }) => {
  const { injuriesList, loading } = useInjuriesList();

  if (loading) return <Loading spinnerColor="#000" />;

  return (
    <Select
      onChange={onChange}
      value={value}
      options={injuriesList}
      optionsList={[]}
      label="Blessure"
      name="blessure"
    />
  );
};

export default InjuriesListContainer;
