import { useHistory } from "react-router-dom";
import { ProductScheme } from "schemes/product";

interface Props {
  productsList: ProductScheme[];
  label: string;
}

const SuiviSection: React.FC<Props> = ({ productsList, label }) => {
  const history = useHistory();
  return (
    <div>
      <h1 className="white-title">{label}</h1>
      <div className="products">
        {productsList.map((product, index) => (
          <div key={index} className="product">
            <h1 className="white-title">{product.name}</h1>
            <h1 className="white-title">
              {product.subscription
                ? Math.round(
                    (product.firstPrice +
                      product.monthlyPrice * product.monthDuration) /
                      product.monthDuration
                  )
                : product.firstPrice}
              €
            </h1>
            {product.subscription ? <p className="indication">/mois*</p> : null}
            <div className="button-div">
              <button
                className="button"
                onClick={() =>
                  history.push({
                    pathname: "payment-intent",
                    state: {
                      productObject: product,
                    },
                  })
                }
              >
                {!product.session && !product.checkup ? "S'ABONNER" : "PAYER"}
              </button>
            </div>
            {product.subscription ? (
              <p className="asterisk">
                *{product.firstPrice + product.monthlyPrice}€ le premier mois
                puis {product.monthlyPrice}€/mois pendant{" "}
                {product.monthDuration - 1} mois - engagement pendant{" "}
                {product.monthDuration} mois.
              </p>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuiviSection;
