import { MyColors } from "global/styles/colors";
import React, { useState } from "react";
import AddButton from "../../../../global/components/Button/AddButton";
import { Dropdown, DropdownContent, DropdownElement } from "./styles";
import { itemsList } from "./types";

interface Props {
  addItemToSerie: any;
  deleteItemToSerie: any;
  number: number;
}

const AddDropdownButton: React.FC<Props> = ({
  addItemToSerie,
  number,
  deleteItemToSerie,
}) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState<string[]>(["work", "rest"]);

  return (
    <Dropdown>
      <AddButton onClick={() => setShow(!show)} />
      {show ? (
        <DropdownContent>
          {itemsList.map((item) => (
            <DropdownElement
              background={() =>
                list.indexOf(item) !== -1 ? MyColors.secondary : "#fff"
              }
              onClick={() => {
                let indexOf: number = list.indexOf(item);
                if (indexOf === -1) {
                  addItemToSerie(number - 1, item);
                  setList([...list, item]);
                } else {
                  deleteItemToSerie(number - 1, item);
                  list.splice(indexOf, 1);
                }

                setShow(false);
              }}
            >
              <p>{item}</p>
            </DropdownElement>
          ))}
        </DropdownContent>
      ) : null}
    </Dropdown>
  );
};

export default AddDropdownButton;
