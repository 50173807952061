import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";
import { useAddSeanceGoal } from "./hooks/useAddSeanceGoal";
import AddSeanceGoal from "components/AddSeanceGoal";

const AddSeanceGoalContainer = () => {
  const { handleSubmit, loading } = useAddSeanceGoal();
  return (
    <AddSeanceGoal
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default AddSeanceGoalContainer;
