import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const usePositionsList = () => {
  const [positionsList, setpositionsList] = useState<any[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function getData() {
      const positionsSnapshot = await firestore.collection("positions").get();

      const array:
        | ((prevState: never[]) => never[])
        | { label: any; value: string }[] = [];
      positionsSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const label = data.langs.fr.toUpperCase();
        array.push({ label: label, value: doc.id });
      });
      setpositionsList(array);
    }

    getData();
  }, [firestore]);

  return { positionsList };
};
