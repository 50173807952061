import Tag from "global/components/Tag";
import { MyShadows } from "global/styles/box-shadow";
import styled from "styled-components";

const Div = styled.div`
  width: 250px;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: ${MyShadows.primary};
  padding: 20px;
  margin: 20px;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Title = styled.p`
  //width: 250px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
`;

interface Props {
  hit: any;
  onClick: any;
}

const Hit: React.FC<Props> = ({ hit, onClick }) => {
  console.log(hit);
  //(hit.hit.objectID);
  return (
    <Div onClick={() => onClick(hit.hit.objectID)}>
      <Title>{hit.hit.title.toUpperCase()}</Title>

      <div style={{ width: 250 }}>
        <Tag label={hit.hit.category} />
        <Tag label={hit.hit.subCategory} />
      </div>
    </Div>
  );
};

export default Hit;
