import { CenterDiv } from "components/CreateEvent/styles";
import Input from "global/components/Form/Input";

const Form: React.FC = () => {
  return (
    <CenterDiv>
      <Input
        name={`date`}
        type="date"
        placeholder="Date..."
        label="Date de départ en séléction"
      />
      <Input
        name={`country`}
        type="text"
        placeholder="Pays..."
        label="Country"
      />
      <Input
        name={`days`}
        type="number"
        placeholder="Nombre de jours en séléction"
        label="Nbr de jours"
      />
    </CenterDiv>
  );
};

export default Form;
