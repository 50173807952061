import { HeightDiv } from "components/CreateSeance/styles";
import { MyShadows } from "global/styles/box-shadow";
import { BigProfilePic } from "global/styles/img";
import { NormalText } from "global/styles/polices";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Div = styled.div`
  width: 250px;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: ${MyShadows.primary};
  padding: 20px;
  margin: 20px;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Title = styled.p`
  //width: 250px;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  text-transform: uppercase;
`;

const Hit = (props: any) => {
  const history = useHistory();
  return (
    <Div
      onClick={async () => {
        //dispatch(await selectedAthlete(props.hit.objectID));
        history.push({
          pathname: "athlete-planning",
          state: { athleteId: props.hit.objectID },
        });
      }}
    >
      <BigProfilePic img={props.hit.photoUrl} />
      <HeightDiv />
      <Title>
        {props.hit.firstName} {props.hit.lastName}
      </Title>
      <NormalText>{props.hit.subscription}</NormalText>
      <NormalText>{props.hit.appRole}</NormalText>
    </Div>
  );
};

export default Hit;
