const chat = (state = null, action: { type: any; payload: any }) => {
  switch (action.type) {
    case "SELECT_CHAT_ROOM":
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default chat;
