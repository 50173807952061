import React from "react";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";
import AddBalanceSheet from "components/AddBalanceSheet";
import { useAddBalanceSheet } from "./hooks/useAddBalanceSheet";

interface Props {
  athleteId: string;
}

const AddBalanceSheetContainer: React.FC<Props> = ({ athleteId }) => {
  const { handleSubmit, loading } = useAddBalanceSheet(athleteId);
  return (
    <AddBalanceSheet
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default AddBalanceSheetContainer;
