import moment from "moment";
import { EditMyProfileValues } from "./types";

export const initialValues: EditMyProfileValues = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  addressLine: "",
  adressPostalCode: "",
  addressCity: "",
  birthDate: moment().subtract(20, "y").format("YYYY-MM-DD"),
  croppedImage: "",
  blobImage: "",
};
