import ExercisesList from "containers/ExercisesList/List";
import { StyledCol, StyledRow } from "global/styles/flexbox";
import Header from "global/components/Header";
import React from "react";
import { StyledPopup } from "components/ImageCrop/styles";
import FormExerciseContainer from "containers/CreateSeance/FormExercise";
import { useOpen } from "global/hooks/useOpen";
import Exercises from "components/CreateSeance/Exercises";
import { SeanceInEventFormProps } from "containers/CreateEvent/SeanceInEventForm";
import Select from "global/components/Select";

const SeanceInEventForm: React.FC<SeanceInEventFormProps> = ({
  exercises,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  setSelectedExercise,
  selectedExercise,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  values,
  setFieldValue,
  onChangeCommentsExercise,
  themes,
}) => {
  const { open, openOrClose } = useOpen();
  return (
    <div>
      <StyledPopup open={open} closeOnDocumentClick={false}>
        <FormExerciseContainer
          exercise={exercises[selectedExercise]}
          addSerie={addSerie}
          deleteSerie={deleteSerie}
          editSerie={editSerie}
          duplicateSerie={duplicateSerie}
          addItemToSerie={addItemToSerie}
          openOrClose={openOrClose}
          deleteItemToSerie={deleteItemToSerie}
          onChangeCommentsExercise={onChangeCommentsExercise}
        />
      </StyledPopup>
      <StyledRow>
        <StyledCol lg={9}>
          {/* <Header title={"Seance Informations"} /> */}
          {/* <SeanceForm values={values} setFieldValue={setFieldValue} /> */}
          {/* <HeightDiv /> */}
          {themes.length ? (
            <Select
              options={null}
              optionsList={themes}
              value={values.theme}
              onChange={(e: any) => setFieldValue("theme", e.target.value)}
              label="Thème affiché dans le planning"
              name="theme"
            />
          ) : null}

          <Header title={`Seance Exercises (${exercises.length})`} />
          <Exercises
            exercises={exercises}
            deleteExercise={deleteExercise}
            handleDrag={handleDrag}
            setSelectedExercise={setSelectedExercise}
            openOrClose={openOrClose}
          />
        </StyledCol>
        <StyledCol lg={3}>
          <ExercisesList onClick={addExercise} />
        </StyledCol>
      </StyledRow>
    </div>
  );
};

export default SeanceInEventForm;
