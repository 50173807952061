import EditProgramContainer from "containers/AddProgram/EditProgram";
import { GlobalDiv, StyledContainer } from "global/styles";

const EditProgram: React.FC<any> = (props) => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <EditProgramContainer programUid={props.location.state.programUid} />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default EditProgram;
