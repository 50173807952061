import moment from "moment";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { SignUpFormValues } from "../utils/types";

export const useSignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();
  const signUpHandleSubmit = async (values: SignUpFormValues) => {
    setLoading(true);
    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);

      await firestore
        .collection("users")
        .doc(userCredential.user?.uid)
        .set({
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phone,
          birthDate: moment(values.birthDate).toDate(),
          acceptCGU: values.acceptCGU,
          addressLine: values.addressLine,
          addressCity: values.addressCity,
          addressPostalCode: values.addressPostalCode,
          type: values.type,
          group: firestore
            .collection("academies")
            .doc("io4ZMZ1ykN2DiZNCSKCd")
            .collection("groups")
            .doc("AMQLLdbGCIBvLrL8OT2V"),
        });

      await userCredential.user?.sendEmailVerification();

      history.push({ pathname: "sign-up-confirmation" });
    } catch (error: any) {
      //const errorCode = error.code;
      const errorMessage = error.message;
      alert.error(errorMessage);
    }
    setLoading(false);
  };

  return { signUpHandleSubmit, loading };
};
