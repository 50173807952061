import { useField } from "formik";
import "./field.css";

interface Props {
  name: string;
  label: string;
  autoFocus?: boolean;
  type: string;
}

const FieldInput = ({ ...props }: Props) => {
  const [field, meta] = useField(props);
  return (
    <div className="group">
      <input {...field} {...props} required />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label className="form-label">{props.label}</label>
      {meta.error && meta.touched ? (
        <div className="error">{meta.error}</div>
      ) : (
        <div className="no-error">p</div>
      )}
    </div>
  );
};

export default FieldInput;
