import { MyColors } from "global/styles/colors";
import styled from "styled-components";

export const BlocValueDiv = styled.div`
  display: grid;
  justify-content: center;
  margin: 0 20px;
`;

export const TitleBloc = styled.p`
  text-align: center;
  color: ${MyColors.secondary};
`;

export const FormDiv = styled.div`
  padding: 30px;
`;

export const SerieDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
`;

export const ButtonsDiv = styled.div`
  display: flex;
`;

export const AddDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const CancelButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivWidth40 = styled.div`
  width: 40px;
`;
