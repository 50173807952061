import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useAthletesList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const [academy, setAcademy] = useState("io4ZMZ1ykN2DiZNCSKCd");
  const [athletesList, setAthletesList] = useState([]);

  useEffect(() => {
    async function getAtheletesList() {
      setLoading(true);

      const academySnapshot = await firestore
        .collection("academies")
        .doc(academy)
        .get();

      const usersSnapshot = await firestore
        .collection("users")
        .where("academy", "==", academySnapshot.ref)
        .where("accountActivated", "==", true)
        .get();

      const array: any = [];
      usersSnapshot.docs.forEach((user) => {
        const data = user.data();
        array.push({
          key: user.id,
          firstName: data.firstName,
          lastName: data.lastName,
          photoUrl: data.photoUrl,
        });
      });

      setAthletesList(array);
      setLoading(false);
    }

    getAtheletesList();
  }, [firestore, academy]);

  return { academy, setAcademy, athletesList, loading };
};
