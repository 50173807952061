import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import { DocumentReference, DocumentData } from "@firebase/firestore-types";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

import { FormValues } from "../types";

export const useEditExercise = (exerciseRef: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [exercise, setExercise] = useState({});
  const [oldValues, setOldValues] = useState({
    blobImage: "",
    videoUrl: "",
  });
  const firestore = useFirestore();
  const uploadOnStorage = useUploadOnStorage();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      let imageUrl: any = values.blobImage;
      if (values.blobImage !== oldValues.blobImage)
        imageUrl = await uploadOnStorage(values.blobImage, "exercisesImg");

      let videoUrl: any = values.videoUrl;
      if (values.videoUrl !== "" && values.videoUrl !== oldValues.videoUrl)
        videoUrl = await uploadOnStorage(values.videoUrl, "exercisesVideo");

      const keywords: string[] = [];
      values.langs.fr.keywords.map((keyword: any) =>
        keywords.push(keyword.text)
      );

      const themesArray: DocumentReference<DocumentData>[] = [];
      values.themes.forEach((theme) => {
        themesArray.push(firestore.collection("themes").doc(theme.value));
      });

      const muscleGroupsArray: DocumentReference<DocumentData>[] = [];
      values.muscleGroups.forEach((theme) => {
        muscleGroupsArray.push(
          firestore.collection("muscleGroups").doc(theme.value)
        );
      });

      const jointInjuriesArray: DocumentReference<DocumentData>[] = [];
      values.jointInjuries.forEach((theme) => {
        jointInjuriesArray.push(
          firestore.collection("jointInjuries").doc(theme.value)
        );
      });

      const goalsArray: DocumentReference<DocumentData>[] = [];
      values.goals.forEach((theme) => {
        goalsArray.push(firestore.collection("goals").doc(theme.value));
      });

      const equipmentsArray: DocumentReference<DocumentData>[] = [];
      values.equipments.forEach((theme) => {
        equipmentsArray.push(
          firestore.collection("equipments").doc(theme.value)
        );
      });

      const positionsArray: DocumentReference<DocumentData>[] = [];
      values.positions.forEach((theme) => {
        positionsArray.push(firestore.collection("positions").doc(theme.value));
      });

      await firestore
        .collection("exercises")
        .doc(exerciseRef)
        .update({
          imageUrl: imageUrl,
          videoUrl: videoUrl,
          langs: {
            fr: {
              name: values.langs.fr.name,
              description: values.langs.fr.description,
              keywords: keywords,
            },
          },
          themes: themesArray,
          level: firestore.collection("levels").doc(values.level),
          muscleGroups: muscleGroupsArray,
          jointInjuries: jointInjuriesArray,
          goals: goalsArray,
          equipments: equipmentsArray,
          positions: positionsArray,
        });
      alert.success("Exercise edited");
      history.push({ pathname: "exercises-list" });
    } catch (error) {
      alert.error("Error");
    }

    setLoading(false);
  };

  useEffect(() => {
    async function getExercise() {
      setLoading(true);
      const querySnapshot = await firestore
        .collection("exercises")
        .doc(exerciseRef)
        .get();
      const data = querySnapshot.data();

      let createdBy: string = "";
      if (data?.createdBy !== undefined) {
        createdBy = (await data.createdBy.get()).data().firstName;
      } else {
        createdBy = "Tanguy";
      }

      const themesArr: any[] = [];

      await Promise.all(
        data?.themes.map(async (theme: any) => {
          const themeData = (await theme.get()).data();
          themesArr.push({
            label: themeData.name.fr.toUpperCase(),
            value: theme.id,
          });
        })
      );

      const muscleGroupsArr: any[] = [];
      await Promise.all(
        data?.muscleGroups.map(async (muscleGroup: any) => {
          const muscleGroupData = (await muscleGroup.get()).data();
          muscleGroupsArr.push({
            label: muscleGroupData.langs.fr.toUpperCase(),
            value: muscleGroup.id,
          });
        })
      );

      const jointInjuriesArr: any[] = [];
      await Promise.all(
        data?.jointInjuries.map(async (jointInjury: any) => {
          const jointInjuryData = (await jointInjury.get()).data();
          jointInjuriesArr.push({
            label: jointInjuryData.langs.fr.toUpperCase(),
            value: jointInjury.id,
          });
        })
      );

      const equipmentsArr: any[] = [];
      await Promise.all(
        data?.equipments.map(async (equipment: any) => {
          const equipmentData = (await equipment.get()).data();
          equipmentsArr.push({
            label: equipmentData.langs.fr.toUpperCase(),
            value: equipment.id,
          });
        })
      );

      const goalsArr: any[] = [];
      await Promise.all(
        data?.goals.map(async (goal: any) => {
          const goalData = (await goal.get()).data();
          goalsArr.push({
            label: goalData.langs.fr.toUpperCase(),
            value: goal.id,
          });
        })
      );

      const positionsArr: any[] = [];
      await Promise.all(
        data?.positions.map(async (position: any) => {
          const positionData = (await position.get()).data();
          positionsArr.push({
            label: positionData.langs.fr.toUpperCase(),
            value: position.id,
          });
        })
      );

      const exercise = {
        level: (await data?.level.get()).id,
        themes: themesArr,
        muscleGroups: muscleGroupsArr,
        jointInjuries: jointInjuriesArr,
        equipments: equipmentsArr,
        goals: goalsArr,
        positions: positionsArr,
        croppedImage: data?.imageUrl,
        blobImage: data?.imageUrl,
        videoUrl: data?.videoUrl,
        langs: data?.langs,
        createdBy: createdBy,
      };

      setExercise(exercise);
      setOldValues(exercise);
      setLoading(false);
    }
    getExercise();
  }, [exerciseRef, firestore]);

  return { handleSubmit, exercise, loading };
};
