import Axios from "axios";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";

export const useAthletesResponses = (academyId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useAlert();
  const [athletesResponses, setAthleteResponses] = useState([]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const res = await Axios.post(
          `${process.env.REACT_APP_FIREBASE_URL}getResponsesForAcademy`,
          {
            data: {
              academyId: academyId,
            },
          }
        );

        setAthleteResponses(res.data.result);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getData();
  }, [alert, academyId]);

  return { athletesResponses, loading };
};
