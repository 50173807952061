import Axios from "axios";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";

export const useAddReportWorkCycle = (workCycleUid: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currentCycle, setCurrentCycle] = useState<any>(null);
  const [reportUrl, setReportUrl] = useState<string | null>(null);
  const alert = useAlert();
  const uploadOnStorage = useUploadOnStorage();
  const firestore = useFirestore();
  const history = useHistory();
  const athleteId = useAppSelector((state) => state.athlete?.id);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      const chartImages = {
        sleep:
          values.blobImageSleep !== ""
            ? await uploadOnStorage(values.blobImageSleep, "endCycleReport")
            : "",
        stress:
          values.blobImageStress !== ""
            ? await uploadOnStorage(values.blobImageStress, "endCycleReport")
            : "",
        tired:
          values.blobImageTired !== ""
            ? await uploadOnStorage(values.blobImageTired, "endCycleReport")
            : "",
        joy:
          values.blobImageJoy !== ""
            ? await uploadOnStorage(values.blobImageJoy, "endCycleReport")
            : "",
        selfConfidence:
          values.blobImageSelfConfidence !== ""
            ? await uploadOnStorage(
                values.blobImageSelfConfidence,
                "endCycleReport"
              )
            : "",
        concentration:
          values.blobImageConcentration !== ""
            ? await uploadOnStorage(
                values.blobImageConcentration,
                "endCycleReport"
              )
            : "",
        mediumIntensity:
          values.blobImageMediumIntensity !== ""
            ? await uploadOnStorage(
                values.blobImageMediumIntensity,
                "endCycleReport"
              )
            : "",
        highIntensity:
          values.blobImageHighIntensity !== ""
            ? await uploadOnStorage(
                values.blobImageHighIntensity,
                "endCycleReport"
              )
            : "",
        dynamism:
          values.blobImageDynamism !== ""
            ? await uploadOnStorage(values.blobImageDynamism, "endCycleReport")
            : "",
        perceivedDuration:
          values.blobImagePerceivedDuration !== ""
            ? await uploadOnStorage(
                values.blobImagePerceivedDuration,
                "endCycleReport"
              )
            : "",
        condition:
          values.blobImageCondition !== ""
            ? await uploadOnStorage(values.blobImageCondition, "endCycleReport")
            : "",
      };

      const res = await Axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}createEndCycleReport`,
        {
          data: {
            workCycleUid: workCycleUid,
            athleteId: athleteId,
            startDate: startDate,
            endDate: endDate,
            currentCycle: {
              workResume: values.currentWorkResume.toUpperCase(),
              axeP1: values.axeP1.toUpperCase(),
              axeP2: values.axeP2.toUpperCase(),
              axeP3: values.axeP3.toUpperCase(),
              axeP4: values.axeP4.toUpperCase(),
              axeS1: values.axeS1.toUpperCase(),
              axeS2: values.axeS2.toUpperCase(),
              axeS3: values.axeS3.toUpperCase(),
              axeS4: values.axeS4.toUpperCase(),
            },
            nextCycle: values.createNextWorkCycle
              ? {
                  startDate: values.nextStartDate,
                  endDate: values.nextEndDate,
                  workResume: values.nextWorkResume.toUpperCase(),
                  axeP1: values.nextAxeP1.toUpperCase(),
                  axeP2: values.nextAxeP2.toUpperCase(),
                  axeP3: values.nextAxeP3.toUpperCase(),
                  axeP4: values.nextAxeP4.toUpperCase(),
                  axeS1: values.nextAxeS1.toUpperCase(),
                  axeS2: values.nextAxeS2.toUpperCase(),
                  axeS3: values.nextAxeS3.toUpperCase(),
                  axeS4: values.nextAxeS4.toUpperCase(),
                }
              : null,
            conclusion: {
              pains: values.pains.toUpperCase(),
              health: values.health.toUpperCase(),
              mental: values.mental.toUpperCase(),
              athletic: values.athletic.toUpperCase(),
              statistic: values.statistic.toUpperCase(),
              other: values.other.toUpperCase(),
              conclusion: values.conclusion.toUpperCase(),
            },
            healthMarkers: {
              sleepChart: chartImages.sleep,
              stressChart: chartImages.stress,
              tiredChart: chartImages.tired,
              comments: values.healthComments.toUpperCase(),
            },
            mentalMarkers: {
              joyChart: chartImages.joy,
              selfConfidenceChart: chartImages.selfConfidence,
              concentrationChart: chartImages.concentration,
              comments: values.mentalComments.toUpperCase(),
            },
            effortMarkers: {
              mediumIntensityChart: chartImages.mediumIntensity,
              highIntensityChart: chartImages.highIntensity,
              intensityComments: values.intensityComments.toUpperCase(),
              dynamismChart: chartImages.dynamism,
              perceivedDurationChart: chartImages.perceivedDuration,
              conditionChart: chartImages.condition,
              comments: values.effortComments.toUpperCase(),
            },
          },
        }
      );

      if (res.status === 200) {
        alert.success(
          "Le rapport est en cours de création. Il sera prêt d'ici 15 minutes !"
        );

        history.push({
          pathname: "athlete-work-cycles",
          state: { athleteId: athleteId },
        });
      } else {
        alert.error("Une erreur est survenue. Contactez Corentin");
      }
    } catch (error) {
      //console.log(error);
      alert.error("Error");
    }

    setLoading(false);
  };

  useEffect(() => {
    async function getCycle() {
      const workCycle = await firestore
        .collection("workCycles")
        .doc(workCycleUid)
        .get();
      const data = workCycle.data();
      setStartDate(moment(data?.startDate.toDate()).toDate());
      setEndDate(moment(data?.endDate.toDate()).toDate());

      if (data?.currentCycle !== null && data?.currentCycle !== undefined) {
        setCurrentCycle(data?.currentCycle);
      }
      if (data?.url !== null && data?.url !== undefined) {
        setReportUrl(data?.url);
      }
    }

    getCycle();
  }, [firestore, workCycleUid]);

  return { loading, handleSubmit, currentCycle, reportUrl };
};
