import CellDay from "components/AthletePlanning/CellDay";
import { CellsRow } from "components/AthletePlanning/CellDay/styles";
import moment from "moment";
import { CellsContainerProps } from "./types";

const CellsContainer: React.FC<CellsContainerProps> = ({
  currentMonth,
  eventsList,
  deleteEvent,
  athleteId,
  deleteInjury,
}) => {
  const monthStart = moment(currentMonth).startOf("month");
  const monthEnd = moment(monthStart).endOf("month");
  const startDate = moment(monthStart).isoWeekday(1);
  const endDate = moment(monthEnd).endOf("week");

  const dateFormat = "YYYY-MM-DD";
  const rows = [];

  let days = [];
  let day = startDate;

  let x = 0;
  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      const formattedDate = moment(day).format(dateFormat);
      // checks events list
      const events = [];
      for (const key in eventsList) {
        if (eventsList[key].date === formattedDate)
          events.push(eventsList[key]);
      }

      days.push(
        <CellDay
          date={formattedDate}
          key={i}
          events={events}
          deleteEvent={deleteEvent}
          athleteId={athleteId}
          deleteInjury={deleteInjury}
        />
      );
      day = moment(day).add(1, "d");
    }
    rows.push(<CellsRow key={x}>{days}</CellsRow>);
    x++;
    days = [];
  }
  return <div>{rows}</div>;
};

export default CellsContainer;
