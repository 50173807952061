import algoliasearch from "algoliasearch";

export const useAlgoliaSearch = () => {
  const searchClient = algoliasearch(
    "T79WCNV9XB",
    "92e1200554c4fb74650629148467e564"
  );

  return { searchClient };
};
