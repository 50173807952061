import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { BalanceSheet } from "../types";

export const useAthleteBalanceSheets = (athleteId: string) => {
  const [loading, setloading] = useState<boolean>(false);
  const [balanceSheets, setBalanceSheets] = useState<BalanceSheet[]>([]);
  const firestore = useFirestore();
  const alert = useAlert();

  async function getBalanceSheets() {
    setloading(true);
    try {
      const athleteRef = firestore.collection("users").doc(athleteId);

      const balanceSheetsSnapshot = await firestore
        .collection("balanceSheets")
        .where("owner", "==", athleteRef)
        .get();

      const array: BalanceSheet[] = [];
      balanceSheetsSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        array.push({
          id: doc.id,
          date: moment(data?.date.toDate()).format("D MMMM YYYY"),
          name: data?.name,
          type: data?.type,
          pdfUrl: data?.pdfUrl ?? null,
          videoUrl: data?.videoUrl ?? null,
        });
      });

      setBalanceSheets(array);
    } catch (error) {
      //console.log(error);
      alert.error("Error");
    }
    setloading(false);
  }

  async function deleteBalancheSheet(id: string) {
    setloading(true);
    try {
      await firestore.collection("balanceSheets").doc(id).delete();
      await getBalanceSheets();
    } catch (error) {
      alert.error("Error");
    }
    setloading(false);
  }

  useEffect(() => {
    getBalanceSheets();
  }, [alert, athleteId, firestore]);

  return { balanceSheets, loading, deleteBalancheSheet };
};
