import AthleteRawData from "components/AthleteRawData";
import { HeightDiv } from "components/CreateSeance/styles";
import SelectTwoDatesContainer from "containers/SelectTwoDates";
import { useAthleteRawData } from "./hooks/useAthleteRawData";

interface Props {
  athleteId: string;
}

const AthleteRawDataContainer: React.FC<Props> = ({ athleteId }) => {
  const {
    data,
    cptPains,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    reliability,
    loading,
  } = useAthleteRawData(athleteId);

  return (
    <div>
      <SelectTwoDatesContainer
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />

      <HeightDiv />
      <AthleteRawData
        data={data}
        cptPains={cptPains}
        startDate={startDate}
        endDate={endDate}
        reliability={reliability}
        loading={loading}
      />
    </div>
  );
};

export default AthleteRawDataContainer;
