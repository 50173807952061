import { FormValues } from "containers/AddReportWorkCycle/utils/initialValues";

export interface FormikProps {
  initialValues: any;
  handleSubmit: (values: FormValues) => Promise<void>;
  validationSchema: any;
  loading: boolean;
  currentCycle: any;
  reportUrl: string | null;
}

export interface FormProps {
  isValid: boolean;
  dirty: boolean;
  loading: boolean;
  setFieldValue: any;
  values: any;
  currentCycle: any;
  reportUrl: string | null;
}

export const mainAxesArray = [
  {
    name: "axeP1",
    label: "Axe 1",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "axeP2",
    label: "Axe 2",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "axeP3",
    label: "Axe 3",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "axeP4",
    label: "Axe 4",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
];

export const secondaryAxesArray = [
  {
    name: "axeS1",
    label: "Axe 1",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "axeS2",
    label: "Axe 2",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "axeS3",
    label: "Axe 3",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "axeS4",
    label: "Axe 4",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
];

export const nextMainAxesArray = [
  {
    name: "nextAxeP1",
    label: "Axe 1",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "nextAxeP2",
    label: "Axe 2",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "nextAxeP3",
    label: "Axe 3",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
  {
    name: "nextAxeP4",
    label: "Axe 4",
    placeholder: "ÉCHAUFFEMENT CHEVILLE DTE - 2 x semaine",
  },
];

export const nextSecondaryAxesArray = [
  {
    name: "nextAxeS1",
    label: "Axe 1",
    placeholder: "POSTURE 1X PECTORAL ADD IJ Q FESSIERS PSOAS",
  },
  {
    name: "nextAxeS2",
    label: "Axe 2",
    placeholder: "POSTURE 1X PECTORAL ADD IJ Q FESSIERS PSOAS",
  },
  {
    name: "nextAxeS3",
    label: "Axe 3",
    placeholder: "POSTURE 1X PECTORAL ADD IJ Q FESSIERS PSOAS",
  },
  {
    name: "nextAxeS4",
    label: "Axe 4",
    placeholder: "POSTURE 1X PECTORAL ADD IJ Q FESSIERS PSOAS",
  },
];

export const conclusionArray = [
  {
    name: "pains",
    label: "Douleurs",
    placeholder: "Attention",
  },
  {
    name: "health",
    label: "Santé",
    placeholder: "Bien",
  },
  {
    name: "mental",
    label: "Mental",
    placeholder: "Pas bien",
  },
  {
    name: "athletic",
    label: "Athlétique",
    placeholder: "Super",
  },
  {
    name: "statistic",
    label: "Statistiques",
    placeholder: "Pas ouf",
  },
  {
    name: "other",
    label: "Autres",
    placeholder: "T'es chaud frérot",
  },
  {
    name: "conclusion",
    label: "La conclusion",
    placeholder: "Bientôt la LDC",
  },
];
