import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentContainer from "containers/Payment";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const Payment = (props: any) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentContainer productObject={props.location.state.productObject} />
    </Elements>
  );
};

export default Payment;
