import styled from "styled-components";

export const InputDiv = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 40px;
  /* margin-top: -30px; */
`;

export const StyledInputFile = styled.input`
  width: 100%;
`;
