import CreateArticle from "components/CreateArticle";
import { useCreateArticle } from "./hooks/useCreateArticle";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const CreateArticleContainer = () => {
  const {
    handleSubmit,
    loading,
    contents,
    addContent,
    selectTypeofContent,
    editContent,
    deleteContent,
    handleDrag,
  } = useCreateArticle();
  return (
    <CreateArticle
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
      contents={contents}
      addContent={addContent}
      selectTypeofContent={selectTypeofContent}
      editContent={editContent}
      deleteContent={deleteContent}
      handleDrag={handleDrag}
    />
  );
};

export default CreateArticleContainer;
