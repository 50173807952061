import { Td, Tr } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Paper } from "global/styles";
import { LittleProfilePic } from "global/styles/img";
import { Loading } from "styles";

interface Props {
  data: any[];
  loading: boolean;
}

const headItemList = [
  "Date",
  null,
  "Athlete",
  "Type",
  "Localisation",
  "Latéralité",
  "Intensité",
];

const AthletesPains: React.FC<Props> = ({ data, loading }) => {
  console.log(data);
  if (loading) return <Loading />;

  return (
    <Paper>
      <StyledTable>
        <HeadTable headItemList={headItemList} />
        <tbody>
          {data.map((values: any, index: number) => (
            <Tr key={index}>
              <Td>
                {values.stringDate !== null && values.stringDate !== undefined
                  ? values.stringDate
                  : values.date}
              </Td>
              <Td>
                <LittleProfilePic img={values.photoUrl} />
              </Td>
              <Td>{values.name}</Td>
              <Td>{values.pain.type}</Td>
              <Td>{values.pain.location}</Td>
              <Td>{values.pain.laterality}</Td>
              <Td>{values.pain.intensity}</Td>
            </Tr>
          ))}
        </tbody>
      </StyledTable>
    </Paper>
  );
};

export default AthletesPains;
