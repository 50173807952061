import {
  StyledDivTwiceDiv,
  StyledSectionTitle,
  StyledSectionValue,
  StyledTitle,
  StyledTwiceDiv,
  StyledValue,
  Div,
} from "./styles";

interface Props {
  tired: Array<string | number>;
}

const TiredSection: React.FC<Props> = ({ tired }) => {
  return (
    <div>
      <StyledSectionTitle>
        <StyledTitle>Fatigue</StyledTitle>
      </StyledSectionTitle>
      <StyledDivTwiceDiv>
        <Div>
          <StyledTwiceDiv>
            <StyledTitle>Semaine 0</StyledTitle>
          </StyledTwiceDiv>
          <StyledSectionValue>
            <StyledValue>{tired[0]}</StyledValue>
          </StyledSectionValue>
        </Div>
        <Div>
          <StyledTwiceDiv>
            <StyledTitle>Semaine-1</StyledTitle>
          </StyledTwiceDiv>
          <StyledSectionValue>
            <StyledValue>{tired[1]}</StyledValue>
          </StyledSectionValue>
        </Div>
      </StyledDivTwiceDiv>
    </div>
  );
};

export default TiredSection;
