import { useLibrarySubCategoriesList } from "./hooks/useLibrarySubCategoriesList";
import Select from "global/components/Select";

interface Props {
  selected: any;
  setSelected: any;
  category: string;
}

const LibrarySubCategoriesList: React.FC<Props> = ({
  selected,
  setSelected,
  category,
}) => {
  const { list } = useLibrarySubCategoriesList(category);
  return (
    <Select
      options={list}
      value={selected}
      onChange={setSelected}
      optionsList={[]}
      label="Sous Catégorie"
      name="subCategory"
    />
  );
};

export default LibrarySubCategoriesList;
