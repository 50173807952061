import { CenterDiv } from "components/CreateEvent/styles";
import Input from "global/components/Form/Input";
import React from "react";
import styled from "styled-components";
import Days from "./DayCheckbox/Days";

interface Props {
  weeks: any[];
  addDaysFromDay: any;
}

const Div = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 30px;
`;

const DuplicateOnDate: React.FC<Props> = ({ weeks, addDaysFromDay }) => {
  return (
    <Div>
      <CenterDiv>
        <Input
          name="numberOfWeeks"
          type="number"
          placeholder="Number of weeks..."
          label="Number of weeks repeat"
        />
      </CenterDiv>

      <Days addDaysFromDay={addDaysFromDay} />
      {weeks}
    </Div>
  );
};

export default DuplicateOnDate;
