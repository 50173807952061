import Tag from "global/components/Tag";
import { MyShadows } from "global/styles/box-shadow";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Div = styled.div`
  width: 250px;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: ${MyShadows.primary};
  padding: 20px;
  margin: 20px;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Title = styled.p`
  //width: 250px;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
`;

const Hit = (props: any) => {
  const history = useHistory();
  return (
    <Div
      onClick={() =>
        history.push({
          pathname: "edit-program",
          state: { programUid: props.hit.objectID },
        })
      }
    >
      <Title>{props.hit.langs.fr.name.toUpperCase()}</Title>

      <div style={{ width: 250 }}>
        {props.hit.goals !== undefined
          ? props.hit.goals.map((goal: string) => <Tag label={goal} />)
          : null}
        {props.hit.equipments !== undefined
          ? props.hit.equipments.map((goal: string) => <Tag label={goal} />)
          : null}
        {props.hit.level !== undefined ? (
          <Tag label={`Niveau ${props.hit.level}`} />
        ) : null}
        <Tag label={`${props.hit.days} jours`} />
        {props.hit.free ? <Tag label="Free" /> : null}
        {props.hit.active ? <Tag label="Actif" /> : <Tag label="Inactif" />}
      </div>
    </Div>
  );
};

export default Hit;
