import styled from "styled-components";

export const Div = styled.div`
  margin-bottom: 18px;
`;

export const IconDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
