import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";

export const useDeleteCard = (cardId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();
  const deleteCard = async () => {
    setLoading(true);
    try {
      await firestore.collection("cards").doc(cardId).delete();
      alert.success("card deleted");
      history.push({
        pathname: "athlete-cards",
        state: { athleteId: athleteId },
      });
    } catch (error) {
      //console.log(error);
      alert.error("error");
    }
    setLoading(false);
  };

  return { deleteCard, loading };
};
