import { Input, MenuItem, Select } from "@material-ui/core";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  work: Work["work"];
  editSerie: any;
  index: number;
}

const WorkValue: React.FC<Props> = ({ work, editSerie, index }) => {
  return (
    <BlocValueDiv>
      <TitleBloc>WORK</TitleBloc>
      <Select
        name="type"
        value={work?.type}
        onChange={(e) => editSerie(e, index, "work")}
      >
        <MenuItem value={"rep"}>Reps</MenuItem>
      </Select>
      <Input
        type="number"
        name="value"
        value={work?.value}
        onChange={(e) => editSerie(e, index, "work")}
      />
    </BlocValueDiv>
  );
};

export default WorkValue;
