import { _activityHourToDate } from "global/functions/activityHourToDate";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";

import { Content, FormValues } from "../utils/initialValues";

export const useEditArticle = (articleUid: string) => {
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const uploadOnStorage = useUploadOnStorage();
  const alert = useAlert();
  const history = useHistory();
  const [contents, setcontents] = useState<Content[]>([]);
  const [article, setarticle] = useState<any>({});
  const [oldValues, setOldValues] = useState({
    blobImage: "",
  });

  const addContent = () => {
    setcontents([...contents, { type: "text", value: "", url: "" }]);
  };

  function selectTypeofContent(index: number, type: string) {
    const newContents = contents;

    newContents[index].type = type;

    const items = reorder(newContents, index, index);

    console.log(items);

    setcontents(items);
  }

  const handleDrag = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      contents,
      result.source.index,
      result.destination.index
    );

    setcontents(items);
  };

  const editContent = async (e: any, index: number, type?: string) => {
    const newContents = contents;

    switch (newContents[index].type) {
      case "text":
      case "title":
      case "youtube":
        newContents[index].value = e.target.value;
        break;
      case "source":
        console.log(type);
        if (type === "value") {
          newContents[index].value = e.target.value;
        } else {
          newContents[index].url = e.target.value;
        }
        break;
      case "video":
        const videoUrl = await uploadOnStorage(
          e.target.files[0],
          "articlesVideo"
        );
        newContents[index].value = videoUrl;
        break;
      case "img":
        const imageUrl = await uploadOnStorage(
          e.target.files[0],
          "articlesImg"
        );
        newContents[index].value = imageUrl;
        break;
      default:
        break;
    }

    const items = reorder(newContents, index, index);

    console.log(items);

    setcontents(items);
  };

  const deleteContent = (index: any) => {
    const newContents = contents;

    newContents.splice(index, 1);
    console.log(newContents);

    const items = reorder(newContents, index, index);
    console.log(items);
    setcontents(items);
  };

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      let imageUrl: any = values.blobImage;
      if (values.blobImage !== oldValues.blobImage)
        imageUrl = await uploadOnStorage(values.blobImage, "articlesImg");

      const keywords: string[] = [];
      values.langs.fr.keywords.map((keyword: any) =>
        keywords.push(keyword.text)
      );

      await firestore
        .collection("articles")
        .doc(articleUid)
        .update({
          langs: {
            fr: {
              name: values.langs.fr.name,
              description: values.langs.fr.description,
              keywords: keywords,
            },
          },
          category: firestore
            .collection("libraryCategories")
            .doc(values.category),
          subCategory: firestore
            .collection("libraryCategories")
            .doc(values.category)
            .collection("subCategories")
            .doc(values.subCategory),
          content: contents,

          img: imageUrl,
          isNewsFeed: values.isNewsFeed,

          time: values.time,
          date: _activityHourToDate(values.date, "00:00"),

          createdAt: new Date(),
          createdBy: firestore.collection("users").doc(uid),
        });
      alert.success("article edited");
      history.push({ pathname: "articles-list" });
    } catch (error) {
      alert.error("Error");
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    async function getArticle() {
      setLoading(true);
      const querySnapshot = await firestore
        .collection("articles")
        .doc(articleUid)
        .get();
      const data = querySnapshot.data();

      const article = {
        category: (await data?.category.get()).id,
        subCategory: (await data?.subCategory.get()).id,

        date: data?.date,

        langs: data?.langs,

        time: data?.time,
        isNewsFeed: data?.isNewsFeed,

        croppedImage: data?.img,
        blobImage: data?.img,
      };

      setarticle(article);
      setOldValues(article);
      setcontents(data?.content);
      setLoading(false);
    }
    getArticle();
  }, [articleUid, firestore]);

  return {
    handleSubmit,
    loading,
    contents,
    addContent,
    selectTypeofContent,
    editContent,
    deleteContent,
    handleDrag,
    article,
  };
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  if (!list.length) return [];

  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  if (removed !== undefined) result.splice(endIndex, 0, removed);

  return result;
};
