import { SelectTwoDatesProps } from "containers/SelectTwoDates";
import { StyledContainer } from "global/styles";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WidthDiv = styled.div`
  width: 20px;
`;

const SelectTwoDates: React.FC<SelectTwoDatesProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <StyledContainer>
      <Div>
        <input type="date" value={startDate} onChange={setStartDate} />
        <WidthDiv />
        <input type="date" value={endDate} onChange={setEndDate} />
        <WidthDiv />
      </Div>
    </StyledContainer>
  );
};

export default SelectTwoDates;
