import AddAthleteContainer from "containers/AddAthlete";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const AddAthlete: React.FC<any> = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <AddAthleteContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default AddAthlete;
