import { DivBeetweenButton } from "components/CreateSeance/Exercises/styles";
import AddButton from "global/components/Button/AddButton";
import { useState } from "react";
import { InfoDiv } from "./styles";
import moment from "moment";
import DuplicateOnDate from "containers/DuplicateOnDate";

interface Props {
  dates: string[];
  setDates: any;
  values: any;
}

const DatesChoice: React.FC<Props> = ({ dates, setDates, values }) => {
  const [add, setAdd] = useState(false);

  return (
    <div>
      <InfoDiv>
        <p>{`${dates.length} selected date${
          dates.length > 1 ? "s" : ""
        }. Duplicate this event ? Click on the add button.`}</p>
        <DivBeetweenButton />
        <AddButton onClick={() => setAdd(true)} />
      </InfoDiv>

      {dates.map((date) => (
        <p>{moment(date).format("Do MMM YYYY")}</p>
      ))}

      {add ? (
        <DuplicateOnDate dates={dates} setDates={setDates} values={values} />
      ) : null}
    </div>
  );
};

export default DatesChoice;
