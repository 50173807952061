import SilverSubscriptionContainerWithTrail from "containers/SilverSubscription";

const SignUpSubscriptionChoiceWithTrail = (props: any) => {
  return (
    <SilverSubscriptionContainerWithTrail
      id={props.match.params.uid}
      email={props.match.params.email}
    />
  );
};

export default SignUpSubscriptionChoiceWithTrail;
