import { CardScheme } from "schemes/card";
import { Div, Title } from "./styles";

interface Props {
  card: CardScheme;
}

function getName(type: string) {
  if (type === "rehab") return "Réhabilitation";
  if (type === "care") return "Soins";

  return "Séance";
}

const GlobalSection: React.FC<Props> = ({ card }) => {
  return (
    <Div>
      {card.document !== null ? (
        <a href={card.document} target="_blank" rel="noreferrer">
          <button className="button">Télécharger le document associé</button>
        </a>
      ) : null}
      <Title>Fiche {getName(card.type)}</Title>
      <p>{card.date}</p>
      <p>{card.duration} min</p>
    </Div>
  );
};

export default GlobalSection;
