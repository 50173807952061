import React from "react";
import "./styles/index.css";
import { WithContext as ReactTags } from "react-tag-input";
import { KeywordsInputProps } from "./types";
import { LabelField } from "../Input/styles";

const KeywordsInput: React.FC<KeywordsInputProps> = ({
  keywords,
  setFieldValue,
  lng,
}) => {
  function handleDelete(i: number) {
    setFieldValue(
      `langs[${lng}].keywords`,
      keywords.filter((keyword: any, index: number) => index !== i)
    );
  }

  function handleAddition(tag: any) {
    setFieldValue(`langs[${lng}].keywords`, [
      ...keywords,
      { id: keywords.length + 1, text: tag },
    ]);
  }
  return (
    <div>
      <LabelField>Keywords</LabelField>
      <ReactTags
        tags={keywords}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        placeholder="Ajoutez un mot-clé"
      />
    </div>
  );
};

export default KeywordsInput;
