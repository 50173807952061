import { Hits, SearchBox } from "react-instantsearch-dom";
import Hit from "./Hit";

const ToposList: React.FC = () => {
  return (
    <div>
      <SearchBox />
      <Hits hitComponent={Hit} />
    </div>
  );
};

export default ToposList;
