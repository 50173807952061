import AddAthlete from "components/AddAthlete";
import { useAddAthlete } from "./hooks/useAddAthlete";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const AddAthleteContainer = () => {
  const { handleSubmit, loading } = useAddAthlete();
  return (
    <>
      <AddAthlete
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        loading={loading}
      />
    </>
  );
};

export default AddAthleteContainer;
