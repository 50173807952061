import Card from "components/Card";
import { useCard } from "./hooks/useCard";

interface Props {
  cardId: string;
}

const CardContainer: React.FC<Props> = ({ cardId }) => {
  const { card, loading } = useCard(cardId);
  return <Card card={card} loading={loading} />;
};

export default CardContainer;
