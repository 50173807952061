import { Loading } from "global/styles";
import moment from "moment";
import "./silver-subscription.css";

interface Props {
  onClick: any;
  loading: boolean;
  products: any[];
  productselected: any;
  selectProduct: any;
  trial?: boolean;
}

const Pro: React.FC<Props> = ({
  onClick,
  loading,
  products,
  productselected,
  selectProduct,
  trial,
}) => {
  if (productselected === null) return <Loading />;

  return (
    <div className="signup">
      <div className="div-signup-registration">
        <div className="logo-container">
          <img src="/assets/young_generate.png" alt="logo-enjeux" />
        </div>
        {/* <span>Étape 2 sur 3</span> */}

        <h1 className="white-title" style={{ fontSize: 32 }}>
          {Math.round(productselected.price * 100) / 100}€/mois
        </h1>

        <h2 style={{ fontSize: 20, fontWeight: "normal" }}>
          Entraîne toi comme les pros avec ton propre responsable de la
          performance et de la santé ! 🏋🏻‍♂️
        </h2>
        <h2 style={{ fontSize: 16, fontWeight: "normal" }}>
          1. Nous te contactons pour définir un rdv avec toi
        </h2>
        <h2 style={{ fontSize: 16, fontWeight: "normal" }}>
          2. Tu fais un bilan en visio avec ton responsable de la performance
        </h2>
        <h2 style={{ fontSize: 16, fontWeight: "normal" }}>
          3. Il te génère un programme sur mesure grâce à ton bilan
        </h2>
        <h2 style={{ fontSize: 16, fontWeight: "normal" }}>
          4. Tu t'entraîne comme un pro avec ton programme et tu peux poser les
          questions que tu souhaites
        </h2>

        <button className="button" onClick={onClick} disabled={loading}>
          {loading ? (
            <div>
              <Loading /> Le chargement peut prendre du temps...
            </div>
          ) : (
            "Je commence mon abonnement"
          )}
        </button>
        <p style={{ fontSize: 12 }}>*Sans engagement</p>
      </div>
    </div>
  );
};

export default Pro;
