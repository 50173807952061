import { _activityHourToDate } from "global/functions/activityHourToDate";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import moment from "moment";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";

export const useAddCard = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();
  const uploadOnStorage = useUploadOnStorage();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      let document: any = null;
      if (values.document !== "")
        document = await uploadOnStorage(values.document, "cards");

      await firestore.collection("cards").add({
        createdBy: firestore.collection("users").doc(uid),
        createdAt: new Date(),
        athlete: firestore.collection("users").doc(athleteId),
        date: _activityHourToDate(values.date, values.hour),

        type: values.type,
        duration: values.duration,
        comments: values.comments,

        //care
        why: values.type === "care" ? values.why : null,
        estimatedStop: values.type === "care" ? values.estimatedStop : null,
        drugs: values.type === "care" ? values.drugs : null,
        appointments: values.type === "care" ? values.appointments : null,
        workingAxes: values.type === "care" ? values.workingAxes : null,
        physiotherapy: values.type === "care" ? values.physiotherapy : null,
        tools: values.type === "care" ? values.tools : null,
        technical: values.type === "care" ? values.technical : null,

        //rehab & seance
        injury:
          values.type === "rehab"
            ? firestore.collection("injuries").doc(values.injury)
            : null,
        daysAfterInjury:
          values.type === "rehab" ? values.daysAfterInjury : null,
        seance:
          values.type === "care"
            ? null
            : values.seance !== ""
            ? firestore.collection("events").doc(values.seance)
            : null,
        organization: values.type === "care" ? null : values.organization,
        document: document,
      });

      alert.success(`${values.type} card created`);

      history.push({
        pathname: "athlete-cards",
        state: {
          athleteId: athleteId,
        },
      });
    } catch (error) {
      //console.log(error);
      alert.error("Error");
    }

    setLoading(false);
  };

  const selectInjury = async (injuryId: string, setFieldValue: any) => {
    if (injuryId !== "") {
      const injuryDocumentSnapshot = await firestore
        .collection("injuries")
        .doc(injuryId)
        .get();

      const data = injuryDocumentSnapshot.data();

      const difference =
        moment().toDate().getTime() -
        moment(data?.date.toDate()).toDate().getTime();
      const days = Math.ceil(difference / (1000 * 3600 * 24));

      setFieldValue("injury", injuryId);
      setFieldValue("daysAfterInjury", days);
    }
  };

  const selectSeance = async (seanceId: string, setFieldValue: any) => {
    setFieldValue("seance", seanceId);
  };

  return { handleSubmit, loading, selectInjury, selectSeance };
};
