import * as Yup from "yup";

export const validationSchema = Yup.object({
  programsCategories: Yup.array().min(
    1,
    "Une Catégorie de programme obligatoire"
  ),
  goals: Yup.array().min(1, "Un objectif obligatoire"),
  langs: Yup.object()
    .shape({
      fr: Yup.object().shape({
        name: Yup.string().required("Required"),
      }),
    })

    .required("Required"),
});
