import { useState } from "react";

export const useOpen = () => {
  const [open, setOpen] = useState(false);

  const openOrClose = () => {
    setOpen(!open);
  };

  return { open, openOrClose };
};
