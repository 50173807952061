import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { Exercise, Work } from "schemes/exercise";

export const useEditExercises = (seanceRef: string) => {
  const [exercises, setExercises] = useState<any[]>([]);
  const [selectedExercise, setSelectedExercise] = useState(0); // index of selectedExercise
  const alert = useAlert();
  const firestore = useFirestore();

  const deleteExercise = (i: any) => {
    setExercises(exercises.filter((exercise, index) => index !== i));
  };

  const addExercise = (newExercise: Exercise) => {
    let isInTable: boolean = false;
    exercises.forEach((exercise: Exercise) => {
      if (newExercise.key === exercise.key) isInTable = true;
    });
    if (!isInTable) {
      newExercise.comments = "";
      newExercise.works = [];
      setExercises([...exercises, newExercise]);
    } else {
      alert.error("Exercise already in the list");
    }
  };

  function onChangeCommentsExercise(comments: string) {
    const newExercises = exercises;

    newExercises[selectedExercise].comments = comments;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  }

  const handleDrag = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      exercises,
      result.source.index,
      result.destination.index
    );

    setExercises(items);
  };

  const addSerie = (key: string) => {
    if (exercises[selectedExercise].works?.length > 5) {
      alert.error("6 series maximum");
      return;
    }

    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;
    newWorks.push({
      work: { type: "rep", value: 10 },
      rest: { type: "passive", time: "second", value: 60 },
    });
    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const deleteSerie = (indexOfWork: any) => {
    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;

    newWorks.splice(indexOfWork, 1);

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);
    setExercises(items);
  };

  const editSerie = (e: any, index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    switch (item) {
      case "charge":
      case "work":
      case "rest":
      case "time":
        console.log(e.target.name);
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]:
              e.target.name === "type" || e.target.name === "time"
                ? e.target.value
                : parseInt(e.target.value),
          },
        };
        break;
      case "goalCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]: e.target.value,
          },
        };
        break;
      case "speedCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]:
              e.target.name === "type" || e.target.name === "time"
                ? e.target.value
                : parseFloat(e.target.value),
          },
        };
        break;
      case "series":
        newWorks[index] = {
          ...newWorks[index],
          [item]: parseInt(e.target.value),
        };
        break;
      case "tempo":
      case "laterality":
      case "typeCardio":
      case "distanceCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: e.target.value,
        };
        break;
      default:
        break;
    }

    newExercises[selectedExercise].works = newWorks;
    console.log(newWorks);

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    console.log(items);

    setExercises(items);
  };

  const duplicateSerie = (work: Work) => {
    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;

    newWorks.push(work);

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const addItemToSerie = (index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    switch (item) {
      case "work":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "rep",
            value: 10,
          },
        };
        break;
      case "time":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "second",
            value: 60,
          },
        };
        break;
      case "rest":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "passive",
            time: "second",
            value: 60,
          },
        };
        break;
      case "charge":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "kg",
            value: 10,
          },
        };
        break;
      case "speedCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "kmh",
            value: 16,
          },
        };
        break;
      case "goalCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "%fcMax",
            value: "70-80",
          },
        };
        break;
      case "tempo":
        newWorks[index] = {
          ...newWorks[index],
          tempo: "4/2/1/1",
        };
        break;
      case "laterality":
        newWorks[index] = {
          ...newWorks[index],
          laterality: "twice",
        };
        break;
      case "typeCardio":
        newWorks[index] = {
          ...newWorks[index],
          typeCardio: "training",
        };
        break;
      case "distanceCardio":
        newWorks[index] = {
          ...newWorks[index],
          distanceCardio: "5km",
        };
        break;
      case "series":
        newWorks[index] = {
          ...newWorks[index],
          series: 4,
        };
        break;
      default:
        break;
    }

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const deleteItemToSerie = (index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    delete newWorks[index][item];

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  useEffect(() => {
    async function getExercisesSeance() {
      try {
        const querySnapshot = await firestore
          .collection("seances")
          .doc(seanceRef)
          .get();

        const newEx: Exercise[] = [];
        await Promise.all(
          querySnapshot.data()?.exercises.map(async (exercise: any) => {
            let exerciseId = await (await exercise.exercise.get()).id;
            let exerciseData = await (await exercise.exercise.get()).data();
            if (exerciseId !== "undefined")
              // exercise.works.forEach((work: any) => {
              //   // if (work.goalCardio) {
              //   //   work.goalCardio.type = "%fcMax";
              //   //   work.goalCardio.value = work.goalCardio.value.toString();
              //   // }
              //   // if (work.rest) work.rest.time = "second";
              // });
              newEx.push({
                key: exerciseId,
                data: {
                  imageUrl: exerciseData.imageUrl,
                  name: exerciseData.langs.fr.name,
                },
                works: exercise.works,
                comments: exercise.comments,
              });
            //console.log(newEx);
          })
        );

        setExercises(newEx);
      } catch (error) {
        //console.log(error);
      }
    }

    getExercisesSeance();
  }, [firestore, seanceRef]);

  return {
    exercises,
    setSelectedExercise,
    selectedExercise,
    addExercise,
    deleteExercise,
    handleDrag,
    addSerie,
    deleteSerie,
    editSerie,
    duplicateSerie,
    addItemToSerie,
    deleteItemToSerie,
    onChangeCommentsExercise,
  };
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
