import React from "react";
import { BeautifulTr, BeautifulTd } from "./styles";
import { Props } from "./types";

const HeadTable: React.FC<Props> = ({ headItemList }) => {
  return (
    <thead>
      <BeautifulTr>
        {headItemList.map((label: string | null, index: number) => (
          <BeautifulTd key={index}>{label}</BeautifulTd>
        ))}
      </BeautifulTr>
    </thead>
  );
};

export default React.memo(HeadTable);
