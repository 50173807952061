import { FormValues } from "containers/CreateEvent/utils/initialValues";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { Exercise } from "schemes/exercise";

export const useStepsDuplicateEvent = (
  values: FormValues,
  setFieldValue: any,
  setExercises: any,
  eventId: string
) => {
  const [step, setStep] = useState(2);
  const alert = useAlert();
  const firestore = useFirestore();

  useEffect(() => {
    async function getEventValues() {
      const querySnapshot = await firestore
        .collection("events")
        .doc(eventId)
        .get();

      const data = querySnapshot.data();

      if (data?.seance) {
        setFieldValue("subName", data?.seance.langs.fr.name);
        setFieldValue("seanceName", data?.seance.langs.fr.name);
        setFieldValue("seanceDescription", data?.seance.langs.fr.description);
        setFieldValue("type", "seance");
      } else {
        setStep(3);
        setFieldValue("subName", data?.subName);
        switch (data?.name) {
          case "MATCH":
            setFieldValue("type", "match");
            break;
          case "TRAINING":
            setFieldValue("type", "training");
            break;
          case "OTHER":
            setFieldValue("type", "other");
            break;
          default:
            break;
        }
      }

      setFieldValue("duration", data?.duration);
      setFieldValue("theme", data?.theme.id);

      setFieldValue("comments", data?.comments);
      setFieldValue("hour", moment(data?.date.toDate()).format("HH:mm"));
      setFieldValue("date", moment(data?.date.toDate()).format("YYYY-MM-DD"));
    }

    getEventValues();
  }, [eventId, firestore, setFieldValue]);

  const nextStepFromSeanceChoice = async (seanceRef: string) => {
    const querySnapshot = await firestore
      .collection("seances")
      .doc(seanceRef)
      .get();

    const data = querySnapshot.data();

    setFieldValue("subName", data?.langs.fr.name);
    setFieldValue("seanceName", data?.langs.fr.name);
    setFieldValue("seanceDescription", data?.langs.fr.description);
    setFieldValue("duration", data?.estimatedDuration);
    setFieldValue("theme", data?.theme.id);

    const newEx: Exercise[] = [];
    await Promise.all(
      data?.exercises.map(async (exerciseObject: any) => {
        const exerciseSnapshot = await firestore
          .collection("exercises")
          .doc(exerciseObject.exercise.id)
          .get();
        let exerciseData = await exerciseSnapshot.data();

        exerciseObject.works.forEach((work: any) => {
          if (work.goalCardio) {
            work.goalCardio.type = "%fcMax";
            work.goalCardio.value = work.goalCardio.value.toString();
          }
          if (work.rest) work.rest.time = "second";
        });

        newEx.push({
          key: exerciseObject.exercise.id,
          data: {
            imageUrl: exerciseData?.imageUrl,
            name: exerciseData?.langs.fr.name,
          },
          works: exerciseObject.works,
          comments: exerciseObject.comments,
        });
      })
    );

    setExercises(newEx);

    setStep(step + 1);
  };

  const nextStep = () => {
    switch (step) {
      case 0:
        //(values.type);
        if (values.type === "seance") {
          setStep(1);
        } else {
          if (values.type === "match") {
            setFieldValue("subName", "MATCH");
          }
          if (values.type === "training") {
            setFieldValue("subName", "TRAINING");
          }
          setStep(3);
        }
        break;
      case 3:
        if (values.subName === "") {
          alert.error("name required");
          return;
        }
        setStep(4);
        break;
      default:
        setStep(step + 1);
        break;
    }
  };

  const prevStep = () => {
    switch (step) {
      case 3:
        if (values.type !== "seance") {
          setStep(0);
        } else {
          setStep(step - 1);
        }

        break;

      default:
        setStep(step - 1);
        break;
    }
  };

  return { step, nextStep, prevStep, nextStepFromSeanceChoice };
};
