import { Input, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  rest: Work["rest"];
  editSerie: any;
  index: number;
}

const Rest: React.FC<Props> = ({ rest, editSerie, index }) => {
  //console.log(rest);
  return (
    <BlocValueDiv>
      <TitleBloc>REST</TitleBloc>
      <Select
        name="type"
        value={rest?.type}
        onChange={(e) => editSerie(e, index, "rest")}
      >
        <MenuItem value={"passive"}>Passive</MenuItem>
        <MenuItem value={"active"}>Active</MenuItem>
      </Select>
      <Select
        name="time"
        value={rest?.time}
        onChange={(e) => editSerie(e, index, "rest")}
      >
        <MenuItem value={"hour"}>Hours</MenuItem>
        <MenuItem value={"minute"}>Minutes</MenuItem>
        <MenuItem value={"second"}>Secondes</MenuItem>
      </Select>
      <Input
        type="number"
        name="value"
        value={rest?.value}
        onChange={(e) => editSerie(e, index, "rest")}
      />
    </BlocValueDiv>
  );
};

export default Rest;
