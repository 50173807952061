import { MyColors } from "global/styles/colors";
import styled from "styled-components";

export const HeaderDiv = styled.div`
  width: 100%;
  height: 42px;
  background-color: ${MyColors.secondary};
  margin-bottom: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.p`
  color: #fff;
  font-size: 16px;
  padding-left: 20px;
`;
