import { Td, Tr } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Paper } from "global/styles";
import { LittleProfilePic } from "global/styles/img";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { Loading } from "styles";

interface Props {
  loading: boolean;
  unreadMessages: any[];
}

const headItemList = ["", "Nom", "Message", "Quand"];

const UnreadMessages: React.FC<Props> = ({ loading, unreadMessages }) => {
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const history = useHistory();
  if (loading) return <Loading spinnerColor="#FFF" />;
  if (!unreadMessages.length)
    return <p style={{ color: "white" }}>Aucun message non lu</p>;
  return (
    <div>
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {unreadMessages.map((values: any, index: number) => (
              <Tr
                key={index}
                onClick={() =>
                  history.push({
                    pathname: "athlete-chat",
                    state: { athleteId: athleteId },
                  })
                }
              >
                <Td>
                  <LittleProfilePic img={values.photoUrl} />
                </Td>
                <Td>
                  {values.firstName} {values.lastName}
                </Td>
                <Td>{values.content}</Td>
                <Td>{values.date}</Td>
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default UnreadMessages;
