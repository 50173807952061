import styled from "styled-components";

export const StyledSelect = styled.select`
  width: 100%;
  padding: 0 20px;
  height: 40px;
  border: 0px;
  margin: 0px 30px 18px 0px;

  border-radius: 40px;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
  font-family: "Mulish";
  color: #b6b6b6;

  ::after {
    position: absolute;
    right: 10px;
  }
`;

export const StyledOption = styled.option`
  padding: 10px;
  font-family: "Bebas Neue";
`;
