import { Form } from "formik";
import FieldInput from "global/components/Form/Field";

const FormComponent = () => {
  return (
    <Form style={{ marginTop: 50 }}>
      <FieldInput type="email" label="Email" name="email" autoFocus />
      <FieldInput type="password" label="Mot de passe" name="password" />
      <p style={{ fontSize: 12 }}>
        En m'inscrivant, j'accepte les{" "}
        <a href="https://enjeux-football.com/terms-of-services" target="blank">
          conditions d'utilisations
        </a>{" "}
        et la{" "}
        <a href="https://enjeux-football.com/privacy" target="blank">
          politique de confidentialité
        </a>{" "}
        de Digital Enjeux Sport
      </p>
      <button className="button">Suivant</button>
    </Form>
  );
};

export default FormComponent;
