export const MyColors = {
  primary: "rgba(5, 120, 149, 1)",
  secondary: "#71e0f1",
};

export const MyGradients = {
  primary:
    "linear-gradient(180deg,rgba(5, 120, 149, 1) 0%,rgba(0, 78, 104, 1) 100%);",
  secondary:
    "linear-gradient(180deg,rgba(224, 224, 224, 1) 0%,rgba(255, 255, 255, 1) 100%);",
  red: "linear-gradient(180deg, rgba(253,29,29,1) 0%, rgba(207,55,0,1) 100%)",
};
