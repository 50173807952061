import FaChevronLeft from "global/components/Icons/FaChevronLeft";
import FaChevronRight from "global/components/Icons/FaChevronRight";
import moment from "moment";
import { DivMonth, TextMonth } from "./styles";

interface Props {
  currentMonth: Date;
  prevMonth: any;
  nextMonth: any;
}

const Month: React.FC<Props> = ({ currentMonth, prevMonth, nextMonth }) => {
  const dateFormat = "MMMM YYYY";

  return (
    <DivMonth>
      <div onClick={prevMonth}>
        <FaChevronLeft />
      </div>
      <TextMonth>{moment(currentMonth).format(dateFormat)}</TextMonth>
      <div onClick={nextMonth}>
        <FaChevronRight />
      </div>
    </DivMonth>
  );
};

export default Month;
