import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useGoalsList = (themes: any[]) => {
  const [goalsList, setgoalsList] = useState<any[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function getData() {
      const array:
        | ((prevState: never[]) => never[])
        | { label: any; value: string }[] = [];

      await Promise.all(
        themes.map(async (theme: any) => {
          const goalsSnapshot = await firestore
            .collection("themes")
            .doc(theme.value)
            .collection("goals")
            .get();

          goalsSnapshot.docs.forEach((doc) => {
            const data = doc.data();
            const label = data.langs.fr.toUpperCase();
            array.push({ label: label, value: doc.ref.path });
          });
        })
      );

      setgoalsList(array);
    }

    getData();
  }, [firestore, themes]);

  return { goalsList };
};
