const Help = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ color: "#FFF" }}>
        Pour tout problème avec l'application, veuillez contacter
        contact@enjeux-football.com
      </p>
    </div>
  );
};

export default Help;
