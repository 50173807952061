import AddNationalSelection from "components/AddNationalSelection";
import { useAddNationalSelection } from "./hooks/useAddNationalSelection";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const AddNationalSelectionContainer = () => {
  const { handleSubmit, loading } = useAddNationalSelection();
  return (
    <AddNationalSelection
      handleSubmit={handleSubmit}
      loading={loading}
      initialValues={initialValues}
      validationSchema={validationSchema}
    />
  );
};

export default AddNationalSelectionContainer;
