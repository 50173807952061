import { MyShadows } from "global/styles/box-shadow";
import { MyColors } from "global/styles/colors";
import styled from "styled-components";

export const ColorCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 90px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivCircle = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0;
`;

export const DivIcons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${MyColors.secondary};
  padding: 20px 0;
  margin-bottom: 30px;
  border-radius: 20px;
`;

export const StyledContainer = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  min-height: 270px;
  min-width: 100px;
  margin: 10px;

  text-align: center;
`;

export const StyledValue = styled.p`
  color: #fff;
`;

export const StyledIcon = styled.img`
  height: 50px;
`;

export const StyledTextIcon = styled.p`
  color: #000;
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
`;

export const AddButtonDiv = styled.div`
  text-align: center;
  margin-top: 40px;
`;
