import {
  StyledItem,
  Div,
  Title,
} from "global/components/RefinementList/styles";
import { connectRefinementList } from "react-instantsearch-dom";

const RefinementList = ({ items, refine, createURL }) => (
  <Div>
    <Title>Offre</Title>
    <ul>
      {items.map((item) => (
        <li key={item.label}>
          <StyledItem
            href={createURL(item.value)}
            isRefined={item.isRefined}
            onClick={(event) => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {item.label} ({item.count})
          </StyledItem>
        </li>
      ))}
    </ul>
  </Div>
);

const AcademyRefinementList = connectRefinementList(RefinementList);

export default AcademyRefinementList;
