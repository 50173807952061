import {
  StyledSectionTitle,
  StyledSectionValue,
  StyledTitle,
  StyledValue,
} from "./styles";

interface Props {
  label: string;
  value: number;
}

const CptSection: React.FC<Props> = ({ label, value }) => {
  return (
    <div>
      <StyledSectionTitle>
        <StyledTitle>{label}</StyledTitle>
      </StyledSectionTitle>
      <StyledSectionValue>
        <StyledValue>{value}</StyledValue>
      </StyledSectionValue>
    </div>
  );
};

export default CptSection;
