import { Form, Formik } from "formik";
import Button from "global/components/Button/Button";
import { InputFields } from "./InputFields";
import Logo from "./Logo";
import { FormikProps } from "./types";

import "./sign-in.css";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty }) => (
        <div className="signup">
          <div className="div-signup-registration">
            <div className="logo-container">
              <img
                src="/assets/logo-enjeuxfootball-application-3.png"
                alt="logo-enjeux"
              />
            </div>
            <Form className="form">
              <div>
                <InputFields />
                <Button
                  label="Se connecter"
                  dirty={dirty}
                  isValid={isValid}
                  loading={loading}
                />
                {/* <div className="already-member">
                  <a href={`${process.env.REACT_APP_URL}signup/registration`}>
                    Pas de compte ? S'inscrire
                  </a>
                </div> */}
                {/* <div className="already-member">
                <a href="#">Mot de passe oublié</a>
              </div> */}
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default FormikForm;
