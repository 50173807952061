import Select from "global/components/Select";
import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";
import { Props } from "./types/intex";

const SelectAcademyContainer: React.FC<Props> = ({ academy, setAcademy }) => {
  const academiesList = useAppSelector(
    (state) => state.firebase.profile.academies
  );
  const [academies, setAcademies] = useState([]);

  const firestore = useFirestore();

  useEffect(() => {
    async function getAcademies() {
      if (academiesList) {
        const newAcademies: any = [{ name: "Académie", value: "" }];
        await Promise.all(
          academiesList.map(async (academy: any) => {
            const data = (await academy.get()).data();

            newAcademies.push({
              name: data?.name,
              value: academy.id,
            });
          })
        );

        setAcademies(newAcademies);
      }
    }

    getAcademies();
  }, [academiesList, firestore]);

  return (
    <Select
      onChange={setAcademy}
      value={academy}
      options={academies}
      optionsList={[]}
      label="Academy"
      name="string"
    />
  );
};

export default React.memo(SelectAcademyContainer);
