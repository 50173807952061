import MultiSelect from "global/components/MultiSelect";
import { usePositionsList } from "./hooks/usePositionsList";

interface Props {
  selected: any;
  setSelected: any;
}

const PositionsListContainer: React.FC<Props> = ({ selected, setSelected }) => {
  const { positionsList } = usePositionsList();
  return (
    <MultiSelect
      optionsList={positionsList}
      value={selected}
      onChange={setSelected}
      label="Position(s)"
    />
  );
};

export default PositionsListContainer;
