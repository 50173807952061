import AddInjuryContainer from "containers/AddInjury";
import AthleteNavContainer from "containers/AthleteNav";
import { GlobalDiv } from "global/styles";
import { StyledCol, StyledRow } from "global/styles/flexbox";

const AddInjury = (props: any) => {
  return (
    <GlobalDiv>
      <StyledRow>
        <StyledCol xs={12} sm={12} md={3} lg={2}>
          <AthleteNavContainer athleteId={props.location.state.athleteId} />
        </StyledCol>
        <StyledCol xs={12} sm={12} md={9} lg={10}>
          <AddInjuryContainer athleteId={props.location.state.athleteId} />
        </StyledCol>
      </StyledRow>
    </GlobalDiv>
  );
};

export default AddInjury;
