import CellsContainer from "containers/AthletePlanning/Cells";
import { Paper, StyledContainer } from "global/styles";
import React from "react";
// import { useHistory } from "react-router-dom";
import { Loading } from "styles";
import Month from "./Month";
import { AthletePlanningProps } from "./types";
import WeekDays from "./WeekDays";

const AthletePlanning: React.FC<AthletePlanningProps> = ({
  currentMonth,
  prevMonth,
  nextMonth,
  eventsList,
  deleteEvent,
  athleteId,
  deleteInjury,
  loading,
}) => {
  //const history = useHistory();
  if (loading) return <Loading />;

  return (
    <StyledContainer>
      {/* <button
        onClick={() =>
          history.push({
            pathname: "add-national-selection",
            state: { athleteId: athleteId },
          })
        }
      >
        Déclarer sélection
      </button> */}
      <Paper>
        <Month
          currentMonth={currentMonth}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
        />
        <WeekDays />
        <CellsContainer
          currentMonth={currentMonth}
          eventsList={eventsList}
          deleteEvent={deleteEvent}
          athleteId={athleteId}
          deleteInjury={deleteInjury}
        />
      </Paper>
    </StyledContainer>
  );
};

export default AthletePlanning;
