import { Title } from "global/styles";
import React from "react";
import { HeaderDiv } from "./styles";
import { Props } from "./types";

const HeaderContainer: React.FC<Props> = ({ title, displayButton, Button }) => {
  return (
    <HeaderDiv>
      <Title>{title}</Title>
      {displayButton ? Button : null}
    </HeaderDiv>
  );
};

export default HeaderContainer;
