import AddReportWorkCycleContainer from "containers/AddReportWorkCycle";
import AthleteNavContainer from "containers/AthleteNav";
import DeleteButton from "global/components/Button/DeleteRoundedButton";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import { StyledCol, StyledRow } from "global/styles/flexbox";
import { useDeleteWorkCycle } from "./hooks/useDeleteWorkCycle";

const AthleteWorkCycle = (props: any) => {
  const athleteId = props.location.state.athleteId;
  const workCycleUid = props.location.state.workCycleUid;

  const { deleteWorkCycle } = useDeleteWorkCycle(workCycleUid, athleteId);
  return (
    <GlobalDiv>
      <StyledRow>
        <StyledCol xs={12} sm={12} md={3} lg={2}>
          <AthleteNavContainer athleteId={athleteId} />
        </StyledCol>
        <StyledCol xs={12} sm={12} md={9} lg={10}>
          <StyledContainer>
            <HeaderContainer
              title=""
              displayButton
              Button={<DeleteButton onClick={() => deleteWorkCycle()} />}
            />
            <AddReportWorkCycleContainer workCycleUid={workCycleUid} />
          </StyledContainer>
        </StyledCol>
      </StyledRow>
    </GlobalDiv>
  );
};

export default AthleteWorkCycle;
