import CreateGroupEventContainer from "containers/CreateEvent/CreateGroupEvent";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const CreateGroupEvent: React.FC<any> = (props) => {
  //console.log(props.location.state.athleteId);
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Create Event" />
        <CreateGroupEventContainer
          date={props.location.state.date}
          groupId="Wjfg9CH821s2HCduWdSb"
        />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default CreateGroupEvent;
