import moment from "moment";

export interface FormValues {
  category: string;
  subCategory: string;
  date: string;
  langs: {
    fr: {
      name: "";
      description: string;
      keywords: string[];
    };
  };
  time: number;
  isNewsFeed: boolean;

  croppedImage: string;
  blobImage: string;
}

export interface Content {
  type: string;
  value: any;
  url?: string;
}

export const initialValues: FormValues = {
  category: "",
  subCategory: "",

  date: moment().format("YYYY-MM-DD"),

  langs: {
    fr: {
      name: "",
      description: "",
      keywords: [],
    },
  },

  time: 5,
  isNewsFeed: false,

  croppedImage: "",
  blobImage: "",
};
