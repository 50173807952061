import Axios from "axios";
import { CptPains } from "components/AthleteMedicalDashboard/types";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useFirestore } from "react-redux-firebase";

export const useAthleteMedicalDashboard = (athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [medicalDashboard, setMedicalDashboard] = useState(null);
  const [cptPains, setCptPains] = useState<CptPains | null>(null);
  const [pains, setPains] = useState<any[] | null>(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const firestore = useFirestore();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const userSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();
        const userData = userSnapshot.data();
        if (userData?.cptPains) setCptPains(userData?.cptPains);
        if (userData?.pains) setPains(userData?.pains);

        const res = await Axios.post(
          `${process.env.REACT_APP_FIREBASE_URL}getMedicalDashboard`,
          {
            data: {
              athleteId: athleteId,
            },
          }
        );

        setMedicalDashboard(res.data.result);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getData();
  }, [alert, athleteId, dispatch, firestore]);

  return { medicalDashboard, cptPains, pains, loading };
};
