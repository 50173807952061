import FormikForm from "components/CreateSeance/FormikForm";
import { Exercise } from "schemes/exercise";
import { useCreateSeance } from "./hooks/useCreateSeance";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  exercises: Exercise[];
}

const FormSeanceContainer: React.FC<Props> = ({ exercises }) => {
  const { handleSubmit, loading } = useCreateSeance(exercises);
  return (
    <FormikForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      loading={loading}
    />
  );
};

export default FormSeanceContainer;
