import { Input, MenuItem, Select } from "@material-ui/core";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  goalCardio: Work["goalCardio"];
  editSerie: any;
  index: number;
}

const GoalCardioValue: React.FC<Props> = ({ goalCardio, editSerie, index }) => {
  return (
    <BlocValueDiv>
      <TitleBloc>GOAL</TitleBloc>
      <Select
        name="type"
        value={goalCardio?.type}
        onChange={(e) => editSerie(e, index, "goalCardio")}
      >
        <MenuItem value={"%fcMax"}>%FC MAX</MenuItem>
        <MenuItem value={"%VMA"}>%FC VMA</MenuItem>
      </Select>
      <Input
        type="text"
        name="value"
        value={goalCardio?.value}
        onChange={(e) => editSerie(e, index, "goalCardio")}
      />
    </BlocValueDiv>
  );
};

export default GoalCardioValue;
