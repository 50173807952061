import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";

export const useAthleteCards = () => {
  const [cardsList, setCardsList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const firestore = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    async function getCardsList() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        const cardsSnapshot = await firestore
          .collection("cards")
          .where("athlete", "==", athleteSnapshot.ref)
          .orderBy("date", "desc")
          .get();

        const array: any[] = [];
        await Promise.all(
          cardsSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            //console.log((await data.createdBy.get()).data());
            const name = (await data.createdBy.get()).data().firstName;

            array.push({
              uid: doc.id,
              date: moment(data.date.toDate()).format("dddd DD MMMM YYYY"),
              name: name,
              type: data.type,
              duration: data.duration,
            });
          })
        );

        setCardsList(array);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getCardsList();
  }, [alert, athleteId, firestore]);

  return { cardsList, loading };
};
