import { Exercise } from "schemes/exercise";
import ExerciseLine from "./Exercise";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

interface Props {
  exercises: any[];
  deleteExercise: any;
  handleDrag: any;
  setSelectedExercise: any;
  openOrClose: any;
}

const Exercises: React.FC<Props> = ({
  exercises,
  deleteExercise,
  handleDrag,
  setSelectedExercise,
  openOrClose,
}) => {
  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="exercises">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {exercises.map((exercise: Exercise, index: number) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ExerciseLine
                      exercise={exercise}
                      deleteExercise={() => deleteExercise(index)}
                      number={index + 1}
                      setSelectedExercise={setSelectedExercise}
                      openOrClose={openOrClose}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Exercises;
