import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";

export const useWeeklyGoals = (athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [weeklyGoals, setWeeklyGoals] = useState<any[]>([]);
  const dispatch = useDispatch();
  const alert = useAlert();
  const firestore = useFirestore();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        const weeklyGoalsSnapshot = await firestore
          .collection("weeklyGoals")
          .where("athlete", "==", athleteSnapshot.ref)
          .orderBy("startDate", "desc")
          .get();

        const array: any[] = [];

        weeklyGoalsSnapshot.docs.forEach((document) => {
          const data = document.data();

          array.push(data);
        });

        //console.log(array);

        setWeeklyGoals(array);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }

      setLoading(false);
    }

    getData();
  }, [alert, athleteId, dispatch, firestore]);

  return { weeklyGoals, loading };
};
