import { MyColors, MyGradients } from "global/styles/colors";
import styled from "styled-components";

export const LineSection = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const StyledSectionTitle = styled.div`
  background: ${MyGradients.primary};
  min-width: 200px;
  height: 50px;
  border-radius: 30px;

  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled.p`
  font-size: 26px;
  color: #fff;
  font-family: "Bebas Neue";
`;

export const StyledSectionValue = styled.div`
  width: 200px;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledValue = styled.p`
  font-size: 30px;
  color: ${MyGradients.primary};
  font-family: "Bebas Neue";

  text-align: center;
`;

export const StyledDivTwiceDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const StyledTwiceDiv = styled.div`
  width: 180px;
  height: 50px;
  background-color: ${MyColors.primary};
  border-radius: 30px;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
`;

export const Div = styled.div`
  text-align: -webkit-center;
`;

export const DivBody = styled.div`
  margin: auto;
  display: flex;
  width: fit-content;

  @media (max-width: 500px) {
    display: block;
  }
`;

export const ImgBody = styled.img`
  height: 260px;
`;

interface PainLocalisationProps {
  height: string;
  width: string;
  marginTop: string;
  marginLeft: string;
}

export const PainLocalisation = styled.div<PainLocalisationProps>`
  position: absolute;
  z-index: 1;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  border: dashed red;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-items: center;
  display: flex;
`;
