import { Loading } from "global/styles";
import React from "react";
import { Button } from "./styles";

import { Props } from "./types";

export const ValidationButton: React.FC<Props> = ({
  isValid,
  dirty,
  edit,
  loading,
}) => {
  return (
    <Button
      id="button-validation"
      type="submit"
      disabled={!(isValid && dirty) || loading}
    >
      {loading ? <Loading /> : edit ? "Edit" : "Validate"}
    </Button>
  );
};
