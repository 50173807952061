import Payment from "components/Payment";
import { ProductScheme } from "schemes/product";
import { usePayment } from "./hooks/usePayment";

interface Props {
  productObject: ProductScheme;
}

const PaymentContainer: React.FC<Props> = ({ productObject }) => {
  const { loading, handleSubmit, stripe, elements } = usePayment(productObject);
  return (
    <Payment
      loading={loading}
      handleSubmit={handleSubmit}
      product={productObject}
      stripe={stripe}
      elements={elements}
    />
  );
};

export default PaymentContainer;
