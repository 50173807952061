export function _activityHourToDate(
  stringDate: string,
  stringHour: string
): Date | undefined {
  if (stringHour === undefined) return undefined;

  const trueHour = stringHour.split(":");
  const hour = trueHour[0];
  const minutes = trueHour[1];

  const trueDate = new Date(stringDate);
  trueDate.setHours(parseInt(hour), parseInt(minutes));
  return trueDate;
}
