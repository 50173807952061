import { Field } from "formik";

const Active = () => {
  return (
    <div className="cgu">
      <div className="cgu-checkbox">
        <Field type="checkbox" name="active" />
      </div>

      <label>Actif</label>
    </div>
  );
};

export default Active;
