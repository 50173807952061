import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import "firebase/firestore";

let firebaseConfig = null;
let dev = false;

if (dev) {
  firebaseConfig = {
    apiKey: "AIzaSyDiXJUxbPCB6gd_-O8qiA_5u2Q1L-wswHE",
    authDomain: "enjeux-test.firebaseapp.com",
    projectId: "enjeux-test",
    storageBucket: "enjeux-test.appspot.com",
    messagingSenderId: "892976633686",
    appId: "1:892976633686:web:fc21d617b179e5c340e8f7",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyA55samtgSw8vRP8-UDJCXfcKXsIEJkAs8",
    authDomain: "enjeux-app.firebaseapp.com",
    databaseURL: "https://enjeux-app.firebaseio.com",
    projectId: "enjeux-app",
    storageBucket: "enjeux-app.appspot.com",
    messagingSenderId: "579489040938",
    appId: "1:579489040938:web:538e39e5aa0bfbc9ccd153",
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//initialize storage (MAYBE WE SHOULD MOCK IT?)
const storage = firebase.storage();

//connect to emulated firestore database
const firestore = firebase.firestore();
const auth = firebase.auth();

export { firestore, auth, storage, firebase };
