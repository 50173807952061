import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";

export const useAthletePlanning = (athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const alert = useAlert();
  const [reload, setReload] = useState<boolean>(false);
  const [eventsList, seteventsList] = useState<any[]>([]);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const monthStart = moment(currentMonth)
    .startOf("month")
    .isoWeekday(1)
    .toDate();
  const monthEnd = moment(currentMonth).endOf("month").isoWeekday(7).toDate();
  //console.log(monthStart);
  //console.log(monthEnd);

  const prevMonth = () => {
    setCurrentMonth(moment(currentMonth).subtract(1, "M").toDate());
  };

  const nextMonth = () => {
    setCurrentMonth(moment(currentMonth).add(1, "M").toDate());
  };

  async function deleteEvent(eventId: string) {
    setLoading(true);
    try {
      await firestore.collection("events").doc(eventId).delete();
      setReload(!reload);
    } catch (error) {
      //console.log(error);
      alert.error("error");
    }
  }

  async function deleteInjury(injuryId: string) {
    setLoading(true);
    try {
      await firestore.collection("injuries").doc(injuryId).delete();
      setReload(!reload);
    } catch (error) {
      //console.log(error);
      alert.error("error");
    }
  }

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        let clubName = "";
        if (athleteSnapshot.data()?.club)
          clubName = (await athleteSnapshot.data()?.club.get()).data().name;

        const eventsSnapshot = await firestore
          .collection("events")
          .where("athlete", "==", athleteSnapshot.ref)
          .where("date", ">=", monthStart)
          .where("date", "<=", monthEnd)
          .get();

        const injuriesSnapshot = await firestore
          .collection("injuries")
          .where("athlete", "==", athleteSnapshot.ref)
          // .where("date", ">=", monthStart)
          // .where("date", "<=", monthEnd)
          .get();

        const list: any[] = [];

        injuriesSnapshot.docs.forEach((injury) => {
          for (let i = 0; i <= injury.data().days; i++) {
            list.push({
              eventId: injury.id,
              date: moment(injury.data().date.toDate())
                .add(i, "d")
                .format("YYYY-MM-DD"),
              name: "INJURY",
              injury: injury.data().injury,
              day: i,
            });
          }
        });

        await Promise.all(
          eventsSnapshot.docs.map(async (doc) => {
            let img = "";
            let color = "#30336b";
            if (doc.data().name !== "SELECTION") {
              img = (await doc.data().theme.get()).data().imageUrl;
              color = (await doc.data().theme.get()).data().color1;
            }

            if (doc.data().match !== null && doc.data().match !== undefined) {
              img =
                clubName !== doc.data().match.homeTeam.team_name
                  ? doc.data().match.homeTeam.logo
                  : doc.data().match.awayTeam.logo;
            }

            list.push({
              eventId: doc.id,
              date: moment(doc.data().date.toDate()).format("YYYY-MM-DD"),
              hour: moment(doc.data().date.toDate()).format("HH:mm"),
              name: doc.data().name,
              color: color,
              img: img,
            });
          })
        );

        seteventsList(list);
      } catch (error) {
        //console.log(error);
        alert.error("error");
      }
      setLoading(false);
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, reload, currentMonth]);

  return {
    eventsList,
    deleteEvent,
    currentMonth,
    prevMonth,
    nextMonth,
    deleteInjury,
    loading,
  };
};
