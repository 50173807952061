import DeleteButton from "global/components/Button/DeleteRoundedButton";
import EditButton from "global/components/Button/EditButton";
import { Exercise } from "schemes/exercise";
import Preview from "./Preview";
import {
  ButtonsDiv,
  DivBeetweenButton,
  ExerciseDiv,
  ExerciseImg,
  ImgDiv,
  Number,
} from "./styles";

interface Props {
  exercise: Exercise;
  deleteExercise: any;
  number: number;
  setSelectedExercise: any;
  openOrClose: any;
}

const ExerciseLine: React.FC<Props> = ({
  exercise,
  deleteExercise,
  number,
  setSelectedExercise,
  openOrClose,
}) => {
  console.log(exercise.works);
  return (
    <div>
      <ExerciseDiv>
        <ImgDiv>
          <Number>{number}</Number>
          <ExerciseImg src={exercise.data.imageUrl} alt={exercise.data.name} />
        </ImgDiv>

        <p>{exercise.data.name}</p>

        <ButtonsDiv>
          <EditButton
            onClick={() => {
              setSelectedExercise(number - 1);
              openOrClose();
            }}
          />
          <DivBeetweenButton />
          <DeleteButton onClick={deleteExercise} />
        </ButtonsDiv>
      </ExerciseDiv>
      {!exercise.works?.length ? (
        <p>Aucune configuration</p>
      ) : (
        <Preview works={exercise.works} />
      )}
    </div>
  );
};

export default ExerciseLine;
