import AdminDashboard from "components/AdminDashboard";
import { GlobalDiv } from "global/styles";

const AdminDashboardPage = () => {
  return (
    <GlobalDiv>
      <AdminDashboard />
    </GlobalDiv>
  );
};

export default AdminDashboardPage;
