import { SpinnerLoader } from "global/components/Loaders/SpinnerLoader";
import { BigProfilePic } from "global/styles/img";
import React from "react";
import { Row } from "react-flexbox-grid";
import { StyledCol } from "./styles";

interface Props {
  athletesList: any[];
  onClick: (athlete: any) => void;
  loading: boolean;
}

const HorizontalAthletesList: React.FC<Props> = ({
  athletesList,
  onClick,
  loading,
}) => {
  if (loading) return <SpinnerLoader />;
  return (
    <Row center="xs">
      {athletesList.map((athlete: any) => (
        <StyledCol key={athlete.key} onClick={() => onClick(athlete)}>
          <BigProfilePic img={athlete.photoUrl} />
          <p>{athlete.firstName}</p>
          <p>{athlete.lastName}</p>
        </StyledCol>
      ))}
    </Row>
  );
};

export default HorizontalAthletesList;
