import { LabelField } from "global/components/Form/Input/styles";
import { InputDiv } from "global/components/Form/InputVideoFile/styles";
import React from "react";
import { InputFileProps } from "./types";

const InputFile: React.FC<InputFileProps> = ({ onFileChange, label }) => {
  return (
    <InputDiv>
      <LabelField>{label}</LabelField>

      <input type="file" onChange={onFileChange} accept="image/*" />
    </InputDiv>
  );
};

export default InputFile;
