import TopBar from "menus/TopBar";
import AthleteHome from "pages/AthleteHome";
import MyInvoices from "pages/MyInvoices";
import MyProfile from "pages/MyProfile";
import MySubscription from "pages/MySubscription";
import React from "react";
import { Route } from "react-router-dom";

const AthleteApp: React.FC = () => {
  return (
    <>
      <TopBar />
      <Route path={`/app/athlete/home`} component={AthleteHome} />
      <Route path={`/app/athlete/my-profile`} component={MyProfile} />
      <Route path={`/app/athlete/my-subscription`} component={MySubscription} />
      <Route path={`/app/athlete/my-invoices`} component={MyInvoices} />
    </>
  );
};

export default AthleteApp;
