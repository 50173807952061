import styled from "styled-components";

interface ProfilePicProps {
  img: string;
}

export const LittleProfilePic = styled.div<ProfilePicProps>`
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  background-image: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: cover;
`;

export const BigProfilePic = styled.div<ProfilePicProps>`
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50px;

  margin: auto;

  background-image: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: cover;
`;

export const ImageUrl = styled.img`
  width: 200px;
  margin: auto;
`;

export const ThemeUrl = styled.img`
  width: 100px;
`;
