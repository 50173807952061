import { RoundedButton } from "../styles";
import React from "react";
import { Props } from "./types";
import FaTimes from "global/components/Icons/FaTimes";

const CancelRoundedButton: React.FC<Props> = ({ onClick, color }) => {
  return (
    <RoundedButton onClick={onClick}>
      <FaTimes color={color} />
    </RoundedButton>
  );
};

export default CancelRoundedButton;
