import CreateEvent from "../../components/CreateEvent";
import { useEditEvent } from "./hooks/useEditEvent";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  eventId: string;
  athleteId: string;
}

const EditEventContainer: React.FC<Props> = ({ eventId, athleteId }) => {
  const {
    handleSubmit,
    athletesList,
    addAthlete,
    deleteAthlete,
    dates,
    setDates,
    exercises,
    setExercises,
    setSelectedExercise,
    selectedExercise,
    addExercise,
    deleteExercise,
    handleDrag,
    addSerie,
    deleteSerie,
    editSerie,
    duplicateSerie,
    addItemToSerie,
    deleteItemToSerie,
    onChangeCommentsExercise,
    loading,
  } = useEditEvent(eventId, athleteId);

  return (
    <CreateEvent
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      athletesList={athletesList}
      addAthlete={addAthlete}
      deleteAthlete={deleteAthlete}
      dates={dates}
      setDates={setDates}
      exercises={exercises}
      setSelectedExercise={setSelectedExercise}
      selectedExercise={selectedExercise}
      addExercise={addExercise}
      deleteExercise={deleteExercise}
      handleDrag={handleDrag}
      addSerie={addSerie}
      deleteSerie={deleteSerie}
      editSerie={editSerie}
      duplicateSerie={duplicateSerie}
      addItemToSerie={addItemToSerie}
      deleteItemToSerie={deleteItemToSerie}
      seanceRef=""
      setExercises={setExercises}
      onChangeCommentsExercise={onChangeCommentsExercise}
      edit={true}
      eventId={eventId}
      loading={loading}
    />
  );
};

export default EditEventContainer;
