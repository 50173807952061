import AddNutrition from "components/AddNutrition";
import { useAddNutrition } from "./hooks/useAddNutrition";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const AddNutritionContainer = () => {
  const { handleSubmit, loading } = useAddNutrition();

  return (
    <AddNutrition
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default AddNutritionContainer;
