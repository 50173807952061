import { useHistory } from "react-router-dom";

const SignUpStep1 = () => {
  const history = useHistory();
  return (
    <div className="signup">
      <div className="div-signup-registration">
        <div className="logo-container">
          <img
            src="/assets/logo-enjeuxfootball-application-3.png"
            alt="logo-enjeux"
          />
        </div>
        <span>Étape 1 sur 3</span>
        <h1>Complètes la configuration de ton compte</h1>
        <p>
          Inscris-toi avec ton email et un mot de passe. Choisis ton suivi et
          c'est parti !{" "}
        </p>
        <button
          className="button"
          onClick={() => history.push("/signup/regform")}
        >
          Suivant
        </button>
        <p style={{ fontSize: 10 }}>
          Déjà un compte ? Connecte-toi sur l'application mobile !
        </p>
      </div>
    </div>
  );
};

export default SignUpStep1;
