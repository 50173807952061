import ProgramPayment from "components/ProgramPayment";
import { useProgramPayment } from "./hooks/useProgramPayment";

interface Props {
  userUid: string;
  userEmail: string;
  programUid: string;
}

const ProgramPaymentContainer: React.FC<Props> = ({
  userUid,
  userEmail,
  programUid,
}) => {
  const {
    loading,
    handleSubmit,
    stripe,
    elements,
    price,
    programName,
  } = useProgramPayment(userUid, userEmail, programUid);
  return (
    <ProgramPayment
      loading={loading}
      handleSubmit={handleSubmit}
      price={price}
      programName={programName}
      stripe={stripe}
      elements={elements}
    />
  );
};

export default ProgramPaymentContainer;
