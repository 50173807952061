import firebase from "firebase";
import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import initializeStripe from "../../../stripe/initializeStripe";

interface Product {
  id: string;
  price: number;
  month: string;
}

export const useProSubscription = (uid: string, email: string) => {
  const [products, setproducts] = useState<any[]>([]);
  const [productselected, setproductselected] = useState<Product | null>(null);
  const [loading, setloading] = useState<boolean>(false);
  const firestore = useFirestore();

  const subscribe = async () => {
    setloading(true);
    // Create a new checkout session in the subcollection inside this users document
    const checkoutSessionRef = await firestore
      .collection("users")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        price: productselected?.id,
        success_url: `${process.env.REACT_APP_URL}signup/confirm-pro/${email}`,
        cancel_url: `${process.env.REACT_APP_URL}signup/planform-pro/${uid}/${email}`,
        payment_method_types: ["card", "paypal"],
      });

    //Wait for the CheckoutSession to get attached by the extension
    checkoutSessionRef.onSnapshot(async (snap: any) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        //We have a session, let's redirect to Checkout
        //Init Stripe
        const stripe = await initializeStripe();
        stripe?.redirectToCheckout({ sessionId });
      }
    });
  };

  useEffect(() => {
    async function getSilverProducts() {
      try {
        const productsSnapshot = await firestore
          .collection("products")
          .doc(process.env.REACT_APP_STRIPE_PRODUCT_PRO)
          .collection("prices")
          .where("active", "==", true)
          .get();

        const array:
          | ((prevState: never[]) => never[])
          | {
              label: number;
              id: string;
              price: number;
            }[] = [];

        productsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const label = data.interval_count;
          const price = data.unit_amount / 100 / label;
          array.push({
            label: label.toString(),
            id: doc.id,
            price: price,
          });
        });

        array.sort(function (a: any, b: any) {
          return b.price - a.price;
        });

        console.log(array);

        setproductselected({
          id: array[0].id,
          price: array[0].price,
          month: array[0].label.toString(),
        });

        setproducts(array);
      } catch (error) {
        console.log(error);
      }
    }

    getSilverProducts();
    // admin.firestore().collection("products").doc("prod_MBpMz5LmoLQWAO").collection("prices").get()
    // on chope les 3 prices et on les affiches
    // quand on clique sur le bouton on appelle createCheckoutSession avec en params uiduser + uidprice
  }, [firestore]);

  function selectProduct(product: any) {
    setproductselected({
      id: product.id,
      price: product.price,
      month: product.label,
    });
  }

  return { subscribe, loading, products, productselected, selectProduct };
};
