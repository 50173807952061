import firebase from "firebase";
import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import initializeStripe from "../../../stripe/initializeStripe";

interface Product {
  id: string;
  price: number;
  month: string;
}

export const useSilverSubscriptionWithoutTrial = (
  userUid: string,
  priceUid: string
) => {
  const [products, setproducts] = useState<any[]>([]);
  const [productselected, setproductselected] = useState<Product | null>(null);
  const [loading, setloading] = useState<boolean>(false);
  const firestore = useFirestore();

  const subscribe = async (priceUid: string) => {
    console.log(priceUid);
  };

  useEffect(() => {
    async function getSilverProducts() {
      setloading(true);
      // Create a new checkout session in the subcollection inside this users document
      const checkoutSessionRef = await firestore
        .collection("users")
        .doc(userUid)
        .collection("checkout_sessions")
        .add({
          price: priceUid,
          success_url: `${process.env.REACT_APP_URL}signup/confirm`,
          cancel_url: `${process.env.REACT_APP_URL}signup/planform/${userUid}/${priceUid}`,
          allow_promotion_codes: true,
          payment_method_types: ["card", "paypal"],
        });

      //Wait for the CheckoutSession to get attached by the extension
      checkoutSessionRef.onSnapshot(async (snap: any) => {
        const { sessionId } = snap.data();
        if (sessionId) {
          //We have a session, let's redirect to Checkout
          //Init Stripe
          const stripe = await initializeStripe();
          stripe?.redirectToCheckout({ sessionId });
        }
      });
    }

    getSilverProducts();
    // admin.firestore().collection("products").doc("prod_MBpMz5LmoLQWAO").collection("prices").get()
    // on chope les 3 prices et on les affiches
    // quand on clique sur le bouton on appelle createCheckoutSession avec en params uiduser + uidprice
  }, [firestore]);

  function selectProduct(product: any) {
    setproductselected({
      id: product.id,
      price: product.price,
      month: product.label,
    });
  }

  const createPaymentSession = async (
    amount: number,
    currency: string,
    userId: string,
    email: string
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}createPaymentSession`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount, currency, userId, email }),
        }
      );

      const data = await response.json();
      return data.sessionId;
    } catch (error) {
      console.error(error);
      throw new Error("Erreur lors de la création de la session de paiement");
    }
  };

  const handlePaymentSuccess = async (sessionId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}handlePaymentSuccess`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId }),
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw new Error("Erreur lors du traitement du paiement");
    }
  };

  // Exemple d'utilisation
  const handlePayment = async () => {
    const amount = 12999; // Montant en centimes
    const currency = "eur"; // L'ID de l'utilisateur Firebase

    try {
      // Créer une session de paiement
      // const sessionId = await createPaymentSession(
      //   amount,
      //   currency,
      //   uid,
      //   email
      // );
      // const stripe = await initializeStripe();
      // const result = await stripe?.redirectToCheckout({
      //   sessionId,
      // });
      // if (result?.error) {
      //   console.error(result.error.message);
      // } else {
      //   // Le paiement a été effectué avec succès, mettre à jour le rôle de l'utilisateur
      //   await handlePaymentSuccess(sessionId);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    subscribe,
    loading,
    products,
    productselected,
    selectProduct,
    handlePayment,
  };
};
