import "./sign-up-confirmation.css";

const SignUpConfirmationComponent = () => {
  return (
    <div className="sign-up-confirmation">
      <div>
        <div className="sign-up-confirmation-logo">
          <img src="/assets/logo-enjeux-blanc.png" alt="logo-enjeux" />
        </div>
        <div className="container">
          <div>
            <h1 className="title">Bienvenue !</h1>
            <div className="corps">
              <div>
                <p>Vous allez recevoir un mail de confirmation.</p>
                <p>
                  Cliquez sur le lien dans le mail pour valider votre compte.
                </p>
                <p>À tout de suite !</p>
                <p className="bold">La team Enjeux</p>
                <div className="already-member">
                  <a href={`${process.env.REACT_APP_URL}sign-in`}>
                    Se connecter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpConfirmationComponent;
