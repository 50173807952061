import Header from "global/components/Header";
import { Hits, SearchBox } from "react-instantsearch-dom";
import Hit from "./Hit";

interface Props {
  onClick: any;
}

const ExerciseChoiceInSeance: React.FC<Props> = ({ onClick }) => {
  return (
    <div>
      <Header title="Exercise List" />
      <div className="exercise-choice-list">
        <SearchBox />
        <Hits hitComponent={(hit) => <Hit hit={hit} onClick={onClick} />} />
      </div>
    </div>
  );
};

export default ExerciseChoiceInSeance;
