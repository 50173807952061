import ArticlesListContainer from "containers/ArticlesList";
import AddButton from "global/components/Button/AddButton";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";
import { useHistory } from "react-router-dom";

const ArticlesList: React.FC<any> = () => {
  const history = useHistory();

  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer
          title="Articles"
          displayButton
          Button={
            <AddButton
              onClick={() => history.push({ pathname: "create-article" })}
            />
          }
        />
        <ArticlesListContainer
          onClick={(objectID: string) =>
            history.push({
              pathname: "edit-article",
              state: { articleUid: objectID },
            })
          }
        />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default React.memo(ArticlesList);
