import { Formik } from "formik";
import Input from "global/components/Form/Input";
import { CenterForm } from "global/components/Form/Input/styles";
import TextArea from "global/components/Form/Input/TextArea";
import InputPdfFile from "global/components/Form/InputPdfFile";
import KeywordsInput from "global/components/Form/KeywordsInput";
import { ValidationButton } from "global/components/Form/ValidateButton";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, setFieldValue, values }) => (
        <CenterForm>
          <InputPdfFile
            onChange={(e: any) => setFieldValue("pdfUrl", e.target.files[0])}
            label="Pdf"
          />
          <Input
            name={`langs.${"fr"}.name`}
            type="text"
            placeholder="Name of topo..."
            label="Name"
          />
          <TextArea
            name={`langs.${"fr"}.description`}
            type="text"
            placeholder="Description..."
            label="Description"
          />
          <KeywordsInput
            keywords={values.langs.fr.keywords}
            setFieldValue={setFieldValue}
            lng={"fr"}
          />
          <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
        </CenterForm>
      )}
    </Formik>
  );
};

export default FormikForm;
