import { Formik } from "formik";
import React from "react";
import DuplicateForm from "./DuplicateForm";
import EditForm from "./EditForm";
import Form from "./Form";

import { FormikProps } from "./types";

const CreateEvent = ({
  initialValues,
  handleSubmit,
  validationSchema,
  edit,
  athletesList,
  addAthlete,
  deleteAthlete,
  dates,
  setDates,
  exercises,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  setSelectedExercise,
  selectedExercise,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  seanceRef,
  setExercises,
  onChangeCommentsExercise,
  eventId,
  duplicate,
  loading,
  group,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) =>
        edit ? (
          <EditForm
            isValid={isValid}
            dirty={dirty}
            values={values}
            setFieldValue={setFieldValue}
            athletesList={athletesList}
            addAthlete={addAthlete}
            deleteAthlete={deleteAthlete}
            dates={dates}
            setDates={setDates}
            exercises={exercises}
            addExercise={addExercise}
            deleteExercise={deleteExercise}
            handleDrag={handleDrag}
            addSerie={addSerie}
            deleteSerie={deleteSerie}
            setSelectedExercise={setSelectedExercise}
            selectedExercise={selectedExercise}
            editSerie={editSerie}
            duplicateSerie={duplicateSerie}
            addItemToSerie={addItemToSerie}
            deleteItemToSerie={deleteItemToSerie}
            seanceRef={seanceRef}
            setExercises={setExercises}
            onChangeCommentsExercise={onChangeCommentsExercise}
            eventId={eventId}
            loading={loading}
          />
        ) : duplicate ? (
          <DuplicateForm
            isValid={isValid}
            dirty={dirty}
            values={values}
            setFieldValue={setFieldValue}
            athletesList={athletesList}
            addAthlete={addAthlete}
            deleteAthlete={deleteAthlete}
            dates={dates}
            setDates={setDates}
            exercises={exercises}
            addExercise={addExercise}
            deleteExercise={deleteExercise}
            handleDrag={handleDrag}
            addSerie={addSerie}
            deleteSerie={deleteSerie}
            setSelectedExercise={setSelectedExercise}
            selectedExercise={selectedExercise}
            editSerie={editSerie}
            duplicateSerie={duplicateSerie}
            addItemToSerie={addItemToSerie}
            deleteItemToSerie={deleteItemToSerie}
            seanceRef={seanceRef}
            setExercises={setExercises}
            onChangeCommentsExercise={onChangeCommentsExercise}
            eventId={eventId}
            loading={loading}
          />
        ) : (
          <Form
            isValid={isValid}
            dirty={dirty}
            values={values}
            setFieldValue={setFieldValue}
            athletesList={athletesList}
            addAthlete={addAthlete}
            deleteAthlete={deleteAthlete}
            dates={dates}
            setDates={setDates}
            exercises={exercises}
            addExercise={addExercise}
            deleteExercise={deleteExercise}
            handleDrag={handleDrag}
            addSerie={addSerie}
            deleteSerie={deleteSerie}
            setSelectedExercise={setSelectedExercise}
            selectedExercise={selectedExercise}
            editSerie={editSerie}
            duplicateSerie={duplicateSerie}
            addItemToSerie={addItemToSerie}
            deleteItemToSerie={deleteItemToSerie}
            seanceRef={seanceRef}
            setExercises={setExercises}
            onChangeCommentsExercise={onChangeCommentsExercise}
            loading={loading}
            group={group}
          />
        )
      }
    </Formik>
  );
};

export default CreateEvent;
