import styled from "styled-components";

export const StyledCroppedImage = styled.img`
  width: 250px;
  margin-bottom: 18px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
