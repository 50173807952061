import { Form, Formik } from "formik";
import { FormikProps } from "./types";
import Fields from "./Fields";
import ImageCropContainer from "containers/ImageCrop";
import "./my-profile.css";
import Button from "global/components/Button/Button";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
  getInitalValues,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <div className="my-profile">
            <Form className="form">
              <div>
                <Fields
                  getInitialValues={getInitalValues}
                  setFieldValue={setFieldValue}
                />
                {/* <ImageCropContainer
                  croppedImage={values.croppedImage}
                  setFieldValue={setFieldValue}
                  label="User Photo"
                  aspect={4 / 4}
                /> */}
                <Button
                  label="Modifier"
                  isValid={isValid}
                  dirty={dirty}
                  loading={loading}
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default FormikForm;
