export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export const initialValues: FormValues = {
  firstName: "",
  lastName: "",
  email: "",
};
