import Input from "global/components/Form/Input";
import TextArea from "global/components/Form/Input/TextArea";
import React from "react";
import { CenterDiv } from "./styles";

interface Props {
  edit?: boolean;
}

const Fields: React.FC<Props> = ({ edit }) => {
  return (
    <CenterDiv>
      <Input
        name={`subName`}
        type="text"
        placeholder="Name of events..."
        label="Name"
      />
      {edit ? (
        <Input name={`date`} type="date" placeholder="Date..." label="Date" />
      ) : null}
      <Input name={`hour`} type="time" placeholder="Hour..." label="Hour" />
      <Input
        name={`duration`}
        type="number"
        placeholder="Durée..."
        label="Durée"
      />
      <TextArea
        name={`comments`}
        type="text"
        placeholder="Comments..."
        label="Comments"
      />
    </CenterDiv>
  );
};

export default Fields;
