import SilverSubscription from "components/SilverSubscription";
import { useSilverSubscriptionWithoutTrial } from "./hooks/useSilverSubscriptionWithoutTrial";

interface Props {
  userUid: string;
  priceUid: string;
}

const SilverSubscriptionContainerWithoutTrial: React.FC<Props> = ({
  userUid,
  priceUid,
}) => {
  const {
    subscribe,
    loading,
    products,
    productselected,
    selectProduct,
    handlePayment,
  } = useSilverSubscriptionWithoutTrial(userUid, priceUid);
  return (
    <SilverSubscription
      onClick={subscribe}
      handlePayment={handlePayment}
      loading={loading}
      products={products}
      productselected={productselected}
      selectProduct={selectProduct}
    />
  );
};

export default SilverSubscriptionContainerWithoutTrial;
