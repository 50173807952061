import { DocumentReference, DocumentData } from "@firebase/firestore-types";
import firebase from "firebase";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { Exercise } from "schemes/exercise";

import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../types";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";

export const useCreateSeance = (exercises: Exercise[]) => {
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useAlert();
  const uploadOnStorage = useUploadOnStorage();
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const firestore = useFirestore();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      if (!exercises.length) {
        alert.error("Seance should have at least one exercise");
        setLoading(false);
        return;
      }

      let img: any = "";
      if (values.blobImage !== "") {
        img = await uploadOnStorage(values.blobImage, "seancesImg");
      }
      const keywords: string[] = [];
      values.langs.fr.keywords.map((keyword: any) =>
        keywords.push(keyword.text)
      );

      const themesArray: DocumentReference<DocumentData>[] = [];
      values.themes.forEach((theme) => {
        themesArray.push(firestore.collection("themes").doc(theme.value));
      });

      const goalsArray: DocumentReference<DocumentData>[] = [];
      values.goals.forEach((goal: any) => {
        goalsArray.push(firestore.doc(goal.value));
      });

      const muscleGroupsArray: DocumentReference[] = [];
      const muscleGroupsArrayId: string[] = [];

      const jointInjuriesArray: DocumentReference<DocumentData>[] = [];
      const jointInjuriesArrayId: string[] = [];

      const equipmentsArray: DocumentReference<DocumentData>[] = [];
      const equipmentsArrayId: string[] = [];

      const positionsArray: DocumentReference<DocumentData>[] = [];
      const positionsArrayId: string[] = [];

      const exercisesInSeance: any[] = [];
      await Promise.all(
        exercises.map(async (exercise: Exercise) => {
          const exerciseSnapshot = await firebase
            .firestore()
            .collection("exercises")
            .doc(exercise.key)
            .get();
          const exerciseData = exerciseSnapshot.data();

          exerciseData?.muscleGroups.forEach((theme: DocumentReference) => {
            if (muscleGroupsArrayId.indexOf(theme.id) === -1) {
              muscleGroupsArrayId.push(theme.id);
              muscleGroupsArray.push(
                firestore.collection("muscleGroups").doc(theme.id)
              );
            }
          });

          exerciseData?.jointInjuries.forEach((theme: DocumentReference) => {
            if (jointInjuriesArrayId.indexOf(theme.id) === -1) {
              jointInjuriesArrayId.push(theme.id);
              jointInjuriesArray.push(
                firestore.collection("jointInjuries").doc(theme.id)
              );
            }
          });

          exerciseData?.equipments.forEach((theme: DocumentReference) => {
            if (equipmentsArrayId.indexOf(theme.id) === -1) {
              equipmentsArrayId.push(theme.id);
              equipmentsArray.push(
                firestore.collection("equipments").doc(theme.id)
              );
            }
          });

          exerciseData?.positions.forEach((theme: DocumentReference) => {
            if (positionsArrayId.indexOf(theme.id) === -1) {
              positionsArrayId.push(theme.id);
              positionsArray.push(
                firestore.collection("positions").doc(theme.id)
              );
            }
          });

          if (exercise.comments === undefined || exercise.comments === null)
            exercise.comments = "";
          exercisesInSeance.push({
            exercise: firebase
              .firestore()
              .collection("exercises")
              .doc(exercise.key),
            works: exercise.works,
            comments: exercise.comments,
          });
        })
      );

      await firestore.collection("seances").add({
        themes: themesArray,
        level: firestore.collection("levels").doc(values.level),
        muscleGroups: muscleGroupsArray,
        jointInjuries: jointInjuriesArray,
        goals: goalsArray,
        equipments: equipmentsArray,
        positions: positionsArray,
        imageUrl: img,

        langs: {
          fr: {
            name: values.langs.fr.name,
            description: values.langs.fr.description,
            keywords: keywords,
          },
        },
        estimatedDuration: values.estimatedDuration,
        createdBy: firebase.firestore().collection("users").doc(uid),
        createdAt: new Date(),
        exercises: exercisesInSeance,
        educative: values.educative,
        free: values.free,
        inLibrary: values.inLibrary,
        preparation: values.preparation,
      });

      alert.success(`Seance created `);
      history.push({ pathname: "seances-list" });
    } catch (error) {
      alert.error("error");
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
