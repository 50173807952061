import moment from "moment";
import { SignUpFormValues } from "./types";

export const SignUpInitialValues: SignUpFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  birthDate: moment().subtract(20, "y").format("YYYY-MM-DD"),
  password: "",
  passwordConfirmation: "",
  acceptCGU: false,
  addressCity: "",
  addressLine: "",
  addressPostalCode: "",
  type: "private",
};
