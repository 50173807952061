import AthleteProfile from "components/AthleteProfile";
import { useAthleteProfile } from "./hooks/useAthleteProfile";

const AthleteProfileContainer = () => {
  const { profile, loading, subscriptionsList } = useAthleteProfile();
  return (
    <AthleteProfile
      profile={profile}
      loading={loading}
      subscriptionsList={subscriptionsList}
    />
  );
};

export default AthleteProfileContainer;
