import { MyShadows } from "global/styles/box-shadow";
import styled from "styled-components";

interface EventContainerProps {
  bgColor: string;
  height: string;
}

export const EventContainer = styled.div<EventContainerProps>`
  background-color: ${(props) => props.bgColor};
  border-radius: 10px;
  width: 100%;
  height: ${(props) => props.height};
  margin: 10px 0;
  box-shadow: ${MyShadows.primary};

  :hover {
    cursor: pointer;
  }
`;

export const ActionsDiv = styled.div`
  margin: 0 10px;
  height: 32px;
  width: 158px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const EventTitleDiv = styled.div`
  margin: 0 10px;
  width: 158px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EventTitle = styled.p`
  font-size: 14px;
  height: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  margin: 0;
`;

export const EventImg = styled.img`
  max-height: 60px;
`;
