import TopBar from "menus/TopBar";
import Payment from "pages/Payment";
import Products from "pages/Products";
import SignUpSubscriptionChoice from "pages/SignUpRegistration/SignUpSubscriptionChoice";
import React from "react";
import { Route } from "react-router-dom";
//

const NobodyApp: React.FC = () => {
  return (
    <>
      <TopBar />
      <Route
        path="/signup/planform2/:uid"
        component={SignUpSubscriptionChoice}
      />
      <Route path={`/app/nobody/products`} component={Products} />
      <Route path={`/app/nobody/payment-intent`} component={Payment} />
    </>
  );
};

export default NobodyApp;
