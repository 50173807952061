import { CardScheme } from "schemes/card";
import { Div } from "./styles";

interface Props {
  card: CardScheme;
}

const RehabSection: React.FC<Props> = ({ card }) => {
  return (
    <Div>
      <p>Blessure : {card.injury}</p>
      <p>Jour(s) après blessure : {card.daysAfterInjury}</p>
    </Div>
  );
};

export default RehabSection;
