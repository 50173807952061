import { FormValues } from "containers/CreateEvent/utils/initialValues";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { Exercise } from "schemes/exercise";

export const useSteps = (
  values: FormValues,
  setFieldValue: any,
  setExercises: any,
  group?: boolean
) => {
  const [step, setStep] = useState(0);
  const alert = useAlert();
  const [themes, setthemes] = useState<any[]>([]);
  const firestore = useFirestore();

  const nextStepFromSeanceChoice = async (seanceRef: string) => {
    const querySnapshot = await firestore
      .collection("seances")
      .doc(seanceRef)
      .get();

    const data = querySnapshot.data();

    setFieldValue("subName", data?.langs.fr.name);
    setFieldValue("seanceName", data?.langs.fr.name);
    setFieldValue("seanceDescription", data?.langs.fr.description);
    setFieldValue("duration", data?.estimatedDuration);

    const themesArr:
      | ((prevState: never[]) => never[])
      | { name: string; value: any }[] = [];
    await Promise.all(
      data?.themes.map(async (theme: any) => {
        const name = (await theme.get()).data().name.fr;
        themesArr.push({ name: name, value: theme.id });
        setFieldValue("theme", theme.id);
      })
    );
    setthemes(themesArr);

    const newEx: Exercise[] = [];
    await Promise.all(
      data?.exercises.map(async (exerciseObject: any) => {
        const exerciseSnapshot = await firestore
          .collection("exercises")
          .doc(exerciseObject.exercise.id)
          .get();
        let exerciseData = await exerciseSnapshot.data();

        exerciseObject.works.forEach((work: any) => {
          if (work.goalCardio) {
            work.goalCardio.type = "%fcMax";
            work.goalCardio.value = work.goalCardio.value.toString();
          }
          if (work.rest) work.rest.time = "second";
        });

        newEx.push({
          key: exerciseObject.exercise.id,
          data: {
            imageUrl: exerciseData?.imageUrl,
            name: exerciseData?.langs.fr.name,
          },
          works: exerciseObject.works,
          comments: exerciseObject.comments,
        });
      })
    );

    setExercises(newEx);

    setStep(step + 1);
  };

  const nextStep = () => {
    switch (step) {
      case 0:
        //(values.type);
        if (values.type === "seance") {
          setStep(1);
        } else {
          if (values.type === "match") {
            setFieldValue("subName", "MATCH");
          }
          if (values.type === "training") {
            setFieldValue("subName", "TRAINING");
          }
          setStep(3);
        }
        break;
      case 3:
        if (values.subName === "") {
          alert.error("name required");
          return;
        }
        if (group) {
          setStep(5);
        } else {
          setStep(4);
        }

        break;
      default:
        setStep(step + 1);
        break;
    }
  };

  const prevStep = () => {
    switch (step) {
      case 3:
        if (values.type !== "seance") {
          setStep(0);
        } else {
          setStep(step - 1);
        }

        break;

      case 5:
        if (group) {
          setStep(3);
        } else {
          setStep(4);
        }
        break;
      default:
        setStep(step - 1);
        break;
    }
  };

  return { step, nextStep, prevStep, nextStepFromSeanceChoice, themes };
};
