import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";

export const useMyAthletes = () => {
  const auth = useAppSelector((state) => state.firebase.auth);
  const [athletes, setathletes] = useState<any[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const firestore = useFirestore();
  const alert = useAlert();

  function getAccountStatus(pending: boolean, accountActivated: boolean) {
    if (pending) return "pending";
    if (accountActivated) return "accountActivated";
    return "!accountActivated";
  }

  useEffect(() => {
    async function getData() {
      setloading(true);
      try {
        const userSnapshot = await firestore
          .collection("users")
          .doc(auth.uid)
          .get();
        const athletesSnapshot = await firestore
          .collection("users")
          .where("coach", "==", userSnapshot.ref)
          .get();

        const arr: any[] = [];
        athletesSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          arr.push({
            id: doc.id,
            name: data.firstName + " " + data.lastName,
            email: data.email,
            accountStatus: getAccountStatus(
              data.pending ?? false,
              data.accountActivated
            ),
          });
        });

        setathletes(arr);
      } catch (error) {
        alert.error("Error");
      }
      setloading(false);
    }
    getData();
  }, [alert, auth.uid, firestore]);

  return { athletes, loading };
};
