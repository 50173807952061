import ProSubscription from "containers/SilverSubscription/pro";

const ProSubscriptionPage = (props: any) => {
  return (
    <ProSubscription
      id={props.match.params.uid}
      email={props.match.params.email}
    />
  );
};

export default ProSubscriptionPage;
