import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";

export const useUnreadMessages = () => {
  const [loading, setloading] = useState<boolean>(false);
  const [unreadMessages, setunreadMessages] = useState([]);
  const firestore = useFirestore();
  const alert = useAlert();
  useEffect(() => {
    async function getData() {
      setloading(true);
      try {
        const array: any = [];

        const channelsSnapshot = await firestore
          .collection("channels")
          .where("adminUnreadMessage", "==", true)
          .get();

        await Promise.all(
          channelsSnapshot.docs.map(async (doc) => {
            const messagesSnapshot = await firestore
              .collection("channels")
              .doc(doc.id)
              .collection("messages")
              .orderBy("timestamp", "desc")
              .limit(1)
              .get();

            const splits = doc.id.split("_");
            const athleteId = splits[0];

            if (!messagesSnapshot.empty) {
              const athlete = (
                await firestore.collection("users").doc(athleteId).get()
              ).data();
              const data = messagesSnapshot.docs[0].data();
              const date = new Date(parseInt(data.timestamp));
              if (athlete)
                array.push({
                  content: data.content,
                  photoUrl: athlete.photoUrl,
                  firstName: athlete.firstName,
                  lastName: athlete.lastName,
                  date: moment(date).format("dddd DD MMMM HH:mm"),
                });
            }
          })
        );

        setunreadMessages(array);
      } catch (error) {
        alert.error("error");
      }
      setloading(false);
    }

    getData();
  }, [firestore, alert]);

  return { unreadMessages, loading };
};
