import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { ProductScheme } from "schemes/product";
import { useAppSelector } from "store/storeTypes";

export const usePayment = (productObject: ProductScheme) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  //const [invoiceId, setInvoiceId] = useState<string | null>(null);

  const auth = useAppSelector((state) => state.firebase.auth);
  const profile = useAppSelector((state) => state.firebase.profile);
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    async function createPaymentIntent() {
      setLoading(true);
      try {
        if (productObject.name === "ESSAIE") {
          const res = await Axios.post(
            `${process.env.REACT_APP_FIREBASE_URL}createSubscription`,
            {
              data: {
                userUid: auth.uid,
                productUid: productObject.id,
                customerId: "cus_KW34h1gWfJmCVN",
                paymentMethod: "",
                //invoiceId: invoiceId,
              },
            }
          );

          if (res.data.result.message === "success") {
            alert.success("Paiement réussi");
            history.push({
              pathname: "/redirection",
              state: { role: "athlete" },
            });
          } else {
            alert.error(
              "Une erreur est survenue lors de la création de votre abonnement, veuillez nous contacter."
            );
          }
          return;
        }
        const res = await Axios.post(
          `${process.env.REACT_APP_FIREBASE_URL}paymentIntent`,
          {
            data: {
              email: auth.email,
              productUid: productObject.id,
              addressLine: profile.addressLine,
              addressCity: profile.addressCity,
              addressPostalCode: profile.addressPostalCode,
              name: profile.firstName + " " + profile.lastName,
              phone: profile.phoneNumber,
            },
          }
        );

        setCustomerId(res.data.result.customerId);
        setClientSecret(res.data.result.clientSecret);
        //setInvoiceId(res.data.result.invoiceId);
      } catch (error) {
        alert.error("Error");
      }
      setLoading(false);
    }

    createPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: cardElement!,
    });

    const result = await stripe?.confirmCardPayment(clientSecret!, {
      payment_method: {
        card: cardElement!,
      },
    });

    if (result?.error) {
      alert.error(result.error.message);
      setLoading(false);
      return;
    }

    const res = await Axios.post(
      `${process.env.REACT_APP_FIREBASE_URL}createSubscription`,
      {
        data: {
          userUid: auth.uid,
          productUid: productObject.id,
          customerId: customerId,
          paymentMethod: paymentMethod?.paymentMethod?.id,
          //invoiceId: invoiceId,
        },
      }
    );

    if (res.data.result.message === "success") {
      alert.success("Paiement réussi");
      history.push({ pathname: "/redirection", state: { role: "athlete" } });
    } else {
      alert.error(
        "Une erreur est survenue lors de la création de votre abonnement, veuillez nous contacter."
      );
    }
  };

  return { loading, handleSubmit, stripe, elements };
};
