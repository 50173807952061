import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";
import { useCollectionData } from "react-firebase-hooks/firestore";
import moment from "moment";
import { useEffect } from "react";

export const useChatRoom = (athleteId: string) => {
  const currentUser = useAppSelector((state) => state.firebase);
  const doc = `${athleteId}_enjeux`;

  const firestore = useFirestore();
  const alert = useAlert();

  const messagesRef = firestore
    .collection("channels")
    .doc(doc)
    .collection("messages");
  const query = messagesRef.orderBy("timestamp").limit(25);

  const [messagesList] = useCollectionData(query);

  const sendMessage = async (e: any, message: string) => {
    e.preventDefault();

    try {
      firestore
        .collection("channels")
        .doc(doc)
        .collection("messages")
        .doc(moment().valueOf().toString())
        .set({
          content: message,
          timestamp: moment().valueOf().toString(),
          idFrom: currentUser.auth.uid,
          idTo: athleteId,
          type: 0,
        });

      firestore.collection("channels").doc(doc).update({
        athleteUnreadMessage: true,
      });
    } catch (error) {
      //console.log(error);
      alert.error("Error lors de l'envoie du message");
    }
  };

  useEffect(() => {
    firestore.collection("channels").doc(doc).update({
      adminUnreadMessage: false,
    });
  }, [doc, firestore]);

  return { messagesList, sendMessage };
};
