import { Form } from "formik";
import styled from "styled-components";

export const CenterForm = styled(Form)``;

export const CenterDiv = styled.div`
  width: 250px;
  margin: auto;
`;

export const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;
