const ProgramPaymentConfirmation = (props: any) => {
  return (
    <div className="silver-div">
      <h1>Merci pour votre achat !</h1>
      <p>Pour commencer votre programme :</p>
      <ul>
        <li>
          🚀 Rendez-vous dans l'onglet "Mes programmes" sur l'application mobile
        </li>
        <li>🚀 Sélectionnez votre nouveau programme</li>
        <li>🚀 Cliquez sur "Commencer" pour débutez votre programme</li>
      </ul>
    </div>
  );
};

export default ProgramPaymentConfirmation;
