import { Input, MenuItem, Select } from "@material-ui/core";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  speedCardio: Work["speedCardio"];
  editSerie: any;
  index: number;
}

const SpeedCardioValue: React.FC<Props> = ({
  speedCardio,
  editSerie,
  index,
}) => {
  return (
    <BlocValueDiv>
      <TitleBloc>SPEED</TitleBloc>
      <Select
        name="type"
        value={speedCardio?.type}
        onChange={(e) => editSerie(e, index, "speedCardio")}
      >
        <MenuItem value={"kmh"}>Km/h</MenuItem>
        <MenuItem value={"watt"}>Watt</MenuItem>
        <MenuItem value={"fc"}>FC</MenuItem>
        <MenuItem value={"rpm"}>RPM</MenuItem>
        <MenuItem value={"%vma"}>%VMA</MenuItem>
        <MenuItem value={"%pma"}>%PMA</MenuItem>
        <MenuItem value={"%fcMax"}>%FC MAX</MenuItem>
      </Select>
      <Input
        type="number"
        name="value"
        value={speedCardio?.value}
        onChange={(e) => editSerie(e, index, "speedCardio")}
      />
    </BlocValueDiv>
  );
};

export default SpeedCardioValue;
