import CustomRefinementList from "components/ExercisesList/CustomRefinementList";
import ExerciseChoiceInSeance from "components/ExercisesList/ExerciseChoiceInSeance";
import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import React from "react";
import { InstantSearch, Pagination } from "react-instantsearch-dom";

interface Props {
  onClick: any;
}
//test
const ExercisesListContainer: React.FC<Props> = ({ onClick }) => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`exercises_${process.env.REACT_APP_ENV_NAME}`}
    >
      <CustomRefinementList
        attribute="themes.name.fr"
        limit={20}
        label="Thèmes"
      />
      <ExerciseChoiceInSeance onClick={onClick} />
      <Pagination showLast />
    </InstantSearch>
  );
};

export default ExercisesListContainer;
