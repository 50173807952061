import { Field } from "formik";
import Input from "global/components/Form/Input";
import TextArea from "global/components/Form/Input/TextArea";
import MultiTextArea from "./MultiTextArea";
import { nextMainAxesArray, nextSecondaryAxesArray } from "./types";

interface Props {
  values: any;
}

const NextWorkCycle: React.FC<Props> = ({ values }) => {
  return (
    <div>
      <div className="cgu">
        <div className="cgu-checkbox">
          <Field type="checkbox" name="createNextWorkCycle" />
        </div>

        <label>Créer prochain cycle</label>
      </div>
      {values.createNextWorkCycle ? (
        <div>
          <Input
            name="nextStartDate"
            type="date"
            placeholder="Date du prochain cycle"
            label="Date"
          />
          <Input
            name="nextEndDate"
            type="date"
            placeholder="Date du prochain cycle"
            label="Date"
          />
          <TextArea
            name="nextWorkResume"
            type="text"
            placeholder="6 semaines de travail + 1 semaine de récup"
            label="Résumé du prochain cycle"
            autoFocus
          />
          <MultiTextArea label="Axes principaux" items={nextMainAxesArray} />
          <MultiTextArea
            label="Axes secondaires"
            items={nextSecondaryAxesArray}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NextWorkCycle;
