import Select from "global/components/Select";
import { Loading } from "global/styles";
import { useSeanceDayList } from "./hooks/useSeanceDayList";

interface Props {
  value: any;
  selectSeance: any;
  date: string;
}

const SeanceDayList: React.FC<Props> = ({ value, selectSeance, date }) => {
  const { seanceDayList, loading } = useSeanceDayList(date);

  if (loading) return <Loading spinnerColor="#000" />;

  if (!seanceDayList.length) return null;

  return (
    <Select
      onChange={selectSeance}
      value={value}
      options={seanceDayList}
      optionsList={[]}
      label="Associer une séance"
      name="seance"
    />
  );
};

export default SeanceDayList;
