import SeancesListContainer from "containers/SeancesList";

interface Props {
  nextStepFromSeanceChoice: any;
}

const SeanceChoice: React.FC<Props> = ({ nextStepFromSeanceChoice }) => {
  return (
    <SeancesListContainer
      onClick={(objectID: string) => nextStepFromSeanceChoice(objectID)}
    />
  );
};

export default SeanceChoice;
