import AthleteNavContainer from "containers/AthleteNav";
import AthleteProfileContainer from "containers/AthleteProfile";
import { GlobalDiv } from "global/styles";
import { StyledCol, StyledRow } from "global/styles/flexbox";
import React from "react";

const AthleteProfile: React.FC = (props: any) => {
  return (
    <GlobalDiv>
      <StyledRow>
        <StyledCol xs={12} sm={12} md={3} lg={2}>
          <AthleteNavContainer athleteId={props.location.state.athleteId} />
        </StyledCol>
        <StyledCol xs={12} sm={12} md={9} lg={10}>
          <AthleteProfileContainer />
        </StyledCol>
      </StyledRow>
    </GlobalDiv>
  );
};

export default AthleteProfile;
