import FieldInput from "global/components/Form/Field";
import { useEffect } from "react";

interface Props {
  getInitialValues: any;
  setFieldValue: any;
}

const Fields: React.FC<Props> = ({ getInitialValues, setFieldValue }) => {
  useEffect(() => {
    getInitialValues(setFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <FieldInput type="text" label="Prénom*" name="firstName" autoFocus />
      <FieldInput type="text" label="Nom*" name="lastName" />
      <FieldInput type="date" label="Date de naissance*" name="birthDate" />
      {/* <FieldInput type="email" label="Email" name="email" /> */}
      <FieldInput type="tel" label="Téléphone" name="phone" />
      <FieldInput type="text" label="Adresse" name="addressLine" />
      <FieldInput type="text" label="Ville" name="addressCity" />
      <FieldInput type="text" label="Code Postal" name="addressPostalCode" />
    </div>
  );
};

export default Fields;
