import * as Yup from "yup";

export const validationSchema = Yup.object({
  firstName: Yup.string().required("Requis"),
  lastName: Yup.string().required("Requis"),
  birthDate: Yup.string().required("Requis"),
  email: Yup.string().email("Invalid email address").required("Requis"),
  password: Yup.string()
    .required("Mot de passe requis")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
    ),
  passwordConfirmation: Yup.string()
    .required("Requis")
    .oneOf([Yup.ref("password")], "Les mots de passes doivent être identique"),
  acceptCGU: Yup.bool().oneOf([true], "Vous devez accepter les CGU"),
  addressLine: Yup.string().required("Requis"),
  addressCity: Yup.string().required("Requis"),
  addressPostalCode: Yup.string().required("Requis"),
});
