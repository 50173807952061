import { _activityHourToDate } from "global/functions/activityHourToDate";
import moment from "moment";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";

export const useAddNationalSelection = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const athlete = useAppSelector((state) => state.athlete);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      for (let i = 0; i < values.days; i++) {
        await firestore.collection("events").add({
          createdBy: firestore.collection("users").doc(uid),
          createdAt: new Date(),
          athlete: firestore.collection("users").doc(athlete?.id),
          date: _activityHourToDate(
            moment(values.date).add(i, "d").format("YYYY-MM-DD"),
            "00:00"
          ),
          name: "SELECTION",
          subName: values.country,
        });
      }

      alert.success(`National selection created`);

      history.push({
        pathname: "athlete-planning",
        state: {
          athleteId: athlete?.id,
        },
      });
    } catch (error) {
      // console.log(error);
      alert.error("Error");
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
