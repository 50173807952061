import { BigProfilePic } from "global/styles/img";
import React from "react";
import { useAppSelector } from "store/storeTypes";
import Menu from "./Menu";
import { ColumnContainer, ImgDiv, Name } from "./styles";

interface Props {
  athleteId: string;
}

const AthleteNav: React.FC<Props> = ({ athleteId }) => {
  const athlete = useAppSelector((state) => state.athlete);

  if (athlete)
    return (
      <ColumnContainer>
        <ImgDiv>
          <BigProfilePic img={athlete.photoUrl} />
          <Name>
            {athlete.firstName} {athlete.lastName}
          </Name>
          {/* <SubTitle>{selectedAthlete.academy.name}</SubTitle>
          <SubTitle>{selectedAthlete.group.name}</SubTitle> */}
        </ImgDiv>

        <Menu athleteId={athleteId} />
      </ColumnContainer>
    );

  return null;
};

export default AthleteNav;
