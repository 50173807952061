import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

export const useProgramPayment = (
  userUid: string,
  userEmail: string,
  programUid: string
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [price, setprice] = useState<number | null>(null);
  const [programName, setprogramName] = useState<string | null>(null);
  //const [invoiceId, setInvoiceId] = useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert();
  const history = useHistory();
  const firestore = useFirestore();

  useEffect(() => {
    async function createPaymentIntent() {
      setLoading(true);
      try {
        const programSnapshot = await firestore
          .collection("programs")
          .doc(programUid)
          .get();
        const programData = programSnapshot.data();

        setprogramName(programData?.langs.fr.name);
        setprice(programData?.price);

        const res = await Axios.post(
          `${process.env.REACT_APP_FIREBASE_URL}programPayment`,
          {
            data: {
              email: userEmail,
              price: programData?.price,
              description: programData?.langs.fr.name,
            },
          }
        );

        console.log(res);

        setCustomerId(res.data.result.customerId);
        setClientSecret(res.data.result.clientSecret);
        //setInvoiceId(res.data.result.invoiceId);
      } catch (error) {
        console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    createPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    await stripe?.createPaymentMethod({
      type: "card",
      card: cardElement!,
    });

    const result = await stripe?.confirmCardPayment(clientSecret!, {
      payment_method: {
        card: cardElement!,
      },
    });

    if (result?.error) {
      alert.error(result.error.message);
      setLoading(false);
      return;
    }

    const res = await Axios.post(
      `${process.env.REACT_APP_FIREBASE_URL}addProgramToUserProgramsList`,
      {
        data: {
          userUid: userUid,
          programUid: programUid,
          //invoiceId: invoiceId,
        },
      }
    );

    if (res.data.result.message === "success") {
      alert.success("Paiement réussi");
      history.push({ pathname: "/confirm-program-payment" });
    } else {
      alert.error(
        "Une erreur est survenue lors du paiement, veuillez nous contacter : contact@corentinhavouis.com"
      );
    }
  };

  return { loading, handleSubmit, stripe, elements, price, programName };
};
