import styled from "styled-components";

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

interface DropdownElementProps {
  background: any;
}

export const DropdownElement = styled.div<DropdownElementProps>`
  background-color: ${(props) => props.background};
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
