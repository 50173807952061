import Select from "global/components/Select";

interface Props {
  value: any;
  setFieldValue: any;
}

const TypeCardSelect: React.FC<Props> = ({ setFieldValue, value }) => {
  return (
    <Select
      onChange={(e: any) => setFieldValue("type", e.target.value)}
      value={value}
      options={[
        { name: "Réhab", value: "rehab" },
        { name: "Séance", value: "seance" },
        { name: "Soins", value: "care" },
      ]}
      optionsList={[]}
      label="Type of event"
      name="type"
    />
  );
};

export default TypeCardSelect;
