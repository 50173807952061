import { FormValues } from "../types";

export const initialValues: FormValues = {
  themes: [],
  estimatedDuration: 0,
  croppedImage: "",
  blobImage: "",
  langs: {
    fr: {
      name: "",
      description: "",
      keywords: [],
    },
  },
  level: "1",
  muscleGroups: [],
  jointInjuries: [],
  equipments: [],
  goals: [],
  positions: [],
  educative: false,
  free: false,
  inLibrary: false,
  preparation: false,
};
