import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const ExcelButton = styled.button`
  width: 50px;
  height: 50px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    90deg,
    rgba(64, 151, 100, 1) 0%,
    rgba(131, 211, 119, 1) 100%
  );

  border-radius: 50%;
  border: 0px;
  box-sizing: border-box;
  outline: unset;
`;

const ExportRawDataExcel = ({csvData, fileName}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
      for(let x = 0; x < csvData.length; x++) {
        if (csvData[x].activity !== null) {
            csvData[x] = {
              ...csvData[x],
              'theme': csvData[x].activity.theme,
              'hour': csvData[x].activity.hour,
              'duration': csvData[x].activity.duration,
              'mediumIntensity': csvData[x].activity.markers.mediumIntensity,
              'highIntensity': csvData[x].activity.markers.highIntensity,
              'condition': csvData[x].activity.markers.condition,
              'dynamism': csvData[x].activity.markers.dynamism,
              'perceivedDuration': csvData[x].activity.markers.perceivedDuration,
            }
        }
    
        if (csvData[x].pains !== null) {
          for(let i = 0; i < csvData[x].pains.length; i++) {
            const y = i + 1
            csvData[x] = {
              ...csvData[x],
              ['type' + y]: csvData[x].pains[i].type,
              ['location' + y]: csvData[x].pains[i].location,
              ['laterality' + y]: csvData[x].pains[i].laterality,
              ['intensity' + y]: csvData[x].pains[i].intensity,
            }
          }
        }

        delete csvData[x].activity
        delete csvData[x].pains
        delete csvData[x].date2
      }

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <ExcelButton onClick={(e) => exportToCSV(csvData,fileName)}><FontAwesomeIcon icon={faFileExcel} size="2x" color="#fff" /></ExcelButton>
    )
}

export default ExportRawDataExcel