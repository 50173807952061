import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { CardScheme } from "schemes/card";

export const useCard = (cardId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [card, setCard] = useState<CardScheme | null>(null);
  const alert = useAlert();
  const firestore = useFirestore();
  useEffect(() => {
    async function getCard() {
      setLoading(true);
      try {
        const cardDocSnapshot = await firestore
          .collection("cards")
          .doc(cardId)
          .get();

        const data = cardDocSnapshot.data();
        setCard({
          document:
            data?.document !== null && data?.document !== undefined
              ? data?.document
              : null,
          date: moment(data?.date.toDate()).format("dddd MMMM YYYY HH:mm"),
          duration: data?.duration,
          type: data?.type,
          comments: data?.comments,

          injury:
            data?.type === "rehab"
              ? (await data.injury.get()).data().injury
              : null,
          daysAfterInjury: data?.type === "rehab" ? data.daysAfterInjury : null,
          seanceName:
            data?.seance !== null
              ? (await data?.seance.get()).data().subName
              : null,
          themeName:
            data?.seance !== null
              ? (await data?.seance.get()).data().name
              : null,
          eventUid:
            data?.seance !== null ? (await data?.seance.get()).id : null,

          organization: data?.type === "care" ? null : data?.organization,

          why: data?.type === "care" ? data.why : null,
          estimatedStop: data?.type === "care" ? data.estimatedStop : null,
          workingAxes: data?.type === "care" ? data.workingAxes : null,

          appointments: data?.type === "care" ? data.appointments : null,
          drugs: data?.type === "care" ? data.drugs : null,

          physiotherapy: data?.type === "care" ? data.physiotherapy : null,
          technical: data?.type === "care" ? data.technical : null,
          tools: data?.type === "care" ? data.tools : null,
        });
      } catch (error) {
        alert.error("Error");
      }
      setLoading(false);
    }

    getCard();
  }, [alert, cardId, firestore]);

  return { card, loading };
};
