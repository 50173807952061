import Select from "global/components/Select";
import { CenterDiv } from "./styles";

interface Props {
  setFieldValue: any;
  value: any;
}

const TypeChoice: React.FC<Props> = ({ setFieldValue, value }) => {
  const options = [
    { name: "Seance", value: "seance" },
    { name: "Training", value: "training" },
    { name: "Match", value: "match" },
    { name: "Other", value: "other" },
  ];

  return (
    <CenterDiv>
      <Select
        onChange={(e: any) => setFieldValue("type", e.target.value)}
        value={value}
        options={options}
        optionsList={[]}
        label="Type of event"
        name="type"
      />
    </CenterDiv>
  );
};

export default TypeChoice;
