import { FormProps } from "./types";
import { CenterForm } from "global/components/Form/Input/styles";
import React, { useEffect } from "react";
import Fields from "./Fields";
import { ValidationButton } from "global/components/Form/ValidateButton";
import ImageCropContainer from "containers/ImageCrop";
import Input from "global/components/Form/Input";
import { Field } from "formik";

import KeywordsInput from "global/components/Form/KeywordsInput";
import Contents from "./Contents";
import LibraryCategoriesList from "containers/LibraryCategoriesList";
import LibrarySubCategoriesList from "containers/LibrarySubCategoriesList";
// import HorizontalLangsList from "global/components/Langs/HorizontalLangsList";

const Form: React.FC<FormProps> = ({
  values,
  setFieldValue,
  dirty,
  isValid,
  edit,
  article,
  loading,
  contents,
  addContent,
  selectTypeofContent,
  editContent,
  deleteContent,
  handleDrag,
}) => {
  console.log(values);
  // const handleLang = (newLang: string) => {
  //   if (lang === newLang) return;

  //   setLang(newLang);
  // };

  useEffect(() => {
    //(exercise);
    if (edit && Object.getOwnPropertyNames(article).length) {
      const fields = ["croppedImage", "blobImage", "videoUrl", "theme"];
      fields.forEach((field) => setFieldValue(field, article[field]));

      let keywords: any[] = [];
      let i: number = 1;
      article.langs.fr.keywords.forEach((keyword: string) => {
        keywords.push({ id: i, text: keyword });
        i++;
      });

      const lang = "fr";

      setFieldValue(`langs[${lang}].name`, article.langs.fr.name);
      setFieldValue(`langs[${lang}].description`, article.langs.fr.description);
      setFieldValue(`langs[${lang}].keywords`, keywords);

      setFieldValue("time", article.time);
      setFieldValue("isNewsFeed", article.isNewsFeed);
      setFieldValue("category", article.category);
      setFieldValue("subCategory", article.subCategory);
    }
  }, [edit, setFieldValue, article]);

  return (
    <CenterForm>
      {/* <SelectThemeContainer
        theme={values.themes}
        setTheme={(e: any) => setFieldValue("themes", e)}
      /> */}

      {/* CATEGORY SELECT */}
      <LibraryCategoriesList
        selected={values.category}
        setSelected={(e: any) => setFieldValue("category", e.target.value)}
      />

      {/* SUBCATEGORY SELECT */}
      {values.category !== "" ? (
        <LibrarySubCategoriesList
          selected={values.subCategory}
          setSelected={(e: any) => setFieldValue("subCategory", e.target.value)}
          category={values.category}
        />
      ) : null}

      <div style={{ height: 20 }} />

      <ImageCropContainer
        croppedImage={values.croppedImage}
        croppedImageValue="croppedImage"
        blobImageValue="blobImage"
        setFieldValue={setFieldValue}
        label="Image (obligatoire)"
        aspect={4 / 4}
      />

      {/* <HorizontalLangsList onClick={handleLang} lang={lang} /> */}
      <Fields lng="fr" />
      <KeywordsInput
        keywords={values.langs.fr.keywords}
        setFieldValue={setFieldValue}
        lng="fr"
      />

      <Input
        name="date"
        type="date"
        placeholder="Date..."
        label="Date de publication"
      />

      <Input
        name="time"
        type="number"
        placeholder="5"
        label="Temps de lecture"
      />

      <div className="cgu">
        <div className="cgu-checkbox">
          <Field type="checkbox" name="isNewsFeed" />
        </div>

        <label>Fil d'actu ?</label>
      </div>

      <Contents
        contents={contents}
        addContent={addContent}
        selectTypeofContent={selectTypeofContent}
        editContent={editContent}
        deleteContent={deleteContent}
        handleDrag={handleDrag}
      />

      <ValidationButton
        dirty={dirty}
        isValid={isValid}
        edit={edit}
        loading={loading}
      />
    </CenterForm>
  );
};

export default Form;
