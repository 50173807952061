export const itemsList = [
  "series",
  "time",
  "work",
  "rest",
  "charge",
  "tempo",
  "laterality",
  "typeCardio",
  "speedCardio",
  "distanceCardio",
  "goalCardio",
];
