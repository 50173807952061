import { CardScheme } from "schemes/card";
import { Div, Separation, Title } from "./styles";

interface Props {
  card: CardScheme;
}

const CareSection: React.FC<Props> = ({ card }) => {
  return (
    <Div>
      <Title>Raison</Title>
      <p>{card.why}</p>
      <Separation />
      <Title>Temps d'arrêt estimé</Title>
      <p>{card.estimatedStop} jour(s)</p>
      <Separation />
      <Title>Axe(s) de travail</Title>
      <p>{card.workingAxes}</p>
      <Separation />
      <Title>Médicament(s)</Title>
      <p>{card.drugs}</p>
      <Separation />
      <Title>Rdv</Title>
      <p>{card.appointments}</p>
      <Separation />
      <Title>Physiothérapie</Title>
      {card.physiotherapy?.map((item, index) => (
        <p key={index}>- {item}</p>
      ))}
      <Separation />
      <Title>Technique(s)</Title>
      {card.technical?.map((item, index) => (
        <p key={index}>- {item}</p>
      ))}
      <Separation />
      <Title>Outil(s)</Title>
      {card.tools?.map((item, index) => (
        <p key={index}>- {item}</p>
      ))}
    </Div>
  );
};

export default CareSection;
