import { CardElement } from "@stripe/react-stripe-js";
import {
  CreditCardElementContainer,
  CardForm,
  CARD_OPTIONS,
} from "components/Payment";
import { Loading } from "global/styles";

interface Props {
  loading: boolean;
  handleSubmit: any;
  stripe: any;
  elements: any;
  price: number | null;
  programName: string | null;
}

const ProgramPayment: React.FC<Props> = ({
  loading,
  handleSubmit,
  price,
  programName,
  stripe,
  elements,
}) => {
  if (price === null || programName === null) return <Loading />;
  return (
    <div className="payment">
      <div>
        <div>
          <h1 className="white-title">{programName}</h1>
          <h2 className="white-title">{price}€</h2>
        </div>

        <form onSubmit={handleSubmit} className="form-payment">
          <CreditCardElementContainer>
            <CardForm>
              <CardElement options={CARD_OPTIONS} />
            </CardForm>
          </CreditCardElementContainer>
          <button
            className="button"
            type="submit"
            disabled={!stripe || !elements || loading}
          >
            {loading ? <Loading /> : "Payer"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProgramPayment;
