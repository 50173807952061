import React from "react";
import { Route } from "react-router-dom";

import CreateAcademy from "pages/CreateAcademy";
import CreateGroup from "pages/CreateGroup";
import CreateChoice from "pages/CreateChoice";
import AdminUserList from "pages/AdminUsersList";
// EXERCISES
import ExercisesList from "pages/ExercisesList";
import CreateExercise from "pages/CreateExercise";
import EditExercise from "pages/CreateExercise/EditExercise";
// ATHLETE DASHBOARD
import AthleteSeasonHistory from "../pages/AthleteSeasonHistory";
import AthleteWeeklyGoals from "pages/AthleteWeeklyGoals";
import AthletePlanning from "pages/AthletePlanning";
import CreateSeance from "pages/CreateSeance";
import SeancesList from "pages/SeancesList";
import EditSeance from "pages/CreateSeance/EditSeance";
import CreateEvent from "pages/CreateEvent";
import AthleteProfile from "pages/AthleteProfile";
import TopBar from "menus/TopBar";
import AthletesResponses from "pages/AthletesReponses";
import AdminDashboardPage from "pages/AdminDashboard";
import AthleteMedicalDashboard from "pages/AthleteMedicalDashboard";
import AthleteCurves from "pages/AthleteCurves";
import EditEvent from "pages/CreateEvent/EditEvent";
import DuplicateEvent from "pages/CreateEvent/DuplicateEvent";
import AthleteBalanceSheets from "pages/AthleteBalanceSheets";
import AddBalanceSheet from "pages/AddBalanceSheet";
import AthleteRawData from "pages/AthleteRawData";
import AddInjury from "pages/AddInjury";
import EditInjury from "pages/AddInjury/EditInjury";
import Chat from "pages/Chat";
import AddNationalSelection from "pages/AddNationalSelection";
import AddCard from "pages/AddCard";
import AthleteCards from "pages/AthleteCards";
import AthleteCard from "pages/AthleteCard";
import ToposList from "pages/ToposList";
import AddTopo from "pages/AddTopo";
import NutritionsList from "pages/NutritionsList";
import AddNutrition from "pages/AddNutrition";
import AthleteWorkCycles from "pages/AthleteWorkCycles";
import AddWorkCycle from "pages/AddWorkCycle";
import AthleteWorkCycle from "pages/AthleteWorkCycle";
import UnreadMessages from "pages/UnreadMessages";
import SeanceGoalsList from "pages/SeanceGoalsList";
import AddSeanceGoal from "pages/AddSeanceGoal";
import ProgramsList from "pages/ProgramsList";
import AddProgram from "pages/AddProgram";
import EditProgram from "pages/AddProgram/EditProgram";
import CreateArticle from "pages/LibraryArticles/create-article";
import ArticlesList from "pages/ArticlesList";
import EditArticle from "pages/LibraryArticles/edit-article";
import AthleteActivities from "pages/AthleteActivities";
//

const AdminApp: React.FC = () => {
  console.log("AdminApp");
  return (
    <>
      <TopBar />
      {/* DASHBOARd */}
      <Route path={`/app/admin/home`} component={AdminDashboardPage} />
      {/* USERS */}
      <Route path={`/app/admin/users-list`} component={AdminUserList} />
      {/*  */}
      {/* ATHLETES */}
      <Route path={`/app/admin/athlete-profile`} component={AthleteProfile} />
      {/*  */}
      <Route path={`/app/admin/create-academy`} component={CreateAcademy} />
      <Route path={`/app/admin/create-group`} component={CreateGroup} />
      <Route path={`/app/admin/create`} component={CreateChoice} />
      {/* SEANCES */}
      <Route path={`/app/admin/seances-list`} component={SeancesList} />
      <Route path={`/app/admin/create-seance`} component={CreateSeance} />
      <Route path={`/app/admin/edit-seance`} component={EditSeance} />
      {/* EXERCISES */}
      <Route path={`/app/admin/exercises-list`} component={ExercisesList} />
      <Route path={`/app/admin/create-exercise`} component={CreateExercise} />
      <Route path={`/app/admin/edit-exercise`} component={EditExercise} />
      {/*  */}
      {/* DASHBOARDS */}
      <Route
        path={`/app/admin/athlete-season-history`}
        component={AthleteSeasonHistory}
      />
      <Route
        path={`/app/admin/athlete-weekly-goals`}
        component={AthleteWeeklyGoals}
      />
      <Route
        path={`/app/admin/athlete-medical-dashboard`}
        component={AthleteMedicalDashboard}
      />
      <Route path={`/app/admin/athlete-curves`} component={AthleteCurves} />
      {/*  */}
      {/* ATHLETE PLANNING */}
      <Route path={`/app/admin/athlete-planning`} component={AthletePlanning} />
      <Route
        path={`/app/admin/athlete-activities`}
        component={AthleteActivities}
      />
      {/* ATHLETE RAW DATA */}
      <Route path={`/app/admin/athlete-raw-data`} component={AthleteRawData} />
      {/* EVENTS */}
      <Route path={`/app/admin/create-event`} component={CreateEvent} />
      <Route path={`/app/admin/edit-event`} component={EditEvent} />
      <Route path={`/app/admin/duplicate-event`} component={DuplicateEvent} />
      {/* RESPONSES */}
      <Route
        path={`/app/admin/athletes-responses`}
        component={AthletesResponses}
      />
      {/*  */}
      {/* BALANCE SHEET */}
      <Route
        path={`/app/admin/add-balance-sheet`}
        component={AddBalanceSheet}
      />
      <Route
        path={`/app/admin/athlete-balance-sheets`}
        component={AthleteBalanceSheets}
      />
      {/* INJURY */}
      <Route path={`/app/admin/add-injury`} component={AddInjury} />
      <Route path={`/app/admin/edit-injury`} component={EditInjury} />
      {/* CHAT */}
      <Route path={`/app/admin/chat`} component={UnreadMessages} />
      <Route path={`/app/admin/athlete-chat`} component={Chat} />
      {/* NATIONAL SELECTION */}
      <Route
        path={`/app/admin/add-national-selection`}
        component={AddNationalSelection}
      />
      {/* CARDS */}
      <Route path={`/app/admin/athlete-cards`} component={AthleteCards} />
      <Route path={`/app/admin/add-card`} component={AddCard} />
      <Route path={`/app/admin/athlete-card`} component={AthleteCard} />
      {/* TOPOS */}
      <Route path={`/app/admin/topos-list`} component={ToposList} />
      <Route path={`/app/admin/add-topo`} component={AddTopo} />
      {/* NUTRITIONS */}
      <Route path={`/app/admin/nutritions-list`} component={NutritionsList} />
      <Route path={`/app/admin/add-nutrition`} component={AddNutrition} />
      {/* WORK CYCLES */}
      <Route
        path={`/app/admin/athlete-work-cycles`}
        component={AthleteWorkCycles}
      />
      <Route path={`/app/admin/add-work-cycle`} component={AddWorkCycle} />
      <Route path={`/app/admin/work-cycle`} component={AthleteWorkCycle} />

      <Route
        path={`/app/admin/seance-goals-list`}
        component={SeanceGoalsList}
      />
      <Route path={`/app/admin/create-seance-goal`} component={AddSeanceGoal} />
      <Route path={`/app/admin/programs-list`} component={ProgramsList} />
      <Route path={`/app/admin/add-program`} component={AddProgram} />
      <Route path={`/app/admin/edit-program`} component={EditProgram} />

      <Route path={`/app/admin/articles-list`} component={ArticlesList} />
      <Route path={`/app/admin/create-article`} component={CreateArticle} />
      <Route path={`/app/admin/edit-article`} component={EditArticle} />
    </>
  );
};

export default AdminApp;
