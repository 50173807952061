import { Tr, Td } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Loading, Paper } from "styles";

interface Props {
  loading: boolean;
  goalsList: any[];
}

const headItemList = ["Nom"];

const SeanceGoalsList: React.FC<Props> = ({ loading, goalsList }) => {
  if (loading) return <Loading />;
  return (
    <div>
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {goalsList.map((values: any, index: number) => (
              <Tr
                key={index}
                // onClick={() =>
                //   history.push({
                //     pathname: "athlete-chat",
                //     state: { athleteId: athleteId },
                //   })
                // }
              >
                <Td>{values.name}</Td>
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default SeanceGoalsList;
