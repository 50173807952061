import { Col } from "react-flexbox-grid";
import styled from "styled-components";

export const StyledCol = styled(Col)`
  width: 200px;
  margin: 10px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
