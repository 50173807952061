import CreateExercise from "components/CreateExercise";
import React from "react";
import { useCreateExercise } from "./hooks/useCreateExercise";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const CreateExerciseContainer: React.FC<any> = () => {
  const { handleSubmit, loading } = useCreateExercise();
  return (
    <CreateExercise
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default CreateExerciseContainer;
