import AddInjury from "components/AddInjury";
import { useEditInjury } from "./hooks/useEditInjury";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  athleteId: string;
  injuryId: string;
}

const EditInjuryContainer: React.FC<Props> = ({ athleteId, injuryId }) => {
  const { handleSubmit, editedInjury, loading } = useEditInjury(
    athleteId,
    injuryId
  );

  return (
    <AddInjury
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      edit
      editedInjury={editedInjury}
      loading={loading}
    />
  );
};

export default EditInjuryContainer;
