import DeleteCard from "containers/AddCard/DeleteCard";
import AthleteNavContainer from "containers/AthleteNav";
import CardContainer from "containers/Card";
import DeleteRoundedButton from "global/components/Button/DeleteRoundedButton";
import HeaderContainer from "global/components/HeaderContainer";
import { useOpen } from "global/hooks/useOpen";
import { GlobalDiv, StyledContainer } from "global/styles";
import { StyledCol, StyledRow } from "global/styles/flexbox";

const AthleteCard = (props: any) => {
  const { open, openOrClose } = useOpen();
  return (
    <GlobalDiv>
      <StyledRow>
        <StyledCol xs={12} sm={12} md={3} lg={2}>
          <AthleteNavContainer athleteId={props.location.state.athleteId} />
        </StyledCol>
        <StyledCol xs={12} sm={12} md={9} lg={10}>
          <StyledContainer>
            <HeaderContainer
              title=""
              displayButton
              Button={<DeleteRoundedButton onClick={openOrClose} />}
            />
            <DeleteCard
              open={open}
              cancelClick={openOrClose}
              cardId={props.location.state.cardId}
            />
            <CardContainer cardId={props.location.state.cardId} />
          </StyledContainer>
        </StyledCol>
      </StyledRow>
    </GlobalDiv>
  );
};

export default AthleteCard;
