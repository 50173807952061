import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

export const useDeleteSeance = (seanceId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();
  const deleteSeance = async () => {
    setLoading(true);
    try {
      await firestore.collection("seances").doc(seanceId).delete();
      alert.success("seance deleted");
      history.push({ pathname: "seances-list" });
    } catch (error) {
      //console.log(error);
      alert.error("error");
    }
    setLoading(false);
  };

  return { deleteSeance, loading };
};
