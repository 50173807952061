import AthleteMedicalDashboard from "components/AthleteMedicalDashboard";
import { useAthleteMedicalDashboard } from "./hooks/useAthleteMedicalDashboard";

interface Props {
  athleteId: string;
}

const AthleteMedicalDashboardContainer: React.FC<Props> = ({ athleteId }) => {
  const {
    medicalDashboard,
    cptPains,
    pains,
    loading,
  } = useAthleteMedicalDashboard(athleteId);

  return (
    <AthleteMedicalDashboard
      medicalDashboard={medicalDashboard}
      cptPains={cptPains}
      pains={pains}
      athleteId={athleteId}
      loading={loading}
    />
  );
};

export default AthleteMedicalDashboardContainer;
