import styled from "styled-components";
import DayCheckbox from ".";

const Div = styled.div`
  display: flex;
  margin: 18px 0 30px 0;
`;

interface Props {
  addDaysFromDay: any;
}

const Days: React.FC<Props> = ({ addDaysFromDay }) => {
  const daysList = [
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
    { name: "Sunday", value: 0 },
  ];

  return (
    <Div>
      {daysList.map((day) => (
        <DayCheckbox
          day={day.name}
          value={day.value}
          addDaysFromDay={addDaysFromDay}
        />
      ))}
    </Div>
  );
};

export default Days;
