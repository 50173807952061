import { DivBeetweenButton } from "components/CreateSeance/Exercises/styles";
import { Button } from "global/components/Form/ValidateButton/styles";
import React from "react";
import styled from "styled-components";

interface Props {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
}

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ManageSteps: React.FC<Props> = ({ step, nextStep, prevStep }) => {
  return (
    <Div>
      {step > 0 ? (
        <Button type="button" onClick={prevStep}>
          Previous
        </Button>
      ) : null}

      {step !== 6 && step !== 1 ? <DivBeetweenButton /> : null}
      {step !== 6 && step !== 1 ? (
        <Button type="button" onClick={nextStep}>
          Next
        </Button>
      ) : null}
    </Div>
  );
};

export default ManageSteps;
