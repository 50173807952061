import { BalanceSheet } from "containers/AthleteBalanceSheets/types";
import FaEye from "global/components/Icons/FaEye";
import FaTimes from "global/components/Icons/FaTimes";
import FaTrash from "global/components/Icons/FaTrash";
import { Td } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Loading, Paper } from "global/styles";
import styled from "styled-components";

const Tr = styled.tr`
  height: 70px;
`;

interface Props {
  balanceSheets: BalanceSheet[];
  loading: boolean;
  deleteBalanceSheet: any;
}

const headItemList = ["Name", "Date", "PDF", "Vidéo", ""];

const AthleteBalanceSheets: React.FC<Props> = ({
  balanceSheets,
  loading,
  deleteBalanceSheet,
}) => {
  console.log(balanceSheets);
  if (loading) return <Loading spinnerColor="#000" />;
  if (!balanceSheets.length) return <p>Aucun bilan pour ce joueur</p>;
  return (
    <div>
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {balanceSheets.map((values: any, index: number) => (
              <Tr key={index}>
                <Td>{values.name}</Td>
                <Td>{values.date}</Td>
                <Td>
                  <a href={values.pdfUrl} target="_blank" rel="noreferrer">
                    <FaEye />
                  </a>
                </Td>
                <Td>
                  {values.videoUrl !== "" ? (
                    <a href={values.videoUrl} target="_blank" rel="noreferrer">
                      <FaEye />
                    </a>
                  ) : (
                    <FaTimes color="" />
                  )}
                </Td>
                <Td onClick={() => deleteBalanceSheet(values.id)}>
                  <FaTrash color="red" />
                </Td>
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default AthleteBalanceSheets;
