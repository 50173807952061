import moment from "moment";

export interface FormValues {
  type: string;
  date: string;
  name: string;
  pdfUrl: string;
  videoUrl: string;
}

export const initialValues: FormValues = {
  type: "bodyCheckup",
  date: moment().format("YYYY-MM-DD"),
  name: "",
  pdfUrl: "",
  videoUrl: "",
};
