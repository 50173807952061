import { StyledOption, StyledSelect } from "./styles";
import React from "react";
import { Props } from "./types";
import { LabelField } from "../Form/Input/styles";
import { CenterDiv } from "components/CreateEvent/styles";

const Select: React.FC<Props> = ({
  options,
  value,
  onChange,
  optionsList,
  label,
}) => {
  if (typeof options === "object" && !Array.isArray(options))
    for (const key in options) {
      if (label === "Theme") {
        optionsList?.push({ name: options[key].name.fr, value: key });
      } else {
        optionsList?.push({ name: options[key].name, value: key });
      }
    }
  else {
    optionsList = options;
  }

  return (
    <CenterDiv>
      <LabelField>{label}</LabelField>
      <StyledSelect value={value} onChange={onChange}>
        {optionsList?.map((option: any, index: number) => (
          <StyledOption key={index} value={option.value}>
            {option.name}
          </StyledOption>
        ))}
      </StyledSelect>
    </CenterDiv>
  );
};

export default Select;
