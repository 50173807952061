import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useLibraryCategoriesList = () => {
  const [categories, setcategories] = useState<any[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function getData() {
      const categoriesSnapshot = await firestore
        .collection("libraryCategories")
        .get();

      const array:
        | ((prevState: never[]) => never[])
        | { name: string; value: string }[] = [
        { name: "Catégorie", value: "" },
      ];
      categoriesSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const label = data.name.fr;
        array.push({ name: label, value: doc.id });
      });

      setcategories(array);
    }

    getData();
  }, [firestore]);

  return { categories };
};
