/* eslint-disable jsx-a11y/anchor-is-valid */
import { StyledContainer, Title } from "global/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Props } from "./types";

const Box: React.FC<Props> = ({ label, route }) => {
  const history = useHistory();
  return (
    <StyledContainer>
      <a onClick={() => history.push({ pathname: route })}>
        <Title>{label}</Title>
      </a>
    </StyledContainer>
  );
};

export default Box;
