import { Form, Formik } from "formik";
import "./index.css";
import FormComponent from "./FormComponent";
import { initialValues } from "containers/AddProgram/utils/initialValues";
import { Day } from "schemes/program";

interface FormikProps {
  initialValues: typeof initialValues;
  loading: boolean;
  open: boolean;
  openOrClose: any;
  handleSubmit: any;
  validationSchema: any;
  setselectedDay: any;
  days: Day[];
  addDay: any;
  addSeanceInDay: any;
  deleteSeanceInDay: any;
  deleteDay: any;
  edit?: boolean;
  program: any;
  isEditingSeance: boolean;

  //seance
  exercises: any[];
  addExercise: any;
  deleteExercise: any;
  handleDrag: any;
  addSerie: any;
  deleteSerie: any;
  setSelectedExercise: any;
  selectedExercise: number;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  deleteItemToSerie: any;
  onChangeCommentsExercise: any;
  themes: any[];
  setisEditingSeance: any;
  settheme: any;
  confirmAddSeanceInDay: any;
}

const AddProgram = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
  open,
  openOrClose,
  setselectedDay,
  days,
  addDay,
  addSeanceInDay,
  deleteSeanceInDay,
  deleteDay,
  edit,
  program,
  isEditingSeance,
  exercises,
  setSelectedExercise,
  selectedExercise,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  onChangeCommentsExercise,
  themes,
  setisEditingSeance,
  settheme,
  confirmAddSeanceInDay,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <div>
            <Form className="form">
              <FormComponent
                open={open}
                openOrClose={openOrClose}
                addSeanceInDay={addSeanceInDay}
                values={values}
                setFieldValue={setFieldValue}
                days={days}
                setselectedDay={setselectedDay}
                addDay={addDay}
                deleteDay={deleteDay}
                deleteSeanceInDay={deleteSeanceInDay}
                edit={edit}
                dirty={dirty}
                isValid={isValid}
                loading={loading}
                handleSubmit={handleSubmit}
                validationSchema={validationSchema}
                program={program}
                isEditingSeance={isEditingSeance}
                exercises={exercises}
                setSelectedExercise={setSelectedExercise}
                selectedExercise={selectedExercise}
                addExercise={addExercise}
                deleteExercise={deleteExercise}
                handleDrag={handleDrag}
                addSerie={addSerie}
                deleteSerie={deleteSerie}
                editSerie={editSerie}
                duplicateSerie={duplicateSerie}
                addItemToSerie={addItemToSerie}
                deleteItemToSerie={deleteItemToSerie}
                onChangeCommentsExercise={onChangeCommentsExercise}
                themes={themes}
                setisEditingSeance={setisEditingSeance}
                settheme={settheme}
                confirmAddSeanceInDay={confirmAddSeanceInDay}
              />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddProgram;
