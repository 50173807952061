import moment from "moment";
import { WeekDaysDiv, Day, DayDiv } from "./styles";

const WeekDays = () => {
  const dateFormat = "dddd";
  const days = [];

  // let startDate = dateFns.startOfWeek(this.state.currentMonth);
  let startDate = moment().startOf("week").isoWeekday(1);

  for (let i = 0; i < 7; i++) {
    days.push(
      <DayDiv key={i}>
        <Day>{moment(startDate).add(i, "d").format(dateFormat)}</Day>
      </DayDiv>
    );
  }

  return <WeekDaysDiv>{days}</WeekDaysDiv>;
};

export default WeekDays;
