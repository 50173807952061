import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";

export const Div = styled.div`
  width: 90px;
  margin: 0 10px;
  text-align: center;
`;

interface Props {
  day: string;
  value: number;
  addDaysFromDay: any;
}

const DayCheckbox: React.FC<Props> = ({ day, value, addDaysFromDay }) => {
  return (
    <Div>
      <p>{day}</p>
      {/* <Checkbox onChange={(e) => addDaysFromDay(value, e.target.checked)} /> */}
    </Div>
  );
};

export default DayCheckbox;
