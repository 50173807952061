export interface FormValues {
  theme: string;
  type: string;
  subName: string;
  hour: string;
  duration: number | null;
  comments: string;
  numberOfWeeks: string;
  seanceName: string;
  seanceDescription: string;
  date?: string;
}

export const initialValues: FormValues = {
  type: "training",
  theme: "",
  subName: "",
  hour: "10:00",
  duration: null,
  comments: "",
  numberOfWeeks: "1",
  seanceName: "",
  seanceDescription: "",
};
