import ChatRoom from "components/ChatRoom";
import { useChatRoom } from "./hooks/useChatRoom";

interface Props {
  athleteId: string;
}

const ChatRoomContainer: React.FC<Props> = ({ athleteId }) => {
  const { messagesList, sendMessage } = useChatRoom(athleteId);

  return <ChatRoom messagesList={messagesList} sendMessage={sendMessage} />;
};

export default ChatRoomContainer;
