import { Field } from "formik";
import styled from "styled-components";
import { LabelField } from "../Input/styles";

const Div = styled.div`
  margin-bottom: 20px;
`;

const ListCheckbox = styled.div`
  display: grid;
  text-align: "initial";
  font-family: "Mulish";
`;

interface Props {
  label: string;
  list: any[];
  name: string;
}

const Checkbox: React.FC<Props> = ({ label, list, name }) => {
  return (
    <Div>
      <LabelField>{label}</LabelField>
      <ListCheckbox role="group">
        {list.map((item: any, index: number) => (
          <label key={index}>
            <Field
              type="checkbox"
              name={name}
              value={item.value}
              className="checkbox"
            />
            {item.label}
          </label>
        ))}
      </ListCheckbox>
    </Div>
  );
};

export default Checkbox;
