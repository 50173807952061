import { Lang } from "containers/CreateExercise/types";

export interface FormValues {
  pdfUrl: string;
  langs: {
    fr: Lang;
  };
}

export const initialValues: FormValues = {
  pdfUrl: "",
  langs: {
    fr: {
      name: "",
      description: "",
      keywords: [],
    },
  },
};
