import { _activityHourToDate } from "global/functions/activityHourToDate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";

export const useEditInjury = (athleteId: string, injuryId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editedInjury, setEditedInjury] = useState({});
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    async function getInjury() {
      const injurySnapshot = await firestore
        .collection("injuries")
        .doc(injuryId)
        .get();
      const data = injurySnapshot.data();
      const injury = {
        date: moment(data!.date.toDate()).format("YYYY-MM-DD"),
        injury: data!.injury,
        days: data!.days,
      };
      setEditedInjury(injury);
    }

    getInjury();
  }, [firestore, injuryId]);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await firestore
        .collection("injuries")
        .doc(injuryId)
        .update({
          editedBy: firestore.collection("users").doc(uid),
          editedAt: new Date(),
          date: _activityHourToDate(values.date, "00:00"),
          injury: values.injury,
          days: values.days,
        });

      alert.success(`Injury edited`);

      history.push({
        pathname: "athlete-planning",
        state: {
          athleteId: athleteId,
        },
      });
    } catch (error) {
      // console.log(error);
      alert.error("Error");
    }

    setLoading(false);
  };

  return { handleSubmit, editedInjury, loading };
};
