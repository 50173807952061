import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import React from "react";
import { InstantSearch } from "react-instantsearch-dom";
import UsersList from "components/UsersList";
import SubscriptionRefinementList from "components/UsersList/SubscriptionRefinementList";
// import DesactivateToggleRefinement from "components/UsersList/DesactivateToggleRefinement";

const UsersListContainer: React.FC = () => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`users_${process.env.REACT_APP_ENV_NAME}`}
    >
      <SubscriptionRefinementList attribute="subscription" />
      <SubscriptionRefinementList attribute="academy" />
      {/* <DesactivateToggleRefinement
        label="Compte désactivé"
        value={true}
        attribute="accountActivated"
      /> */}
      <UsersList />
    </InstantSearch>
  );
};

export default UsersListContainer;
