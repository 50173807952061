/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import "./top-bar.css";
import { ReactComponent as CaretIcon } from "./icons/caret.svg";
import { ReactComponent as ChatIcon } from "./icons/messenger.svg";

import { useState } from "react";
import AdminNav from "./admin/AdminNav";
// import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import AthleteNav from "./athlete/AthleteNav";
import NobodyNav from "./nobody";
import CoachNav from "./coach/CoachNav";
import { useHistory } from "react-router-dom";
import { useNbrUnreadMessages } from "hooks/useNbrUnreadMessages";

const TopBar = () => {
  const [open, setOpen] = useState(false);
  const role = useAppSelector(
    (state) => state.firebase.profile.token?.claims.role
  );

  const history = useHistory();
  const { nbrUnreadMessages } = useNbrUnreadMessages();

  if (role === null && role === null) return null;

  function getMenu() {
    switch (role) {
      case "admin":
        return <AdminNav open={open} setOpen={setOpen} />;
      case "athlete":
        return <AthleteNav open={open} setOpen={setOpen} />;
      case "coach":
        return <CoachNav open={open} setOpen={setOpen} />;
      case "nobody":
      case undefined:
        return <NobodyNav open={open} setOpen={setOpen} />;
    }
  }

  return (
    <div>
      <nav className="navbar-enjeux">
        <ul className="navbar-nav-enjeux">
          {role === "admin" && role !== undefined ? (
            <Chat
              icon={<ChatIcon />}
              history={history}
              nbr={nbrUnreadMessages}
            ></Chat>
          ) : null}

          <NavItem icon={<CaretIcon />} open={open} setOpen={setOpen}>
            {getMenu()}
          </NavItem>
        </ul>
      </nav>
    </div>
  );
};

function NavItem(props: any) {
  const { open } = props;

  return (
    <li className="nav-item">
      <a className="icon-button" onClick={() => props.setOpen(!open)}>
        {props.icon}
      </a>

      {open && props.children}
    </li>
  );
}

function Chat(props: any) {
  const { history } = props;

  return (
    <li className="nav-item" style={{ position: "relative" }}>
      <a
        className="icon-button"
        onClick={() => history.push({ pathname: "chat" })}
      >
        {props.icon}
      </a>
      {props.nbr > 0 ? (
        <span className="icon-button-badge">{props.nbr}</span>
      ) : null}
    </li>
  );
}

export default TopBar;
