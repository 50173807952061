import { Exercise } from "schemes/exercise";
import { PreviewDiv } from "./styles";

interface Props {
  works: Exercise["works"];
}

const Preview: React.FC<Props> = ({ works }) => {
  return (
    <PreviewDiv>
      <p>{works?.length} series</p>
    </PreviewDiv>
  );
};

export default Preview;
