import AthletesPains from "components/AthletesPains";
import { HeightDiv } from "components/CreateSeance/styles";
import { StyledContainer } from "global/styles";
import { Loading } from "styles";
import BodiesSection from "./BodiesSection";
import SicknessSection from "./SicknessSection";
import SleepSection from "./SleepSection";
import StressSection from "./StressSection";
import { LineSection } from "./styles";
import TiredSection from "./TiredSection";
import { CptPains } from "./types";
import WeekIndicationSection from "./WeekIndicationSection";
import WeekPainsSection from "./WeekPainsSection";
import WeightSection from "./WeightSection";

interface Props {
  medicalDashboard: any;
  cptPains: CptPains | null;
  pains: any[] | null;
  athleteId: string;
  loading: boolean;
}

const AthleteMedicalDashboard: React.FC<Props> = ({
  medicalDashboard,
  cptPains,
  pains,
  athleteId,
  loading,
}) => {
  if (loading || medicalDashboard === null) return <Loading />;

  return (
    <div>
      <WeekIndicationSection athleteId={athleteId} />
      <HeightDiv />
      <StyledContainer>
        <LineSection>
          <WeightSection weight={medicalDashboard.weight} />
          <SicknessSection sickness={medicalDashboard.sickness} />
          <WeekPainsSection pains={medicalDashboard.weekPains} />
        </LineSection>
      </StyledContainer>
      <HeightDiv />
      <StyledContainer>
        <LineSection>
          <SleepSection sleep={medicalDashboard.sleep} />
          <TiredSection tired={medicalDashboard.tired} />
          <StressSection stress={medicalDashboard.stress} />
        </LineSection>
      </StyledContainer>
      <HeightDiv />
      <BodiesSection cptPains={cptPains} />
      <HeightDiv />
      {pains !== null ? (
        <AthletesPains data={pains.slice(0, 10)} loading={loading} />
      ) : null}
    </div>
  );
};

export default AthleteMedicalDashboard;
