import { Form, Formik } from "formik";
import { FormikProps } from "./types";
import "./sign-up.css";
import Fields from "./Fields";
import Button from "global/components/Button/Button";
import AlreadyMember from "./AlreadyMember";
import CGUCheckbox from "./CGUCheckbox";
import Logo from "./Logo";
import Select from "global/components/Form/Select";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <div className="sign-up">
            <Form className="form">
              <div>
                <Select
                  value={values.type}
                  onChange={(e: any) => setFieldValue("type", e.target.value)}
                />
                <Fields />
                <CGUCheckbox />
                <Button
                  label="S'inscrire"
                  dirty={dirty}
                  isValid={isValid}
                  loading={loading}
                />
                <AlreadyMember />
              </div>
            </Form>
            <Logo />
          </div>
        );
      }}
    </Formik>
  );
};

export default FormikForm;
