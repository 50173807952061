/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ReactComponent as HomeIcon } from "../icons/home.svg";

import React, { useState, useEffect, useRef } from "react";

import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";
import LogoutButton from "menus/Logout";

interface StateProps {
  open: boolean;
  setOpen: any;
}

const AthleteNav: React.FC<StateProps> = ({ open, setOpen }) => {
  const [activeMenu] = useState<any>("main");
  const [menuHeight, setMenuHeight] = useState<any>(null);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current.firstChild.offsetHeight);
  }, []);

  function calcHeight(el: any) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownUnderItem(props: any) {
    return (
      <Link
        className="menu-item-enjeux"
        onClick={() => props.setOpen(!open)}
        to={{
          pathname: `/app/athlete/${props.link}`,
        }}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
      </Link>
    );
  }

  return (
    <div
      className="dropdown-enjeux"
      style={{ height: menuHeight, zIndex: 3 }}
      ref={dropdownRef}
    >
      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<HomeIcon />}
            link="home"
          >
            Accueil
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<HomeIcon />}
            link="products"
          >
            Offres
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<HomeIcon />}
            link="my-profile"
          >
            Profil
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<HomeIcon />}
            link="my-subscription"
          >
            Abonnement
          </DropdownUnderItem>
          {/* <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<HomeIcon />}
            link="my-invoices"
          >
            Factures
          </DropdownUnderItem> */}
          <LogoutButton />
        </div>
      </CSSTransition>
    </div>
  );
};

export default AthleteNav;
