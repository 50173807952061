import ExercisesList from "containers/ExercisesList/List";
import { StyledCol, StyledRow } from "global/styles/flexbox";
import Header from "global/components/Header";
import React from "react";
import { StyledPopup } from "components/ImageCrop/styles";
import FormExerciseContainer from "containers/CreateSeance/FormExercise";
import { useOpen } from "global/hooks/useOpen";
import Exercises from "components/CreateSeance/Exercises";
import Select from "global/components/Select";
import { CancelButtonDiv } from "components/CreateSeance/FormExercise/styles";
import CancelRoundedButton from "global/components/Button/CancelRoundedButton";

export interface SeanceInProgramFormProps {
  exercises: any[];
  addExercise: any;
  deleteExercise: any;
  handleDrag: any;
  addSerie: any;
  deleteSerie: any;
  setSelectedExercise: any;
  selectedExercise: number;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  deleteItemToSerie: any;
  values: any;
  setFieldValue: any;
  onChangeCommentsExercise: any;
  themes: any[];
  setisEditingSeance: any;
  settheme: any;
  confirmAddSeanceInDay: any;
}

const SeanceInProgramForm: React.FC<SeanceInProgramFormProps> = ({
  exercises,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  setSelectedExercise,
  selectedExercise,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  values,
  setFieldValue,
  onChangeCommentsExercise,
  themes,
  setisEditingSeance,
  settheme,
  confirmAddSeanceInDay,
}) => {
  const { open, openOrClose } = useOpen();
  return (
    <div>
      <CancelButtonDiv>
        <CancelRoundedButton
          onClick={() => setisEditingSeance(false)}
          color="#B6B6B6"
        />
      </CancelButtonDiv>
      <StyledPopup open={open} closeOnDocumentClick={false}>
        <FormExerciseContainer
          exercise={exercises[selectedExercise]}
          addSerie={addSerie}
          deleteSerie={deleteSerie}
          editSerie={editSerie}
          duplicateSerie={duplicateSerie}
          addItemToSerie={addItemToSerie}
          openOrClose={openOrClose}
          deleteItemToSerie={deleteItemToSerie}
          onChangeCommentsExercise={onChangeCommentsExercise}
        />
      </StyledPopup>
      <StyledRow>
        <StyledCol lg={9}>
          {themes.length ? (
            <Select
              options={null}
              optionsList={themes}
              value={values.theme}
              onChange={(e: any) => settheme(e.target.value)}
              label="Thème affiché dans le planning"
              name="theme"
            />
          ) : null}

          <Header title={`Seance Exercises (${exercises.length})`} />
          <Exercises
            exercises={exercises}
            deleteExercise={deleteExercise}
            handleDrag={handleDrag}
            setSelectedExercise={setSelectedExercise}
            openOrClose={openOrClose}
          />
          <button
            className="button"
            type="button"
            onClick={confirmAddSeanceInDay}
          >
            AJOUTER
          </button>
        </StyledCol>
        <StyledCol lg={3}>
          <ExercisesList onClick={addExercise} />
        </StyledCol>
      </StyledRow>
    </div>
  );
};

export default SeanceInProgramForm;
