import { MyShadows } from "global/styles/box-shadow";
import { MyGradients } from "global/styles/colors";
import styled from "styled-components";

export const RoundedButton = styled.button`
  width: 40px;
  height: 40px;
  box-shadow: ${MyShadows.primary};
  background: ${MyGradients.secondary};

  border-radius: 50%;
  border: 0px;
  box-sizing: border-box;
  outline: unset;
`;
