interface Athlete {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  appRole: string;
}

const athlete = (
  state: null | Athlete = null,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "SELECT_ATHLETE":
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default athlete;
