import { FormValues } from "containers/SignUpRegistration/utils/initialValues";
import { Formik } from "formik";
import FormComponent from "./form";
import * as Yup from "yup";

export interface FormikProps {
  initialValues: FormValues;
  loading: boolean;

  handleSubmit: (
    values: FormValues,
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
    }
  ) => Promise<void>;

  validationSchema: Yup.ObjectSchema<
    | {
        email: string;
        password: string;
      }
    | undefined,
    object
  >;
}

const SignUpForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty }) => (
        <div className="signup">
          <div className="div-signup-registration">
            <div className="logo-container">
              <img
                src="/assets/logo-enjeuxfootball-application-3.png"
                alt="logo-enjeux"
              />
            </div>
            <span>Étape 1 sur 3</span>
            <h1>Crée ton compte</h1>
            <p style={{ fontSize: 12 }}>
              Le mot de passe doit contenir au moins 8 caractères, une
              majuscule, une minuscule, un chiffre et un caractère spécial
            </p>
            <FormComponent />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default SignUpForm;
