import Input from "global/components/Form/Input";
import { CenterForm } from "global/components/Form/Input/styles";
import { ValidationButton } from "global/components/Form/ValidateButton";
import { FormProps } from "./types";

const Form: React.FC<FormProps> = ({ isValid, loading, dirty }) => {
  //console.log(values);
  return (
    <CenterForm>
      <Input
        name="startDate"
        type="date"
        placeholder="Date de début"
        label="Début du cycle"
      />
      <Input
        name="endDate"
        type="date"
        placeholder="Date de fin"
        label="Fin du cycle"
      />

      <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
    </CenterForm>
  );
};

export default Form;
