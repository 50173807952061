/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as GroupUserIcon } from "../icons/group.svg";
import { ReactComponent as PictureIcon } from "../icons/photo.svg";
import { ReactComponent as DumbbellIcon } from "../icons/dumbbell.svg";

import React, { useState, useEffect, useRef } from "react";

import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";
import LogoutButton from "menus/Logout";

interface StateProps {
  open: boolean;
  setOpen: any;
}

const AdminNav: React.FC<StateProps> = ({ open, setOpen }) => {
  const [activeMenu] = useState<any>("main");
  const [menuHeight, setMenuHeight] = useState<any>(null);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current.firstChild.offsetHeight);
  }, []);

  function calcHeight(el: any) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  // function DropdownItem(props: any) {
  //   return (
  //     <a
  //       className="menu-item-enjeux"
  //       onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
  //     >
  //       <span className="icon-button">{props.leftIcon}</span>
  //       {props.children}
  //       <span className="icon-right">{props.rightIcon}</span>
  //     </a>
  //   );
  // }

  function DropdownUnderItem(props: any) {
    return (
      <Link
        className="menu-item-enjeux"
        onClick={() => props.setOpen(!open)}
        to={{
          pathname: `/app/admin/${props.link}`,
          state: { userId: "0" },
        }}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </Link>
    );
  }

  return (
    <div
      className="dropdown-enjeux"
      style={{ height: menuHeight, zIndex: 3 }}
      ref={dropdownRef}
    >
      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<HomeIcon />}
            link="home"
          >
            Home
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<GroupUserIcon />}
            link="users-list"
          >
            Joueurs
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<GroupUserIcon />}
            link="athletes-responses"
          >
            Réponses
          </DropdownUnderItem>
          {/* <DropdownItem leftIcon={<PlusIcon />} goToMenu="add">
            Ajouter
          </DropdownItem> */}
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PictureIcon />}
            link="exercises-list"
          >
            Exercices
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<DumbbellIcon />}
            link="seances-list"
          >
            Séances
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PictureIcon />}
            link="topos-list"
          >
            Topos
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PictureIcon />}
            link="nutritions-list"
          >
            Nutrition
          </DropdownUnderItem>
          {/* <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PictureIcon />}
            link="seance-goals-list"
          >
            Objectifs
          </DropdownUnderItem> */}
          {/* <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PictureIcon />}
            link="programs-list"
          >
            Programmes
          </DropdownUnderItem> */}
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PictureIcon />}
            link="articles-list"
          >
            Articles
          </DropdownUnderItem>
          <LogoutButton />
        </div>
      </CSSTransition>

      {/* <CSSTransition
        in={activeMenu === "add"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h2>retour</h2>
          </DropdownItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PlusIcon />}
            link="create-file"
          >
            FICHIERS
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PlusIcon />}
            link="create-bilan"
          >
            BILAN
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PlusIcon />}
            link="create-image"
          >
            IMAGE
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PlusIcon />}
            link="create-seance"
          >
            SÉANCE
          </DropdownUnderItem>
          <DropdownUnderItem
            open={open}
            setOpen={setOpen}
            leftIcon={<PlusIcon />}
            link="create-player"
          >
            JOUEUR
          </DropdownUnderItem>
        </div>
      </CSSTransition> */}
    </div>
  );
};

export default AdminNav;
