import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { FormValues } from "../utils/initialValues";
import { useState } from "react";

export const useAddSeanceGoal = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const firestore = useFirestore();
  const alert = useAlert();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      await firestore.collection("goals").add({
        langs: { fr: values.name },
      });
      alert.success("Objectif ajouté");
      history.push({
        pathname: "seance-goals-list",
      });
    } catch (error) {
      //console.log(error);
      alert.error("Erreur");
    }
    setLoading(false);
  };

  return { handleSubmit, loading };
};
