import styled from "styled-components";

export const Td = styled.td`
  text-align: center;
  font-family: "Mulish";
`;

export const Tr = styled.tr`
  height: 70px;

  :hover {
    opacity: 0.2;
    cursor: pointer;
  }
`;
