import moment from "moment";

export interface FormValues {
  //global
  type: string;
  date: string;
  hour: string;
  duration: number;
  comments: string;
  document: string;

  //care
  why: string;
  estimatedStop: number;
  drugs: string;
  appointments: string;
  workingAxes: string;
  physiotherapy: string[];
  tools: string[];
  technical: string[];

  //rehab & seance
  injury: string;
  daysAfterInjury: number;
  seance: string;
  organization: string;
}

export const initialValues: FormValues = {
  type: "care",
  date: moment().format("YYYY-MM-DD"),
  hour: "10:00",
  duration: 60,
  comments: "",
  document: "",

  //care
  why: "",
  estimatedStop: 7,
  drugs: "",
  appointments: "",
  workingAxes: "",
  physiotherapy: [],
  tools: [],
  technical: [],

  //rehab & seance
  injury: "",
  daysAfterInjury: 0,
  seance: "",
  organization: "",
};
