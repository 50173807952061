import MultiSelect from "global/components/MultiSelect";
import { useEquipmentsList } from "./hooks/useEquipmentsList";

interface Props {
  selected: any;
  setSelected: any;
}

const EquipmentsListContainer: React.FC<Props> = ({
  selected,
  setSelected,
}) => {
  const { equipmentsList } = useEquipmentsList();
  return (
    <MultiSelect
      optionsList={equipmentsList}
      value={selected}
      onChange={setSelected}
      label="Équipements"
    />
  );
};

export default EquipmentsListContainer;
