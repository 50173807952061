import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import moment from "moment";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";
import { EditMyProfileValues } from "../utils/types";

export const useEditProfile = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [oldBlobImage, setBlobImage] = useState<string>("");
  const auth = useAppSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const alert = useAlert();
  const uploadOnStorage = useUploadOnStorage();

  const getInitialValues = async (setFieldValue: any) => {
    setLoading(true);
    try {
      const userSnapshotDocument = await firestore
        .collection("users")
        .doc(auth.uid)
        .get();

      const data = userSnapshotDocument.data();

      // setFieldValue("email", data?.email);
      setFieldValue("firstName", data?.firstName);
      setFieldValue("lastName", data?.lastName);
      setFieldValue("phone", data?.phoneNumber);
      setFieldValue("addressLine", data?.addressLine);
      setFieldValue("addressCity", data?.addressCity);
      setFieldValue("addressPostalCode", data?.addressPostalCode);
      setFieldValue(
        "birthDate",
        moment(data?.birthDate.toDate()).format("YYYY-MM-DD")
      );
      setFieldValue(
        "croppedImage",
        data?.photoUrl === undefined ? "" : data?.photoUrl
      );
      setFieldValue(
        "blobImage",
        data?.photoUrl === undefined ? "" : data?.photoUrl
      );
      setBlobImage(data?.photoUrl);
    } catch (error) {
      alert.error("Error");
    }
    setLoading(false);
  };

  const handleSubmit = async (values: EditMyProfileValues) => {
    setLoading(true);
    try {
      let photoUrl: any = values.blobImage;
      if (values.blobImage !== oldBlobImage && values.blobImage !== "")
        photoUrl = await uploadOnStorage(values.blobImage, "usersImg");

      if (photoUrl === "") photoUrl = null;

      await firestore
        .collection("users")
        .doc(auth.uid)
        .update({
          // email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          birthDate: moment(values.birthDate).toDate(),
          phoneNumber: values.phone,
          photoUrl: photoUrl,
        });
      alert.success("Profil modifié");
    } catch (error) {
      //console.log(error);
      alert.error("Error");
    }
    setLoading(false);
  };

  return { loading, handleSubmit, getInitialValues };
};
