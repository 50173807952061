import { RoundedButton } from "../styles";
import React from "react";
import { Props } from "./types";
import FaPlus from "global/components/Icons/FaPlus";

const AddButton: React.FC<Props> = ({ onClick }) => {
  return (
    <RoundedButton type="button" onClick={onClick}>
      <FaPlus />
    </RoundedButton>
  );
};

export default AddButton;
