import SelectAcademy from "containers/SelectAcademy";
import Input from "global/components/Form/Input";
import { CenterForm } from "global/components/Form/Input/styles";
import { ValidationButton } from "global/components/Form/ValidateButton";
import React from "react";
import { useAppSelector } from "store/storeTypes";
import { FormProps } from "./types";

const CreateGroup: React.FC<FormProps> = ({
  dirty,
  isValid,
  values,
  setFieldValue,
  loading,
}) => {
  ////(values);
  const langs = useAppSelector((state) => state.firestore.data.langs);
  const lng = useAppSelector((state) => state.lng);

  if (langs)
    return (
      <CenterForm>
        <SelectAcademy
          academy={values.academyId}
          setAcademy={(e: any) => setFieldValue("academyId", e.target.value)}
        />
        <Input
          name={"name"}
          type="text"
          placeholder="Name of group..."
          label={langs[lng].passwordInputNullError}
        />

        <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
      </CenterForm>
    );

  return null;
};

export default CreateGroup;
