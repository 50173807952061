import Select from "global/components/Select";
import { useLevelsList } from "./hooks/useLevelsList";

interface Props {
  selected: any;
  setSelected: any;
}

const LevelsListContainer: React.FC<Props> = ({ selected, setSelected }) => {
  const { levelsList } = useLevelsList();
  return (
    <Select
      options={levelsList}
      value={selected}
      onChange={setSelected}
      optionsList={levelsList}
      label="Niveau"
      name="name"
    />
  );
};

export default LevelsListContainer;
