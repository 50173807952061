import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";
import CreateSeanceContainer from "containers/CreateSeance";

const CreateSeance: React.FC<any> = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Create seance" />
        <CreateSeanceContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default React.memo(CreateSeance);
