import Input from "global/components/Form/Input";
import { CenterForm } from "global/components/Form/Input/styles";
import { ValidationButton } from "global/components/Form/ValidateButton";
import { FormProps } from "./types";

const Form: React.FC<FormProps> = ({ dirty, isValid, loading }) => {
  return (
    <CenterForm>
      <Input
        name="name"
        type="text"
        placeholder="Nom de l'objectif"
        label="Name"
      />

      <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
    </CenterForm>
  );
};

export default Form;
