import Axios from "axios";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";

export const useAddAthlete = () => {
  const auth = useAppSelector((state) => state.firebase.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useAlert();
  const history = useHistory();
  const firestore = useFirestore();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      const userSnapshot = await firestore
        .collection("users")
        .doc(auth.uid)
        .get();
      const userData = userSnapshot.data();

      // await firestore.collection("users").add({
      //   email: values.email,
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   coach: firestore.collection("users").doc(auth.uid),
      //   pending: true,
      // });

      await Axios.post(
        "https://hook.eu1.make.com/q4nyhc251n0o28cqtuacrrq2m52gohk9",
        {
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            coachName: userData?.firstName,
            coachId: auth.uid,
          },
        }
      );
      alert.success(
        `Un mail vient d'être envoyé à ${values.email}. L'athlète peut maintenant créer son compte`
      );
      history.replace({ pathname: "my-athletes" });
    } catch (error: any) {
      alert.error("Erreur, contactez corentin");
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
