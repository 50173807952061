import { Formik } from "formik";
import Form from "./Form";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  edit,
  article,
  loading,
  contents,
  addContent,
  selectTypeofContent,
  editContent,
  deleteContent,
  handleDrag,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form
          isValid={isValid}
          dirty={dirty}
          values={values}
          setFieldValue={setFieldValue}
          edit={edit}
          article={article}
          loading={loading}
          contents={contents}
          addContent={addContent}
          selectTypeofContent={selectTypeofContent}
          editContent={editContent}
          deleteContent={deleteContent}
          handleDrag={handleDrag}
        />
      )}
    </Formik>
  );
};

export default FormikForm;
