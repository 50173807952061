import { Input } from "@material-ui/core";
import React from "react";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  series: Work["series"];
  editSerie: any;
  index: number;
}

const Series: React.FC<Props> = ({ series, editSerie, index }) => {
  return (
    <BlocValueDiv>
      <TitleBloc>SÉRIES</TitleBloc>
      <Input
        type="number"
        name="series"
        value={series}
        onChange={(e) => editSerie(e, index, "series")}
      />
    </BlocValueDiv>
  );
};

export default Series;
