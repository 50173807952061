import DeleteSeance from "containers/CreateSeance/DeleteSeance";
import EditSeanceContainer from "containers/CreateSeance/EditSeance";
import DeleteRoundedButton from "global/components/Button/DeleteRoundedButton";
import HeaderContainer from "global/components/HeaderContainer";
import { useOpen } from "global/hooks/useOpen";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const EditSeance: React.FC<any> = (props) => {
  const { open, openOrClose } = useOpen();
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer
          title="Edit seance"
          displayButton
          Button={<DeleteRoundedButton onClick={openOrClose} />}
        />
        <DeleteSeance
          open={open}
          cancelClick={openOrClose}
          seanceRef={props.location.state.objectID}
        />
        <EditSeanceContainer seanceRef={props.location.state.objectID} />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default React.memo(EditSeance);
