import { Formik } from "formik";
import { CenterForm } from "global/components/Form/Input/styles";
import TextArea from "global/components/Form/Input/TextArea";
import InputPdfFile from "global/components/Form/InputPdfFile";
import { ValidationButton } from "global/components/Form/ValidateButton";
import { StyledContainer } from "global/styles";
import CareForm from "./CareForm";
import GlobalForm from "./GlobalForm";
import RehabForm from "./RehabForm";
import SeanceForm from "./SeanceForm";
import TypeCardSelect from "./TypeCardSelect";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
  selectInjury,
  selectSeance,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, setFieldValue, values }) => (
        <StyledContainer>
          <CenterForm>
            <TypeCardSelect setFieldValue={setFieldValue} value={values.type} />
            <GlobalForm />
            {values.type === "care" ? <CareForm /> : null}
            {values.type === "rehab" ? (
              <RehabForm
                values={values}
                selectInjury={selectInjury}
                setFieldValue={setFieldValue}
              />
            ) : null}
            {values.type === "rehab" || values.type === "seance" ? (
              <SeanceForm
                values={values}
                selectSeance={(e: any) =>
                  selectSeance(e.target.value, setFieldValue)
                }
              />
            ) : null}
            <TextArea
              name="comments"
              type="text"
              placeholder="Commentaires et observations..."
              label="Commentaires"
            />
            <InputPdfFile
              onChange={(e: any) =>
                setFieldValue("document", e.target.files[0])
              }
              label="Associer un pdf"
            />
            <ValidationButton
              dirty={dirty}
              isValid={isValid}
              loading={loading}
            />
          </CenterForm>
        </StyledContainer>
      )}
    </Formik>
  );
};

export default FormikForm;
