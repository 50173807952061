import MultiSelect from "global/components/MultiSelect";
import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { Props } from "./types";

const SelectThemeContainer: React.FC<Props> = ({ theme, setTheme }) => {
  const [themesList, setthemesList] = useState<any[]>([]);

  const firestore = useFirestore();

  useEffect(() => {
    async function getData() {
      const themesSnapshot = await firestore.collection("themes").get();

      const array:
        | ((prevState: never[]) => never[])
        | { label: any; value: string }[] = [];
      themesSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const label = data.name.fr;
        array.push({ label: label, value: doc.id });
      });
      setthemesList(array);
    }

    getData();
  }, [firestore]);

  if (themesList)
    return (
      <MultiSelect
        optionsList={themesList}
        value={theme}
        onChange={setTheme}
        label="Thème(s) (obligatoire)"
      />
    );
  return null;
};

export default SelectThemeContainer;
