import { MyShadows } from "global/styles/box-shadow";
import { MyGradients } from "global/styles/colors";
import styled from "styled-components";

export const Button = styled.button`
  margin-top: 30px;
  width: 250px;
  height: 40px;
  box-shadow: ${MyShadows.primary};
  background: ${MyGradients.primary};

  border-radius: 50px;
  border: 0px;
  box-sizing: border-box;
  outline: unset;

  font-family: "Mulish";
  font-weight: 400;
  font-size: 14px;

  &:disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
    & > * {
      pointer-events: none;
    }
  }
`;
