import { DocumentReference, DocumentData } from "@firebase/firestore-types";
import firebase from "firebase";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { Exercise } from "schemes/exercise";

import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../types";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";

export const useEditSeance = (exercises: Exercise[], seanceRef: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useAlert();
  const firestore = useFirestore();
  const uploadOnStorage = useUploadOnStorage();

  const history = useHistory();
  const [seance, setSeance] = useState({});
  const uid = useAppSelector((state) => state.firebase.auth.uid);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      if (!exercises.length) {
        alert.error("Seance should have at least one exercise");
        setLoading(false);
        return;
      }

      let img: any = "";
      if (values.blobImage !== "") {
        img = await uploadOnStorage(values.blobImage, "seancesImg");
      }

      const keywords: string[] = [];
      values.langs.fr.keywords.map((keyword: any) =>
        keywords.push(keyword.text)
      );

      const themesArray: DocumentReference<DocumentData>[] = [];
      values.themes.forEach((theme) => {
        themesArray.push(firestore.collection("themes").doc(theme.value));
      });

      const goalsArray: DocumentReference<DocumentData>[] = [];
      values.goals.forEach((goal: any) => {
        goalsArray.push(firestore.doc(goal.value));
      });

      const muscleGroupsArray: DocumentReference[] = [];
      const muscleGroupsArrayId: string[] = [];

      const jointInjuriesArray: DocumentReference<DocumentData>[] = [];
      const jointInjuriesArrayId: string[] = [];

      const equipmentsArray: DocumentReference<DocumentData>[] = [];
      const equipmentsArrayId: string[] = [];

      const positionsArray: DocumentReference<DocumentData>[] = [];
      const positionsArrayId: string[] = [];

      const exercisesInSeance: any[] = [];
      await Promise.all(
        exercises.map(async (exercise: Exercise) => {
          const exerciseSnapshot = await firebase
            .firestore()
            .collection("exercises")
            .doc(exercise.key)
            .get();
          const exerciseData = exerciseSnapshot.data();

          exerciseData?.muscleGroups.forEach((theme: DocumentReference) => {
            if (muscleGroupsArrayId.indexOf(theme.id) === -1) {
              muscleGroupsArrayId.push(theme.id);
              muscleGroupsArray.push(
                firestore.collection("muscleGroups").doc(theme.id)
              );
            }
          });

          exerciseData?.jointInjuries.forEach((theme: DocumentReference) => {
            if (jointInjuriesArrayId.indexOf(theme.id) === -1) {
              jointInjuriesArrayId.push(theme.id);
              jointInjuriesArray.push(
                firestore.collection("jointInjuries").doc(theme.id)
              );
            }
          });

          exerciseData?.equipments.forEach((theme: DocumentReference) => {
            if (equipmentsArrayId.indexOf(theme.id) === -1) {
              equipmentsArrayId.push(theme.id);
              equipmentsArray.push(
                firestore.collection("equipments").doc(theme.id)
              );
            }
          });

          exerciseData?.positions.forEach((theme: DocumentReference) => {
            if (positionsArrayId.indexOf(theme.id) === -1) {
              positionsArrayId.push(theme.id);
              positionsArray.push(
                firestore.collection("positions").doc(theme.id)
              );
            }
          });

          if (exercise.comments === undefined || exercise.comments === null)
            exercise.comments = "";
          exercisesInSeance.push({
            exercise: firebase
              .firestore()
              .collection("exercises")
              .doc(exercise.key),
            works: exercise.works,
            comments: exercise.comments,
          });
        })
      );

      await firestore
        .collection("seances")
        .doc(seanceRef)
        .update({
          themes: themesArray,
          level: firestore.collection("levels").doc(values.level),
          muscleGroups: muscleGroupsArray,
          jointInjuries: jointInjuriesArray,
          goals: goalsArray,
          equipments: equipmentsArray,
          positions: positionsArray,
          imageUrl: img,

          langs: {
            fr: {
              name: values.langs.fr.name,
              description: values.langs.fr.description,
              keywords: keywords,
            },
          },
          estimatedDuration: values.estimatedDuration,
          editedBy: firebase.firestore().collection("users").doc(uid),
          editedAt: new Date(),
          exercises: exercisesInSeance,
          educative: values.educative,
          free: values.free,
          inLibrary: values.inLibrary,
          preparation: values.preparation,
        });

      alert.success(`Seance edited`);
      history.push({ pathname: "seances-list" });
    } catch (error) {
      console.log(error);
      alert.error("error");
    }

    setLoading(false);
  };

  useEffect(() => {
    async function getSeance() {
      try {
        const querySnapshot = await firestore
          .collection("seances")
          .doc(seanceRef)
          .get();

        const data = querySnapshot.data();

        let createdBy: string = "";
        if (data?.createdBy !== undefined) {
          createdBy = (await data.createdBy.get()).data().firstName;
        } else {
          createdBy = "Tanguy";
        }

        const themesArr: any[] = [];

        await Promise.all(
          data?.themes.map(async (theme: any) => {
            const themeData = (await theme.get()).data();
            themesArr.push({
              label: themeData.name.fr.toUpperCase(),
              value: theme.id,
            });
          })
        );

        const muscleGroupsArr: any[] = [];
        await Promise.all(
          data?.muscleGroups.map(async (muscleGroup: any) => {
            const muscleGroupData = (await muscleGroup.get()).data();
            muscleGroupsArr.push({
              label: muscleGroupData.langs.fr.toUpperCase(),
              value: muscleGroup.id,
            });
          })
        );

        const jointInjuriesArr: any[] = [];
        await Promise.all(
          data?.jointInjuries.map(async (jointInjury: any) => {
            const jointInjuryData = (await jointInjury.get()).data();
            jointInjuriesArr.push({
              label: jointInjuryData.langs.fr.toUpperCase(),
              value: jointInjury.id,
            });
          })
        );

        const equipmentsArr: any[] = [];
        await Promise.all(
          data?.equipments.map(async (equipment: any) => {
            const equipmentData = (await equipment.get()).data();
            equipmentsArr.push({
              label: equipmentData.langs.fr.toUpperCase(),
              value: equipment.id,
            });
          })
        );

        const goalsArr: any[] = [];
        await Promise.all(
          data?.goals.map(async (goal: any) => {
            const goalRef = (await goal.get()).ref.path;
            const goalData = (await goal.get()).data();
            goalsArr.push({
              label: goalData.langs.fr.toUpperCase(),
              value: goalRef,
            });
          })
        );

        const positionsArr: any[] = [];
        await Promise.all(
          data?.positions.map(async (position: any) => {
            const positionData = (await position.get()).data();
            positionsArr.push({
              label: positionData.langs.fr.toUpperCase(),
              value: position.id,
            });
          })
        );

        console.log(data?.free);
        console.log(data?.inLibrary);

        let newSeance = {
          level: (await data?.level.get()).id,
          themes: themesArr,
          muscleGroups: muscleGroupsArr,
          jointInjuries: jointInjuriesArr,
          equipments: equipmentsArr,
          goals: goalsArr,
          positions: positionsArr,
          langs: data?.langs,
          blobImage: data?.imageUrl,
          estimatedDuration: data?.estimatedDuration,
          createdBy: createdBy,
          educative:
            data?.educative === undefined ||
            data?.educative === null ||
            data?.educative === false
              ? false
              : true,
          free:
            data?.free === undefined ||
            data?.free === null ||
            data?.free === false
              ? false
              : true,
          inLibrary:
            data?.inLibrary === undefined ||
            data?.inLibrary === null ||
            data?.inLibrary === false
              ? false
              : true,
          preparation:
            data?.preparation === undefined ||
            data?.preparation === null ||
            data?.preparation === false
              ? false
              : true,
        };

        console.log(newSeance);
        setSeance(newSeance);
      } catch (error) {
        //console.log(error);
      }
    }

    getSeance();
  }, [firestore, seanceRef]);

  return { handleSubmit, seance, loading };
};
