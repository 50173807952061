import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  typeCardio: Work["typeCardio"];
  editSerie: any;
  index: number;
}

const TypeCardioValue: React.FC<Props> = ({ typeCardio, editSerie, index }) => {
  return (
    <BlocValueDiv>
      <TitleBloc>TYPE CARDIO</TitleBloc>
      <Select
        name="typeCardio"
        value={typeCardio}
        onChange={(e) => editSerie(e, index, "typeCardio")}
      >
        <MenuItem value={"warmUp"}>Warm Up</MenuItem>
        <MenuItem value={"training"}>Training</MenuItem>
        <MenuItem value={"coolDown"}>Cool Down</MenuItem>
      </Select>
    </BlocValueDiv>
  );
};

export default TypeCardioValue;
