import { Formik } from "formik";
import Form from "./Form";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <div
          style={{
            backgroundColor: "#FFF",
            height: "100vh",
          }}
        >
          <Form
            isValid={isValid}
            dirty={dirty}
            values={values}
            setFieldValue={setFieldValue}
            loading={loading}
          />
        </div>
      )}
    </Formik>
  );
};

export default FormikForm;
