import CreateAthleteAccount from "components/CreateAthleteAccount";
import React from "react";
import { useCreateAthleteAccount } from "./hooks/useCreateAthleteAccount";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  coachUid: string;
}

const CreateAthleteAccountContainer: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  coachUid,
}) => {
  const { handleSubmit, loading } = useCreateAthleteAccount(
    firstName,
    lastName,
    email,
    coachUid
  );

  return (
    <CreateAthleteAccount
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default CreateAthleteAccountContainer;
