import {
  StyledDivTwiceDiv,
  StyledSectionTitle,
  StyledSectionValue,
  StyledTitle,
  StyledTwiceDiv,
  StyledValue,
  Div,
} from "./styles";

interface Props {
  stress: Array<number | string>;
}

const StressSection: React.FC<Props> = ({ stress }) => {
  return (
    <div>
      <StyledSectionTitle>
        <StyledTitle>Stress</StyledTitle>
      </StyledSectionTitle>
      <StyledDivTwiceDiv>
        <Div>
          <StyledTwiceDiv>
            <StyledTitle>Semaine 0</StyledTitle>
          </StyledTwiceDiv>
          <StyledSectionValue>
            <StyledValue>{stress[0]}</StyledValue>
          </StyledSectionValue>
        </Div>
        <Div>
          <StyledTwiceDiv>
            <StyledTitle>Semaine-1</StyledTitle>
          </StyledTwiceDiv>
          <StyledSectionValue>
            <StyledValue>{stress[1]}</StyledValue>
          </StyledSectionValue>
        </Div>
      </StyledDivTwiceDiv>
    </div>
  );
};

export default StressSection;
