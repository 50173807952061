import { LittleProfilePic } from "global/styles/img";
import moment from "moment";
import { MessageScheme } from "schemes/chatRoom";
import { useAppSelector } from "store/storeTypes";

interface Props {
  msg: MessageScheme;
}

const ChatMessage: React.FC<Props> = ({ msg }) => {
  const currentUser = useAppSelector((state) => state.firebase);
  const { idFrom, content, type, timestamp } = msg;
  const date = new Date(parseInt(timestamp));

  const messageClass = idFrom === currentUser.auth.uid ? "sent" : "received";

  return (
    <div>
      <div className={`message ${messageClass}`}>
        {/* <p className="date">{moment(date).format("dddd DD/MM, HH:mm")}</p> */}
        <LittleProfilePic img={"photoUrl"} />
        {type === 0 ? (
          <div>
            <p className="message-text">{content}</p>
            <span className="message-date">
              {moment(date).format("dddd DD MMMM HH:mm")}
            </span>
          </div>
        ) : (
          <img src={content} width="200" alt="" />
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
