import "./select.css";

interface Props {
  value: any;
  onChange: any;
}

const Select: React.FC<Props> = ({ value, onChange }) => {
  return (
    <div className="group">
      <select value={value} onChange={onChange}>
        <option value="private">Particulier</option>
        <option value="professional">Professionnel</option>
      </select>
      <span className="bar"></span>
    </div>
  );
};

export default Select;
