import MultiSelect from "global/components/MultiSelect";
import { useGoalsList } from "./hooks/useGoalsList";

interface Props {
  selected: any;
  setSelected: any;
  themes: any[];
}

const GoalsListContainer: React.FC<Props> = ({
  selected,
  setSelected,
  themes,
}) => {
  const { goalsList } = useGoalsList(themes);
  return (
    <MultiSelect
      optionsList={goalsList}
      value={selected}
      onChange={setSelected}
      label="Objectif(s)"
    />
  );
};

export default GoalsListContainer;
