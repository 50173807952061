import React from "react";
import MenuItem from "./MenuItem";
import { StyledUl } from "./styles";
import { MenuItemProps } from "./types";

interface Props {
  athleteId: string;
}

const Menu: React.FC<Props> = ({ athleteId }) => {
  const MenuItems: MenuItemProps[] = [
    { label: "Profil", link: "athlete-profile", state: { athleteId } },
    // { label: "Season", link: "athlete-season-history" },
    { label: "Planning", link: "athlete-planning", state: { athleteId } },
    {
      label: "Activités",
      link: "athlete-activities",
      state: { athleteId },
    },
    {
      label: "Weekly Goals",
      link: "athlete-weekly-goals",
      state: { athleteId },
    },
    {
      label: "Curves",
      link: "athlete-curves",
      state: { athleteId },
    },
    {
      label: "Medical",
      link: "athlete-medical-dashboard",
      state: { athleteId },
    },
    {
      label: "Excel",
      link: "athlete-raw-data",
      state: { athleteId },
    },
    {
      label: "Bilans",
      link: "athlete-balance-sheets",
      state: { athleteId },
    },
    {
      label: "Fiches",
      link: "athlete-cards",
      state: { athleteId },
    },
    {
      label: "Cycles",
      link: "athlete-work-cycles",
      state: { athleteId },
    },
    {
      label: "Chat",
      link: "athlete-chat",
      state: { athleteId },
    },
  ];
  return (
    <StyledUl>
      {MenuItems.map((menuItem: any, index: number) => (
        <MenuItem
          key={index}
          label={menuItem.label}
          link={menuItem.link}
          state={menuItem.state}
        />
      ))}
    </StyledUl>
  );
};

export default Menu;
