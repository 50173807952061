import { StyledPopup } from "components/ImageCrop/styles";
import React from "react";
import CancelButton from "../Button/CancelButton";
import RedButton from "../Button/RedButton";
import { Div } from "./styles";
import { Props } from "./types";

const DeletePopup: React.FC<Props> = ({
  open,
  cancelClick,
  validateClick,
  loading,
}) => {
  return (
    <StyledPopup open={open} closeOnDocumentClick={false}>
      <Div>
        <div>
          <p>Delete ?</p>
        </div>

        <div>
          <RedButton onClick={validateClick} loading={loading} />
          <div />
          <CancelButton onClick={cancelClick} />
        </div>
      </Div>
    </StyledPopup>
  );
};

export default DeletePopup;
