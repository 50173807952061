import AthleteCurves from "components/AthleteCurves";
import { useAthleteCurves } from "./hooks/useAthleteCurves";

interface Props {
  athleteId: string;
}

const AthleteCurvesContainer: React.FC<Props> = ({ athleteId }) => {
  const { marker, setMarker, data, loading } = useAthleteCurves(athleteId);
  return (
    <AthleteCurves
      marker={marker}
      setMarker={setMarker}
      data={data!}
      loading={loading}
    />
  );
};

export default AthleteCurvesContainer;
