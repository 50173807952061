import { HeightDiv } from "components/CreateSeance/styles";
import ExportRawDataExcel from "containers/AthleteRawData/ExportRawDataExcel";
import { RawData } from "containers/AthleteRawData/types";
import { Td, Tr } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Paper, StyledContainer } from "global/styles";
import styled from "styled-components";
import { Loading } from "styles";

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;

interface Props {
  data: RawData[] | null;
  cptPains: number;
  startDate: string;
  endDate: string;
  reliability: any;
  loading: boolean;
}

const AthleteRawData: React.FC<Props> = ({
  data,
  cptPains,
  startDate,
  endDate,
  reliability,
  loading,
}) => {
  const headItemList = [
    "Semaine",
    "Date",
    "Repos",
    "Sommeil",
    "Stess",
    "Fatigue",
    "Concentration",
    "Plaisir",
    "Confiance en soi",
    "Maladie",
    "Poids",
    "Entrainement",
    "Heure",
    "Durée",
    "Intensité moyenne",
    "Haute intensité",
    "Condition",
    "Dynamisme",
    "Durée perçue",
  ];

  for (let i = 0; i < cptPains; i++) {
    headItemList.push(`Douleur ${i + 1}`, "Latéralité", "Intensité");
  }

  if (loading || data === null || reliability === null) return <Loading />;

  return (
    <div>
      <StyledContainer>
        <ButtonDiv>
          <ExportRawDataExcel
            csvData={data}
            fileName={`${
              data.length ? data[0].athlete : ""
            }_${startDate}-${endDate}`}
          />
        </ButtonDiv>
        <div>
          <p>Fiabilité : {reliability.reliability}%</p>
          <p>
            Réponses : {reliability.responses}/{reliability.days}
          </p>
        </div>
      </StyledContainer>
      <HeightDiv />
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {data.map((values: RawData, index: number) => (
              <Tr key={index}>
                <Td>{values.week}</Td>
                <Td>{values.date}</Td>
                <Td>{values.rest}</Td>
                <Td>{values.sleep}</Td>
                <Td>{values.stress}</Td>
                <Td>{values.tired}</Td>
                <Td>{values.concentration}</Td>
                <Td>{values.pleasure}</Td>
                <Td>{values.selfConfidence}</Td>
                <Td>{values.sickness}</Td>
                <Td>{values.weight}</Td>
                {values.activity !== null && values.activity !== undefined ? (
                  <>
                    <Td>{values.activity!.theme}</Td>
                    <Td>{values.activity!.hour}</Td>
                    <Td>{values.activity!.duration}</Td>
                    <Td>{values.activity!.markers.mediumIntensity}</Td>
                    <Td>{values.activity!.markers.highIntensity}</Td>
                    <Td>{values.activity!.markers.condition}</Td>
                    <Td>{values.activity!.markers.dynamism}</Td>
                    <Td>{values.activity!.markers.perceivedDuration}</Td>
                  </>
                ) : (
                  <>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </>
                )}
                {values.pains?.map((pain, index) => (
                  <>
                    <Td>
                      {pain.type.toUpperCase()} {pain.location.toUpperCase()}
                    </Td>
                    <Td>{pain.laterality.toUpperCase()}</Td>
                    <Td>{pain.intensity}</Td>
                  </>
                ))}
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default AthleteRawData;
