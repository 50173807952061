import AthleteActivities from "components/AthleteActivities";
import { useAthleteActivities } from "./hooks/useAthleteActivities";

interface Props {
  athleteId: string;
}

const AthleteActivitiesContainer: React.FC<Props> = ({ athleteId }) => {
  const { activities, loading } = useAthleteActivities(athleteId);
  return <AthleteActivities data={activities} loading={loading} />;
};

export default AthleteActivitiesContainer;
