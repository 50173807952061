import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";

export const useInvoices = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [invoicesList, setInvoicesList] = useState<any[]>([]);

  const auth = useAppSelector((state) => state.firebase.auth);

  const alert = useAlert();
  const firestore = useFirestore();

  useEffect(() => {
    async function getSubscriptionData() {
      setLoading(true);
      try {
        const subscriptionSnapshot = await firestore
          .collection("users")
          .doc(auth.uid)
          .collection("subscriptions")
          .get();

        const invoicesSnapshot = await firestore
          .collection("users")
          .doc(auth.uid)
          .collection("subscriptions")
          .doc(subscriptionSnapshot.docs[0].id)
          .collection("invoices")
          .get();

        const array: any[] = [];
        invoicesSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          array.push({
            id: data.id,
            invoicePdf: data.invoice_pdf,
          });
        });

        setInvoicesList(array);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getSubscriptionData();
  }, [alert, auth.uid, firestore]);

  return { loading, invoicesList };
};
