import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  label: string;
  display: boolean;
  setDisplay: any;
}

const Title: React.FC<Props> = ({ label, display, setDisplay }) => {
  return (
    <div className="label-container" onClick={() => setDisplay(!display)}>
      <h2>{label}</h2>
      {display ? (
        <FontAwesomeIcon icon={faChevronUp} size="1x" color="000" />
      ) : (
        <FontAwesomeIcon icon={faChevronDown} size="1x" color="000" />
      )}
    </div>
  );
};

export default Title;
