import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  laterality: Work["laterality"];
  editSerie: any;
  index: number;
}

const Rest: React.FC<Props> = ({ laterality, editSerie, index }) => {
  return (
    <BlocValueDiv>
      <TitleBloc>LATERALITY</TitleBloc>
      <Select
        name="laterality"
        value={laterality}
        onChange={(e) => editSerie(e, index, "laterality")}
      >
        <MenuItem value={"twice"}>Twice</MenuItem>
        <MenuItem value={"right"}>Right</MenuItem>
        <MenuItem value={"left"}>Left</MenuItem>
      </Select>
    </BlocValueDiv>
  );
};

export default Rest;
