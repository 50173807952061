import CreateExerciseContainer from "containers/CreateExercise";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const CreateExercise: React.FC = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Create Exercice" />
        <CreateExerciseContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default CreateExercise;
