import { Formik } from "formik";
import Form from "./Form";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
  currentCycle,
  reportUrl,
}: FormikProps) => {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isValid, setFieldValue, values }) => (
        <Form
          isValid={isValid}
          dirty={true}
          loading={loading}
          setFieldValue={setFieldValue}
          values={values}
          currentCycle={currentCycle}
          reportUrl={reportUrl}
        />
      )}
    </Formik>
  );
};

export default FormikForm;
