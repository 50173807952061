import MultiSelect from "global/components/MultiSelect";
import { useJointInjuriesList } from "./hooks/useJointInjuriesList";

interface Props {
  selected: any;
  setSelected: any;
}

const JointInjuriesListContainer: React.FC<Props> = ({
  selected,
  setSelected,
}) => {
  const { jointInjuriesList } = useJointInjuriesList();
  return (
    <MultiSelect
      optionsList={jointInjuriesList}
      value={selected}
      onChange={setSelected}
      label="Lésion(s) articulaire(s)"
    />
  );
};

export default JointInjuriesListContainer;
