import AddSeanceGoalContainer from "containers/AddSeanceGoal";
import { GlobalDiv, StyledContainer } from "global/styles";

const AddSeanceGoal = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <AddSeanceGoalContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default AddSeanceGoal;
