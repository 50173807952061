import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";

export const useSeanceDayList = (date: string) => {
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const [seanceDayList, setSeanceDayList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const alert = useAlert();
  useEffect(() => {
    async function getSeanceDayList() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        const eventsSnapshot = await firestore
          .collection("events")
          .where("athlete", "==", athleteSnapshot.ref)
          .where("date", ">=", moment(date).startOf("day").toDate())
          .where("date", "<=", moment(date).endOf("day").toDate())
          .get();

        const array: any[] = [
          { name: "Sélectionner une séance dans la liste", value: "" },
        ];
        eventsSnapshot.docs.map(async (doc) => {
          if (
            doc.data().name !== "TRAINING" &&
            doc.data().name !== "MATCH" &&
            doc.data().name !== "SELECTION"
          )
            array.push({
              name: doc.data().name,
              value: doc.id,
            });
        });

        setSeanceDayList(array);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getSeanceDayList();
  }, [alert, athleteId, firestore, date]);

  //console.log(seanceDayList);

  return { seanceDayList, loading };
};
