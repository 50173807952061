import {
  StyledSectionTitle,
  StyledSectionValue,
  StyledTitle,
  StyledValue,
} from "./styles";

interface Props {
  sickness: string;
}

const SicknessSection: React.FC<Props> = ({ sickness }) => {
  return (
    <div>
      <StyledSectionTitle>
        <StyledTitle>Malade</StyledTitle>
      </StyledSectionTitle>
      <StyledSectionValue>
        <StyledValue>{sickness}</StyledValue>
      </StyledSectionValue>
    </div>
  );
};

export default SicknessSection;
