import moment from "moment";

export interface FormValues {
  currentWorkResume: string;
  axeP1: string;
  axeP2: string;
  axeP3: string;
  axeP4: string;
  axeS1: string;
  axeS2: string;
  axeS3: string;
  axeS4: string;
  //HEALTH
  croppedImageSleep: any;
  blobImageSleep: any;
  croppedImageStress: any;
  blobImageStress: any;
  croppedImageTired: any;
  blobImageTired: any;
  healthComments: string;

  //MENTAL
  croppedImageJoy: any;
  blobImageJoy: any;
  croppedImageSelfConfidence: any;
  blobImageSelfConfidence: any;
  croppedImageConcentration: any;
  blobImageConcentration: any;
  mentalComments: string;

  //EFFORT
  croppedImageMediumIntensity: any;
  blobImageMediumIntensity: any;
  croppedImageHighIntensity: any;
  blobImageHighIntensity: any;
  intensityComments: string;
  croppedImageDynamism: any;
  blobImageDynamism: any;
  croppedImagePerceivedDuration: any;
  blobImagePerceivedDuration: any;
  croppedImageCondition: any;
  blobImageCondition: any;
  effortComments: string;

  //CONCLUSION
  pains: string;
  health: string;
  mental: string;
  athletic: string;
  statistic: string;
  other: string;
  conclusion: string;

  //NEXT WORK CYCLE

  createNextWorkCycle: boolean;
  nextStartDate: string;
  nextEndDate: string;
  nextWorkResume: string;
  nextAxeP1: string;
  nextAxeP2: string;
  nextAxeP3: string;
  nextAxeP4: string;
  nextAxeS1: string;
  nextAxeS2: string;
  nextAxeS3: string;
  nextAxeS4: string;
}

export const initialValues: FormValues = {
  currentWorkResume: "",
  axeP1: "",
  axeP2: "",
  axeP3: "",
  axeP4: "",
  axeS1: "",
  axeS2: "",
  axeS3: "",
  axeS4: "",
  croppedImageSleep: "",
  blobImageSleep: "",
  croppedImageStress: "",
  blobImageStress: "",
  croppedImageTired: "",
  blobImageTired: "",
  healthComments: "",
  croppedImageJoy: "",
  blobImageJoy: "",
  croppedImageSelfConfidence: "",
  blobImageSelfConfidence: "",
  croppedImageConcentration: "",
  blobImageConcentration: "",
  mentalComments: "",
  croppedImageMediumIntensity: "",
  blobImageMediumIntensity: "",
  croppedImageHighIntensity: "",
  blobImageHighIntensity: "",
  intensityComments: "",
  croppedImageDynamism: "",
  blobImageDynamism: "",
  croppedImagePerceivedDuration: "",
  blobImagePerceivedDuration: "",
  croppedImageCondition: "",
  blobImageCondition: "",
  effortComments: "",
  pains: "",
  health: "",
  mental: "",
  athletic: "",
  statistic: "",
  other: "",
  conclusion: "",
  createNextWorkCycle: false,
  nextStartDate: moment().format("YYYY-MM-DD"),
  nextEndDate: moment().add(6, "w").format("YYYY-MM-DD"),
  nextWorkResume: "",
  nextAxeP1: "",
  nextAxeP2: "",
  nextAxeP3: "",
  nextAxeP4: "",
  nextAxeS1: "",
  nextAxeS2: "",
  nextAxeS3: "",
  nextAxeS4: "",
};
