import CreateAthleteAccountContainer from "containers/CreateAthleteAccount";
import React from "react";

const CreateAthleteAccount: React.FC<any> = (props: any) => {
  return (
    <CreateAthleteAccountContainer
      firstName={props.match.params.firstName}
      lastName={props.match.params.lastName}
      email={props.match.params.email}
      coachUid={props.match.params.coachUid}
    />
  );
};

export default CreateAthleteAccount;
