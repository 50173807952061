import { store } from '.'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

//Define RootState and AppDispatch state inferred from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
