import styled from "styled-components";

export const StyledLi = styled.li`
  font-family: "Mulish";
  text-align: center;
  margin: 20px 0;

  :hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;

export const StyledUl = styled.ul`
  padding-bottom: 30px;
`;
