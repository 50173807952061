import { StyledValue } from "./styles";
import moment from "moment";
import "moment/locale/fr";
import { StyledContainer } from "global/styles";
import HeaderContainer from "global/components/HeaderContainer";
import AddButton from "global/components/Button/AddButton";
import { useHistory } from "react-router-dom";

interface Props {
  athleteId: string;
}

const WeekIndicationSection: React.FC<Props> = ({ athleteId }) => {
  const monday = moment().subtract(1, "w").isoWeekday(1).format("D MMMM");
  const sunday = moment().subtract(1, "w").isoWeekday(6).format("D   MMMM");

  const history = useHistory();

  return (
    <StyledContainer>
      <HeaderContainer
        title=""
        displayButton
        Button={
          <AddButton
            onClick={() =>
              history.push({
                pathname: "add-injury",
                state: { athleteId: athleteId },
              })
            }
          />
        }
      />
      <StyledValue>
        Semaine {moment().subtract(1, "w").isoWeek()} : du {monday} au {sunday}
      </StyledValue>
    </StyledContainer>
  );
};

export default WeekIndicationSection;
