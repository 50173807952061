import { Lang } from "containers/CreateExercise/types";

export interface FormValues {
  type: string;
  level: string;
  equipments: any[];
  goals: any[];
  sports: any[];
  programsCategories: any[];
  langs: {
    fr: Lang;
  };
  active: boolean;
}

export const initialValues: FormValues = {
  type: "rehab",
  level: "1",
  equipments: [],
  goals: [],
  sports: [{ label: "FOOTBALL", value: "foot" }],
  programsCategories: [],
  langs: {
    fr: {
      name: "",
      description: "",
      keywords: [],
    },
  },
  active: true,
};
