import SeanceGoalsListContainer from "containers/SeanceGoalsList";
import AddButton from "global/components/Button/AddButton";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import { useHistory } from "react-router-dom";

const SeanceGoalsList = () => {
  const history = useHistory();
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer
          title="Objectifs"
          displayButton
          Button={
            <AddButton
              onClick={() => history.push({ pathname: "create-seance-goal" })}
            />
          }
        />
        <SeanceGoalsListContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default SeanceGoalsList;
