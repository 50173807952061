import HorizontalAthletesList from "components/HorizontalAthletesList";
import SelectAcademy from "containers/SelectAcademy";
import { useAthletesList } from "./hooks/useAthletesList";

interface Props {
  onClick: any;
}

const HorizontalAthletesListContainer: React.FC<Props> = ({ onClick }) => {
  const { academy, setAcademy, athletesList, loading } = useAthletesList();
  return (
    <div>
      <SelectAcademy
        academy={academy}
        setAcademy={(e: any) => setAcademy(e.target.value)}
      />
      <HorizontalAthletesList
        athletesList={athletesList}
        onClick={onClick}
        loading={loading}
      />
    </div>
  );
};

export default HorizontalAthletesListContainer;
