import { RoundedButton } from "../styles";
import React from "react";
import { Props } from "./types";
import FaPen from "global/components/Icons/FaPen";

const EditButton: React.FC<Props> = ({ onClick }) => {
  return (
    <RoundedButton type="button" onClick={onClick}>
      <FaPen color="#B6B6B6" />
    </RoundedButton>
  );
};

export default EditButton;
