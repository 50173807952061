import AddInjury from "components/AddInjury";
import { useAddInjury } from "./hooks/useAddInjury";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  athleteId: string;
}

const AddInjuryContainer: React.FC<Props> = ({ athleteId }) => {
  const { handleSubmit, loading } = useAddInjury(athleteId);

  return (
    <AddInjury
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      editedInjury={{}}
      loading={loading}
    />
  );
};

export default AddInjuryContainer;
