import { useDispatch } from "react-redux";
import { Logout } from "store/auth/actionsFirebase";
import { Link } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "./icons/logout.svg";

const LogoutButton = () => {
  const dispatch = useDispatch();
  return (
    <Link
      className="menu-item-enjeux"
      onClick={async () => {
        dispatch(Logout());
      }}
      to={{
        pathname: `/sign-in`,
      }}
    >
      <span className="icon-button">
        <LogoutIcon />
      </span>
      Déconnexion
    </Link>
  );
};

export default LogoutButton;
