import CreateSeance from "components/CreateSeance";
import { useEditExercises } from "./hooks/useEditExercises";

interface Props {
  seanceRef: string;
}

const EditSeanceContainer: React.FC<Props> = ({ seanceRef }) => {
  const {
    exercises,
    setSelectedExercise,
    selectedExercise,
    addExercise,
    deleteExercise,
    handleDrag,
    addSerie,
    deleteSerie,
    editSerie,
    duplicateSerie,
    addItemToSerie,
    deleteItemToSerie,
    onChangeCommentsExercise,
  } = useEditExercises(seanceRef);
  return (
    <CreateSeance
      exercises={exercises}
      setSelectedExercise={setSelectedExercise}
      selectedExercise={selectedExercise}
      addExercise={addExercise}
      deleteExercise={deleteExercise}
      handleDrag={handleDrag}
      addSerie={addSerie}
      deleteSerie={deleteSerie}
      editSerie={editSerie}
      duplicateSerie={duplicateSerie}
      addItemToSerie={addItemToSerie}
      deleteItemToSerie={deleteItemToSerie}
      edit={true}
      seanceRef={seanceRef}
      onChangeCommentsExercise={onChangeCommentsExercise}
    />
  );
};

export default EditSeanceContainer;
