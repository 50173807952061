import { Input } from "@material-ui/core";
import React from "react";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  distanceCardio: Work["distanceCardio"];
  editSerie: any;
  index: number;
}

const DistanceCardioValue: React.FC<Props> = ({
  distanceCardio,
  editSerie,
  index,
}) => {
  return (
    <BlocValueDiv>
      <TitleBloc>DISTANCE</TitleBloc>
      <Input
        type="text"
        name="distanceCardio"
        value={distanceCardio}
        onChange={(e) => editSerie(e, index, "distanceCardio")}
      />
    </BlocValueDiv>
  );
};

export default DistanceCardioValue;
