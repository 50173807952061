import Input from "global/components/Form/Input";

const GlobalForm = () => {
  return (
    <div>
      <Input
        name="date"
        type="date"
        placeholder="Date de la séance"
        label="Date"
      />
      <Input name="hour" type="time" placeholder="Heure" label="Heure début" />
      <Input
        name="duration"
        type="number"
        placeholder="Durée en minute"
        label="Durée (minutes)"
      />
    </div>
  );
};

export default GlobalForm;
