import NutritionsList from "components/NutritionsList";
import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import React from "react";
import { InstantSearch, Pagination } from "react-instantsearch-dom";

const NutritionsListContainer: React.FC = () => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`nutritions_${process.env.REACT_APP_ENV_NAME}`}
    >
      <NutritionsList />
      <Pagination showLast />
    </InstantSearch>
  );
};

export default NutritionsListContainer;
