import { FormProps } from "./types";
import React from "react";
import Fields from "./Fields";
import { Form } from "formik";
import Button from "global/components/Button/Button";

const CreateAthleteAccount: React.FC<FormProps> = ({
  values,
  setFieldValue,
  dirty,
  isValid,
  loading,
}) => {
  //(values);
  return (
    <Form className="form">
      <Fields />
      <Button
        label="S'inscrire"
        dirty={dirty}
        isValid={isValid}
        loading={loading}
      />
    </Form>
  );
};

export default CreateAthleteAccount;
