import { Loading } from "global/styles";

interface Props {
  loading: boolean;
  invoicesList: any[];
}

const MyInvoices: React.FC<Props> = ({ loading, invoicesList }) => {
  if (loading) return <Loading />;
  return (
    <div>
      {invoicesList.map((invoice, index) => (
        <a
          key={index}
          href={invoice.invoicePdf}
          target="_blank"
          rel="noreferrer"
        >
          {invoice.id}
        </a>
      ))}
    </div>
  );
};

export default MyInvoices;
