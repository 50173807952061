import AthletesResponsesContainer from "containers/AthletesResponses";
import { GlobalDiv } from "global/styles";
import { useAppSelector } from "store/storeTypes";

const AthletesResponses = () => {
  const profile = useAppSelector((state) => state.firebase.profile);
  const academy = profile.academy;
  return (
    <GlobalDiv>
      <AthletesResponsesContainer academyId={academy.id} />
    </GlobalDiv>
  );
};

export default AthletesResponses;
