import FaCheck from "global/components/Icons/FaCheck";
import FaTimes from "global/components/Icons/FaTimes";
import { Tr, Td } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Loading, Paper } from "global/styles";
import { LittleProfilePic } from "global/styles/img";
import { useHistory } from "react-router-dom";

interface Props {
  loading: boolean;
  athletes: any[];
}

const headItemList = ["", "Nom", "Email", "Compte"];

const MyAthletes: React.FC<Props> = ({ loading, athletes }) => {
  const history = useHistory();
  if (loading) return <Loading />;
  if (!athletes.length) return <p>Aucun athlète !</p>;

  return (
    <div>
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {athletes.map((values: any, index: number) => (
              <Tr
                key={index}
                onClick={() =>
                  history.push({
                    pathname: "athlete-planning",
                    state: { athleteId: values.id },
                  })
                }
              >
                <Td>
                  <LittleProfilePic img={values.photoUrl} />
                </Td>
                <Td>{values.name}</Td>
                <Td>{values.email}</Td>
                <Td>
                  {values.accountStatus === "accountActivated" ? (
                    <FaCheck />
                  ) : values.accountStatus === "!accountActivated" ? (
                    <FaTimes color="#FF0000" />
                  ) : (
                    "En attente"
                  )}
                </Td>
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default MyAthletes;
