import { ValidationButton } from "global/components/Form/ValidateButton";
import React from "react";
import { CenterDiv } from "./styles";

interface Props {
  dates: string[];
  athletesList: any[];
  values: any;
  dirty: boolean;
  isValid: boolean;
  loading: boolean;
}

const Resume: React.FC<Props> = ({
  dates,
  athletesList,
  values,
  dirty,
  isValid,
  loading,
}) => {
  return (
    <CenterDiv>
      <p>Resume</p>
      <p>
        {athletesList.length
          ? `${dates.length * athletesList.length} events`
          : ""}
      </p>
      <p>{`Type : ${values.type}`}</p>
      <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
    </CenterDiv>
  );
};

export default Resume;
