import { DocumentReference, DocumentData } from "@firebase/firestore-types";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";

import { FormValues } from "../types";

export const useCreateExercise = () => {
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const uploadOnStorage = useUploadOnStorage();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const imageUrl = await uploadOnStorage(values.blobImage, "exercisesImg");

      let videoUrl: any = "";
      if (values.videoUrl !== "")
        videoUrl = await uploadOnStorage(values.videoUrl, "exercisesVideo");

      const keywords: string[] = [];
      values.langs.fr.keywords.map((keyword: any) =>
        keywords.push(keyword.text)
      );

      const themesArray: DocumentReference<DocumentData>[] = [];
      values.themes.forEach((theme) => {
        themesArray.push(firestore.collection("themes").doc(theme.value));
      });

      const muscleGroupsArray: DocumentReference<DocumentData>[] = [];
      values.muscleGroups.forEach((theme) => {
        muscleGroupsArray.push(
          firestore.collection("muscleGroups").doc(theme.value)
        );
      });

      const jointInjuriesArray: DocumentReference<DocumentData>[] = [];
      values.jointInjuries.forEach((theme) => {
        jointInjuriesArray.push(
          firestore.collection("jointInjuries").doc(theme.value)
        );
      });

      const goalsArray: DocumentReference<DocumentData>[] = [];
      values.goals.forEach((theme) => {
        goalsArray.push(firestore.collection("goals").doc(theme.value));
      });

      const equipmentsArray: DocumentReference<DocumentData>[] = [];
      values.equipments.forEach((theme) => {
        equipmentsArray.push(
          firestore.collection("equipments").doc(theme.value)
        );
      });

      const positionsArray: DocumentReference<DocumentData>[] = [];
      values.positions.forEach((theme) => {
        positionsArray.push(firestore.collection("positions").doc(theme.value));
      });

      await firestore
        .collection("exercises")
        .doc()
        .set({
          themes: themesArray,
          level: firestore.collection("levels").doc(values.level),
          muscleGroups: muscleGroupsArray,
          jointInjuries: jointInjuriesArray,
          goals: goalsArray,
          equipments: equipmentsArray,
          positions: positionsArray,
          imageUrl: imageUrl,
          videoUrl: videoUrl,
          langs: {
            fr: {
              name: values.langs.fr.name,
              description: values.langs.fr.description,
              keywords: keywords,
            },
          },
          createdAt: new Date(),
          createdBy: firestore.collection("users").doc(uid),
        });
      alert.success("Exercise created");
      history.push({ pathname: "exercises-list" });
    } catch (error) {
      alert.error("Error");
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
