import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

import Redirection from "./Redirection";
import SignIn from "../containers/SignIn";

import { useFirestore } from "react-redux-firebase";

import { useAppSelector } from "store/storeTypes";
import SignUp from "pages/SignUp";
import SignUpConfirmation from "pages/SignUp/SignUpConfirmation";
import NobodyApp from "./NobodyApp";
import AdminApp from "./AdminApp";
import AthleteApp from "./AthleteApp";
import CoachApp from "./CoachApp";
import SilverSubscription from "pages/SilverSubscription";
import CreateAthleteAccount from "pages/CreateAthleteAccount";
import Confirmation from "pages/CreateAthleteAccount/confirmation";
import ProgramPayment from "pages/ProgramPayment";
import ProgramPaymentConfirmation from "pages/ProgramPaymentConfirmation";
import SignUpStep1 from "pages/SignUpRegistration/SignUpStep1";
import SignUpForm from "pages/SignUpRegistration/SignUpForm";
import SignUpSubscriptionChoiceWithoutTrial from "pages/SignUpRegistration/SignUpSubscriptionChoiceWithoutTrial";
import Help from "pages/help";
import SignUpSubscriptionChoiceWithTrial from "pages/SignUpRegistration/SignUpSubscriptionChoice";
import ProSubscriptionPage from "pages/SignUpRegistration/SignUpSubscriptionChoicePro";
import ConfirmationPro from "pages/CreateAthleteAccount/confirmation-pro";

const App: React.FC = () => {
  console.log("App");
  const role = useAppSelector(
    (state) => state?.firebase?.profile?.token?.claims?.role
  );

  return (
    <div className="app-wrapper">
      <Router>
        <Switch>
          <Route path="/sign-up" component={SignUp} />
          <Route path="/sign-up-confirmation" component={SignUpConfirmation} />
          <Route path="/sign-in" component={SignIn} />
          <Route exact path="/" component={SignIn} />
          <Route path="/redirection" component={Redirection} />

          <Route path="/help" component={Help} />

          <Route
            path="/athlete-create-account/:email/:firstName/:lastName/:coachUid"
            component={CreateAthleteAccount}
          />
          <Route path="/confirmation" component={Confirmation} />
          <Route
            path="/silver-subscription/:id"
            component={SilverSubscription}
          />
          <Route
            path="/program-payment/:userUid/:userEmail/:programUid"
            component={ProgramPayment}
          />
          <Route
            path="/confirm-program-payment"
            component={ProgramPaymentConfirmation}
          />

          {/* sign up */}
          <Route path="/signup/registration" component={SignUpStep1} />
          <Route path="/signup/regform" component={SignUpForm} />
          <Route
            path="/signup/planform-with-trial/:uid/:email"
            component={SignUpSubscriptionChoiceWithTrial}
          />
          <Route
            path="/signup/planform/:userUid/:priceUid"
            component={SignUpSubscriptionChoiceWithoutTrial}
          />
          <Route
            path="/signup/planform-pro/:uid/:email"
            component={ProSubscriptionPage}
          />
          <Route path="/signup/confirm" component={Confirmation} />
          <Route
            path="/signup/confirm-pro/:email"
            component={ConfirmationPro}
          />

          {role === undefined || role === "nobody" ? <NobodyApp /> : null}
          {role === "coach" ? <CoachApp /> : null}
          {role === "admin" ? <AdminApp /> : null}
          {role === "athlete" ? <AthleteApp /> : null}
        </Switch>
      </Router>
    </div>
  );
};

export default App;
