import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { ProductScheme } from "schemes/product";

export const useProductsList = () => {
  const [productsList, setProductsList] = useState<ProductScheme[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    async function getProductsList() {
      setLoading(true);
      try {
        const productsSnapshot = await firestore
          .collection("products")
          .where("active", "==", true)
          .get();

        const array: ProductScheme[] = [];
        productsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          array.push({
            id: doc.id,

            name: data.name,
            firstPrice: data.firstPrice,
            monthDuration: data.monthDuration,
            monthlyPrice: data.monthlyPrice,
            priceId: data.priceId,
            privateCgvLink: data.privateCgvLink,
            professionalCgvLink: data.professionalCgvLink,
            distance: data.distance,
            session: data.session,
            subscription: data.subscription,
            checkup: data.checkup,
          });
        });

        array.sort(function (a, b) {
          return a.monthDuration - b.monthDuration;
        });

        setProductsList(array);
      } catch (error) {
        alert.error("Error");
      }
      setLoading(false);
    }

    getProductsList();
  }, [alert, firestore]);

  return { productsList, loading };
};
