import { TextFoncé } from "../../styles";
import { ImgBody, PainLocalisation } from "./styles";
import { CptPains } from "./types";

interface Props {
  cptPains: CptPains | null;
}

const SkeletonBody: React.FC<Props> = ({ cptPains }) => {
  if (cptPains === null) return <ImgBody src={`/assets/Squelette_Face.svg`} />;
  return (
    <div>
      {cptPains.cervicalArticular !== 0 ? (
        <PainLocalisation
          height="25px"
          width="25px"
          marginTop="2px"
          marginLeft="60px"
        >
          <TextFoncé>{cptPains.cervicalArticular}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightShoulder !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="14px"
          marginLeft="34px"
        >
          <TextFoncé>{cptPains.rightShoulder}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftShoulder !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="14px"
          marginLeft="91px"
        >
          <TextFoncé>{cptPains.leftShoulder}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.dorsalArticular !== 0 ? (
        <PainLocalisation
          height="50px"
          width="25px"
          marginTop="34px"
          marginLeft="60px"
        >
          <TextFoncé>{cptPains.dorsalArticular}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.pubis !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="87px"
          marginLeft="62px"
        >
          <TextFoncé>{cptPains.pubis}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightHip !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="100px"
          marginLeft="40px"
        >
          <TextFoncé>{cptPains.rightHip}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftHip !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="100px"
          marginLeft="84px"
        >
          <TextFoncé>{cptPains.leftHip}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightKneeArticular !== 0 ? (
        <PainLocalisation
          height="25px"
          width="25px"
          marginTop="157px"
          marginLeft="40px"
        >
          <TextFoncé>{cptPains.rightKneeArticular}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftKneeArticular !== 0 ? (
        <PainLocalisation
          height="25px"
          width="25px"
          marginTop="157px"
          marginLeft="79px"
        >
          <TextFoncé>{cptPains.leftKneeArticular}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightFootArticular !== 0 ? (
        <PainLocalisation
          height="25px"
          width="25px"
          marginTop="227px"
          marginLeft="41px"
        >
          <TextFoncé>{cptPains.rightFootArticular}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftFootArticular !== 0 ? (
        <PainLocalisation
          height="25px"
          width="25px"
          marginTop="227px"
          marginLeft="78px"
        >
          <TextFoncé>{cptPains.leftFootArticular}</TextFoncé>
        </PainLocalisation>
      ) : null}
      <ImgBody src={`/assets/Squelette_Face.svg`} />
    </div>
  );
};

export default SkeletonBody;
