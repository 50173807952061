import { CenterForm } from "./styles";
import React from "react";
import ManageSteps from "./ManageSteps";
import SeanceChoice from "./SeanceChoice";
import TypeChoice from "./TypeChoice";
import { FormProps } from "./types";
import Fields from "./Fields";
import AthletesChoice from "./AthletesChoice";
import DatesChoice from "./DatesChoice";
import Resume from "./Resume";
import SeanceInEventForm from "containers/CreateEvent/SeanceInEventForm";
import { useStepsDuplicateEvent } from "./ManageSteps/hooks/useStepsDuplicateEvent";

const DuplicateForm: React.FC<FormProps> = ({
  isValid,
  dirty,
  values,
  setFieldValue,
  athletesList,
  addAthlete,
  deleteAthlete,
  dates,
  setDates,
  exercises,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  setSelectedExercise,
  selectedExercise,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  edit,
  seanceRef,
  setExercises,
  onChangeCommentsExercise,
  eventId,
  loading,
}) => {
  const {
    step,
    prevStep,
    nextStep,
    nextStepFromSeanceChoice,
  } = useStepsDuplicateEvent(values, setFieldValue, setExercises, eventId!);
  const stepsList = [
    <TypeChoice setFieldValue={setFieldValue} value={values.type} />,
    <SeanceChoice nextStepFromSeanceChoice={nextStepFromSeanceChoice} />,
    <SeanceInEventForm
      exercises={exercises}
      setSelectedExercise={setSelectedExercise}
      selectedExercise={selectedExercise}
      addExercise={addExercise}
      deleteExercise={deleteExercise}
      handleDrag={handleDrag}
      addSerie={addSerie}
      deleteSerie={deleteSerie}
      editSerie={editSerie}
      duplicateSerie={duplicateSerie}
      addItemToSerie={addItemToSerie}
      deleteItemToSerie={deleteItemToSerie}
      values={values}
      setFieldValue={setFieldValue}
      onChangeCommentsExercise={onChangeCommentsExercise}
      themes={[]}
    />,
    <Fields />,
    <AthletesChoice
      athletesList={athletesList}
      addAthlete={addAthlete}
      deleteAthlete={deleteAthlete}
    />,
    <DatesChoice dates={dates} setDates={setDates} values={values} />,
    <Resume
      dates={dates}
      athletesList={athletesList}
      values={values}
      dirty={dirty}
      isValid={isValid}
      loading={loading}
    />,
  ];

  return (
    <div>
      <CenterForm>{stepsList[step]}</CenterForm>
      <ManageSteps step={step} prevStep={prevStep} nextStep={nextStep} />
    </div>
  );
};

export default DuplicateForm;
