import { DivBeetweenButton } from "components/CreateSeance/Exercises/styles";
import FaPen from "global/components/Icons/FaPen";
import FaTrash from "global/components/Icons/FaTrash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ActionsDiv,
  EventContainer,
  EventTitle,
  EventTitleDiv,
} from "./styles";

interface Props {
  name: string;
  day: number;
  injuryId: string;
  athleteId: string;
  deleteInjury: any;
}

const Injury: React.FC<Props> = ({
  name,
  day,
  injuryId,
  athleteId,
  deleteInjury,
}) => {
  const [hover, setHover] = useState(false);
  const history = useHistory();
  return (
    <EventContainer
      height="32px"
      bgColor="#1e1b1b"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!hover ? (
        <EventTitleDiv>
          <EventTitle>{day === 0 ? name : `J+${day}`}</EventTitle>
        </EventTitleDiv>
      ) : (
        <ActionsDiv>
          <div
            onClick={() =>
              history.push({
                pathname: "edit-injury",
                state: { injuryId: injuryId, athleteId: athleteId },
              })
            }
          >
            <FaPen color="#FFF" />
          </div>
          <DivBeetweenButton />
          <div onClick={() => deleteInjury(injuryId)}>
            <FaTrash color="#FFF" />
          </div>
        </ActionsDiv>
      )}
    </EventContainer>
  );
};

export default Injury;
