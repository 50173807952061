import { useAppSelector } from "store/storeTypes";
import "./athlete-home.css";

const AthleteHome = () => {
  const athlete = useAppSelector((state) => state.firebase.profile);
  return (
    <div className="athlete-home">
      <div>
        <h1 className="white-title">{`Bienvenue ${athlete.firstName} !`}</h1>

        <div className="body">
          <p>
            Merci d'avoir souscrit à une offre Enjeux ! Voici les prochaines
            étapes pour débuter le suivi :
          </p>
          <h1>Étape 1 :</h1>
          <p>Remplissez le questionnaire du début de suivi.</p>
          <a
            href={`https://3uzbhd1qyxv.typeform.com/to/dwZG2VoV#first_name=${athlete.firstName}&last_name=${athlete.lastName}`}
            target="_blank"
            rel="noreferrer"
          >
            <button className="button">Remplir le questionnaire</button>
          </a>
          <h1>Étape 2 :</h1>
          <p>Convenir d'un rdv pour effectuer le bilan.</p>
          <a
            href={`https://enjeux-lab.fr/bilan-dates`}
            target="_blank"
            rel="noreferrer"
          >
            <button className="button">Les dates du bilan</button>
          </a>
          <h1>Étape 3 :</h1>
          <p>
            Vous pouvez désormais utilisez l'application mobile Enjeux en vous
            connectant avec votre email et votre mot de passe.
          </p>
        </div>

        <a
          href="https://apps.apple.com/fr/app/enjeux-spm/id1561181378"
          target="_blank"
          rel="noreferrer"
        >
          <button className="button">
            <img
              src="/assets/apple-black-logo.png"
              width="20"
              alt="logo-apple"
            />
          </button>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.enjeuxspm.app&gl=FR"
          target="_blank"
          rel="noreferrer"
        >
          <button className="button">
            <img src="/assets/android-logo.png" width="20" alt="logo-android" />
          </button>
        </a>
      </div>

      {/* icon android et apple qui redirige vers les apps */}
    </div>
  );
};

export default AthleteHome;
