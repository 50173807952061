import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useFirestore } from "react-redux-firebase";

export interface CurvesDocumentFromFirebase {
  startDate: any;
  endDate: any;
  markers: {
    mediumIntensity: MarkerObject;
    highIntensity: MarkerObject;
    condition: MarkerObject;
    dynamism: MarkerObject;
    perceivedDuration: MarkerObject;
    tired: MarkerObject;
  };
}

export interface MarkerObject {
  dates: string[];
  gamesValues: (number | null)[];
  groupTrainings: (number | null)[];
  individualTrainings: (number | null)[];
  mm7: (number | null)[];
  mm21: (number | null)[];
}

export const useAthleteCurves = (athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<CurvesDocumentFromFirebase[] | null>(null);
  const [start] = useState<number>(30);
  const [end] = useState<number>(0);
  const [marker, setMarker] = useState<string>("highIntensity");
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        const curvesSnapshot = await firestore
          .collection("curves")
          .where("athlete", "==", athleteSnapshot.ref)
          .orderBy("startDate", "desc")
          .get();

        const array: CurvesDocumentFromFirebase[] = [];

        curvesSnapshot.docs.forEach((document) => {
          const data = document.data();

          array.push({
            startDate: data.startDate,
            endDate: data.endDate,
            markers: data.markers,
          });
        });

        //console.log(array);

        setData(array);
      } catch (error) {
        //console.log(error);
        alert.error("Erreur");
      }
      setLoading(false);
    }

    getData();
  }, [alert, athleteId, dispatch, end, firestore, marker, start]);

  return { marker, setMarker, data, loading };
};
