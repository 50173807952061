import { Hits, SearchBox } from "react-instantsearch-dom";
import Hit from "./Hit";

interface Props {
  onClick: any;
}

const ArticlesList: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="seances-list">
      <SearchBox />
      <Hits hitComponent={(hit) => <Hit hit={hit} onClick={onClick} />} />
    </div>
  );
};

export default ArticlesList;
