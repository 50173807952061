import styled from "styled-components";

export const CellsRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Cell = styled.div`
  width: 178px;
  text-align: center;
  min-height: 300px;
  margin: 0 10px;
`;

export const CellDate = styled.p`
  font-family: "Bebas Neue";
  margin-bottom: 10px;
  width: 178px;
`;
