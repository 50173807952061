import DeleteButton from "global/components/Button/DeleteRoundedButton";
import Tag from "global/components/Tag";
import { SeanceScheme } from "../../schemes/program";

interface Props {
  seance: SeanceScheme;
  deleteSeanceInDay: any;
  daysArrayIndex: number;
}

const Seance: React.FC<Props> = ({
  seance,
  deleteSeanceInDay,
  daysArrayIndex,
}) => {
  return (
    <div className="seance-in-day">
      <p>{seance.langs.fr.name}</p>
      <Tag label={seance.themeData.label} color={seance.themeData.color} />

      <div>
        <DeleteButton
          onClick={() => deleteSeanceInDay(seance.uid, daysArrayIndex)}
        />
      </div>
    </div>
  );
};

export default Seance;
