import { FormValues } from "../types";

export const initialValues: FormValues = {
  themes: [],
  croppedImage: "",
  blobImage: "",
  videoUrl: "",
  langs: {
    fr: {
      name: "",
      description: "",
      keywords: [],
    },
    en: {
      name: "",
      description: "",
      keywords: [],
    },
    mr: {
      name: "",
      description: "",
      keywords: [],
    },
  },
  level: "1",
  muscleGroups: [],
  jointInjuries: [],
  equipments: [],
  goals: [],
  positions: [],
};
