import { useState } from "react";
import Actions from "./Actions";
import { EventContainer, EventTitle, EventTitleDiv } from "./styles";
import { EventProps } from "./types";

const Event: React.FC<EventProps> = ({
  color,
  hour,
  name,
  eventId,
  deleteEvent,
  athleteId,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <EventContainer
      height="32px"
      bgColor={color}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!hover ? (
        <EventTitleDiv>
          <EventTitle>{`${hour} ${name}`}</EventTitle>
        </EventTitleDiv>
      ) : (
        <Actions
          eventId={eventId}
          deleteEvent={deleteEvent}
          athleteId={athleteId}
        />
      )}
    </EventContainer>
  );
};

export default Event;
