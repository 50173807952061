const AlreadyMember = () => {
  return (
    <div className="already-member">
      <a href={`${process.env.REACT_APP_URL}sign-in`}>
        Déjà membre ? Se connecter
      </a>
    </div>
  );
};

export default AlreadyMember;
