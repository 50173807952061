import React, { useState, useCallback } from "react";
import { getCroppedImg } from "./utils/canvasUtils";
import ImageCrop from "components/ImageCrop";
import { Props } from "./types";
import CroppedImage from "components/ImageCrop/CroppedImage";
import { readFile } from "./utils/readFile";

const ImageCropContainer: React.FC<Props> = ({
  croppedImage,
  setFieldValue,
  label,
  aspect,
  croppedImageValue,
  blobImageValue,
}) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const newCroppedImage: any = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      setFieldValue(croppedImageValue, URL.createObjectURL(newCroppedImage));
      // //("donee", { newCroppedImage });
      setFieldValue(blobImageValue, newCroppedImage);
    } catch (e) {
      // console.error(e);
    }
  }, [
    imageSrc,
    croppedAreaPixels,
    setFieldValue,
    croppedImageValue,
    blobImageValue,
  ]);

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl: any = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };

  if (croppedImage)
    return (
      <CroppedImage
        img={croppedImage}
        onClick={() => {
          setImageSrc(null);
          setFieldValue(croppedImageValue, null);
        }}
      />
    );

  return (
    <ImageCrop
      imageSrc={imageSrc}
      crop={crop}
      zoom={zoom}
      setCrop={setCrop}
      setZoom={setZoom}
      onCropComplete={onCropComplete}
      onFileChange={onFileChange}
      showCroppedImage={showCroppedImage}
      label={label}
      aspect={aspect}
    />
  );
};

export default ImageCropContainer;
