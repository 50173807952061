import NutritionsListContainer from "containers/NutritionsList";
import AddButton from "global/components/Button/AddButton";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";
import { useHistory } from "react-router-dom";

const NutritionsList: React.FC<any> = () => {
  const history = useHistory();

  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer
          title="Nutrition"
          displayButton
          Button={
            <AddButton
              onClick={() => history.push({ pathname: "add-nutrition" })}
            />
          }
        />
        <NutritionsListContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default NutritionsList;
