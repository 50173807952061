import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const StyledRow = styled(Row)`
  margin: 0;
`;

export const StyledCol = styled(Col)`
  padding: 10px;
`;
