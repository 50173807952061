import AddWorkCycleContainer from "containers/AddWorkCycle";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const AddWorkCycle: React.FC<any> = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Add Work Cycle" />
        <AddWorkCycleContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default AddWorkCycle;
