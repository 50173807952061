import ReactDOM from "react-dom";

import App from "./app/App";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import "../src/global/styles/index.css";
import "./index.css";
import "./css/texts.css";
import "./i18n.ts";

import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { rrfProps, persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";

const alertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...alertOptions}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <App />
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  </AlertProvider>,
  document.getElementById("root")
);
