import styled from "styled-components";
import { MyShadows } from "./box-shadow";
import { MyGradients } from "./colors";
import { rotate } from "./rotate";

import "./algolia-search.css";

export const GlobalDiv = styled.div`
  margin: 40px 40px 0 40px;
  padding-bottom: 40px;
`;

export const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: ${MyShadows.primary};
  padding: 30px;
`;

export const Title = styled.h1`
  font-family: "Bebas Neue";
  color: #000;
  font-size: 30px;
`;

export const TextInput = styled.input`
  -webkit-appearance: none;
  outline: unset;
  display: block;
  width: 160px;
  height: 30px;
  border-radius: 40px;
  border: 0px;
  box-shadow: ${MyShadows.primary};
  background: ${MyGradients.secondary};
  padding: 0 20px;
  margin-bottom: 30px;

  ::placeholder {
    color: #b6b6b6;
  }
`;

export const Paper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

interface LoadingProps {
  spinnerColor?: string;
}

export const Loading = styled.div<LoadingProps>`
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border: 2px solid;
  border-color: ${(props) => props.spinnerColor || "#fff"};
  border-radius: 50%;
  border-top-color: rgb(255, 255, 255, 0);
  animation: 1s ${rotate} infinite ease-in-out;
`;
