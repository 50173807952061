import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

export const useDeleteWorkCycle = (workCycleUid: string, athleteId: string) => {
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();

  const deleteWorkCycle = async () => {
    try {
      await firestore.collection("workCycles").doc(workCycleUid).delete();

      alert.success(`cycle deleted`);

      history.push({
        pathname: "athlete-work-cycles",
        state: { athleteId: athleteId },
      });
    } catch (error) {
      //console.log(error);
      alert.error("Error");
    }
  };

  return { deleteWorkCycle };
};
