import React from "react";
import { TextFoncé } from "../../styles";
import { ImgBody, PainLocalisation } from "./styles";
import { CptPains } from "./types";

interface Props {
  cptPains: CptPains | null;
}

const BackBody: React.FC<Props> = ({ cptPains }) => {
  if (cptPains === null) return <ImgBody src={`/assets/Muscles_Dos.svg`} />;
  return (
    <div>
      {cptPains.cervical !== 0 ? (
        <PainLocalisation
          height="40px"
          width="40px"
          marginTop="3px"
          marginLeft="52px"
        >
          <TextFoncé>{cptPains.cervical}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.dorsal !== 0 ? (
        <PainLocalisation
          height="50px"
          width="42px"
          marginTop="53px"
          marginLeft="51px"
        >
          <TextFoncé>{cptPains.dorsal}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftButtocks !== 0 ? (
        <PainLocalisation
          height="30px"
          width="30px"
          marginTop="105px"
          marginLeft="40px"
        >
          <TextFoncé>{cptPains.leftButtocks}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightButtocks !== 0 ? (
        <PainLocalisation
          height="30px"
          width="30px"
          marginTop="105px"
          marginLeft="75px"
        >
          <TextFoncé>{cptPains.rightButtocks}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftHamstrings !== 0 ? (
        <PainLocalisation
          height="50px"
          width="20px"
          marginTop="138px"
          marginLeft="40px"
        >
          <TextFoncé>{cptPains.leftHamstrings}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightHamstrings !== 0 ? (
        <PainLocalisation
          height="50px"
          width="20px"
          marginTop="138px"
          marginLeft="83px"
        >
          <TextFoncé>{cptPains.rightHamstrings}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftCalf !== 0 ? (
        <PainLocalisation
          height="50px"
          width="20px"
          marginTop="188px"
          marginLeft="29px"
        >
          <TextFoncé>{cptPains.leftCalf}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightCalf !== 0 ? (
        <PainLocalisation
          height="50px"
          width="20px"
          marginTop="188px"
          marginLeft="95px"
        >
          <TextFoncé>{cptPains.rightCalf}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftFoot !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="243px"
          marginLeft="22px"
        >
          <TextFoncé>{cptPains.leftFoot}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightFoot !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="243px"
          marginLeft="102px"
        >
          <TextFoncé>{cptPains.rightFoot}</TextFoncé>
        </PainLocalisation>
      ) : null}

      <ImgBody src={`/assets/Muscles_Dos.svg`} />
    </div>
  );
};

export default BackBody;
