import { HeightDiv } from "components/CreateSeance/styles";
import { StyledContainer } from "./styles";
import moment from "moment";
import React from "react";
import { NormalTitle } from "global/styles/polices";

interface Props {
  startDate: any;
  endDate: any;
  reliability: number;
  pdfUrl: string | null;
}

const WeekNumber: React.FC<Props> = ({
  startDate,
  reliability,
  endDate,
  pdfUrl,
}) => {
  return (
    <StyledContainer
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <p>SEMAINE</p>
        <NormalTitle>{moment(startDate.toDate()).isoWeek()}</NormalTitle>
        <HeightDiv />
        <p>{moment(startDate.toDate()).format("dddd D")}</p>
        <p>{moment(endDate.toDate()).subtract(1, "d").format("dddd D")}</p>
        <HeightDiv />
        <p>FIABILITÉ</p>
        <NormalTitle>{`${reliability}%`}</NormalTitle>
        {pdfUrl !== null ? (
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            <button className="button">Télécharger</button>
          </a>
        ) : null}
      </div>
    </StyledContainer>
  );
};

export default WeekNumber;
