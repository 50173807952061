import React from "react";
import { TextFoncé } from "../../styles";
import { ImgBody, PainLocalisation } from "./styles";
import { CptPains } from "./types";

interface Props {
  cptPains: CptPains | null;
}

const FrontBody: React.FC<Props> = ({ cptPains }) => {
  if (cptPains === null) return <ImgBody src={`/assets/Muscles_Face.svg`} />;
  return (
    <div>
      {cptPains.abdominals !== 0 ? (
        <PainLocalisation
          height="50px"
          width="30px"
          marginTop="55px"
          marginLeft="56px"
        >
          <TextFoncé>{cptPains.abdominals}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightPsoas !== 0 ? (
        <PainLocalisation
          height="25px"
          width="20px"
          marginTop="103px"
          marginLeft="45px"
        >
          <TextFoncé>{cptPains.rightPsoas}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftPsoas !== 0 ? (
        <PainLocalisation
          height="25px"
          width="20px"
          marginTop="103px"
          marginLeft="78px"
        >
          <TextFoncé>{cptPains.leftPsoas}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightAdductors !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="116px"
          marginLeft="52px"
        >
          <TextFoncé>{cptPains.rightAdductors}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftAdductors !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="116px"
          marginLeft="71px"
        >
          <TextFoncé>{cptPains.leftAdductors}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightQuadriceps !== 0 ? (
        <PainLocalisation
          height="50px"
          width="20px"
          marginTop="126px"
          marginLeft="40px"
        >
          <TextFoncé>{cptPains.rightQuadriceps}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftQuadriceps !== 0 ? (
        <PainLocalisation
          height="50px"
          width="20px"
          marginTop="126px"
          marginLeft="82px"
        >
          <TextFoncé>{cptPains.leftQuadriceps}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.rightKnee !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="178px"
          marginLeft="37px"
        >
          <TextFoncé>{cptPains.rightKnee}</TextFoncé>
        </PainLocalisation>
      ) : null}
      {cptPains.leftKnee !== 0 ? (
        <PainLocalisation
          height="20px"
          width="20px"
          marginTop="178px"
          marginLeft="85px"
        >
          <TextFoncé>{cptPains.leftKnee}</TextFoncé>
        </PainLocalisation>
      ) : null}

      <ImgBody src={`/assets/Muscles_Face.svg`} />
    </div>
  );
};

export default FrontBody;
