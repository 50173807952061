import "./button.css";

interface Props {
  label: string;
  isValid: boolean;
  dirty: boolean;
  loading: boolean;
}

const Button: React.FC<Props> = ({ label, isValid, dirty, loading }) => {
  return (
    <div>
      <button
        className="button"
        type="submit"
        disabled={!(isValid && dirty) || loading}
      >
        {loading ? <div className="white-loading" /> : label}
      </button>
    </div>
  );
};

export default Button;
