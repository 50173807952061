import styled from "styled-components";

export const ExerciseDiv = styled.div`
  height: 120px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }
`;

export const ImgDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const Number = styled.h1`
  font-family: "Bebas Neue";
  margin: 0 10px 0 10px;
`;

export const ExerciseImg = styled.img`
  width: 120px;
`;

export const DivBeetweenButton = styled.div`
  width: 10px;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const PreviewDiv = styled.div`
  display: flex;
`;
