import FormikForm from "../../components/SignIn";
import { validationSchema } from "./formikUtils/yupSchemas";
import { initialValues } from "./formikUtils/initialValues";

import { useHandleLogin } from "./hooks/useHandleLogin";

export const Login = () => {
  const { handleSubmit, loading } = useHandleLogin();

  return (
    <>
      <FormikForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        loading={loading}
      />
    </>
  );
};

export default Login;
