import { Loading } from "global/styles";
import { ProductScheme } from "schemes/product";

import "./products-list.css";
import SuiviSection from "./SuiviSection";

interface Props {
  productsList: ProductScheme[];
  loading: boolean;
}

const ProductsList: React.FC<Props> = ({ productsList, loading }) => {
  if (loading) return <Loading />;
  return (
    <div className="all-products">
      <SuiviSection
        label="SÉANCES"
        productsList={productsList.filter((product) => product.session)}
      />

      <SuiviSection
        label="BILANS"
        productsList={productsList.filter((product) => product.checkup)}
      />

      <SuiviSection
        label="SUIVIS DISTANCES"
        productsList={productsList.filter((product) => product.distance)}
      />

      <SuiviSection
        label="SUIVIS LAB"
        productsList={productsList.filter(
          (product) => !product.distance && !product.session && !product.checkup
        )}
      />
    </div>
  );
};

export default ProductsList;
