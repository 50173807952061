import ExercisesList from "components/ExercisesList";
import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import React from "react";
import { InstantSearch, Pagination } from "react-instantsearch-dom";
import CustomRefinementList from "components/ExercisesList/CustomRefinementList";

const ExercisesListContainer: React.FC = () => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`exercises_${process.env.REACT_APP_ENV_NAME}`}
    >
      <CustomRefinementList
        attribute="themes.name.fr"
        limit={20}
        label="Thèmes"
      />
      <ExercisesList />
      <Pagination showLast />
    </InstantSearch>
  );
};

export default ExercisesListContainer;
