import firebase from "firebase";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { Exercise, Work } from "schemes/exercise";

import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";

function _activityHourToDate(
  stringDate: string,
  stringHour: string
): Date | undefined {
  if (stringHour === undefined) return undefined;

  const trueHour = stringHour.split(":");
  const hour = trueHour[0];
  const minutes = trueHour[1];

  const trueDate = new Date(stringDate);
  trueDate.setHours(parseInt(hour), parseInt(minutes));
  return trueDate;
}

export const useEditEvent = (eventId: string, athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const athlete = useAppSelector((state) => state.athlete);
  const [dates, setDates] = useState([""]);
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const [athletesList, setAthletesList] = useState<any[]>([
    {
      key: athleteId,
      firstName: athlete!.firstName,
      lastName: athlete!.lastName,
      photoUrl: athlete!.photoUrl,
    },
  ]);
  const [exercises, setExercises] = useState<any[]>([]);
  const [selectedExercise, setSelectedExercise] = useState(0); // index of selectedExercise
  const alert = useAlert();
  const history = useHistory();
  const firestore = useFirestore();

  useEffect(() => {
    async function getData() {
      const querySnapshot = await firestore
        .collection("events")
        .doc(eventId)
        .get();

      const data = querySnapshot.data();

      const newEx: Exercise[] = [];
      if (data?.seance)
        await Promise.all(
          data?.seance.exercises.map(async (exerciseObject: any) => {
            const exerciseSnapshot = await firestore
              .collection("exercises")
              .doc(exerciseObject.exercise.id)
              .get();
            let exerciseData = await exerciseSnapshot.data();

            exerciseObject.works.forEach((work: any) => {
              if (work.goalCardio) {
                work.goalCardio.type = "%fcMax";
                work.goalCardio.value = work.goalCardio.value.toString();
              }
              if (work.rest) work.rest.time = "second";
            });

            newEx.push({
              key: exerciseObject.exercise.id,
              data: {
                imageUrl: exerciseData?.imageUrl,
                name: exerciseData?.langs.fr.name,
              },
              works: exerciseObject.works,
              comments: exerciseObject.comments,
            });
          })
        );

      setExercises(newEx);
    }
    getData();
  }, [eventId, firestore]);

  async function _getTheme(type: string, theme: string) {
    try {
      let themeSnapshot: any = null;
      if (type === "other") {
        themeSnapshot = await firestore.collection("themes").doc("_").get();
      }
      if (type === "match") {
        themeSnapshot = await firestore
          .collection("themes")
          .doc("r1v9gLl5kTRsXQJFzldg")
          .get();
      }
      if (type === "training") {
        themeSnapshot = await firestore
          .collection("themes")
          .doc("AM0JEXkv2ReyOo7eGNcu")
          .get();
      }
      if (type === "seance") {
        themeSnapshot = await firestore.collection("themes").doc(theme).get();
      }

      const themeId = themeSnapshot.id;
      const name = themeSnapshot.data().name.fr;

      return { themeId, name };
    } catch (e) {
      alert.error("error");
    }
  }

  const addAthlete = (athlete: any) => {
    setAthletesList([...athletesList, athlete]);
  };

  const deleteAthlete = (athleteId: any) => {
    setAthletesList(
      athletesList.filter((athlete: any) => athlete.key !== athleteId)
    );
  };

  const _getExercises = (dto: any) => {
    const exercises: any = [];

    dto.forEach((exercise: any) => {
      if (exercise.comments === undefined || exercise.comments === null) {
        exercise.comments = "";
      }

      exercise.works.map((work: Work) => {
        if (work.series !== null && isNaN(work.series)) {
          work.series = null;
        }
      });

      exercises.push({
        exercise: firebase.firestore().doc(`/exercises/${exercise.key}`),
        works: exercise.works,
        comments: exercise.comments,
      });
    });

    return exercises;
  };

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      let seance: any;
      let subName: string;
      if (values.type !== "seance") {
        seance = null;
        subName = values.subName;
      } else {
        subName = values.seanceName;
        seance = {
          langs: {
            fr: {
              name: values.seanceName,
              description: values.seanceDescription,
            },
          },
          exercises: _getExercises(exercises),
        };
      }

      const theme = await _getTheme(values.type, values.theme);

      await firestore
        .collection("events")
        .doc(eventId)
        .update({
          //athlete: firebase.firestore().doc(`/users/${athletesList[0].key}`),
          date: _activityHourToDate(values.date!, values.hour),
          name: theme?.name,
          subName: subName,
          theme: firebase.firestore().doc(`/themes/${theme?.themeId}`),
          duration: values.duration,
          seance: seance,
          editedBy: firebase.firestore().doc(`/users/${uid}`),
          editedAt: new Date(),
          comments: values.comments,
        });

      alert.success(`event edited`);
      history.push({
        pathname: "athlete-planning",
        state: { athleteId: athleteId },
      });
    } catch (error) {
      //console.log(error);
    }
    setLoading(false);
  };

  const deleteExercise = (i: any) => {
    setExercises(exercises.filter((exercise, index) => index !== i));
  };

  const addExercise = (newExercise: Exercise) => {
    let isInTable: boolean = false;
    exercises.forEach((exercise: Exercise) => {
      if (newExercise.key === exercise.key) isInTable = true;
    });
    if (!isInTable) {
      newExercise.comments = "";
      newExercise.works = [];
      setExercises([...exercises, newExercise]);
    } else {
      alert.error("Exercise already in the list");
    }
  };

  function onChangeCommentsExercise(comments: string) {
    const newExercises = exercises;

    newExercises[selectedExercise].comments = comments;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  }

  const handleDrag = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      exercises,
      result.source.index,
      result.destination.index
    );

    setExercises(items);
  };

  const addSerie = (key: string) => {
    if (exercises[selectedExercise].works?.length > 5) {
      alert.error("6 series maximum");
      return;
    }

    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;
    newWorks.push({
      work: { type: "rep", value: 10 },
      rest: { type: "passive", time: "second", value: 60 },
    });
    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const deleteSerie = (indexOfWork: any) => {
    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;

    newWorks.splice(indexOfWork, 1);

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);
    setExercises(items);
  };

  const editSerie = (e: any, index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    switch (item) {
      case "charge":
      case "work":
      case "rest":
      case "time":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]:
              e.target.name === "type" || e.target.name === "time"
                ? e.target.value
                : parseInt(e.target.value),
          },
        };
        break;
      case "goalCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]: e.target.value,
          },
        };
        break;
      case "speedCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]:
              e.target.name === "type" || e.target.name === "time"
                ? e.target.value
                : parseFloat(e.target.value),
          },
        };
        break;
      case "series":
        newWorks[index] = {
          ...newWorks[index],
          [item]: parseInt(e.target.value),
        };
        break;
      case "tempo":
      case "laterality":
      case "typeCardio":
      case "distanceCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: e.target.value,
        };
        break;
      default:
        break;
    }

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const duplicateSerie = (work: Work) => {
    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;

    newWorks.push(work);

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const addItemToSerie = (index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    switch (item) {
      case "work":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "rep",
            value: 10,
          },
        };
        break;
      case "time":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "second",
            value: 60,
          },
        };
        break;
      case "rest":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "passive",
            time: "second",
            value: 60,
          },
        };
        break;
      case "charge":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "kg",
            value: 10,
          },
        };
        break;
      case "speedCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "kmh",
            value: 16,
          },
        };
        break;
      case "goalCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "%fcMax",
            value: "70-80",
          },
        };
        break;
      case "tempo":
        newWorks[index] = {
          ...newWorks[index],
          tempo: "4/2/1/1",
        };
        break;
      case "laterality":
        newWorks[index] = {
          ...newWorks[index],
          laterality: "twice",
        };
        break;
      case "typeCardio":
        newWorks[index] = {
          ...newWorks[index],
          typeCardio: "training",
        };
        break;
      case "distanceCardio":
        newWorks[index] = {
          ...newWorks[index],
          distanceCardio: "5km",
        };
        break;
      case "series":
        newWorks[index] = {
          ...newWorks[index],
          series: 4,
        };
        break;
      default:
        break;
    }

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const deleteItemToSerie = (index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    delete newWorks[index][item];

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  return {
    exercises,
    setSelectedExercise,
    selectedExercise,
    addExercise,
    deleteExercise,
    handleDrag,
    addSerie,
    deleteSerie,
    editSerie,
    duplicateSerie,
    addItemToSerie,
    deleteItemToSerie,
    handleSubmit,
    athletesList,
    addAthlete,
    deleteAthlete,
    dates,
    setDates,
    setExercises,
    onChangeCommentsExercise,
    loading,
  };
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
