import ImageCropContainer from "containers/ImageCrop";
import TextArea from "global/components/Form/Input/TextArea";
import { useState } from "react";
import Title from "./Title";

interface Props {
  label: string;
  setFieldValue: any;
  values: any;
}

const Health: React.FC<Props> = ({ label, values, setFieldValue }) => {
  const [display, setDisplay] = useState<boolean>(true);
  return (
    <div>
      <Title label={label} display={display} setDisplay={setDisplay} />
      {display ? (
        <div>
          <ImageCropContainer
            croppedImage={values.croppedImageSleep}
            croppedImageValue="croppedImageSleep"
            blobImageValue="blobImageSleep"
            setFieldValue={setFieldValue}
            label="Graphique Sommeil"
            aspect={4 / 3}
          />
          <ImageCropContainer
            croppedImage={values.croppedImageStress}
            croppedImageValue="croppedImageStress"
            blobImageValue="blobImageStress"
            setFieldValue={setFieldValue}
            label="Graphique Stress"
            aspect={4 / 3}
          />
          <ImageCropContainer
            croppedImage={values.croppedImageTired}
            croppedImageValue="croppedImageTired"
            blobImageValue="blobImageTired"
            setFieldValue={setFieldValue}
            label="Graphique Fatigue"
            aspect={4 / 3}
          />
          <TextArea
            name="healthComments"
            type="text"
            placeholder="RAS"
            label="Commentaires"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Health;
