import FieldInput from "global/components/Form/Field";

const Fields = () => {
  return (
    <div>
      <FieldInput type="text" label="Prénom*" name="firstName" autoFocus />
      <FieldInput type="text" label="Nom*" name="lastName" />
      <FieldInput type="date" label="Date de naissance*" name="birthDate" />
      <FieldInput type="email" label="Email*" name="email" />
      <FieldInput type="password" label="Mot de passe*" name="password" />
      <FieldInput
        type="password"
        label="Confirmation mot de passe*"
        name="passwordConfirmation"
      />
      <FieldInput type="tel" label="Téléphone" name="phone" />
      <FieldInput type="text" label="Adresse*" name="addressLine" />
      <FieldInput type="text" label="Ville*" name="addressCity" />
      <FieldInput type="text" label="Code Postal*" name="addressPostalCode" />
    </div>
  );
};

export default Fields;
