import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import chat from "./chat";
import athlete from "./athlete";
import lng from "./lng";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  lng,
  chat,
  athlete,
});

export default rootReducer;
