import AthleteWorkCycles from "components/AthleteWorkCycles";
import { useAthleteWorkCycles } from "./hooks/useAthleteWorkCycles";

const AthleteWorkCyclesContainer = () => {
  const { workCyclesList, loading } = useAthleteWorkCycles();
  return (
    <AthleteWorkCycles workCyclesList={workCyclesList} loading={loading} />
  );
};

export default AthleteWorkCyclesContainer;
