import { CenterDiv } from "components/CreateEvent/styles";
import Input from "global/components/Form/Input";
import TextArea from "global/components/Form/Input/TextArea";
import { useEffect } from "react";

interface Props {
  setFieldValue: any;
  edit?: boolean;
  editedInjury: any;
}

const Form: React.FC<Props> = ({ setFieldValue, edit, editedInjury }) => {
  useEffect(() => {
    //(exercise);
    if (edit && Object.getOwnPropertyNames(editedInjury).length) {
      setFieldValue(`date`, editedInjury.date);
      setFieldValue(`injury`, editedInjury.injury);
      setFieldValue(`days`, editedInjury.days);
    }
  }, [edit, setFieldValue, editedInjury]);

  return (
    <CenterDiv>
      <Input
        name={`date`}
        type="date"
        placeholder="Date..."
        label="Date de la blessure"
      />
      <TextArea
        name={`injury`}
        type="text"
        placeholder="Blessure..."
        label="Blessure"
      />
      <Input
        name={`days`}
        type="number"
        placeholder="Nombre de jour arrêté"
        label="Temps d'arrêt estimé"
      />
    </CenterDiv>
  );
};

export default Form;
