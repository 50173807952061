import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

import { errors, ErrorsCodes, FormValues } from "../types";

export const useCreateAthleteAccount = (
  firstName: string,
  lastName: string,
  email: string,
  coachUid: string
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const firebase = useFirebase();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, values.password);

      // const userSnapshot = await firestore
      //   .collection("users")
      //   .where("email", "==", email)
      //   .get();
      // await firestore.collection("users").doc(userSnapshot.docs[0].id).delete();

      await firestore
        .collection("users")
        .doc(userCredential.user?.uid)
        .set({
          email: email,
          firstName: firstName,
          lastName: lastName,
          coach: firestore.collection("users").doc(coachUid),
          suiviEnjeux: false,
          accountActivated: true,
          createdAt: new Date(),
        });

      alert.success("Success");
      history.push({ pathname: "/confirmation" });
    } catch (error: any) {
      //console.log(error);
      const errorCode: ErrorsCodes = error.code;

      if (errorCode && errors[errorCode]) {
        alert.error(errors[errorCode]);
      }
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
