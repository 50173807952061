import MyProfile from "components/MyProfile";
import { useEditProfile } from "./hooks/useEditProfile";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const MyProfileContainer = () => {
  const { loading, handleSubmit, getInitialValues } = useEditProfile();
  return (
    <MyProfile
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      loading={loading}
      getInitalValues={getInitialValues}
    />
  );
};

export default MyProfileContainer;
