import styled, { keyframes } from "styled-components";

interface LoadingProps {
  spinnerColor?: string;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  
  to {
    transform: rotate(360deg)
  }
  `;

export const SpinnerLoader = styled.div<LoadingProps>`
  margin: 20px auto 0;
  width: 3rem;
  height: 3rem;
  border: 2px solid;
  border-color: ${(props) => props.spinnerColor || "rgba(5, 120, 149, 1)"};
  border-radius: 50%;
  border-top-color: rgb(255, 255, 255, 0);
  animation: 1s ${rotate} infinite ease-in-out;
`;
