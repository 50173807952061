import { useFirebase } from "react-redux-firebase";
import { Errors, ErrorsCodes, FormValues } from "../types";
import { getRole } from "../functions/getRole";
import { useAlert } from "react-alert";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const roles: any = {
  admin: "admin",
  athlete: "athlete",
  coach: "coach",
  nobody: "nobody",
};

const errors: Errors = {
  "auth/wrong-password": "Your email and/or your password is incorrect",
  "auth/invalid-email": "Your email and/or your password is incorrect",
  "auth/user-not-found": "Your email and/or your password is incorrect",
  "auth/user-disabled": "Your account has been disabled, please contact us",
  "auth/network-request-failed": "Network error",
};

export const useHandleLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const firebase = useFirebase();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const { role, userUid } = await getRole(
        firebase,
        values.email,
        values.password
      );

      // if (!emailVerified) {
      //   alert.info("Veuillez vérifier votre email :)");
      //   setLoading(false);
      //   return;
      // }

      if (role === "athlete") {
        setLoading(false);
        alert.info("Connecte-toi sur l'application mobile pour l'utiliser !");
        return;
      }

      if (role === undefined || role === null) {
        history.push({
          pathname: "/redirection",
          state: { role: role, userUid: userUid },
        });
        return;
      }

      if (role && roles[role]) {
        try {
          console.log("historyPush");
          history.push({
            pathname: "/redirection",
            state: { role: role },
          });
        } catch (error) {
          console.log(error);
        }

        return;
      }

      alert.error(
        "Il y a un problème avec votre compte. Veuillez-nous contacter."
      );
      setLoading(false);
      return undefined;
    } catch (error: any) {
      const errorCode: ErrorsCodes = error.code;

      if (errorCode && errors[errorCode]) {
        alert.error(errors[errorCode]);
      }

      setLoading(false);
      return undefined;
    }
  };

  return { handleSubmit, loading };
};
