import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { FormValues } from "../types";

export const useCreateAcademy = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const uploadOnStorage = useUploadOnStorage();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      let logoUrl: any = "";

      if (values.blobImage) {
        logoUrl = await uploadOnStorage(values.blobImage, "academiesLogo");
      } else {
        logoUrl =
          "https://firebasestorage.googleapis.com/v0/b/enjeux-test.appspot.com/o/academiesLogo%2Flogo.png?alt=media&token=004b7bed-f714-4bf9-b728-1d24eba6adc2";
      }

      await firestore.collection("academies").add({
        name: values.name,
        logoUrl,
      });

      alert.success("Academy created");
      history.push({ pathname: "create" });
    } catch (error) {
      alert.error("Error");
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
