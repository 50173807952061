import AddWorkCycle from "components/AddWorkCycle";
import { useAddWorkCycle } from "./hooks/useAddWorkCycle";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const AddWorkCycleContainer = () => {
  const { handleSubmit, loading } = useAddWorkCycle();
  return (
    <AddWorkCycle
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default AddWorkCycleContainer;
