import moment from "moment";

export interface FormValues {
  date: string;
  country: string;
  days: number;
}

export const initialValues: FormValues = {
  date: moment().format("YYYY-MM-DD"),
  country: "",
  days: 7,
};
