import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";

export const useAthleteWorkCycles = () => {
  const [workCyclesList, setWorkCyclesList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const firestore = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    async function getWorkCyclesList() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        const workCyclesSnapshot = await firestore
          .collection("workCycles")
          .where("athlete", "==", athleteSnapshot.ref)
          .orderBy("startDate", "desc")
          .get();

        const array: any[] = [];
        workCyclesSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          array.push({
            id: doc.id,
            startDate: moment(data.startDate.toDate()).format(
              "dddd DD MMMM YYYY"
            ),
            endDate: moment(data.endDate.toDate()).format("dddd DD MMMM YYYY"),
            url: data.url !== undefined && data.url !== null ? data.url : false,
          });
        });
        setWorkCyclesList(array);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getWorkCyclesList();
  }, [alert, athleteId, firestore]);

  return { loading, workCyclesList };
};
