import { StyledValue } from "./styles";
import React from "react";
import { ColorCircle } from "./styles";

function getColor(color: string) {
  switch (color) {
    case "green":
      return "#acd98a";
    case "orange":
      return "#ffb563";
    case "red":
      return "#dc878e";

    default:
      return "grey";
  }
}

export interface CircleProps {
  value: string;
  color: string;
}

const Circle: React.FC<CircleProps> = ({ value, color }) => {
  return (
    <div style={{ marginTop: 6, display: "flex", justifyContent: "center" }}>
      <ColorCircle color={getColor(color)}>
        <StyledValue>{value}</StyledValue>
      </ColorCircle>
    </div>
  );
};

export default Circle;
