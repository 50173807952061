import UsersListContainer from "containers/UsersList/";
import AddButton from "global/components/Button/AddButton";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";
import { useHistory } from "react-router-dom";

const AdminUsersList: React.FC<any> = () => {
  const history = useHistory();
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer
          title="Athlètes"
          displayButton
          Button={
            <AddButton onClick={() => history.push({ pathname: "create" })} />
          }
        />
        <UsersListContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default React.memo(AdminUsersList);
