import FieldInput from "global/components/Form/Field";
import React from "react";

const Fields = () => {
  return (
    <div>
      <FieldInput type="password" label="Mot de passe*" name="password" />
      <FieldInput
        type="password"
        label="Confirmation mot de passe*"
        name="passwordConfirmation"
      />
    </div>
  );
};

export default Fields;
