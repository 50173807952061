import CreateGroup from "components/CreateGroup";
import { useFirestore } from "react-redux-firebase";
import React, { useState } from "react";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";
import { FormValues } from "./types";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

const CreateGroupContainer: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await firestore
        .collection("academies")
        .doc(values.academyId)
        .collection("groups")
        .doc()
        .set({
          name: values.name,
        });
      alert.success("Group created");
      history.push({ pathname: "create" });
    } catch (error) {
      alert.error("Error");
    }

    setLoading(false);
  };

  return (
    <CreateGroup
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default CreateGroupContainer;
