import { MyShadows } from "global/styles/box-shadow";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Div = styled.div`
  width: 250px;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: ${MyShadows.primary};
  padding: 20px;
  margin: 20px;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Title = styled.p`
  //width: 250px;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
`;

const Hit = (props: any) => {
  const history = useHistory();
  return (
    <a href={props.hit.pdfUrl} target="_blank" rel="noreferrer">
      <Div
      //   onClick={() =>
      //     history.push({
      //       pathname: "edit-exercise",
      //       state: { exerciseRef: props.hit.objectID },
      //     })
      //   }
      >
        <Title>{props.hit.langs.fr.name}</Title>
      </Div>
    </a>
  );
};

export default Hit;
