import ImageCropContainer from "containers/ImageCrop";
import Input from "global/components/Form/Input";
import { CenterForm } from "global/components/Form/Input/styles";
import { ValidationButton } from "global/components/Form/ValidateButton";
import React from "react";
import { FormProps } from "./types";

const CreateAcademy: React.FC<FormProps> = ({
  loading,
  dirty,
  isValid,
  values,
  setFieldValue,
}) => {
  ////(values);
  return (
    <CenterForm>
      <Input
        name="name"
        type="text"
        placeholder="Name of academy..."
        autoFocus
        label="Name"
      />
      {/* <ImageCropContainer
        croppedImage={values.croppedImage}
        setFieldValue={setFieldValue}
        label="Logo"
        aspect={4 / 4}
      /> */}

      <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
    </CenterForm>
  );
};

export default CreateAcademy;
