import AthleteBalanceSheets from "components/AthleteBalanceSheets";
import { useAthleteBalanceSheets } from "./hooks/useAthleteBalanceSheets";

interface Props {
  athleteId: string;
}

const AthleteBalanceSheetsContainer: React.FC<Props> = ({ athleteId }) => {
  const {
    balanceSheets,
    loading,
    deleteBalancheSheet,
  } = useAthleteBalanceSheets(athleteId);
  return (
    <AthleteBalanceSheets
      balanceSheets={balanceSheets}
      loading={loading}
      deleteBalanceSheet={deleteBalancheSheet}
    />
  );
};

export default AthleteBalanceSheetsContainer;
