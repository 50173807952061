import ChatMessage from "./ChatMessage";
import Form from "./Form";

import "./index.css";

interface Props {
  messagesList: any;
  sendMessage: any;
}

const ChatRoom: React.FC<Props> = ({ messagesList, sendMessage }) => {
  return (
    <div className="chat">
      <div className="scroll">
        {messagesList &&
          messagesList.map((msg: any, index: number) => (
            <ChatMessage key={index} msg={msg} />
          ))}
      </div>
      <Form sendMessage={sendMessage} />
    </div>
  );
};

export default ChatRoom;
