import { HeightDiv } from "components/CreateSeance/styles";
import { StyledContainer } from "global/styles";
import BackBody from "./BackBody";
import CptSection from "./CptSection";
import FrontBody from "./FrontBody";
import SkeletonBody from "./SkeletonBody";
import { DivBody, LineSection } from "./styles";
import { CptPains } from "./types";

interface Props {
  cptPains: CptPains | null;
}

const BodiesSection: React.FC<Props> = ({ cptPains }) => {
  return (
    <StyledContainer>
      <DivBody>
        <FrontBody cptPains={cptPains} />
        <BackBody cptPains={cptPains} />
        <SkeletonBody cptPains={cptPains} />
      </DivBody>
      <HeightDiv />
      <HeightDiv />
      <LineSection>
        <CptSection
          label="Musculaire"
          value={cptPains !== null ? cptPains.cptMuscular : 0}
        />
        <CptSection
          label="Articulaire"
          value={cptPains !== null ? cptPains.cptArticular : 0}
        />
        <CptSection
          label="Tendineux"
          value={cptPains !== null ? cptPains.cptTendinous : 0}
        />
        <CptSection
          label="Coups"
          value={cptPains !== null ? cptPains.cptContusion : 0}
        />
      </LineSection>
    </StyledContainer>
  );
};

export default BodiesSection;
