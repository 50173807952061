import TopBar from "menus/TopBar";
import AddAthlete from "pages/AddAthlete";
import AthletePlanning from "pages/AthletePlanning";
import AthleteProfile from "pages/AthleteProfile";
import AthleteRawData from "pages/AthleteRawData";
import AthletesResponses from "pages/AthletesReponses";
import CreateGroupEvent from "pages/CreateEvent/CreateGroupEvent";
import GroupPlanning from "pages/GroupPlanning";
import MyAthletes from "pages/MyAthletes";
import React from "react";
import { Route } from "react-router-dom";

const CoachApp: React.FC = () => {
  return (
    <>
      <TopBar />
      <Route path={`/app/coach/my-athletes`} component={MyAthletes} />
      <Route path={`/app/coach/add-athlete`} component={AddAthlete} />
      <Route path={`/app/coach/group-planning`} component={GroupPlanning} />
      <Route path={`/app/coach/create-event`} component={CreateGroupEvent} />
      <Route
        path={`/app/coach/group-responses`}
        component={AthletesResponses}
      />
      {/* ATHLETE */}
      <Route path={`/app/coach/athlete-raw-data`} component={AthleteRawData} />
      <Route path={`/app/coach/athlete-planning`} component={AthletePlanning} />
      <Route path={`/app/coach/athlete-profile`} component={AthleteProfile} />
    </>
  );
};

export default CoachApp;
