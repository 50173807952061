import { useOpen } from "global/hooks/useOpen";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { Day, SeanceScheme } from "schemes/program";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";
import { DocumentReference, DocumentData } from "@firebase/firestore-types";
import { reorder } from "containers/CreateEvent/hooks/useCreateEvent";
import { Exercise, Work } from "schemes/exercise";
import firebase from "firebase";

export const useAddProgram = () => {
  const auth = useAppSelector((state) => state.firebase.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const { open, openOrClose } = useOpen();

  const [selectedDay, setselectedDay] = useState<number>(0);
  const [days, setdays] = useState<Day[]>([]);

  const [isEditingSeance, setisEditingSeance] = useState<boolean>(false);

  const [exercises, setExercises] = useState<any[]>([]);
  const [selectedExercise, setSelectedExercise] = useState(0); // index of selectedExercise

  const [themes, setthemes] = useState<any[]>([]);
  const [theme, settheme] = useState<string>("");
  const [seanceName, setseanceName] = useState<string>("");
  const [seanceDescription, setseanceDescription] = useState<string>("");

  const alert = useAlert();
  const history = useHistory();
  const firestore = useFirestore();

  const _getExercises = (dto: any) => {
    const exercises: any = [];

    dto.forEach((exercise: any) => {
      if (exercise.comments === undefined || exercise.comments === null)
        exercise.comments = "";
      exercises.push({
        exercise: firebase.firestore().doc(`/exercises/${exercise.key}`),
        works: exercise.works,
        comments: exercise.comments,
      });
    });

    return exercises;
  };

  const confirmAddSeanceInDay = async () => {
    const arr: any[] = [...days];

    const themeSnapshot = await firestore.collection("themes").doc(theme).get();

    const themeData = themeSnapshot.data();

    const seance = {
      theme: firebase.firestore().doc(`/themes/${theme}`),
      themeData: {
        label: themeData?.name.fr.toUpperCase(),
        color: themeData?.color1,
      },
      langs: {
        fr: {
          name: seanceName,
          description: seanceDescription,
        },
      },
      exercises: _getExercises(exercises),
    };

    arr[selectedDay].seances.push(seance);
    setdays(arr);
    setisEditingSeance(false);
    openOrClose();
  };

  const addSeanceInDay = async (uid: string) => {
    const querySnapshot = await firestore.collection("seances").doc(uid).get();

    const data = querySnapshot.data();

    setseanceName(data?.langs.fr.name);
    setseanceDescription(data?.langs.fr.description);

    const themesArr:
      | ((prevState: never[]) => never[])
      | { name: string; value: any }[] = [];
    await Promise.all(
      data?.themes.map(async (theme: any) => {
        const name = (await theme.get()).data().name.fr;
        themesArr.push({ name: name, value: theme.id });
        settheme(theme.id);
      })
    );
    setthemes(themesArr);

    const newEx: Exercise[] = [];
    await Promise.all(
      data?.exercises.map(async (exerciseObject: any) => {
        const exerciseSnapshot = await firestore
          .collection("exercises")
          .doc(exerciseObject.exercise.id)
          .get();
        let exerciseData = exerciseSnapshot.data();

        exerciseObject.works.forEach((work: any) => {
          if (work.goalCardio) {
            work.goalCardio.type = "%fcMax";
            work.goalCardio.value = work.goalCardio.value.toString();
          }
          if (work.rest) work.rest.time = "second";
        });

        newEx.push({
          key: exerciseObject.exercise.id,
          data: {
            imageUrl: exerciseData?.imageUrl,
            name: exerciseData?.langs.fr.name,
          },
          works: exerciseObject.works,
          comments: exerciseObject.comments,
        });
      })
    );

    setExercises(newEx);

    setisEditingSeance(true);
  };

  const deleteSeanceInDay = (uid: string, daysArrayIndex: number) => {
    const arr: any[] = [...days];
    const result = arr[daysArrayIndex].seances.filter(
      (seance: SeanceScheme) => seance.uid !== uid
    );
    arr[daysArrayIndex].seances = result;
    setdays(arr);
  };

  const addDay = () => {
    const arr: any = [...days];
    arr.push({ seances: [] });
    setdays(arr);
  };

  const deleteDay = (daysArrayIndex: number) => {
    const arr: any = [...days];
    arr.splice(daysArrayIndex, 1);
    setdays(arr);
  };

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      if (days.length) {
        const goalsArray: DocumentReference<DocumentData>[] = [];
        values.goals.forEach((theme) => {
          goalsArray.push(firestore.collection("goals").doc(theme.value));
        });

        const equipmentsArray: DocumentReference<DocumentData>[] = [];
        values.equipments.forEach((theme) => {
          equipmentsArray.push(
            firestore.collection("equipments").doc(theme.value)
          );
        });

        const programsCategoriesArray: DocumentReference<DocumentData>[] = [];
        values.programsCategories.forEach((theme) => {
          programsCategoriesArray.push(
            firestore.collection("programsCategories").doc(theme.value)
          );
        });

        const sportsArray: DocumentReference<DocumentData>[] = [];
        values.sports.forEach((theme) => {
          sportsArray.push(firestore.collection("mSports").doc(theme.value));
        });

        const daysArray: any[] = [];
        await Promise.all(
          days.map(async (day: Day, index: number) => {
            const seancesInDay: any[] = [];
            await Promise.all(
              day.seances.map(async (seance: SeanceScheme) => {
                seancesInDay.push(seance);
              })
            );
            daysArray.push({ seances: seancesInDay, day: index + 1 });
          })
        );

        await firestore.collection("programs").add({
          active: values.active,
          type: values.type,
          level: firestore.collection("levels").doc(values.level),

          equipments: equipmentsArray,
          goals: goalsArray,
          langs: {
            fr: {
              name: values.langs.fr.name,
              description: values.langs.fr.description,
            },
          },
          createdAt: new Date(),
          createdBy: firestore.collection("users").doc(auth.uid),
          days: daysArray,
        });

        alert.success("Program created");
        history.push({ pathname: "programs-list" });
      } else {
        alert.error("Il faut au moins 1 jour de programme ;)");
      }
    } catch (error: any) {
      alert.error("Erreur, contactez corentin");
    }

    setLoading(false);
  };

  const deleteExercise = (i: any) => {
    setExercises(exercises.filter((exercise, index) => index !== i));
  };

  const addExercise = (newExercise: Exercise) => {
    let isInTable: boolean = false;
    exercises.forEach((exercise: Exercise) => {
      if (newExercise.key === exercise.key) isInTable = true;
    });
    if (!isInTable) {
      newExercise.comments = "";
      newExercise.works = [];
      setExercises([...exercises, newExercise]);
    } else {
      alert.error("Exercise already in the list");
    }
  };

  function onChangeCommentsExercise(comments: string) {
    const newExercises = exercises;

    newExercises[selectedExercise].comments = comments;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  }

  const handleDrag = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      exercises,
      result.source.index,
      result.destination.index
    );

    setExercises(items);
  };

  const addSerie = (key: string) => {
    if (exercises[selectedExercise].works?.length > 5) {
      alert.error("6 series maximum");
      return;
    }

    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;
    newWorks.push({
      work: { type: "rep", value: 10 },
      rest: { type: "passive", time: "second", value: 60 },
    });
    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const deleteSerie = (indexOfWork: any) => {
    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;

    newWorks.splice(indexOfWork, 1);

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);
    setExercises(items);
  };

  const editSerie = (e: any, index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    switch (item) {
      case "charge":
      case "work":
      case "rest":
      case "time":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]:
              e.target.name === "type" || e.target.name === "time"
                ? e.target.value
                : parseInt(e.target.value),
          },
        };
        break;
      case "goalCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]: e.target.value,
          },
        };
        break;
      case "speedCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            ...newWorks[index][item],
            [e.target.name]:
              e.target.name === "type" || e.target.name === "time"
                ? e.target.value
                : parseFloat(e.target.value),
          },
        };
        break;
      case "series":
        newWorks[index] = {
          ...newWorks[index],
          [item]: parseInt(e.target.value),
        };
        break;
      case "tempo":
      case "laterality":
      case "typeCardio":
      case "distanceCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: e.target.value,
        };
        break;
      default:
        break;
    }

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const duplicateSerie = (work: Work) => {
    const newExercises = exercises;
    const newWorks = exercises[selectedExercise].works;

    newWorks.push(work);

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const addItemToSerie = (index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    switch (item) {
      case "work":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "rep",
            value: 10,
          },
        };
        break;
      case "time":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "second",
            value: 60,
          },
        };
        break;
      case "rest":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "passive",
            time: "second",
            value: 60,
          },
        };
        break;
      case "charge":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "kg",
            value: 10,
          },
        };
        break;
      case "speedCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "kmh",
            value: 16,
          },
        };
        break;
      case "goalCardio":
        newWorks[index] = {
          ...newWorks[index],
          [item]: {
            type: "%fcMax",
            value: "70-80",
          },
        };
        break;
      case "tempo":
        newWorks[index] = {
          ...newWorks[index],
          tempo: "4/2/1/1",
        };
        break;
      case "laterality":
        newWorks[index] = {
          ...newWorks[index],
          laterality: "twice",
        };
        break;
      case "typeCardio":
        newWorks[index] = {
          ...newWorks[index],
          typeCardio: "training",
        };
        break;
      case "distanceCardio":
        newWorks[index] = {
          ...newWorks[index],
          distanceCardio: "5km",
        };
        break;
      case "series":
        newWorks[index] = {
          ...newWorks[index],
          series: 4,
        };
        break;
      default:
        break;
    }

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  const deleteItemToSerie = (index: number, item: string) => {
    const newExercises = exercises;
    let newWorks = exercises[selectedExercise].works;

    delete newWorks[index][item];

    newExercises[selectedExercise].works = newWorks;

    const items = reorder(newExercises, selectedExercise, selectedExercise);

    setExercises(items);
  };

  return {
    handleSubmit,
    loading,
    setselectedDay,
    days,
    addDay,
    addSeanceInDay,
    open,
    openOrClose,
    deleteSeanceInDay,
    deleteDay,
    isEditingSeance,
    exercises,
    setSelectedExercise,
    selectedExercise,
    addExercise,
    deleteExercise,
    handleDrag,
    addSerie,
    deleteSerie,
    editSerie,
    duplicateSerie,
    addItemToSerie,
    deleteItemToSerie,
    setExercises,
    onChangeCommentsExercise,
    setisEditingSeance,
    themes,
    settheme,
    confirmAddSeanceInDay,
  };
};
