import styled from "styled-components";

interface TagProps {
  label: string;
  color?: string;
}

const Label = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  padding: 5px 15px;
  margin: 5px;
`;

const Tag: React.FC<TagProps> = ({ label, color }) => {
  console.log(label);
  return (
    <Label
      style={{
        borderRadius: 20,
        backgroundColor: color
          ? color
          : `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      }}
    >
      {label.toUpperCase()}
    </Label>
  );
};

export default Tag;
