import { CardElement } from "@stripe/react-stripe-js";
import { Loading } from "global/styles";
import { useState } from "react";
import { ProductScheme } from "schemes/product";
import { useAppSelector } from "store/storeTypes";
import styled from "styled-components";
import "./payment.css";

interface Props {
  loading: boolean;
  handleSubmit: any;
  product: ProductScheme;
  stripe: any;
  elements: any;
}

export const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: undefined,
  style: {
    base: {
      iconColor: "#71E0F1",
      color: "#71E0F1",
      fontWeight: 500,
      fontFamily: "Montserrat, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#71E0F1" },
      "::placeholder": { color: "grey" },
    },
    invalid: {
      iconColor: "grey",
      color: "grey",
    },
  },
};

const Payment: React.FC<Props> = ({
  loading,
  handleSubmit,
  product,
  stripe,
  elements,
}) => {
  const profile = useAppSelector((state) => state.firebase.profile);
  const [checkBox, setCheckbox] = useState<number>(0);

  return (
    <div className="payment">
      <div>
        <div>
          <h1 className="white-title">Suivi {product.name}</h1>
          <h2 className="white-title">
            {product.firstPrice + product.monthlyPrice}€{" "}
            {product.monthlyPrice !== 0 ? "le premier mois" : null}
          </h2>
          {product.monthlyPrice !== 0 ? (
            <h2 className="white-title h2">
              puis {product.monthlyPrice}€/mois pendant{" "}
              {product.monthDuration - 1} mois
            </h2>
          ) : null}
        </div>
        <div className="cgu">
          <div className="cgu-checkbox">
            <input
              type="checkbox"
              value={checkBox}
              onChange={() => {
                setCheckbox(checkBox === 0 ? 1 : 0);
              }}
            />
          </div>

          <label>
            J'accepte les{" "}
            <a
              href={
                profile.type === "private"
                  ? product.privateCgvLink
                  : product.professionalCgvLink
              }
              target="_blank"
              rel="noreferrer"
            >
              Conditions Générales de Ventes{" "}
            </a>
          </label>
        </div>

        <form onSubmit={handleSubmit} className="form-payment">
          <CreditCardElementContainer>
            <CardForm>
              <CardElement options={CARD_OPTIONS} />
            </CardForm>
          </CreditCardElementContainer>
          <button
            className="button"
            type="submit"
            disabled={!checkBox || !stripe || !elements || loading}
          >
            {loading ? <Loading /> : "Payer"}
          </button>
        </form>
      </div>
    </div>
  );
};

export const CreditCardElementContainer = styled.div`
  margin-bottom: 30px;
  padding: 0;
  background-color: "black";
  will-change: opacity, transform;
  border: solid 1px #71e0f1;
  border-radius: 4px;
`;

export const CardForm = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }
`;

export default Payment;
