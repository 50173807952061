import SignUpForm from "components/SignUpForm";
import { useSignUpRegistration } from "./hooks/useSignUpRegistration";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const SignUpFormContainer = () => {
  const { handleSubmit, loading } = useSignUpRegistration();
  return (
    <SignUpForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      loading={loading}
    />
  );
};

export default SignUpFormContainer;
