import AthletesResponses from "components/AthletesResponses";
import { useAthletesResponses } from "./hooks/useAthletesResponses";

interface Props {
  academyId: string;
}

const AthletesResponsesContainer: React.FC<Props> = ({ academyId }) => {
  const { athletesResponses, loading } = useAthletesResponses(academyId);
  return <AthletesResponses data={athletesResponses} loading={loading} />;
};

export default AthletesResponsesContainer;
