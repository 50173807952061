import {
  StyledSectionTitle,
  StyledSectionValue,
  StyledTitle,
  StyledValue,
} from "./styles";

interface Props {
  weight: number | string;
}

const WeightSection: React.FC<Props> = ({ weight }) => {
  return (
    <div>
      <StyledSectionTitle>
        <StyledTitle>Poids</StyledTitle>
      </StyledSectionTitle>
      <StyledSectionValue>
        <StyledValue>
          {weight !== "No data" ? `${weight} kg` : weight}
        </StyledValue>
      </StyledSectionValue>
    </div>
  );
};

export default WeightSection;
