import { CancelButtonDiv } from "components/CreateSeance/FormExercise/styles";
import SeancesListContainer from "containers/SeancesList";
import CancelRoundedButton from "global/components/Button/CancelRoundedButton";

interface Props {
  openOrClose: any;
  addSeanceInDay: any;
}

const SeancesList: React.FC<Props> = ({ openOrClose, addSeanceInDay }) => {
  return (
    <div>
      <CancelButtonDiv>
        <CancelRoundedButton onClick={openOrClose} color="#B6B6B6" />
      </CancelButtonDiv>
      <SeancesListContainer onClick={addSeanceInDay} />
    </div>
  );
};

export default SeancesList;
