import SilverSubscriptionContainer from "containers/SilverSubscription";

const SilverSubscription = (props: any) => {
  return (
    <SilverSubscriptionContainer
      id={props.match.params.id}
      email={props.match.params.email}
    />
  );
};

export default SilverSubscription;
