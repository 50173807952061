import TextArea from "global/components/Form/Input/TextArea";
import { useState } from "react";
import Title from "./Title";

interface Props {
  label: string;
  items: any[];
}

const MultiTextArea: React.FC<Props> = ({ label, items }) => {
  const [display, setDisplay] = useState<boolean>(true);
  return (
    <div>
      <Title label={label} display={display} setDisplay={setDisplay} />

      {display
        ? items.map((item, index) => (
            <TextArea
              key={index}
              name={item.name}
              type="text"
              placeholder={item.placeholder}
              label={item.label}
            />
          ))
        : null}
    </div>
  );
};

export default MultiTextArea;
