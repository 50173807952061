import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useAppSelector } from "store/storeTypes";

export const useInjuriesList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [injuriesList, setInjuriesList] = useState<any[]>([]);
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const firestore = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    async function getInjuriesList() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        const injuriesSnapshot = await firestore
          .collection("injuries")
          .where("athlete", "==", athleteSnapshot.ref)
          .get();

        const array: any[] = [
          { name: "Choisir une blessure dans la liste", value: "" },
        ];
        injuriesSnapshot.docs.forEach((doc) => {
          array.push({
            name: doc.data().injury,
            value: doc.id,
          });
        });

        setInjuriesList(array);
      } catch (error) {
        alert.error("Error");
      }
      setLoading(false);
    }
    getInjuriesList();
  }, [alert, athleteId, firestore]);

  return { injuriesList, loading };
};
