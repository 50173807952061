import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  color: string;
}

const FaCopy: React.FC<Props> = ({ color }) => {
  return <FontAwesomeIcon icon={faCopy} size="1x" color={color} />;
};

export default FaCopy;
