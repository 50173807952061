import FieldInput from "global/components/Form/Field";
import React from "react";

export const InputFields: React.FC = () => {
  return (
    <>
      <FieldInput type="email" label="Email" name="email" autoFocus />
      <FieldInput type="password" label="Mot de passe" name="password" />
    </>
  );
};
