import GroupPlanningContainer from "containers/GroupPlanning";
import { GlobalDiv } from "global/styles";

const GroupPlanning = (props: any) => {
  return (
    <GlobalDiv>
      <GroupPlanningContainer groupId="Wjfg9CH821s2HCduWdSb" />
    </GlobalDiv>
  );
};

export default GroupPlanning;
