import { CardScheme } from "schemes/card";
import { Loading } from "styles";
import CareSection from "./CareSection";
import GlobalSection from "./GlobalSection";
import RehabSection from "./RehabSection";
import SeanceSection from "./SeanceSection";
import { Title } from "./styles";

interface Props {
  card: CardScheme | null;
  loading: boolean;
}

const Card: React.FC<Props> = ({ card, loading }) => {
  if (loading || card === null) return <Loading spinnerColor="#000" />;

  return (
    <div>
      <GlobalSection card={card} />
      {card.type === "rehab" ? <RehabSection card={card} /> : null}
      {card.type !== "care" ? (
        <SeanceSection card={card} />
      ) : (
        <CareSection card={card} />
      )}

      <Title>Commentaire(s)</Title>
      <p>{card.comments}</p>
    </div>
  );
};

export default Card;
