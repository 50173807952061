import {
  StyledDivTwiceDiv,
  StyledSectionTitle,
  StyledSectionValue,
  StyledTitle,
  StyledTwiceDiv,
  StyledValue,
  Div,
} from "./styles";

interface Props {
  sleep: string[];
}

const SleepSection: React.FC<Props> = ({ sleep }) => {
  return (
    <div>
      <StyledSectionTitle>
        <StyledTitle>Sommeil</StyledTitle>
      </StyledSectionTitle>
      <StyledDivTwiceDiv>
        <Div>
          <StyledTwiceDiv>
            <StyledTitle>Semaine 0</StyledTitle>
          </StyledTwiceDiv>
          <StyledSectionValue>
            <StyledValue>{sleep[0]}</StyledValue>
          </StyledSectionValue>
        </Div>
        <Div>
          <StyledTwiceDiv>
            <StyledTitle>Semaine-1</StyledTitle>
          </StyledTwiceDiv>
          <StyledSectionValue>
            <StyledValue>{sleep[1]}</StyledValue>
          </StyledSectionValue>
        </Div>
      </StyledDivTwiceDiv>
    </div>
  );
};

export default SleepSection;
