import styled from "styled-components";

interface StyledItemProps {
  isRefined: boolean;
}

export const StyledItem = styled.a<StyledItemProps>`
  font-family: "Mulish";
  font-weight: ${(props) => (props.isRefined ? "bold" : "")};
`;

export const Div = styled.div`
  padding: 10px 30px;
`;

export const Title = styled.h2`
  font-family: "Bebas Neue";
  margin-bottom: 10px;
`;
