import { Td, Tr } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Paper } from "global/styles";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { Loading } from "styles";

interface Props {
  loading: boolean;
  cardsList: any[];
}

const headItemList = ["Qui", "Date", "Type", "Durée"];

const AthleteCards: React.FC<Props> = ({ loading, cardsList }) => {
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const history = useHistory();
  if (loading) return <Loading spinnerColor="#000" />;
  if (!cardsList.length) return <p>Aucune fiche pour ce joueur</p>;
  return (
    <div>
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {cardsList.map((values: any, index: number) => (
              <Tr
                key={index}
                onClick={() =>
                  history.push({
                    pathname: "athlete-card",
                    state: { cardId: values.uid, athleteId: athleteId },
                  })
                }
              >
                <Td>{values.name}</Td>
                <Td>{values.date}</Td>
                <Td>{values.type}</Td>
                <Td>{values.duration} min</Td>
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default AthleteCards;
