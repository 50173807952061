import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useLibrarySubCategoriesList = (category: string) => {
  const [list, setlist] = useState<any[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function getData() {
      const array:
        | ((prevState: never[]) => never[])
        | { name: any; value: string }[] = [
        { name: "Sous catégorie", value: "" },
      ];

      const goalsSnapshot = await firestore
        .collection("libraryCategories")
        .doc(category)
        .collection("subCategories")
        .get();

      goalsSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const label = data.name.fr;

        array.push({ name: label, value: doc.id });
      });

      setlist(array);
    }

    getData();
  }, [category, firestore]);

  return { list };
};
