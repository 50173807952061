import FaCheck from "global/components/Icons/FaCheck";
import FaTimes from "global/components/Icons/FaTimes";
import { Td, Tr } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Paper } from "global/styles";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { Loading } from "styles";

interface Props {
  loading: boolean;
  workCyclesList: any[];
}

const headItemList = ["Date début", "Date fin", "Rapport"];

function displayReport(url: any) {
  if (!url) {
    return <FaTimes color="#FF0000" />;
  } else {
    return <FaCheck />;
  }
}

const AthleteWorkCycles: React.FC<Props> = ({ loading, workCyclesList }) => {
  const history = useHistory();
  const athleteId = useAppSelector((state) => state.athlete?.id);
  if (loading) return <Loading spinnerColor="#000" />;
  if (!workCyclesList.length) return <p>Aucun cycle pour ce joueur</p>;
  return (
    <div>
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {workCyclesList.map((values: any, index: number) => (
              <Tr
                key={index}
                onClick={() =>
                  history.push({
                    pathname: "work-cycle",
                    state: { athleteId: athleteId, workCycleUid: values.id },
                  })
                }
              >
                <Td>{values.startDate}</Td>
                <Td>{values.endDate}</Td>
                <Td>{displayReport(values.url)}</Td>
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default AthleteWorkCycles;
