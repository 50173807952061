import { DivBeetweenButton } from "components/CreateSeance/Exercises/styles";
import FaCopy from "global/components/Icons/FaCopy";
import FaPen from "global/components/Icons/FaPen";
import FaTrash from "global/components/Icons/FaTrash";
import { useHistory } from "react-router-dom";
import { ActionsDiv } from "./styles";

interface Props {
  eventId: string;
  deleteEvent: any;
  athleteId: string;
}

const Actions: React.FC<Props> = ({ eventId, deleteEvent, athleteId }) => {
  const history = useHistory();
  return (
    <ActionsDiv>
      <div
        onClick={() =>
          history.push({
            pathname: "duplicate-event",
            state: { eventId: eventId, athleteId: athleteId },
          })
        }
      >
        <FaCopy color="#FFF" />
      </div>
      <DivBeetweenButton />
      <div
        onClick={() =>
          history.push({
            pathname: "edit-event",
            state: { eventId: eventId, athleteId: athleteId },
          })
        }
      >
        <FaPen color="#FFF" />
      </div>
      <DivBeetweenButton />
      <div onClick={() => deleteEvent(eventId)}>
        <FaTrash color="#FFF" />
      </div>
    </ActionsDiv>
  );
};

export default Actions;
