import DeleteExercise from "containers/CreateExercise/DeleteExercise";
import EditExerciseContainer from "containers/CreateExercise/EditExercise";
import DeleteRoundedButton from "global/components/Button/DeleteRoundedButton";
import HeaderContainer from "global/components/HeaderContainer";
import { useOpen } from "global/hooks/useOpen";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const EditExercise: React.FC<any> = (props) => {
  const { open, openOrClose } = useOpen();
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer
          title="Edit Exercice"
          Button={<DeleteRoundedButton onClick={openOrClose} />}
          displayButton
        />
        <DeleteExercise
          open={open}
          exerciseRef={props.location.state.exerciseRef}
          cancelClick={openOrClose}
        />
        <EditExerciseContainer exerciseRef={props.location.state.exerciseRef} />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default EditExercise;
