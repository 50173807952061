import FaCheck from "global/components/Icons/FaCheck";
import FaTimes from "global/components/Icons/FaTimes";
import { Td, Tr } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Paper } from "global/styles";
import { LittleProfilePic } from "global/styles/img";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Loading } from "styles";

interface Props {
  data: any;
  loading: boolean;
}

const format = "dddd";

const headItemList = [
  null,
  "Athlete",
  moment().subtract(7, "d").format(format),
  moment().subtract(6, "d").format(format),
  moment().subtract(5, "d").format(format),
  moment().subtract(4, "d").format(format),
  moment().subtract(3, "d").format(format),
  moment().subtract(2, "d").format(format),
  moment().subtract(1, "d").format(format),
  "Today",
];

const AthletesResponses: React.FC<Props> = ({ data, loading }) => {
  const history = useHistory();
  if (loading) return <Loading />;
  return (
    <Paper>
      <StyledTable>
        <HeadTable headItemList={headItemList} />
        <tbody>
          {data.map((values: any, index: number) => (
            <Tr
              key={index}
              onClick={() => {
                history.push({
                  pathname: "athlete-raw-data",
                  state: { athleteId: values.id },
                });
              }}
            >
              <Td>
                <LittleProfilePic img={values.photoUrl} />
              </Td>
              <Td>{values.name}</Td>
              {values.responses.map((value: any, index: number) => (
                <Td key={index}>
                  {value ? <FaCheck /> : <FaTimes color="#FF0000" />}
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </StyledTable>
    </Paper>
  );
};

export default AthletesResponses;
