import { InputField, LabelField } from "./styles";
import { useField } from "formik";
import { StyledErrorMessage } from "./styles";
import { Props } from "./types";

const Input = ({ ...props }: Props) => {
  const [field, meta] = useField(props);

  return (
    <>
      <LabelField>{props.label}</LabelField>
      <InputField
        {...field}
        {...props}
        error={meta.touched && meta.error ? "true" : null}
      />

      <StyledErrorMessage
        className="error"
        opacity={meta.touched && meta.error ? 100 : 0}
      >
        {meta.error}
      </StyledErrorMessage>
    </>
  );
};

export default Input;
