import MySubscription from "components/MySubscription";
import { useMySubscription } from "./hooks/useMySubscription";

const MySubscriptionContainer = () => {
  const { loading, subscriptionData } = useMySubscription();
  return (
    <MySubscription loading={loading} subscriptionData={subscriptionData} />
  );
};

export default MySubscriptionContainer;
