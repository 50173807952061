import { Formik } from "formik";
import Form from "./Form";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isValid }) => (
        <Form isValid={isValid} dirty={true} loading={loading} />
      )}
    </Formik>
  );
};

export default FormikForm;
