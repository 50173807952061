import { RoundedButton } from "../styles";
import React from "react";
import { Props } from "./types";
import FaCopy from "global/components/Icons/FaCopy";

const DuplicateButton: React.FC<Props> = ({ onClick, color }) => {
  return (
    <RoundedButton type="button" onClick={onClick}>
      <FaCopy color={color} />
    </RoundedButton>
  );
};

export default DuplicateButton;
