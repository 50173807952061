import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useLevelsList = () => {
  const [levelsList, setlevelsList] = useState<any[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function getData() {
      const levelsSnapshot = await firestore.collection("levels").get();

      const array:
        | ((prevState: never[]) => never[])
        | { name: string; value: string }[] = [];
      levelsSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const label = data.number.toString();
        array.push({ name: label, value: doc.id });
      });

      setlevelsList(array);
    }

    getData();
  }, [firestore]);

  return { levelsList };
};
