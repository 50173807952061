import { StyledIcon, StyledTextIcon } from "./styles";
import { IconProps } from "./types";

const Icon: React.FC<IconProps> = ({ icon, label }) => {
  return (
    <div>
      <StyledIcon src={`/assets/${icon}`} alt={`${label}-icon`} />
      <StyledTextIcon>{label}</StyledTextIcon>
    </div>
  );
};

export default Icon;
