import AthleteWeeklyGoals from "components/AthleteWeeklyGoals";
import React from "react";
import { useWeeklyGoals } from "./hooks/useWeeklyGoals";

interface Props {
  athleteId: string;
}

const AthleteWeeklyGoalsContainer: React.FC<Props> = ({ athleteId }) => {
  const { weeklyGoals, loading } = useWeeklyGoals(athleteId);
  return <AthleteWeeklyGoals weeklyGoals={weeklyGoals} loading={loading} />;
};

export default AthleteWeeklyGoalsContainer;
