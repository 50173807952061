import SelectTwoDates from "components/SelectTwoDates";

export interface SelectTwoDatesProps {
  startDate: string;
  endDate: string;
  setStartDate: any;
  setEndDate: any;
}

const SelectTwoDatesContainer: React.FC<SelectTwoDatesProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <SelectTwoDates
      startDate={startDate}
      endDate={endDate}
      setStartDate={(e: any) => setStartDate(e.target.value)}
      setEndDate={(e: any) => setEndDate(e.target.value)}
    />
  );
};

export default SelectTwoDatesContainer;
