import AddButton from "global/components/Button/AddButton";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Event from "../Event";
import Injury from "../Event/Injury";
import Match from "../Event/Match";
import { Cell, CellDate } from "./styles";

interface Props {
  date: string;
  events: any[];
  deleteEvent: any;
  athleteId: string;
  deleteInjury: any;
}

const CellDay: React.FC<Props> = ({
  date,
  events,
  deleteEvent,
  athleteId,
  deleteInjury,
}) => {
  const dateFormat = "D MMM";
  const history = useHistory();
  return (
    <Cell>
      <CellDate>{moment(date).format(dateFormat)}</CellDate>
      {events.map((event: any, index: number) => {
        if (event.name === "MATCH") {
          return (
            <Match
              key={index}
              eventId={event.eventId}
              color={event.color}
              name={event.name}
              hour={event.hour}
              deleteEvent={deleteEvent}
              img={event.img}
              athleteId={athleteId}
            />
          );
        }
        if (event.name === "INJURY") {
          return (
            <Injury
              key={index}
              name={event.injury}
              day={event.day}
              injuryId={event.eventId}
              athleteId={athleteId}
              deleteInjury={deleteInjury}
            />
          );
        }
        return (
          <Event
            key={index}
            eventId={event.eventId}
            color={event.color}
            name={event.name}
            hour={event.hour}
            deleteEvent={deleteEvent}
            img={event.img}
            athleteId={athleteId}
          />
        );
      })}

      <AddButton
        onClick={() =>
          history.push({
            pathname: "create-event",
            state: { date: date, athleteId: athleteId },
          })
        }
      />
    </Cell>
  );
};

export default CellDay;
