import { ImageUrl } from "global/styles/img";
import styled from "styled-components";

const Div = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 10px 0;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Title = styled.p`
  //width: 250px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Hit = (props: any) => {
  const { hit } = props.hit;
  return (
    <Div
      onClick={() =>
        props.onClick({
          key: hit.objectID,
          data: {
            name: hit.langs.fr.name,
            imageUrl: hit.imageUrl,
          },
        })
      }
    >
      <ImageUrl src={hit.imageUrl} alt={hit.langs.fr.name} />
      <Title>{hit.langs.fr.name}</Title>
    </Div>
  );
};

export default Hit;
