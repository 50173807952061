import Axios from "axios";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";

export const useAthletesPains = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pains, setPains] = useState([]);
  const alert = useAlert();
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const res = await Axios.post(
          `${process.env.REACT_APP_FIREBASE_URL}getPainsForAcademy`,
          {
            data: {
              academyId: "io4ZMZ1ykN2DiZNCSKCd",
            },
          }
        );
        //console.log(res.data.result);
        setPains(res.data.result.splice(0, 40));
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getData();
  }, [alert]);

  return { pains, loading };
};
