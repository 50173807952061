import DuplicateOnDate from "components/DuplicateOnDate";
import { useDuplicateOnDate } from "./hooks/useDuplicateOnDate";

interface Props {
  dates: string[];
  setDates: any;
  values: any;
}

const DuplicateOnDateContainer: React.FC<Props> = ({
  dates,
  setDates,
  values,
}) => {
  const { week, addDaysFromDay } = useDuplicateOnDate(dates, setDates, values);
  return <DuplicateOnDate weeks={week} addDaysFromDay={addDaysFromDay} />;
};

export default DuplicateOnDateContainer;
