import FormExercise from "components/CreateSeance/FormExercise";
import { Exercise } from "schemes/exercise";

interface Props {
  exercise: Exercise;
  addSerie: any;
  deleteSerie: any;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  openOrClose: any;
  deleteItemToSerie: any;
  onChangeCommentsExercise: any;
}

const FormExerciseContainer: React.FC<Props> = ({
  exercise,
  addSerie,
  deleteSerie,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  openOrClose,
  deleteItemToSerie,
  onChangeCommentsExercise,
}) => {
  return (
    <FormExercise
      exercise={exercise}
      addSerie={addSerie}
      deleteSerie={deleteSerie}
      editSerie={editSerie}
      duplicateSerie={duplicateSerie}
      addItemToSerie={addItemToSerie}
      openOrClose={openOrClose}
      deleteItemToSerie={deleteItemToSerie}
      onChangeCommentsExercise={onChangeCommentsExercise}
    />
  );
};

export default FormExerciseContainer;
