import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
} from "./types";

import { Dispatch } from "redux";
import { auth } from "../../firebase/firebase";

function LoginRequest() {
  return {
    type: LOGIN_REQUEST,
  };
}

function LoginSuccess(data: any) {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
}

function LoginFailure(error: any) {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
}

export function Login(email: string, password: string) {
  return async function (dispatch: Dispatch) {
    dispatch(LoginRequest());

    try {
      await auth.signInWithEmailAndPassword(email, password);

      dispatch(LoginSuccess("Login Success"));
    } catch (error: any) {
      let errorMessage = null;

      switch (error.code) {
        case "auth/wrong-password":
        case "auth/invalid-email":
          errorMessage = "Your email and/or your password is incorrect";
          break;

        case "auth/user-disabled":
          errorMessage = "Your account has been disabled, please contact us";
          break;

        case "auth/user-not-found":
          errorMessage = "This account doesn't exist, please contact us";
          break;

        case "auth/network-request-failed":
          errorMessage = "Network error";
          break;

        default:
          errorMessage = "Unknown error";
          break;
      }

      dispatch(LoginFailure(errorMessage));
    }
  };
}

export function Logout() {
  return async function (dispatch: Dispatch) {
    //dispatch(LogoutRequest());

    try {
      await auth.signOut();

      //dispatch(LogoutSuccess("Logout Success"))
    } catch (error) {
      const errorMessage = "Impossible to Logout, please try again";

      //dispatch(LogoutFailure(errorMessage));
    }
  };
}
