import { StyledCol, StyledRow } from "global/styles/flexbox";
import React from "react";
import Box from "./Box";

const items: any[] = [
  { label: "Academy", route: "create-academy" },
  { label: "Group", route: "create-group" },
  { label: "User", route: "create-user" },
];

const Create: React.FC = () => {
  return (
    <StyledRow>
      {items.map((item: any, index: number) => (
        <StyledCol key={index} xs={12} lg={4}>
          <Box label={item.label} route={item.route} />
        </StyledCol>
      ))}
    </StyledRow>
  );
};

export default Create;
