import Input from "@material-ui/core/Input/Input";
import { Content } from "containers/CreateArticle/utils/initialValues";
import AddButton from "global/components/Button/AddButton";
import DeleteButton from "global/components/Button/DeleteRoundedButton";
import InputArticleContentImg from "global/components/Form/InputArticleContentImg";
import InputVideoFile from "global/components/Form/InputVideoFile";
import Select from "global/components/Select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface Props {
  contents: Content[];
  addContent: any;
  selectTypeofContent: any;
  editContent: any;
  deleteContent: any;
  handleDrag: any;
}

const Contents: React.FC<Props> = ({
  contents,
  addContent,
  selectTypeofContent,
  editContent,
  deleteContent,
  handleDrag,
}) => {
  return (
    <div>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="exercises">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {contents.map((content, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ContentDiv
                        contents={contents}
                        index={index}
                        selectTypeofContent={selectTypeofContent}
                        editContent={editContent}
                        deleteContent={deleteContent}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div style={{ height: 50 }} />
      <AddButton onClick={addContent} />
    </div>
  );
};

interface PropsContentDiv {
  contents: any[];
  index: number;
  selectTypeofContent: any;
  editContent: any;
  deleteContent: any;
}
const ContentDiv: React.FC<PropsContentDiv> = ({
  contents,
  index,
  selectTypeofContent,
  editContent,
  deleteContent,
}) => {
  return (
    <div style={{ display: "flex", marginTop: 50 }}>
      <div>
        <p>{index + 1}</p>
        <Select
          options={[
            { name: "Titre", value: "title" },
            { name: "Texte", value: "text" },
            { name: "Image", value: "img" },
            { name: "Vidéo", value: "video" },
            { name: "Youtube", value: "youtube" },
            { name: "Source", value: "source" },
            // { name: "Lien", value: "link" },
            // { name: "CTA", value: "cta" },
          ]}
          value={contents[index].type}
          onChange={(e: any) => selectTypeofContent(index, e.target.value)}
          optionsList={[]}
          label="Type"
          name="name"
        />
        <ContentValue
          type={contents[index].type}
          contents={contents}
          index={index}
          editContent={editContent}
        />
      </div>
      <DeleteButton onClick={() => deleteContent(index)} />
    </div>
  );
};

interface PropsContentValue {
  contents: any[];
  index: number;
  type: string;
  editContent: any;
}
const ContentValue: React.FC<PropsContentValue> = ({
  type,
  contents,
  index,
  editContent,
}) => {
  switch (type) {
    case "text":
    case "title":
    case "youtube":
    case "link":
      return (
        <div>
          <Input
            multiline
            type="text"
            name="value"
            value={contents[index].value}
            onChange={(e) => editContent(e, index)}
          />
        </div>
      );
    case "img":
      return (
        <div>
          <InputArticleContentImg
            onChange={(e: any) => editContent(e, index)}
            label="Img"
          />
        </div>
      );
    case "video":
      return (
        <div>
          <InputVideoFile
            onChange={(e: any) => editContent(e, index)}
            label="Video"
          />
        </div>
      );
    case "source":
      return (
        <div>
          <p>sources</p>
          <Input
            type="text"
            name="value"
            value={contents[index].value}
            onChange={(e) => editContent(e, index, "value")}
          />
          <p>lien</p>
          <Input
            type="text"
            name="url"
            value={contents[index].url}
            onChange={(e) => editContent(e, index, "url")}
          />
        </div>
      );

    default:
      return <div>default</div>;
  }
};

export default Contents;
