import Create from "components/Create";
import { GlobalDiv } from "global/styles";
import React from "react";

const CreateChoice: React.FC = () => {
  return (
    <GlobalDiv>
      <Create />
    </GlobalDiv>
  );
};

export default CreateChoice;
