import moment from "moment";

export interface FormValues {
  startDate: string;
  endDate: string;
}

export const initialValues: FormValues = {
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().add(6, "w").subtract(1, "d").format("YYYY-MM-DD"),
};
