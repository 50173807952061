import ToposList from "components/ToposList";
import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import React from "react";
import { InstantSearch, Pagination } from "react-instantsearch-dom";

const ToposListContainer: React.FC = () => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`topos_${process.env.REACT_APP_ENV_NAME}`}
    >
      <ToposList />
      <Pagination showLast />
    </InstantSearch>
  );
};

export default ToposListContainer;
