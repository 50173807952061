import Tag from "global/components/Tag";
import { MyShadows } from "global/styles/box-shadow";
import styled from "styled-components";

const Div = styled.div`
  width: 250px;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: ${MyShadows.primary};
  padding: 20px;
  margin: 20px;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Title = styled.p`
  //width: 250px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
`;

interface Props {
  hit: any;
  onClick: any;
}

const Hit: React.FC<Props> = ({ hit, onClick }) => {
  //(hit.hit.objectID);
  return (
    <Div onClick={() => onClick(hit.hit.objectID)}>
      <Title>{hit.hit.langs.fr.name.toUpperCase()}</Title>

      <div style={{ width: 250 }}>
        {hit.hit.themes !== undefined
          ? hit.hit.themes.map((theme: any) => (
              <Tag label={theme.name.fr} color={theme.color} />
            ))
          : null}
        {hit.hit.muscleGroups !== undefined
          ? hit.hit.muscleGroups.map((muscleGroup: string) => (
              <Tag label={muscleGroup} />
            ))
          : null}
        {hit.hit.jointInjuries !== undefined
          ? hit.hit.jointInjuries.map((jointInjury: string) => (
              <Tag label={jointInjury} />
            ))
          : null}
        {hit.hit.equipments !== undefined
          ? hit.hit.equipments.map((equipment: string) => (
              <Tag label={equipment} />
            ))
          : null}
        {hit.hit.goals !== undefined
          ? hit.hit.goals.map((goal: string) => <Tag label={goal} />)
          : null}
        {hit.hit.level !== undefined ? (
          <Tag label={`Niveau ${hit.hit.level}`} />
        ) : null}
      </div>
    </Div>
  );
};

export default Hit;
