import { Td, Tr } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { Paper } from "global/styles";
import { Loading } from "styles";

interface Props {
  data: any;
  loading: boolean;
}

const AthleteActivities: React.FC<Props> = ({ data, loading }) => {
  const headItemList = [
    "Semaine",
    "Date",
    "Thème",
    "Type",
    "Haute Intensité",
    "Condition",
    "Dynamisme",
    "Durée perçue",
    "Intensité Moyenne",
  ];

  if (loading) return <Loading />;

  return (
    <div>
      <Paper>
        <StyledTable>
          <HeadTable headItemList={headItemList} />
          <tbody>
            {data.map((values: any, index: number) => (
              <Tr key={index}>
                <Td>{values.week}</Td>
                <Td>{values.date}</Td>
                <Td>{values.theme}</Td>
                <Td>{values.type}</Td>
                {values.markers.map((marker: any, index2: number) => (
                  <>
                    <Td key={index2}>{marker.value}</Td>
                  </>
                ))}
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      </Paper>
    </div>
  );
};

export default AthleteActivities;
