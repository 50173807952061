import { DivBeetweenButton } from "components/CreateSeance/Exercises/styles";
import { HeightDiv } from "components/CreateSeance/styles";
import HorizontalAthletesListContainer from "containers/HorizontalAthletesList";
import AddButton from "global/components/Button/AddButton";
import { BigProfilePic } from "global/styles/img";
import React, { useState } from "react";
import { Row } from "react-flexbox-grid";
import styled from "styled-components";
import { InfoDiv } from "./styles";

interface Props {
  athletesList: any[];
  addAthlete: any;
  deleteAthlete: any;
}

const SelectedAthletesDiv = styled.div`
  margin-bottom: 30px;
`;

const AthleteDiv = styled.div`
  margin: 0 10px;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const AthletesChoice: React.FC<Props> = ({
  athletesList,
  addAthlete,
  deleteAthlete,
}) => {
  const [add, setAdd] = useState(false);
  return (
    <div>
      <SelectedAthletesDiv>
        <InfoDiv>
          <p>
            {`${athletesList.length} selected athlete${
              athletesList.length > 1 ? "s" : ""
            }`}{" "}
          </p>
          <DivBeetweenButton />
          <AddButton onClick={() => setAdd(true)} />
        </InfoDiv>

        <HeightDiv />
        <Row center="xs">
          {athletesList.map((athlete: any) => (
            <AthleteDiv
              key={athlete.key}
              onClick={() => deleteAthlete(athlete.key)}
            >
              <BigProfilePic img={athlete.photoUrl} />
              <p>{athlete.firstName}</p>
              <p>{athlete.lastName}</p>
            </AthleteDiv>
          ))}
        </Row>
      </SelectedAthletesDiv>

      {add ? <HorizontalAthletesListContainer onClick={addAthlete} /> : null}
    </div>
  );
};

export default AthletesChoice;
