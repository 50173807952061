import { useState } from "react";

interface Props {
  sendMessage: any;
}

const Form: React.FC<Props> = ({ sendMessage }) => {
  const [newMsg, setNewMsg] = useState<string>("");
  return (
    <div>
      <form>
        <input value={newMsg} onChange={(e) => setNewMsg(e.target.value)} />
        <button
          type="submit"
          onClick={(e) => {
            sendMessage(e, newMsg);
            setNewMsg("");
          }}
        >
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default Form;
