import SilverSubscriptionContainerWithoutTrial from "containers/SilverSubscription/withoutTrial";

const SignUpSubscriptionChoiceWithoutTrial = (props: any) => {
  return (
    <SilverSubscriptionContainerWithoutTrial
      userUid={props.match.params.userUid}
      priceUid={props.match.params.priceUid}
    />
  );
};

export default SignUpSubscriptionChoiceWithoutTrial;
