import MultiSelect from "global/components/MultiSelect";
import { useMuscleGroupsList } from "./hooks/useMuscleGroupsList";

interface Props {
  selected: any;
  setSelected: any;
}

const MuscleGroupsListContainer: React.FC<Props> = ({
  selected,
  setSelected,
}) => {
  const { muscleGroupsList } = useMuscleGroupsList();
  return (
    <MultiSelect
      optionsList={muscleGroupsList}
      value={selected}
      onChange={setSelected}
      label="Groupe(s) musculaire(s)"
    />
  );
};

export default MuscleGroupsListContainer;
