import InjuriesListContainer from "containers/InjuriesList";
import Input from "global/components/Form/Input";

interface Props {
  values: any;
  selectInjury: any;
  setFieldValue: any;
}

const RehabForm: React.FC<Props> = ({
  values,
  selectInjury,
  setFieldValue,
}) => {
  return (
    <div>
      <InjuriesListContainer
        onChange={(e: any) => selectInjury(e.target.value, setFieldValue)}
        value={values.injury}
      />
      <Input
        name="daysAfterInjury"
        type="number"
        placeholder="J+ après blessure"
        label="Nombre jour après blessure"
      />
    </div>
  );
};

export default RehabForm;
