import React from "react";
import { useHistory } from "react-router-dom";
import { StyledLi } from "./styles";
import { MenuItemProps } from "./types";

const MenuItem: React.FC<MenuItemProps> = ({ label, link, state }) => {
  const history = useHistory();
  return (
    <StyledLi onClick={() => history.push({ pathname: link, state: state })}>
      {label}
    </StyledLi>
  );
};

export default MenuItem;
