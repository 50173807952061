import { StyledPopup } from "components/ImageCrop/styles";
import SeanceInProgramFormContainer from "containers/AddProgram/SeanceInProgramForm";
import EquipmentsListContainer from "containers/EquipmentsList";
import GoalsListContainer from "containers/GoalsList";
import LevelsListContainer from "containers/LevelsList";
import ProgramsCategoriesListContainer from "containers/ProgramsCategoriesList";
import SportsListContainer from "containers/SportsList";
import Button from "global/components/Button/Button";
import MultiSelect from "global/components/MultiSelect";
import Select from "global/components/Select";
import { useEffect } from "react";
import { Day } from "schemes/program";
import Active from "./Active";
import Days from "./Days";
import Fields from "./Fields";
import Free from "./Free";
import SeancesList from "./SeancesList";

interface FormProps {
  isValid: boolean;
  dirty: boolean;
  values: any;
  setFieldValue: any;
  loading: boolean;
  open: boolean;
  openOrClose: any;
  handleSubmit: any;
  validationSchema: any;
  setselectedDay: any;
  days: Day[];
  addDay: any;
  addSeanceInDay: any;
  deleteSeanceInDay: any;
  deleteDay: any;
  edit?: boolean;
  program: any;
  isEditingSeance: boolean;

  //seance
  exercises: any[];
  addExercise: any;
  deleteExercise: any;
  handleDrag: any;
  addSerie: any;
  deleteSerie: any;
  setSelectedExercise: any;
  selectedExercise: number;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  deleteItemToSerie: any;
  onChangeCommentsExercise: any;
  themes: any[];
  setisEditingSeance: any;
  settheme: any;
  confirmAddSeanceInDay: any;
}

const FormComponent: React.FC<FormProps> = ({
  open,
  openOrClose,
  addSeanceInDay,
  values,
  setFieldValue,
  days,
  setselectedDay,
  addDay,
  deleteDay,
  deleteSeanceInDay,
  edit,
  dirty,
  isValid,
  loading,
  program,
  isEditingSeance,
  exercises,
  setSelectedExercise,
  selectedExercise,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  onChangeCommentsExercise,
  themes,
  setisEditingSeance,
  settheme,
  confirmAddSeanceInDay,
}) => {
  useEffect(() => {
    if (edit && program !== null) {
      setFieldValue(`langs.fr.name`, program.langs.fr.name);
      setFieldValue(`langs.fr.description`, program.langs.fr.description);
      setFieldValue("equipments", program.equipments);
      setFieldValue("programsCategories", program.programsCategories);
      setFieldValue("sports", program.sports);
      setFieldValue("goals", program.goals);
      setFieldValue("level", program.level);
      setFieldValue("free", program.free);
      setFieldValue("active", program.active);
    }
  }, [edit, program, setFieldValue]);
  return (
    <div>
      <StyledPopup
        open={open}
        closeOnDocumentClick={false}
        lockScroll={true}
        contentStyle={{ width: "90%" }}
      >
        {!isEditingSeance ? (
          <SeancesList
            openOrClose={openOrClose}
            addSeanceInDay={addSeanceInDay}
          />
        ) : (
          <SeanceInProgramFormContainer
            exercises={exercises}
            setSelectedExercise={setSelectedExercise}
            selectedExercise={selectedExercise}
            addExercise={addExercise}
            deleteExercise={deleteExercise}
            handleDrag={handleDrag}
            addSerie={addSerie}
            deleteSerie={deleteSerie}
            editSerie={editSerie}
            duplicateSerie={duplicateSerie}
            addItemToSerie={addItemToSerie}
            deleteItemToSerie={deleteItemToSerie}
            values={values}
            setFieldValue={setFieldValue}
            onChangeCommentsExercise={onChangeCommentsExercise}
            themes={themes}
            setisEditingSeance={setisEditingSeance}
            settheme={settheme}
            confirmAddSeanceInDay={confirmAddSeanceInDay}
          />
        )}
      </StyledPopup>
      {/* <Free /> */}
      <Active />
      <Select
        options={[
          { name: "Réhab", value: "rehab" },
          { name: "Performance", value: "performance" },
        ]}
        value={values.type}
        onChange={(e: any) => setFieldValue("type", e.target.value)}
        optionsList={[]}
        label="Type"
        name="name"
      />
      {/* <ProgramsCategoriesListContainer
        selected={values.programsCategories}
        setSelected={(e: any) => setFieldValue("programsCategories", e)}
      /> */}
      {/* <SportsListContainer
        selected={values.sports}
        setSelected={(e: any) => setFieldValue("sports", e)}
      /> */}
      {/* <GoalsListContainer
        selected={values.goals}
        setSelected={(e: any) => setFieldValue("goals", e)}
      /> */}
      <Fields lng="fr" />
      <LevelsListContainer
        selected={values.level}
        setSelected={(e: any) => setFieldValue("level", e.target.value)}
      />
      {/* <EquipmentsListContainer
        selected={values.equipments}
        setSelected={(e: any) => setFieldValue("equipments", e)}
      /> */}
      <Days
        days={days}
        openOrClose={openOrClose}
        setselectedDay={setselectedDay}
        addDay={addDay}
        deleteSeanceInDay={deleteSeanceInDay}
        deleteDay={deleteDay}
      />
      <Button
        label={edit ? "Modifier" : "Créer"}
        dirty={dirty}
        isValid={isValid}
        loading={loading}
      />
    </div>
  );
};

export default FormComponent;
