import AthleteNavContainer from "containers/AthleteNav";
import { GlobalDiv } from "global/styles";
import { StyledCol, StyledRow } from "global/styles/flexbox";
import React from "react";

const AthleteSeasonHistory: React.FC = () => {
  return (
    <GlobalDiv>
      <StyledRow>
        <StyledCol xs={12} sm={12} md={3} lg={2}>
          <AthleteNavContainer athleteId="" />
        </StyledCol>
        <StyledCol xs={12} sm={12} md={9} lg={10}>
          {/* <SeasonHistoryContainer /> */}
        </StyledCol>
      </StyledRow>
    </GlobalDiv>
  );
};

export default AthleteSeasonHistory;
