import firebase from "firebase";

function makeName(length: number) {
  let result = [];
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

export const useUploadOnStorage = () => {
  const storageRef = firebase.storage().ref();
  const uploadOnStorage = async (file: any, dir: string) => {
    const name = makeName(30);
    let uploadTask = storageRef.child(`${dir}/` + name).put(file);

    return new Promise(function (resolve, reject) {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // let progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //("Upload is " + progress + "% done");
        },
        function error(error) {
          ////("error", error);
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function complete() {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  return uploadOnStorage;
};
