import AddTopoContainer from "containers/AddTopo";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const AddTopo: React.FC<any> = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Create topo" />
        <AddTopoContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default AddTopo;
