import { InstantSearch, Pagination } from "react-instantsearch-dom";
import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import ArticlesList from "components/ArticlesList";

interface Props {
  onClick: any;
}

const ArticlesListContainer: React.FC<Props> = ({ onClick }) => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`articles_${process.env.REACT_APP_ENV_NAME}`}
    >
      <ArticlesList onClick={onClick} />
      <Pagination showLast />
    </InstantSearch>
  );
};

export default ArticlesListContainer;
