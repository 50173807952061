import DeletePopup from "global/components/DeletePopup";
import React from "react";
import { useDeleteSeance } from "./hooks/useDeleteSeance";

interface Props {
  open: boolean;
  seanceRef: string;
  cancelClick: any;
}

const DeleteSeance: React.FC<Props> = ({ open, seanceRef, cancelClick }) => {
  const { deleteSeance, loading } = useDeleteSeance(seanceRef);
  return (
    <DeletePopup
      open={open}
      validateClick={deleteSeance}
      cancelClick={cancelClick}
      loading={loading}
    />
  );
};

export default DeleteSeance;
