import AddTopo from "components/AddTopo";
import { useAddTopo } from "./hooks/useAddTopo";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const AddTopoContainer = () => {
  const { handleSubmit, loading } = useAddTopo();
  return (
    <AddTopo
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default AddTopoContainer;
