import { useHistory } from "react-router-dom";
import { CardScheme } from "schemes/card";
import { useAppSelector } from "store/storeTypes";
import { Div, SeeSeanceDiv, Separation, Title } from "./styles";

interface Props {
  card: CardScheme;
}

const SeanceSection: React.FC<Props> = ({ card }) => {
  const athleteId = useAppSelector((state) => state.athlete?.id);
  const history = useHistory();
  return (
    <Div>
      {card.seanceName !== null ? (
        <div>
          <Title>Séance</Title>
          <p>
            {card.themeName?.toUpperCase()} {card.seanceName}
          </p>
          <SeeSeanceDiv
            onClick={() =>
              history.push({
                pathname: "edit-event",
                state: { eventId: card.eventUid, athleteId: athleteId },
              })
            }
          >
            <p>voir la séance</p>
          </SeeSeanceDiv>
        </div>
      ) : null}

      <Separation />
      <Title>Organisation</Title>
      <p>{card.organization}</p>
    </Div>
  );
};

export default SeanceSection;
