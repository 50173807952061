import SilverSubscription from "components/SilverSubscription";
import { useSilverSubscriptionWithTrial } from "./hooks/useSilverSubscription";

interface Props {
  id: string;
  email: string;
}

const SilverSubscriptionContainerWithTrial: React.FC<Props> = ({
  id,
  email,
}) => {
  const {
    subscribe,
    loading,
    products,
    productselected,
    selectProduct,
  } = useSilverSubscriptionWithTrial(id, email);
  return (
    <SilverSubscription
      handlePayment={() => {}}
      onClick={subscribe}
      loading={loading}
      products={products}
      productselected={productselected}
      selectProduct={selectProduct}
      trial
    />
  );
};

export default SilverSubscriptionContainerWithTrial;
