import { StyledItem, Div, Title } from "global/components/RefinementList/styles";
import {connectRefinementList } from "react-instantsearch-dom";

const RefinementList = ({
  items,
  refine,
  createURL,
  label
}) => (
  <Div>
  <Title>{label}</Title>
    <ul>
      {items.map((item) => (
        <li key={item.label}>
          <StyledItem
            href={createURL(item.value)}
            isRefined={item.isRefined}
            onClick={(event) => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {item.label.toUpperCase()}
            {" "}
            ({item.count})
          </StyledItem>
        </li>
      ))}
    </ul>
  </Div>
);

const CustomRefinementList = connectRefinementList(RefinementList);

export default CustomRefinementList
