import { Paper } from "@material-ui/core";
import { Tr, Td } from "global/components/Table/BodyTable/styles";
import HeadTable from "global/components/Table/HeadTable";
import { StyledTable } from "global/components/Table/styles";
import { SubscriptionDataScheme } from "schemes/subscriptionData";

interface Props {
  subscriptionsList: SubscriptionDataScheme[];
}

const headItemList = ["Type", "Date début", "Date fin", "Actif"];

const SubscriptionsList: React.FC<Props> = ({ subscriptionsList }) => {
  if (!subscriptionsList.length) return <p>Aucun abonnement rattaché</p>;
  return (
    <Paper>
      <StyledTable>
        <HeadTable headItemList={headItemList} />
        <tbody>
          {subscriptionsList.map(
            (values: SubscriptionDataScheme, index: number) => (
              <Tr key={index}>
                <Td>{values.type}</Td>
                <Td>{values.startedAt}</Td>
                <Td>{values.cancelAt}</Td>
                <Td>{values.active}</Td>
              </Tr>
            )
          )}
        </tbody>
      </StyledTable>
    </Paper>
  );
};

export default SubscriptionsList;
