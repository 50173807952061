import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { ProfileScheme } from "schemes/profile";
import { SubscriptionDataScheme } from "schemes/subscriptionData";
import { useAppSelector } from "store/storeTypes";

export const useAthleteProfile = () => {
  const athlete = useAppSelector((state) => state.athlete);
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<ProfileScheme | null>(null);
  const [subscriptionsList, setSubscriptionsList] = useState<
    SubscriptionDataScheme[]
  >([]);
  const alert = useAlert();
  const firestore = useFirestore();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const athleteRef = firestore.collection("users").doc(athlete!.id);

        const userSnapshot = await firestore
          .collection("users")
          .doc(athlete!.id)
          .get();

        const data = userSnapshot.data();

        setProfile({
          fistName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          phone: data?.phoneNumber !== undefined ? data?.phoneNumber : "N/A",
          birthDate:
            data?.birthDate !== undefined
              ? moment(data?.birthDate.toDate()).format("DD/MM/YYYY")
              : "N/A",
          size: data?.size !== undefined ? data?.size : "N/A",
          weight: data?.weight !== undefined ? data?.weight : "N/A",
        });

        const subscriptionsSnapshot = await firestore
          .collection("subscriptions")
          .where("user", "==", athleteRef)
          .orderBy("startedAt", "desc")
          .get();

        const array: SubscriptionDataScheme[] = [];
        subscriptionsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          array.push({
            startedAt: moment(data.startedAt.toDate()).format("DD-MM-YYYY"),
            cancelAt: moment(data.cancelAt.toDate()).format("DD-MM-YYYY"),
            type: data.productDescription,
            active: data.active ? "Oui" : "Non",
          });
        });

        setSubscriptionsList(array);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getData();
  }, [alert, athlete, firestore]);

  return { profile, loading, subscriptionsList };
};
