import { WeeklyGoal } from "containers/AthleteWeeklyGoals/types";
import { StyledContainer } from "./styles";
import Circle from "./Circle";
import Icon from "./Icon";
import { HeightDiv } from "components/CreateSeance/styles";
import { useAppSelector } from "store/storeTypes";

interface Props {
  goal: WeeklyGoal;
}

const Goal: React.FC<Props> = ({ goal }) => {
  const langs = useAppSelector((state) => state.firestore.data.langs);
  const lng = useAppSelector((state) => state.lng);
  return (
    <StyledContainer>
      <Icon icon={goal.img} label={langs[lng][goal.label]} />
      <HeightDiv />
      <p>SEMAINE</p>
      <Circle value={goal.weekValueToDisplay} color={goal.weekColor} />
      <HeightDiv />
      <p>MÉDIANE</p>
      <Circle value={goal.medianValue.toString()} color={goal.medianColor} />
    </StyledContainer>
  );
};

export default Goal;
