import moment from "moment";
import { SetStateAction, useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useAthleteActivities = (athleteId: string) => {
  const [activities, setActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const firestore = useFirestore();

  useEffect(() => {
    async function getData() {
      const array: SetStateAction<any[]> = [];

      const activitiesSnapshot = await firestore
        .collection("users")
        .doc(athleteId)
        .collection("activities")
        .get();

      await Promise.all(
        activitiesSnapshot.docs.map(async (doc: any) => {
          const theme = (await doc.data().theme.get()).data();

          const markers: { markerName: any; value: any }[] = [];
          doc.data().markers.map(async (marker: any) => {
            const markerName = (await marker.marker.get()).data().name.fr;
            markers.push({
              markerName: markerName,
              value: marker.value,
            });
          });

          array.push({
            id: doc.id,
            week: moment(doc.data().date.toDate()).isoWeek(),
            trueDate: doc.data().date.toDate(),
            date: moment(doc.data().date.toDate()).format("dddd DD MMMM"),
            duration: doc.data().duration,
            type: doc.data().type,
            theme: theme?.name.fr,
            markers: markers,
          });
        })
      );

      array.sort(function (a: any, b: any) {
        return b.trueDate - a.trueDate;
      });

      setActivities(array);

      console.log(array);
      setLoading(false);
    }

    getData();
  }, [athleteId, firestore]);

  return { activities, loading };
};
