import styled from "styled-components";

export const WeekDaysDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const DayDiv = styled.div`
  width: 100%;
  margin: 0 10px;
`;

export const Day = styled.p`
  text-align: center;
  font-family: "Bebas Neue";
  width: 178px;
`;
