import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useMuscleGroupsList = () => {
  const [muscleGroupsList, setmuscleGroupsList] = useState<any[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function getData() {
      const muscleGroupsSnapshot = await firestore
        .collection("muscleGroups")
        .get();

      const array:
        | ((prevState: never[]) => never[])
        | { label: any; value: string }[] = [];
      muscleGroupsSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const label = data.langs.fr.toUpperCase();
        array.push({ label: label, value: doc.id });
      });
      setmuscleGroupsList(array);
    }

    getData();
  }, [firestore]);

  return { muscleGroupsList };
};
