import { Bar } from "react-chartjs-2";
import { MarkerObject } from "containers/AthleteCurves/hooks/useAthleteCurves";
import { StyledContainer } from "global/styles";

interface Props {
  curves: MarkerObject;
  week: number;
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          step: 1,
          max: 100,
        },
      },
    ],
  },
};

const Curves: React.FC<Props> = ({ curves, week }) => {
  const data = {
    labels: curves.dates,
    datasets: [
      {
        type: "line",
        label: "Moyenne mobile courte",
        data: curves.mm7,
        fill: false,
        borderColor: "rgb(30, 218, 36)",
      },
      {
        type: "line",
        label: "Moyenne mobile longue",
        data: curves.mm21,
        fill: false,
        borderColor: "rgb(233, 18, 237)",
      },
      {
        type: "bar",
        label: "Match",
        data: curves.gamesValues,
        borderColor: "rgb(255, 0, 55)",
        backgroundColor: "rgb(255, 0, 55)",
      },
      {
        type: "bar",
        label: "Séance groupe",
        data: curves.groupTrainings,
        borderColor: "rgba(0,78,104,1)",
        backgroundColor: "rgba(0,78,104,1)",
      },
      {
        type: "bar",
        label: "Séance indiv",
        data: curves.individualTrainings,
        borderColor: "rgba(5,120,149,1) ",
        backgroundColor: "rgba(5,120,149,1) ",
      },
    ],
  };

  return (
    <StyledContainer style={{ marginBottom: 20 }}>
      <p>Semaine {week}</p>
      <Bar data={data} options={options} />
    </StyledContainer>
  );
};

export default Curves;
