import DeletePopup from "global/components/DeletePopup";
import React from "react";
import { useDeleteExercise } from "./hooks/useDeleteExercise";
import { DeleteExerciseProps } from "./types";

const DeleteExercise: React.FC<DeleteExerciseProps> = ({
  open,
  exerciseRef,
  cancelClick,
}) => {
  const { deleteExercise, loading } = useDeleteExercise(exerciseRef);
  return (
    <DeletePopup
      open={open}
      validateClick={deleteExercise}
      cancelClick={cancelClick}
      loading={loading}
    />
  );
};

export default DeleteExercise;
