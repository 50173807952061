import { MyShadows } from "global/styles/box-shadow";
import { MyGradients } from "global/styles/colors";
import Popup from "reactjs-popup";
import styled from "styled-components";

export const StyledPopup = styled(Popup)`
  &-content {
    overflow: scroll;
    height: 90%;
    width: 100%;
  }
`;

export const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

export const Button = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 40px;
  box-shadow: ${MyShadows.primary};
  background: ${MyGradients.primary};

  border-radius: 50px;
  border: 0px;
  box-sizing: border-box;
  outline: unset;

  font-family: "Mulish";
  font-weight: 400;
  font-size: 14px;
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
