import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ProgramPaymentContainer from "containers/ProgramPayment";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const ProgramPayment = (props: any) => {
  return (
    <Elements stripe={stripePromise}>
      <ProgramPaymentContainer
        userUid={props.match.params.userUid}
        userEmail={props.match.params.userEmaim}
        programUid={props.match.params.programUid}
      />
    </Elements>
  );
};

export default ProgramPayment;
