import AddProgram from "components/AddProgram";
import { useEditProgram } from "./hooks/useEditProgram";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  programUid: string;
}

const EditProgramContainer: React.FC<Props> = ({ programUid }) => {
  const {
    handleSubmit,
    loading,
    open,
    openOrClose,
    setselectedDay,
    days,
    addDay,
    addSeanceInDay,
    deleteSeanceInDay,
    deleteDay,
    program,
    isEditingSeance,
    exercises,
    setSelectedExercise,
    selectedExercise,
    addExercise,
    deleteExercise,
    handleDrag,
    addSerie,
    deleteSerie,
    editSerie,
    duplicateSerie,
    addItemToSerie,
    deleteItemToSerie,
    onChangeCommentsExercise,
    setisEditingSeance,
    themes,
    settheme,
    confirmAddSeanceInDay,
  } = useEditProgram(programUid);
  return (
    <>
      <AddProgram
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        loading={loading}
        open={open}
        openOrClose={openOrClose}
        setselectedDay={setselectedDay}
        days={days}
        addDay={addDay}
        addSeanceInDay={addSeanceInDay}
        deleteSeanceInDay={deleteSeanceInDay}
        deleteDay={deleteDay}
        edit
        program={program}
        isEditingSeance={isEditingSeance}
        exercises={exercises}
        addExercise={addExercise}
        deleteExercise={deleteExercise}
        handleDrag={handleDrag}
        addSerie={addSerie}
        deleteSerie={deleteSerie}
        setSelectedExercise={setSelectedExercise}
        selectedExercise={selectedExercise}
        editSerie={editSerie}
        duplicateSerie={duplicateSerie}
        addItemToSerie={addItemToSerie}
        deleteItemToSerie={deleteItemToSerie}
        onChangeCommentsExercise={onChangeCommentsExercise}
        themes={themes}
        setisEditingSeance={setisEditingSeance}
        settheme={settheme}
        confirmAddSeanceInDay={confirmAddSeanceInDay}
      />
    </>
  );
};

export default EditProgramContainer;
