import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { SubscriptionDataScheme } from "schemes/subscriptionData";
import { useAppSelector } from "store/storeTypes";

export const useMySubscription = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [
    subscriptionData,
    setSubscriptionData,
  ] = useState<SubscriptionDataScheme | null>(null);

  const auth = useAppSelector((state) => state.firebase.auth);

  const alert = useAlert();
  const firestore = useFirestore();

  useEffect(() => {
    async function getSubscriptionData() {
      setLoading(true);
      try {
        const subscriptionSnapshot = await firestore
          .collection("users")
          .doc(auth.uid)
          .collection("subscriptions")
          .get();

        const data = subscriptionSnapshot.docs[0].data();
        const type = (await data.product.get()).data().name;

        setSubscriptionData({
          type: type,
          cancelAt: moment(data.cancelAt.toDate()).format("DD-MM-YYYY"),
          startedAt: moment(data.startedAt.toDate()).format("DD-MM-YYYY"),
          active: data.active ? "Oui" : "Non",
        });
      } catch (error) {
        alert.error("Error");
      }
      setLoading(false);
    }

    getSubscriptionData();
  }, [alert, auth.uid, firestore]);

  return { loading, subscriptionData };
};
