import styled from "styled-components";

export const DarkText = styled.p`
  text-transform: uppercase;
  color: #25546b;
  font-weight: bold;
`;

export const NormalText = styled.p`
  font-family: "Montserrat";
  text-align: center;
  margin: 4px 0;
`;

export const NormalTitle = styled.p`
  font-family: "Bebas Neue";
  text-align: center;
`;
