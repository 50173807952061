import Select from "global/components/Select";
import { useLibraryCategoriesList } from "./hooks/useLibraryCategoriesList";

interface Props {
  selected: any;
  setSelected: any;
}

const LibraryCategoriesList: React.FC<Props> = ({ selected, setSelected }) => {
  const { categories } = useLibraryCategoriesList();
  return (
    <Select
      options={categories}
      value={selected}
      onChange={setSelected}
      optionsList={categories}
      label="Catégorie"
      name="category"
    />
  );
};

export default LibraryCategoriesList;
