import React from "react";
import { Work } from "schemes/exercise";
import { Number } from "../Exercises/styles";
import Charge from "./BlocValues/Charge";
import DistanceCardioValue from "./BlocValues/Distance";
import GoalCardioValue from "./BlocValues/GoalCardio";
import Laterality from "./BlocValues/Laterality";
import Rest from "./BlocValues/Rest";
import Series from "./BlocValues/Series";
import SpeedCardioValue from "./BlocValues/SpeedCardio";
import Tempo from "./BlocValues/Tempo";
import TimeValue from "./BlocValues/Time";
import TypeCardioValue from "./BlocValues/TypeCardio";
import WorkValue from "./BlocValues/Work";
import Buttons from "./Buttons";
import { SerieDiv } from "./styles";

interface Props {
  number: number;
  work: Work;
  length?: number;
  deleteSerie: any;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  deleteItemToSerie: any;
}

const Serie: React.FC<Props> = ({
  number,
  work,
  length,
  deleteSerie,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
}) => {
  return (
    <SerieDiv>
      <Number>{number}</Number>

      {work.series ? (
        <Series series={work.series} editSerie={editSerie} index={number - 1} />
      ) : null}

      {work.work ? (
        <WorkValue work={work.work} editSerie={editSerie} index={number - 1} />
      ) : null}

      {work.time ? (
        <TimeValue time={work.time} editSerie={editSerie} index={number - 1} />
      ) : null}

      {work.charge ? (
        <Charge charge={work.charge} editSerie={editSerie} index={number - 1} />
      ) : null}

      {work.tempo ? (
        <Tempo tempo={work.tempo} editSerie={editSerie} index={number - 1} />
      ) : null}

      {work.laterality ? (
        <Laterality
          laterality={work.laterality}
          editSerie={editSerie}
          index={number - 1}
        />
      ) : null}

      {work.typeCardio ? (
        <TypeCardioValue
          typeCardio={work.typeCardio}
          editSerie={editSerie}
          index={number - 1}
        />
      ) : null}

      {work.speedCardio ? (
        <SpeedCardioValue
          speedCardio={work.speedCardio}
          editSerie={editSerie}
          index={number - 1}
        />
      ) : null}

      {work.distanceCardio ? (
        <DistanceCardioValue
          distanceCardio={work.distanceCardio}
          editSerie={editSerie}
          index={number - 1}
        />
      ) : null}

      {work.goalCardio ? (
        <GoalCardioValue
          goalCardio={work.goalCardio}
          editSerie={editSerie}
          index={number - 1}
        />
      ) : null}

      {work.rest ? (
        <Rest rest={work.rest} editSerie={editSerie} index={number - 1} />
      ) : null}

      <Buttons
        number={number}
        deleteSerie={deleteSerie}
        duplicateSerie={duplicateSerie}
        work={work}
        addItemToSerie={addItemToSerie}
        deleteItemToSerie={deleteItemToSerie}
      />
    </SerieDiv>
  );
};

export default Serie;
