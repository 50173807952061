import { HeightDiv } from "components/CreateSeance/styles";
import {
  StyledSectionTitle,
  StyledSectionValue,
  StyledTitle,
  StyledValue,
} from "./styles";

interface Props {
  pains: string[];
}

const WeekPainsSection: React.FC<Props> = ({ pains }) => {
  return (
    <div>
      <StyledSectionTitle>
        <StyledTitle>Douleur(s)</StyledTitle>
      </StyledSectionTitle>

      {pains.map((pain, index) => (
        <div key={index}>
          <StyledSectionValue>
            <StyledValue>{pain}</StyledValue>
          </StyledSectionValue>
          <HeightDiv />
        </div>
      ))}
    </div>
  );
};

export default WeekPainsSection;
