import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { FormValues } from "../utils/initialValues";

export const useSignUpRegistration = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const firebase = useFirebase();
  const alert = useAlert();
  const history = useHistory();
  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);

      history.push({
        pathname: `/signup/planform/${userCredential.user?.uid}`,
      });
    } catch (error: any) {
      //const errorCode = error.code;
      const errorMessage = error.message;
      alert.error(errorMessage);
    }
    setLoading(false);
  };

  return { handleSubmit, loading };
};
