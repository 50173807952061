import React from "react";
import WeekLine from "./WeekLine";
import { Loading } from "styles";

interface Props {
  weeklyGoals: any[];
  loading: boolean;
}

const AthleteWeeklyGoals: React.FC<Props> = ({ weeklyGoals, loading }) => {
  if (loading && !weeklyGoals.length) return <Loading />;

  if (!weeklyGoals.length) return <p>Pas de données</p>;

  return (
    <div>
      {weeklyGoals.map((weeklyGoal: any, index: number) => (
        <WeekLine data={weeklyGoal} />
      ))}
    </div>
  );
};

export default AthleteWeeklyGoals;
