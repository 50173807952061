import CreateExercise from "components/CreateExercise";
import { initialValues } from "./utils/initialValues";
import React from "react";
import { useEditExercise } from "./hooks/useEditExercise";
import { EditExerciseProps } from "./types";
import { validationSchema } from "./utils/yupSchemas";

const EditExerciseContainer: React.FC<EditExerciseProps> = ({
  exerciseRef,
}) => {
  const { handleSubmit, exercise, loading } = useEditExercise(exerciseRef);

  return (
    <CreateExercise
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      edit
      exercise={exercise}
      loading={loading}
    />
  );
};

export default EditExerciseContainer;
