import { LabelField } from "global/components/Form/Input/styles";
import React from "react";
import { InputDiv, StyledInputFile } from "../InputVideoFile/styles";
import { InputVideoFileProps } from "../InputVideoFile/types";

const InputPdfFile: React.FC<InputVideoFileProps> = ({ label, onChange }) => {
  return (
    <InputDiv>
      <LabelField>{label}</LabelField>

      <StyledInputFile
        onChange={onChange}
        type="file"
        accept="application/pdf"
      />
    </InputDiv>
  );
};

export default InputPdfFile;
