import { Input, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  charge: Work["charge"];
  editSerie: any;
  index: number;
}

const Charge: React.FC<Props> = ({ charge, editSerie, index }) => {
  return (
    <BlocValueDiv>
      <TitleBloc>CHARGE</TitleBloc>
      <Select
        name="type"
        value={charge?.type}
        onChange={(e) => editSerie(e, index, "charge")}
      >
        <MenuItem value={"kg"}>Kg</MenuItem>
        <MenuItem value={"rm"}>%RM</MenuItem>
        <MenuItem value={"elastic"}>Elastic</MenuItem>
        <MenuItem value={"bodyWeight"}>Body Weight</MenuItem>
      </Select>
      {charge?.type !== "bodyWeight" ? (
        <Input
          type="number"
          name="value"
          value={charge?.value}
          onChange={(e) => editSerie(e, index, "charge")}
        />
      ) : null}
    </BlocValueDiv>
  );
};

export default Charge;
