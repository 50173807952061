import { Loading } from "global/styles";
import React from "react";
import { RedStyledButton } from "./styles";
import { Props } from "./types";

const RedButton: React.FC<Props> = ({ onClick, loading }) => {
  return (
    <RedStyledButton onClick={onClick}>
      {loading ? <Loading /> : "Delete"}
    </RedStyledButton>
  );
};

export default RedButton;
