import AthleteNavContainer from "containers/AthleteNav";
import AthletePlanningContainer from "containers/AthletePlanning";
import { GlobalDiv } from "global/styles";
import { StyledCol, StyledRow } from "global/styles/flexbox";
// import { useFirestore } from "react-redux-firebase";
// import { useHistory } from "react-router-dom";

const AthletePlanning = (props: any) => {
  // const firestore = useFirestore();
  // const history = useHistory();
  return (
    <GlobalDiv>
      <StyledRow>
        <StyledCol xs={12} sm={12} md={3} lg={2}>
          <AthleteNavContainer athleteId={props.location.state.athleteId} />
        </StyledCol>
        <StyledCol xs={12} sm={12} md={9} lg={10}>
          {/* <button
            onClick={async () => {
              await firestore
                .collection("users")
                .doc(props.location.state.athleteId)
                .delete();
              history.push({ pathname: "users-list" });
            }}
          >
            delete player
          </button> */}
          <AthletePlanningContainer
            athleteId={props.location.state.athleteId}
          />
        </StyledCol>
      </StyledRow>
    </GlobalDiv>
  );
};

export default AthletePlanning;
