import CreateAcademy from "components/CreateAcademy/";
import React from "react";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";
import { useCreateAcademy } from "./hooks/useCreateAcademy";

const CreateAcademyContainer: React.FC = () => {
  const { handleSubmit, loading } = useCreateAcademy();

  return (
    <CreateAcademy
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default CreateAcademyContainer;
