import AddNutritionContainer from "containers/AddNutrition";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const AddNutrition: React.FC<any> = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Create nutrition" />
        <AddNutritionContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default AddNutrition;
