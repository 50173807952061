import { StyledContainer } from "components/AthleteWeeklyGoals/styles";
import CreateArticleContainer from "containers/CreateArticle";
import { GlobalDiv } from "global/styles";

const CreateArticle = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <CreateArticleContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default CreateArticle;
