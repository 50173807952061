import React from "react";
import { useHistory } from "react-router-dom";

const Redirection: React.FC = (props: any) => {
  console.log("Redirection");
  const history = useHistory();

  const paths = {
    admin: "/app/admin/home",
    athlete: "/app/athlete/home",
    coach: "app/coach/my-athletes",
    nobody: `/signup/planform/${props.location.state.userUid}`,
  };

  console.log(props.location.state.role);

  switch (props.location.state.role) {
    case "admin":
      history.push(paths["admin"]);
      break;

    case "athlete":
      history.push(paths["athlete"]);
      break;

    case "coach":
      history.push(paths["coach"]);
      break;

    case "nobody":
    case undefined:
      history.push(paths["nobody"]);
      break;
  }

  return null;
};

export default Redirection;
