import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useNbrUnreadMessages = () => {
  const [nbrUnreadMessages, setnbrUnreadMessages] = useState<number>(0);
  const firestore = useFirestore();

  useEffect(() => {
    async function getData() {
      const channelsSnapshot = await firestore
        .collection("channels")
        .where("adminUnreadMessage", "==", true)
        .get();

      setnbrUnreadMessages(channelsSnapshot.docs.length);
    }

    getData();
  }, [firestore]);

  return { nbrUnreadMessages };
};
