import SeanceInEventForm from "components/CreateEvent/SeanceInEvent/SeanceInEventForm";

export interface SeanceInEventFormProps {
  exercises: any[];
  addExercise: any;
  deleteExercise: any;
  handleDrag: any;
  addSerie: any;
  deleteSerie: any;
  setSelectedExercise: any;
  selectedExercise: number;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  deleteItemToSerie: any;
  values: any;
  setFieldValue: any;
  onChangeCommentsExercise: any;
  themes: any[];
}

const SeanceInEventFormContainer: React.FC<SeanceInEventFormProps> = ({
  exercises,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  setSelectedExercise,
  selectedExercise,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  values,
  setFieldValue,
  onChangeCommentsExercise,
  themes,
}) => {
  return (
    <SeanceInEventForm
      exercises={exercises}
      setSelectedExercise={setSelectedExercise}
      selectedExercise={selectedExercise}
      addExercise={addExercise}
      deleteExercise={deleteExercise}
      handleDrag={handleDrag}
      addSerie={addSerie}
      deleteSerie={deleteSerie}
      editSerie={editSerie}
      duplicateSerie={duplicateSerie}
      addItemToSerie={addItemToSerie}
      deleteItemToSerie={deleteItemToSerie}
      values={values}
      setFieldValue={setFieldValue}
      onChangeCommentsExercise={onChangeCommentsExercise}
      themes={themes}
    />
  );
};

export default SeanceInEventFormContainer;
