import AddProgramContainer from "containers/AddProgram";
import { GlobalDiv, StyledContainer } from "global/styles";

const AddProgram: React.FC<any> = () => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <AddProgramContainer />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default AddProgram;
