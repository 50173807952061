import { Loading } from "global/styles";
import { SubscriptionDataScheme } from "schemes/subscriptionData";
import "./my-subscription.css";

interface Props {
  loading: boolean;
  subscriptionData: SubscriptionDataScheme | null;
}

const MySubscription: React.FC<Props> = ({ loading, subscriptionData }) => {
  if (loading || subscriptionData === null) return <Loading />;

  return (
    <div className="my-subscription">
      <p>Type d'abonnement : {subscriptionData.type}</p>
      <p>Démaré le : {subscriptionData.startedAt}</p>
      <p>Fin le : {subscriptionData.cancelAt}</p>
    </div>
  );
};

export default MySubscription;
