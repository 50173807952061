const Logo = () => {
  return (
    <div className="logo">
      <div className="logo-container">
        <img src="/assets/logo-enjeux-blanc.png" alt="logo-enjeux" />
      </div>
    </div>
  );
};

export default Logo;
