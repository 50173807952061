import { Input } from "@material-ui/core";
import React from "react";
import { Work } from "schemes/exercise";
import { BlocValueDiv, TitleBloc } from "../styles";

interface Props {
  tempo: Work["tempo"];
  editSerie: any;
  index: number;
}

const Charge: React.FC<Props> = ({ tempo, editSerie, index }) => {
  return (
    <BlocValueDiv>
      <TitleBloc>TEMPO</TitleBloc>
      <Input
        type="text"
        name="tempo"
        value={tempo}
        onChange={(e) => editSerie(e, index, "tempo")}
      />
    </BlocValueDiv>
  );
};

export default Charge;
