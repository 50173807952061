import Pro from "components/SilverSubscription/Pro";
import { useProSubscription } from "./hooks/useProSubscription";

interface Props {
  id: string;
  email: string;
}

const ProSubscription: React.FC<Props> = ({ id, email }) => {
  const { subscribe, loading, products, productselected, selectProduct } =
    useProSubscription(id, email);
  return (
    <Pro
      onClick={subscribe}
      loading={loading}
      products={products}
      productselected={productselected}
      selectProduct={selectProduct}
    />
  );
};

export default ProSubscription;
