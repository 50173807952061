import { InstantSearch, Pagination } from "react-instantsearch-dom";
import SeancesList from "components/SeancesList";
import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import CustomRefinementList from "components/ExercisesList/CustomRefinementList";
import DesactivateToggleRefinement from "components/UsersList/DesactivateToggleRefinement";

interface Props {
  onClick: any;
}

const SeancesListContainer: React.FC<Props> = ({ onClick }) => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`seances_${process.env.REACT_APP_ENV_NAME}`}
    >
      <CustomRefinementList
        attribute="themes.name.fr"
        limit={20}
        label="Thèmes"
      />
      <CustomRefinementList attribute="goals" limit={20} label="Objectifs" />
      <CustomRefinementList attribute="free" limit={20} label="Gratuite" />
      <CustomRefinementList
        attribute="inLibrary"
        limit={20}
        label="Dans la library"
      />
      <CustomRefinementList attribute="preparation" limit={20} label="Prépa" />
      <CustomRefinementList attribute="educative" limit={20} label="Éducatif" />
      <CustomRefinementList attribute="level" limit={20} label="Niveau" />
      <SeancesList onClick={onClick} />
      <Pagination showLast />
    </InstantSearch>
  );
};

export default SeancesListContainer;
