import ExercisesList from "containers/ExercisesList/List";
import { StyledCol, StyledRow } from "global/styles/flexbox";
import Exercises from "./Exercises";
import FormSeanceContainer from "containers/CreateSeance/FormSeance";
import Header from "global/components/Header";
import React from "react";
import { HeightDiv } from "./styles";
import { StyledPopup } from "components/ImageCrop/styles";
import FormExerciseContainer from "containers/CreateSeance/FormExercise";
import { useOpen } from "global/hooks/useOpen";
import FormEditSeanceContainer from "containers/CreateSeance/FormEditSeance";

interface Props {
  exercises: any[];
  addExercise: any;
  deleteExercise: any;
  handleDrag: any;
  addSerie: any;
  deleteSerie: any;
  setSelectedExercise: any;
  selectedExercise: number;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  deleteItemToSerie: any;
  edit?: boolean;
  seanceRef: string;
  onChangeCommentsExercise: any;
}

const CreateSeance: React.FC<Props> = ({
  exercises,
  addExercise,
  deleteExercise,
  handleDrag,
  addSerie,
  deleteSerie,
  setSelectedExercise,
  selectedExercise,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  deleteItemToSerie,
  edit,
  seanceRef,
  onChangeCommentsExercise,
}) => {
  const { open, openOrClose } = useOpen();
  return (
    <div>
      <StyledPopup open={open} closeOnDocumentClick={false} lockScroll={true}>
        <FormExerciseContainer
          exercise={exercises[selectedExercise]}
          addSerie={addSerie}
          deleteSerie={deleteSerie}
          editSerie={editSerie}
          duplicateSerie={duplicateSerie}
          addItemToSerie={addItemToSerie}
          openOrClose={openOrClose}
          deleteItemToSerie={deleteItemToSerie}
          onChangeCommentsExercise={onChangeCommentsExercise}
        />
      </StyledPopup>
      <StyledRow>
        <StyledCol lg={9}>
          <Header title={"Seance Informations"} />
          {!edit ? (
            <FormSeanceContainer exercises={exercises} />
          ) : (
            <FormEditSeanceContainer
              exercises={exercises}
              seanceRef={seanceRef}
            />
          )}

          <HeightDiv />
          <Header title={`Seance Exercises (${exercises.length})`} />
          <Exercises
            exercises={exercises}
            deleteExercise={deleteExercise}
            handleDrag={handleDrag}
            setSelectedExercise={setSelectedExercise}
            openOrClose={openOrClose}
          />
        </StyledCol>
        <StyledCol lg={3}>
          <ExercisesList onClick={addExercise} />
        </StyledCol>
      </StyledRow>
    </div>
  );
};

export default CreateSeance;
