import { Form, Formik } from "formik";
import { FormikProps } from "./types";
import Button from "global/components/Button/Button";
import FieldInput from "global/components/Form/Field";

const AddAthlete = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <div>
            <Form className="form">
              <div>
                <FieldInput
                  type="text"
                  label="Prénom*"
                  name="firstName"
                  autoFocus
                />
                <FieldInput type="text" label="Nom*" name="lastName" />
                <FieldInput type="email" label="Email*" name="email" />
                <Button
                  label="Ajouter"
                  dirty={dirty}
                  isValid={isValid}
                  loading={loading}
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAthlete;
