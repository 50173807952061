import SignUp from "components/SignUp";
import { useSignUp } from "./hooks/useSignUp";
import { SignUpInitialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const SignUpContainer = () => {
  const { loading, signUpHandleSubmit } = useSignUp();
  return (
    <>
      <SignUp
        initialValues={SignUpInitialValues}
        handleSubmit={signUpHandleSubmit}
        validationSchema={validationSchema}
        loading={loading}
      />
    </>
  );
};

export default SignUpContainer;
