import { Loading, StyledContainer } from "global/styles";
import { ProfileScheme } from "schemes/profile";
import { SubscriptionDataScheme } from "schemes/subscriptionData";
import SubscriptionsList from "./SubscriptionList";

interface Props {
  profile: ProfileScheme | null;
  loading: boolean;
  subscriptionsList: SubscriptionDataScheme[];
}

const AthleteProfile: React.FC<Props> = ({
  profile,
  loading,
  subscriptionsList,
}) => {
  if (loading && profile === null) return <Loading />;
  return (
    <StyledContainer>
      <h2>Nom </h2>
      <p>{profile?.lastName}</p>
      <h2>Prénom </h2>
      <p>{profile?.fistName}</p>
      <h2>Email </h2>
      <p>{profile?.email}</p>
      <h2>Tél </h2>
      <p>{profile?.phone}</p>
      <h2>Date de naissance </h2>
      <p>{profile?.birthDate}</p>
      <h2>Taille </h2>
      <p>{profile?.size}</p>
      <h2>Poids </h2>
      <p>{profile?.weight}</p>
      <h2>Abonnement(s)</h2>
      <SubscriptionsList subscriptionsList={subscriptionsList} />
    </StyledContainer>
  );
};

export default AthleteProfile;
