import AthleteNav from "components/AthleteNav";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import selectAthlete from "store/actions/athleteActions";

interface Props {
  athleteId: string;
}

const AthleteNavContainer: React.FC<Props> = ({ athleteId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function getAthlete() {
      dispatch(await selectAthlete(athleteId));
    }

    getAthlete();
  }, [athleteId, dispatch]);

  return <AthleteNav athleteId={athleteId} />;
};

export default AthleteNavContainer;
