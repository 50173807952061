import React from "react";
import styled, { keyframes } from "styled-components";
import { Row } from "react-flexbox-grid";
import { Field } from "formik";

export const ErrorInputField = styled.p`
  color: #ff0000;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const NameInputField = styled.p`
  color: var(--bleu-ciel);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const InputField = styled(Field)`
  -webkit-appearance: none;
  outline: unset;
  display: block;
  width: 230px;
  height: 40px;
  border-radius: 40px;
  border: 0px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 0 25px;
  margin-bottom: 20px;

  &.focus {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  }
`;

export const SelectField = styled(Field)`
  width: 230px;
  height: auto;
  border: 0px;
  margin: 5px;
  padding: 0.75rem;
  background: linear-gradient(
    90deg,
    rgba(129, 162, 179, 1) 0%,
    rgba(106, 145, 165, 1) 100%
  );
  border-radius: 40px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  outline: none;
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
`;

export const PageTitle = styled.p`
  font-size: 26px;
  color: #fff;
`;

interface ImgProps {
  img: string | null;
}

export const ProfilRoundImg = styled.div<ImgProps>`
  div {
    display: inline-block;
    width: calc(var(--nav-size) * 0.8);
    height: calc(var(--nav-size) * 0.8);
    border-radius: 50%;

    background-image: ${(props) => `url(${props.img})`};
    background-repeat: no-repeat;
    background-position: inherit;
    background-size: cover;
  }
`;

export const Paper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  border-radius: 20px;
  box-shadow: "0 6px 8px rgba(0, 0, 0, 0.0)";
`;

export const DivPadding10 = styled.div`
  padding: 10px;
`;

export const LibraryImg = styled.img`
  width: 150px;
`;

export const ThemeImg = styled.img`
  width: 100px;
  margin: auto;
`;

const bleuFoncé = "#407c97";
const bleuCiel = "#71e0f1";

const xs = "479px";
const sm = "740px";
const md = "960px";
const lg = "1280px";

const green = "#acd98a";
const orange = "#ffb563";
const red = "#dc878e";

const ErrorText = styled.p`
  margin: 10px 0;
  color: red;
`;

export const Table = styled.table`
  border-spacing: 0;
`;

export const ValueTd = styled.td`
  padding: 20px;
  text-align: center;
  font-family: "Montserrat";
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    90deg,
    rgba(84, 164, 187, 1) 0%,
    rgba(113, 224, 241, 1) 100%
  );
  border-radius: 40px;
  border: 0px;
  box-sizing: border-box;
  outline: unset;
  padding: 0 25px;
  margin-top: 20px;
`;

export const Input2 = styled.input`
  -webkit-appearance: none;
  outline: unset;
  display: block;
  height: 40px;
  border-radius: 40px;
  border: 0px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 0 25px;
  margin: auto;

  &.focus {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  }

  ::placeholder {
    color: #fff;
  }
`;

const ButtonRouge = styled.button`
  width: 280px;
  height: 40px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    90deg,
    rgba(217, 29, 29, 1) 28%,
    rgba(241, 107, 107, 1) 100%
  );
  text-transform: uppercase;
  border-radius: 40px;
  border: 0px;
  box-sizing: border-box;
  outline: unset;
  padding: 0 25px;
  margin-top: 20px;

  :hover {
    cursor: pointer;
    color: #fff;
    opacity: 0.8;
  }
`;

const rotate = keyframes`
from {
  transform: rotate(0deg)
}

to {
  transform: rotate(360deg)
}
`;

interface LoadingProps {
  spinnerColor?: string;
}

const Loading = styled.div<LoadingProps>`
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border: 2px solid;
  border-color: ${(props) => props.spinnerColor || "#fff"};
  border-radius: 50%;
  border-top-color: rgb(255, 255, 255, 0);
  animation: 1s ${rotate} infinite ease-in-out;
`;

const BigLoading = styled.div`
  width: 5rem;
  height: 5rem;
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: ${bleuFoncé};
  animation: 1s ${rotate} infinite ease-in-out;
`;

export const Spinner = () => {
  return (
    <Row center="xs">
      <BigLoading />
    </Row>
  );
};

const Container = styled.div`
  background-color: #fff;
  //height: 100%;
  //padding: 15px;
  border-radius: 20px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
`;

const H1Bold = styled.h1`
  color: ${bleuFoncé};
`;

const H1Light = styled.h2`
  color: ${bleuFoncé};
`;

const ProfilPicture = styled.img`
  width: 200px;
  box-shadow: 2px 11px 13px -9px rgba(0, 0, 0, 0.41);
`;

const BeautifulTr = styled.tr`
  /*background: linear-gradient(
    90deg,
    rgba(113, 224, 241, 1) 0%,
    rgba(84, 164, 187, 1) 100%
  );*/
  background: ${bleuCiel};
  padding: 0;
`;

const BeautifulTd = styled.td`
  padding: 10px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
`;

const LastTd = styled.td`
  padding: 10px;
  background: ${bleuCiel};
  color: ${bleuFoncé};
  font-family: "Montserrat";
`;

const ItemTitle = styled.p`
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${bleuCiel};
  text-transform: uppercase;
`;

export const Select = styled.select`
  width: 200px;
  height: auto;
  border: 0px;
  margin: 0px;
  padding: 0.75rem;
  background: linear-gradient(
    90deg,
    rgba(129, 162, 179, 1) 0%,
    rgba(106, 145, 165, 1) 100%
  );
  border-radius: 40px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  outline: none;
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
`;

export const Option = styled.option`
  -webkit-appearance: none;
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0.75rem;
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
  color: ${bleuFoncé};
  font-size: 15px;

  :checked {
    background-color: #6890a4;
    outline: none;
    border: 0px;
  }

  :focus {
    background-color: #6890a4;
    outline: none;
    border: 0px;
  }

  :hover {
    background-color: #6890a4;
    outline: none;
  }

  :not(:checked) {
    background-color: #fff;
    outline: none;
  }
`;

const Form = styled.form`
  margin: auto;

  @media (min-width: ${xs}) {
    width: "100%";
  }
`;

export const TextFoncé = styled.p`
  text-transform: uppercase;
  color: #25546b;
  font-weight: bold;
`;

const TextBlanc = styled.p`
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
`;

export const TextClair = styled.p`
  text-transform: uppercase;
  color: ${bleuCiel};
  font-weight: bold;
`;

const ExcelButton = styled.button`
  width: 50px;
  height: 50px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    90deg,
    rgba(64, 151, 100, 1) 0%,
    rgba(131, 211, 119, 1) 100%
  );

  border-radius: 50%;
  border: 0px;
  box-sizing: border-box;
  outline: unset;
`;

export const ButtonRond = styled.button`
  width: 50px;
  height: 50px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    90deg,
    rgba(84, 164, 187, 1) 0%,
    rgba(113, 224, 241, 1) 100%
  );
  border-radius: 50%;
  border: 0px;
  box-sizing: border-box;
  outline: unset;
`;

export const Div = styled.div`
  width: fit-content;
  margin: auto;
  padding-bottom: 30px;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  outline: unset;
  display: block;
  width: 230px;
  height: 40px;
  border-radius: 40px;
  border: 0px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 0 25px;
  margin-bottom: 20px;

  &.focus {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  }
`;

export const TextArea = styled.textarea`
  -webkit-appearance: none;
  outline: unset;
  display: block;
  width: 250px;
  height: 200px;
  border-radius: 40px;
  border: 0px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 0 25px;

  &.focus {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  }
`;

export const DivImport = styled.div`
  color: rgb(139, 149, 154);
  text-align: center;
  max-width: 500px;
  font-size: 1.2em;
  border-width: 2px;
  border-style: dashed;
  border-color: rgb(226, 230, 234);
  border-image: initial;
  outline: unset;
  padding: 10px;
  margin: auto;
`;

export {
  xs,
  sm,
  md,
  lg,
  bleuCiel,
  bleuFoncé,
  green,
  orange,
  red,
  Button,
  ButtonRouge,
  ErrorText,
  Loading,
  BigLoading,
  Container,
  H1Bold,
  H1Light,
  ProfilPicture,
  BeautifulTr,
  BeautifulTd,
  LastTd,
  ItemTitle,
  Form,
  TextBlanc,
  ExcelButton,
};
