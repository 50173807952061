import DeletePopup from "global/components/DeletePopup";
import React from "react";
import { useDeleteCard } from "./hooks/useDeleteCard";

interface Props {
  open: boolean;
  cardId: string;
  cancelClick: any;
}

const DeleteCard: React.FC<Props> = ({ open, cardId, cancelClick }) => {
  const { deleteCard, loading } = useDeleteCard(cardId);
  return (
    <DeletePopup
      open={open}
      validateClick={deleteCard}
      cancelClick={cancelClick}
      loading={loading}
    />
  );
};

export default DeleteCard;
