import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: {
        en: {
          translations: {
            "Abonnement à vie": "Lifetime subscription",
            "Abonnement annuel": "Annual subscription",
            "Abonnement mensuel": "Monthly subscription",
            "Promotion d'été": "🔥 SUMMER PROMOTION 🔥",
            Discount1: "Up to ",
            Discount2: "-53% ",
            Discount3: "on your Premium annual subscription",
            Countdown: "Time remaining: ",
            Title1: "Level up your game and secure the career you deserve",
            Title2: "Select your offer",
            PriceDetail1: "44,99€ deducted annually",
            PriceDetail2: "4,99€ deducted monthly",
            PriceDetail3: "129,99€ charged once",
            DiscountDetail1: "instead of 95,88€/year",
            DiscountDetail2: "instead of 7,99€/month",
            Mois: "/month",
            Feature1: "Save 25% compared to the monthly subscription",
            Feature2: "Automatic renewal",
            Feature3: "Cancelable at any time",
            Feature4: "All our Premium features",
            Feature5: "One-time payment",
            Feature6: "Subscription valid for life",
            subscribe: "I subscribe",
            "Meilleure offre": "Best offer",
            // Add more translations here
          },
        },
        fr: {
          translations: {
            "Abonnement à vie": "Abonnement à vie",
            "Abonnement annuel": "Abonnement annuel",
            "Abonnement mensuel": "Abonnement mensuel",
            "Promotion d'été": "🔥 PROMOTION D'ÉTÉ 🔥",
            Discount1: "Jusqu'à ",
            Discount2: "-53% ",
            Discount3: "sur votre abonnement annuel Premium",
            Countdown: "Temps restant : ",
            Title1:
              "Passe à la vitesse supérieure et offre-toi la carrière que tu mérites",
            Title2: "Sélectionne l'offre qui te convient",
            PriceDetail1: "44,99€ prélevés tous les ans",
            PriceDetail2: "4,99€ prélevés tous les mois",
            PriceDetail3: "129,99€ prélevés une seule fois",
            DiscountDetail1: "au lieu de 95,88€/an",
            DiscountDetail2: "au lieu de 7,99€/mois",
            Mois: "/mois",
            Feature1: "Économise 25% par rapport à l'abonnement mensuel",
            Feature2: "Renouvellement automatique",
            Feature3: "Annulable à tout moment",
            Feature4: "Toutes nos fonctionnalités Premium",
            Feature5: "Un seul paiement",
            Feature6: "Abonnement valable à vie",
            subscribe: "Je m'abonne",
            "Meilleure offre": "Meilleure offre",
            // Add more translations here
          },
        },
      },
      fallbackLng: "en",
      ns: ["translations"],
      defaultNS: "translations",
      detection: {
        order: [
          "navigator",
          "querystring",
          "cookie",
          "localStorage",
          "sessionStorage",
          "htmlTag",
          "path",
          "subdomain",
        ],
        caches: ["cookie"],
      },
    },
    (err, t) => {
      if (err) return console.error(err);
      console.log("Language detected:", i18n.language); // Log the detected language
    }
  );

export default i18n;
