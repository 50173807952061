import AthleteCardsContainer from "containers/AthleteCards";
import AthleteNavContainer from "containers/AthleteNav";
import AddButton from "global/components/Button/AddButton";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import { StyledCol, StyledRow } from "global/styles/flexbox";
import { useHistory } from "react-router-dom";

const AthleteCards = (props: any) => {
  const history = useHistory();
  return (
    <GlobalDiv>
      <StyledRow>
        <StyledCol xs={12} sm={12} md={3} lg={2}>
          <AthleteNavContainer athleteId={props.location.state.athleteId} />
        </StyledCol>
        <StyledCol xs={12} sm={12} md={9} lg={10}>
          <StyledContainer>
            <HeaderContainer
              title="Fiches"
              displayButton
              Button={
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: "add-card",
                      state: { athleteId: props.location.state.athleteId },
                    })
                  }
                />
              }
            />
            <AthleteCardsContainer />
          </StyledContainer>
        </StyledCol>
      </StyledRow>
    </GlobalDiv>
  );
};

export default AthleteCards;
