import firebase from "firebase";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

export const useDeleteExercise = (exerciseRef: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useAlert();
  const history = useHistory();
  const deleteExercise = () => {
    setLoading(true);
    try {
      firebase.firestore().collection("exercises").doc(exerciseRef).delete();
      alert.success("Exercise deleted");
      history.push({ pathname: "exercises-list" });
    } catch (error) {
      alert.error("Error");
    }

    setLoading(false);
  };

  return { deleteExercise, loading };
};
