import { HeightDiv } from "components/CreateSeance/styles";
import { StyledContainer } from "global/styles";
import moment from "moment";
import { Loading } from "styles";
import Curves from "./Curves";
import SelectMarker from "./SelectMarker";

interface Props {
  marker: string;
  setMarker: any;
  data: any[];
  loading: boolean;
}

const AthleteCurves: React.FC<Props> = ({
  marker,
  setMarker,
  data,
  loading,
}) => {
  if (loading || data === null) return <Loading />;

  if (!data.length) return <p>"Pas de données"</p>;

  return (
    <div>
      <StyledContainer>
        <SelectMarker marker={marker} setMarker={setMarker} />
      </StyledContainer>
      <HeightDiv />
      {data.map((curve) => (
        <Curves
          curves={curve.markers[marker]}
          week={moment(curve.startDate.toDate()).isoWeek()}
        />
      ))}
    </div>
  );
};

export default AthleteCurves;
