import { Checkbox } from "@material-ui/core";
import { WeekDaysDiv } from "components/AthletePlanning/WeekDays/styles";
//import DatesChoice from "components/CreateEvent/DatesChoice";
import { Div } from "components/DuplicateOnDate/DayCheckbox";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const useDuplicateOnDate = (
  dates: string[],
  setDates: any,
  values: any
) => {
  const [week, setWeek] = useState([]);
  const startDate = moment(dates[0]).startOf("week").isoWeekday(1);

  const manageOneDay = (date: string) => {
    const indexOf = dates.indexOf(date);

    if (indexOf === -1) {
      setDates([...dates, moment(date).format("YYYY-MM-DD")]);
    } else {
      setDates(dates.filter((date: any, index: number) => index !== indexOf));
    }
  };

  const addDaysFromDay = (isoWeek: number, checked: boolean) => {
    //(checked);
    const newDates = dates;
    let i = 1;
    while (i <= parseInt(values.numberOfWeeks)) {
      const date = moment(dates[0])
        .add(i, "w")
        .startOf("week")
        .isoWeekday(isoWeek)
        .format("YYYY-MM-DD");

      if (checked) {
        if (dates.indexOf(date) === -1) setDates([...dates, date]);
      } else {
        const indexOf = dates.indexOf(date);
        if (indexOf !== -1) newDates.splice(indexOf, 1);
      }

      i++;
    }

    //(newDates);
  };

  //(dates);

  useEffect(() => {
    const dateFormat = "D MMM";
    const rows: any = [];
    let days = [];
    let day = startDate;

    let x = 0;
    while (x < parseInt(values.numberOfWeeks)) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = moment(day).format(dateFormat);
        const bddDate = moment(day).format("YYYY-MM-DD");

        let isChecked: boolean = false;
        dates.forEach((date) => {
          if (formattedDate === moment(date).format(dateFormat))
            isChecked = true;
        });

        days.push(
          <Div>
            <p>{formattedDate}</p>
            <Checkbox
              checked={isChecked}
              onChange={() => manageOneDay(bddDate)}
            />
          </Div>
        );
        day = moment(day).add(1, "d");
      }
      rows.push(<WeekDaysDiv key={x}>{days}</WeekDaysDiv>);
      x++;
      days = [];
    }

    setWeek(rows);
  }, [dates, values.numberOfWeeks]);

  return { week, addDaysFromDay };
};
