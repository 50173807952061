import AddBalanceSheetContainer from "containers/AddBalanceSheet";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const AddBalanceSheet: React.FC<any> = (props) => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Add Balance Sheet" />
        <AddBalanceSheetContainer athleteId={props.location.state.athleteId} />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default AddBalanceSheet;
