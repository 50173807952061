import AddCard from "components/AddCard";
import { useAddCard } from "./hooks/useAddCard";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

const AddCardContainer = () => {
  const { handleSubmit, loading, selectInjury, selectSeance } = useAddCard();
  return (
    <AddCard
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
      selectInjury={selectInjury}
      selectSeance={selectSeance}
    />
  );
};

export default AddCardContainer;
