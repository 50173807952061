import DuplicateEventContainer from "containers/CreateEvent/DuplicateEvent";
import HeaderContainer from "global/components/HeaderContainer";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const CreateEvent: React.FC<any> = (props) => {
  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer title="Duplicate Event" />
        <DuplicateEventContainer
          eventId={props.location.state.eventId}
          athleteId={props.location.state.athleteId}
        />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default React.memo(CreateEvent);
