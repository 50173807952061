import ImageCropContainer from "containers/ImageCrop";
import TextArea from "global/components/Form/Input/TextArea";
import { useState } from "react";
import Title from "./Title";

interface Props {
  label: string;
  setFieldValue: any;
  values: any;
}

const Mental: React.FC<Props> = ({ label, values, setFieldValue }) => {
  const [display, setDisplay] = useState<boolean>(true);
  return (
    <div>
      <Title label={label} display={display} setDisplay={setDisplay} />
      {display ? (
        <div>
          <ImageCropContainer
            croppedImage={values.croppedImageJoy}
            croppedImageValue="croppedImageJoy"
            blobImageValue="blobImageJoy"
            setFieldValue={setFieldValue}
            label="Graphique Joy"
            aspect={4 / 3}
          />
          <ImageCropContainer
            croppedImage={values.croppedImageSelfConfidence}
            croppedImageValue="croppedImageSelfConfidence"
            blobImageValue="blobImageSelfConfidence"
            setFieldValue={setFieldValue}
            label="Graphique Confiance en soi"
            aspect={4 / 3}
          />
          <ImageCropContainer
            croppedImage={values.croppedImageConcentration}
            croppedImageValue="croppedImageConcentration"
            blobImageValue="blobImageConcentration"
            setFieldValue={setFieldValue}
            label="Graphique Concentration"
            aspect={4 / 3}
          />
          <TextArea
            name="mentalComments"
            type="text"
            placeholder="RAS"
            label="Commentaires"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Mental;
