import { MyShadows } from "global/styles/box-shadow";
import styled from "styled-components";

export const ColumnContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: ${MyShadows.primary};
`;

export const ImgDiv = styled.div`
  padding: 30px;
  margin: auto;
  width: fit-content;
`;

export const Name = styled.p`
  text-align: center;
`;

export const SubTitle = styled.p`
  font-size: 12px;
  text-align: center;
`;
