import AthletePlanning from "components/AthletePlanning";
import { useGroupPlanning } from "./hooks/useGroupPlanning";

interface Props {
  groupId: string;
}

const GroupPlanningContainer: React.FC<Props> = ({ groupId }) => {
  const {
    eventsList,
    deleteEvent,
    currentMonth,
    prevMonth,
    nextMonth,
    deleteInjury,
    loading,
  } = useGroupPlanning(groupId);

  return (
    <AthletePlanning
      eventsList={eventsList}
      currentMonth={currentMonth}
      prevMonth={prevMonth}
      nextMonth={nextMonth}
      deleteEvent={deleteEvent}
      athleteId={groupId}
      deleteInjury={deleteInjury}
      loading={loading}
    />
  );
};

export default GroupPlanningContainer;
