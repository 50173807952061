import * as Yup from "yup";

export const validationSchema = Yup.object({
  themes: Yup.array().min(1, "Un thème obligatoire"),
  blobImage: Yup.string().required("Required"),
  langs: Yup.object()
    .shape({
      fr: Yup.object().shape({
        name: Yup.string().required("Required"),
      }),
    })

    .required("Required"),
});
