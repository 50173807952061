import firebase from "firebase";

const selectAthlete = async (athleteUid: string) => {
  const athleteSnapshotDocument = await firebase
    .firestore()
    .collection("users")
    .doc(athleteUid)
    .get();

  const data = athleteSnapshotDocument.data();

  const athlete = {
    id: athleteUid,
    fistName: data?.firstName,
    lastName: data?.lastName,
    photoUrl: data?.photoUrl,
    appRole: data?.appRole,
  };

  return {
    type: "SELECT_ATHLETE",
    payload: athlete,
  };
};

export default selectAthlete;
