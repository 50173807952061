import CreateSeance from "components/CreateSeance";
import { useExercise } from "./hooks/useExercises";

const CreateSeanceContainer = () => {
  const {
    exercises,
    setSelectedExercise,
    selectedExercise,
    addExercise,
    deleteExercise,
    handleDrag,
    addSerie,
    deleteSerie,
    editSerie,
    duplicateSerie,
    addItemToSerie,
    deleteItemToSerie,
    onChangeCommentsExercise,
  } = useExercise();
  return (
    <CreateSeance
      exercises={exercises}
      setSelectedExercise={setSelectedExercise}
      selectedExercise={selectedExercise}
      addExercise={addExercise}
      deleteExercise={deleteExercise}
      handleDrag={handleDrag}
      addSerie={addSerie}
      deleteSerie={deleteSerie}
      editSerie={editSerie}
      duplicateSerie={duplicateSerie}
      addItemToSerie={addItemToSerie}
      deleteItemToSerie={deleteItemToSerie}
      seanceRef=""
      onChangeCommentsExercise={onChangeCommentsExercise}
    />
  );
};

export default CreateSeanceContainer;
