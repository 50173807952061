import Select from "global/components/Select";

interface Props {
  marker: string;
  setMarker: any;
}

const markersList = [
  { name: "Haute intensité", value: "highIntensity" },
  { name: "Intensité Moyenne", value: "mediumIntensity" },
  { name: "Condition", value: "condition" },
  { name: "Dynamisme/Réactivité", value: "dynamism" },
  { name: "Durée Perçue", value: "perceivedDuration" },
];

const SelectMarker: React.FC<Props> = ({ marker, setMarker }) => {
  return (
    <div>
      <Select
        onChange={(e: any) => setMarker(e.target.value)}
        value={marker}
        options={markersList}
        optionsList={[]}
        label="Marqueur"
        name="string"
      />
    </div>
  );
};

export default SelectMarker;
