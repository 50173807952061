import { Formik } from "formik";
import { CenterForm } from "global/components/Form/Input/styles";
import { ValidationButton } from "global/components/Form/ValidateButton";
import { StyledContainer } from "global/styles";
import Form from "./Form";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty }) => (
        <StyledContainer>
          <CenterForm>
            <Form />
            <ValidationButton
              dirty={dirty}
              isValid={isValid}
              loading={loading}
            />
          </CenterForm>
        </StyledContainer>
      )}
    </Formik>
  );
};

export default FormikForm;
