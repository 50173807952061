import CreateArticle from "components/CreateArticle";
import { useEditArticle } from "./hooks/useEditArticle";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  articleUid: string;
}
const EditArticleContainer: React.FC<Props> = ({ articleUid }) => {
  const {
    handleSubmit,
    loading,
    contents,
    addContent,
    selectTypeofContent,
    editContent,
    deleteContent,
    handleDrag,
    article,
  } = useEditArticle(articleUid);
  return (
    <CreateArticle
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
      contents={contents}
      addContent={addContent}
      selectTypeofContent={selectTypeofContent}
      editContent={editContent}
      deleteContent={deleteContent}
      handleDrag={handleDrag}
      article={article}
      edit
    />
  );
};

export default EditArticleContainer;
