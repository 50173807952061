import React from "react";
import { LabelField } from "../Form/Input/styles";
import { CenterDiv } from "components/CreateEvent/styles";
import { MultiSelect } from "react-multi-select-component";

interface Props {
  label: string;
  optionsList: any[];
  onChange: any;
  value: any[];
}

const MultiSelectComponent: React.FC<Props> = ({
  value,
  onChange,
  optionsList,
  label,
}) => {
  return (
    <CenterDiv>
      <LabelField>{label}</LabelField>
      <MultiSelect
        className="multi-select"
        options={optionsList}
        value={value}
        onChange={onChange}
        labelledBy="Select"
      />
    </CenterDiv>
  );
};

export default MultiSelectComponent;
