import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { FormValues } from "../utils/initialValues";
import firebase from "firebase";
import { _activityHourToDate } from "global/functions/activityHourToDate";
import { useState } from "react";
import { useAppSelector } from "store/storeTypes";

export const useAddWorkCycle = () => {
  const athlete = useAppSelector((state) => state.athlete);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const firestore = useFirestore();
  const alert = useAlert();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      await firestore.collection("workCycles").add({
        athlete: firebase.firestore().doc(`/users/${athlete?.id}`),
        startDate: _activityHourToDate(values.startDate, "10:00"),
        endDate: _activityHourToDate(values.endDate, "10:00"),
      });
      alert.success("Work cycle added");
      history.push({
        pathname: "athlete-work-cycles",
        state: { athleteId: athlete?.id },
      });
    } catch (error) {
      //console.log(error);
      alert.error("Erreur");
    }
    setLoading(false);
  };

  return { handleSubmit, loading };
};
