import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";

export const useSeanceGoalsList = () => {
  const [goalsList, setgoalsList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const goalsSnapshot = await firestore.collection("goals").get();

        const array: any[] = [];
        goalsSnapshot.docs.map(async (doc) => {
          array.push({
            name: doc.data().langs.fr,
            value: doc.id,
          });
        });

        setgoalsList(array);
      } catch (error) {
        //console.log(error);
        alert.error("Error");
      }
      setLoading(false);
    }

    getData();
  }, [alert, firestore]);

  return { goalsList, loading };
};
