import { FormProps } from "./types";
import { CenterForm } from "global/components/Form/Input/styles";
import React, { useEffect, useState } from "react";
import Fields from "./Fields";
import { ValidationButton } from "global/components/Form/ValidateButton";
import ImageCropContainer from "containers/ImageCrop";
import InputVideoFile from "global/components/Form/InputVideoFile";
import SelectThemeContainer from "containers/SelectTheme";
import Video from "global/components/Form/Video";
import MuscleGroupsListContainer from "containers/MuscleGroupsList";
import JointInjuriesListContainer from "containers/JointInjuriesList";
import EquipmentsListContainer from "containers/EquipmentsList";
import LevelsListContainer from "containers/LevelsList";
import PositionsListContainer from "containers/PositionsList";
import { CreatedByText, HeightDiv } from "components/CreateSeance/styles";
// import HorizontalLangsList from "global/components/Langs/HorizontalLangsList";

const Form: React.FC<FormProps> = ({
  values,
  setFieldValue,
  dirty,
  isValid,
  edit,
  exercise,
  loading,
}) => {
  const [lang] = useState("fr");

  // const handleLang = (newLang: string) => {
  //   if (lang === newLang) return;

  //   setLang(newLang);
  // };

  useEffect(() => {
    //(exercise);
    if (edit && Object.getOwnPropertyNames(exercise).length) {
      const fields = ["croppedImage", "blobImage", "videoUrl", "theme"];
      fields.forEach((field) => setFieldValue(field, exercise[field]));

      let keywords: any[] = [];
      let i: number = 1;
      exercise.langs.fr.keywords.forEach((keyword: string) => {
        keywords.push({ id: i, text: keyword });
        i++;
      });

      setFieldValue(`langs[${lang}].name`, exercise.langs.fr.name);
      setFieldValue(
        `langs[${lang}].description`,
        exercise.langs.fr.description
      );
      setFieldValue(`langs[${lang}].keywords`, keywords);

      setFieldValue("themes", exercise.themes);
      setFieldValue("muscleGroups", exercise.muscleGroups);
      setFieldValue("jointInjuries", exercise.jointInjuries);
      setFieldValue("equipments", exercise.equipments);
      setFieldValue("goals", exercise.goals);
      setFieldValue("positions", exercise.positions);
      setFieldValue("level", exercise.level);
    }
  }, [edit, setFieldValue, exercise, lang]);

  return (
    <CenterForm>
      <HeightDiv />
      {edit ? (
        <CreatedByText>Created by {exercise.createdBy}</CreatedByText>
      ) : null}
      <HeightDiv />

      <SelectThemeContainer
        theme={values.themes}
        setTheme={(e: any) => setFieldValue("themes", e)}
      />

      <LevelsListContainer
        selected={values.level}
        setSelected={(e: any) => setFieldValue("level", e.target.value)}
      />

      <MuscleGroupsListContainer
        selected={values.muscleGroups}
        setSelected={(e: any) => setFieldValue("muscleGroups", e)}
      />

      <JointInjuriesListContainer
        selected={values.jointInjuries}
        setSelected={(e: any) => setFieldValue("jointInjuries", e)}
      />

      <EquipmentsListContainer
        selected={values.equipments}
        setSelected={(e: any) => setFieldValue("equipments", e)}
      />

      <PositionsListContainer
        selected={values.positions}
        setSelected={(e: any) => setFieldValue("positions", e)}
      />

      <div style={{ height: 20 }} />

      <ImageCropContainer
        croppedImage={values.croppedImage}
        croppedImageValue="croppedImage"
        blobImageValue="blobImage"
        setFieldValue={setFieldValue}
        label="Image (obligatoire)"
        aspect={4 / 3}
      />
      {values.videoUrl !== null && values.videoUrl !== "" && edit ? (
        <Video
          videoUrl={values.videoUrl}
          onClick={() => setFieldValue("videoUrl", "")}
        />
      ) : (
        <InputVideoFile
          onChange={(e: any) => setFieldValue("videoUrl", e.target.files[0])}
          label="Video"
        />
      )}

      {/* <HorizontalLangsList onClick={handleLang} lang={lang} /> */}
      <Fields lng={lang} />
      {/* <KeywordsInput
        keywords={values.langs.fr.keywords}
        setFieldValue={setFieldValue}
        lng={lang}
      /> */}

      <ValidationButton
        dirty={dirty}
        isValid={isValid}
        edit={edit}
        loading={loading}
      />
    </CenterForm>
  );
};

export default Form;
