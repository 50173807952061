import { Link } from "@material-ui/core";
import React from "react";
import { Props } from "./types";
import { LabelField } from "../Input/styles";
import { Div, IconDiv } from "./styles";
import FaUndoAlt from "global/components/Icons/FaUndoAlt";
import FaEye from "global/components/Icons/FaEye";

const Video: React.FC<Props> = ({ videoUrl, onClick }) => {
  return (
    <Div>
      <LabelField>Video</LabelField>
      <IconDiv>
        <Link href={videoUrl} target="_blank" underline="none">
          <FaEye />
        </Link>
        <Link onClick={onClick} target="_blank" underline="none">
          <FaUndoAlt />
        </Link>
      </IconDiv>
    </Div>
  );
};

export default Video;
