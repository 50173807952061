import { CenterForm } from "global/components/Form/Input/styles";
import TextArea from "global/components/Form/Input/TextArea";
import { ValidationButton } from "global/components/Form/ValidateButton";
import MultiTextArea from "./MultiTextArea";
import {
  conclusionArray,
  FormProps,
  mainAxesArray,
  secondaryAxesArray,
} from "./types";
import "./styles.css";
import Health from "./Health";
import Mental from "./Mental";
import Effort from "./Effort";
import NextWorkCycle from "./NextWorkCycle";
import { useEffect } from "react";

const Form: React.FC<FormProps> = ({
  isValid,
  loading,
  dirty,
  setFieldValue,
  values,
  currentCycle,
  reportUrl,
}) => {
  useEffect(() => {
    if (currentCycle !== null) {
      setFieldValue("currentWorkResume", currentCycle.workResume);
      setFieldValue("axeP1", currentCycle.axeP1);
      setFieldValue("axeP2", currentCycle.axeP2);
      setFieldValue("axeP3", currentCycle.axeP3);
      setFieldValue("axeP4", currentCycle.axeP4);
      setFieldValue("axeS1", currentCycle.axeS1);
      setFieldValue("axeS2", currentCycle.axeS2);
      setFieldValue("axeS3", currentCycle.axeS3);
      setFieldValue("axeS4", currentCycle.axeS4);
    }
  }, [currentCycle, setFieldValue]);

  //console.log(values);
  return (
    <div className="end-report-div">
      {reportUrl !== null ? (
        <a href={reportUrl} target="_blank" rel="noreferrer">
          <button className="button">Télécharger</button>
        </a>
      ) : null}
      <CenterForm>
        <TextArea
          name="currentWorkResume"
          type="text"
          placeholder="6 semaines de travail + 1 semaine de récup"
          label="Résumé du cycle"
          autoFocus
        />
        <MultiTextArea label="Axes principaux" items={mainAxesArray} />
        <MultiTextArea label="Axes secondaires" items={secondaryAxesArray} />
        <Health
          label="Marqueurs Santé"
          setFieldValue={setFieldValue}
          values={values}
        />
        <Mental
          label="Marqueurs Mentaux"
          setFieldValue={setFieldValue}
          values={values}
        />
        <Effort
          label="Marqueurs Effort"
          setFieldValue={setFieldValue}
          values={values}
        />
        <MultiTextArea label="Conclusion" items={conclusionArray} />
        <NextWorkCycle values={values} />
        <ValidationButton dirty={dirty} isValid={isValid} loading={loading} />
      </CenterForm>
    </div>
  );
};

export default Form;
