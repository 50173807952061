import AddReportWorkCycle from "components/AddReportWorkCycle";
import { useAddReportWorkCycle } from "./hooks/useAddReportWorkCycle";
import { initialValues } from "./utils/initialValues";
import { validationSchema } from "./utils/yupSchemas";

interface Props {
  workCycleUid: string;
}

const AddReportWorkCycleContainer: React.FC<Props> = ({ workCycleUid }) => {
  const {
    loading,
    handleSubmit,
    currentCycle,
    reportUrl,
  } = useAddReportWorkCycle(workCycleUid);
  return (
    <AddReportWorkCycle
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      loading={loading}
      currentCycle={currentCycle}
      reportUrl={reportUrl}
    />
  );
};

export default AddReportWorkCycleContainer;
