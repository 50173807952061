import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

export const useJointInjuriesList = () => {
  const [jointInjuriesList, setjointInjuriesList] = useState<any[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function getData() {
      const jointInjuriesSnapshot = await firestore
        .collection("jointInjuries")
        .get();

      const array:
        | ((prevState: never[]) => never[])
        | { label: any; value: string }[] = [];
      jointInjuriesSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const label = data.langs.fr.toUpperCase();
        array.push({ label: label, value: doc.id });
      });
      setjointInjuriesList(array);
    }

    getData();
  }, [firestore]);

  return { jointInjuriesList };
};
