/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { Activity, Pain, RawData } from "../types";

export const useAthleteRawData = (athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<RawData[] | null>(null);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(8, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [cptPains, setCptPains] = useState<number>(0);
  const [reliability, setReliability] = useState<any | null>(null);

  const firestore = useFirestore();
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const athleteSnapshot = await firestore
          .collection("users")
          .doc(athleteId)
          .get();

        const collectsSnapshot = await firestore
          .collection("collects")
          .where("owner", "==", athleteSnapshot.ref)
          .where("date", ">=", moment(startDate).startOf("day").toDate())
          .where("date", "<=", moment(endDate).endOf("day").toDate())
          .get();

        const cptDays: number = await getCptDays(
          moment(startDate).toDate(),
          moment(endDate).toDate()
        );

        // collectsSnapshot.docs.map((doc) => {
        //   console.log(doc.data().activities);
        // });

        setReliability({
          reliability: Math.round(
            (collectsSnapshot.docs.length / cptDays) * 100
          ),
          responses: collectsSnapshot.docs.length,
          days: cptDays,
        });

        const array: RawData[] = [];
        let cptP: number = 0;
        await Promise.all(
          collectsSnapshot.docs.map(async (document) => {
            const data = document.data();

            if (data.pains !== undefined) {
              if (data.pains.length > cptP) cptP = data.pains.length;
            }

            if (data.activities) {
              if (data.activities.length) {
                await Promise.all(
                  data.activities.map(async (activity: any, index: number) => {
                    if (index === 0) {
                      array.push({
                        athlete:
                          athleteSnapshot.data()?.firstName +
                          " " +
                          athleteSnapshot.data()?.lastName,
                        week: `${moment(data.date.toDate()).isoWeek()}`,
                        date: moment(data.date.toDate()).format("DD/MM/YYYY"),
                        date2: data.date.toDate(),
                        rest: data.rest ? "OUI" : null,
                        sleep: data.health ? data.health.sleep : null,
                        stress: data.health ? data.health.stress : null,
                        tired: data.health ? data.health.tired : null,
                        concentration: data.mental
                          ? data.mental.concentration
                          : null,
                        pleasure: data.mental ? data.mental.pleasure : null,
                        selfConfidence: data.mental
                          ? data.mental.selfConfidence
                          : null,
                        sickness: data.health
                          ? data.health.sickness !== undefined
                            ? data.health.sickness
                            : null
                          : null,
                        weight: data.health
                          ? data.health.weight !== undefined
                            ? data.health.weight
                            : null
                          : null,
                        activity:
                          activity !== undefined
                            ? await getActivity(activity)
                            : null,
                        pains:
                          data.pains !== undefined
                            ? await getPains(data.pains)
                            : null,
                      });
                    } else {
                      array.push({
                        athlete:
                          athleteSnapshot.data()?.firstName +
                          " " +
                          athleteSnapshot.data()?.lastName,
                        week: `${moment(data.date.toDate()).isoWeek()}`,
                        date: moment(data.date.toDate()).format("DD/MM/YYYY"),
                        date2: data.date.toDate(),
                        rest: data.rest ? "OUI" : null,
                        sleep: null,
                        stress: null,
                        tired: null,
                        concentration: null,
                        pleasure: null,
                        selfConfidence: null,
                        sickness: null,
                        weight: null,
                        activity:
                          activity !== undefined
                            ? await getActivity(activity)
                            : null,
                        pains: null,
                      });
                    }
                  })
                );
              } else {
                array.push({
                  athlete:
                    athleteSnapshot.data()?.firstName +
                    " " +
                    athleteSnapshot.data()?.lastName,
                  week: `${moment(data.date.toDate()).isoWeek()}`,
                  date: moment(data.date.toDate()).format("DD/MM/YY"),
                  date2: data.date.toDate(),
                  rest: "OUI",
                  sleep: null,
                  stress: null,
                  tired: null,
                  concentration: null,
                  pleasure: null,
                  selfConfidence: null,
                  sickness: null,
                  weight: null,
                  activity: null,
                  pains:
                    data.pains !== undefined
                      ? await getPains(data.pains)
                      : null,
                });
              }
            } else {
              if (data.rest) {
                array.push({
                  athlete:
                    athleteSnapshot.data()?.firstName +
                    " " +
                    athleteSnapshot.data()?.lastName,
                  week: `${moment(data.date.toDate()).isoWeek()}`,
                  date: moment(data.date.toDate()).format("DD/MM/YY"),
                  date2: data.date.toDate(),
                  rest: "OUI",
                  sleep: null,
                  stress: null,
                  tired: null,
                  concentration: null,
                  pleasure: null,
                  selfConfidence: null,
                  sickness: null,
                  weight: null,
                  activity: null,
                  pains:
                    data.pains !== undefined
                      ? await getPains(data.pains)
                      : null,
                });
              }
            }
          })
        );

        if (cptP !== 0) setCptPains(cptP);

        array.sort(function (a: any, b: any) {
          return a.date2 - b.date2;
        });

        setData(array);
      } catch (error) {
        //console.log(error);
        alert.error("error");
      }
      setLoading(false);
    }

    getData();
  }, [alert, athleteId, dispatch, endDate, firestore, startDate]);

  async function getActivity(activity: any): Promise<Activity | null> {
    try {
      const theme = (await activity.theme.get()).data().name.fr;

      return {
        theme: getTypeOfTraining(theme),
        hour: moment(activity.date.toDate()).format("HH:mm"),
        duration: activity.duration,
        markers: {
          mediumIntensity: await getValueFromMarker(
            activity.markers,
            "mediumIntensity"
          ),
          highIntensity: await getValueFromMarker(
            activity.markers,
            "highIntensity"
          ),
          condition: await getValueFromMarker(activity.markers, "condition"),
          dynamism: await getValueFromMarker(activity.markers, "dynamism"),
          perceivedDuration: await getValueFromMarker(
            activity.markers,
            "perceivedDuration"
          ),
        },
      };
    } catch (error) {
      //console.log(error);
      return null;
    }
  }

  async function getValueFromMarker(
    markers: any[],
    markerId: string
  ): Promise<number> {
    try {
      let value = 0;

      const markersSnapshot = await firestore
        .collection("markers")
        .where("id", "==", markerId)
        .get();

      const markerPath = markersSnapshot.docs[0].ref.path;

      markers.forEach((marker: any) => {
        if (marker.marker.path === markerPath) {
          value = marker.value;
        }
      });

      return value;
    } catch (error) {
      //console.log(error);
      return 0;
    }
  }

  return {
    data,
    cptPains,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    reliability,
    loading,
  };
};

async function getPains(pains: any[]): Promise<Pain[]> {
  return pains.map(
    (pain: any): Pain => {
      return {
        type: pain.type,
        location: pain.location,
        laterality: pain.laterality,
        intensity: pain.intensity,
      };
    }
  );
}

async function getCptDays(startDate: Date, endDate: Date) {
  let cpt = 0;
  let currentDate = startDate;
  while (currentDate <= endDate) {
    cpt++;
    currentDate = moment(currentDate).add(1, "d").toDate();
  }
  return cpt;
}

function getTypeOfTraining(theme: string) {
  switch (theme) {
    case "MATCH":
    case "COMPÉTITION":
      return "MATCH";
    case "TRAINING":
      return "FOOT";
    default:
      return "INDIV";
  }
}
