import styled from "styled-components";
import AddButton from "global/components/Button/AddButton";
import CancelRoundedButton from "global/components/Button/CancelRoundedButton";
import React from "react";
import { Exercise, Work } from "schemes/exercise";
import Serie from "./Serie";
import { AddDiv, CancelButtonDiv, FormDiv } from "./styles";
import { MyShadows } from "global/styles/box-shadow";
import { LabelField } from "global/components/Form/Input/styles";
import { HeightDiv } from "../styles";

const TextArea = styled.textarea`
  font-family: "Mulish";
  -webkit-appearance: none;
  width: 300px;
  outline: unset;
  display: block;
  height: 80px;
  border-radius: 15px;
  border: 0px;
  box-shadow: ${MyShadows.primary};
  padding: 10px 20px;
  border: none;

  ::placeholder {
    color: #b6b6b6;
    font-size: 12px;
  }

  &.focus {
    border: "solid blue 2px";
  }
`;

interface Props {
  exercise: Exercise;
  addSerie: any;
  deleteSerie: any;
  editSerie: any;
  duplicateSerie: any;
  addItemToSerie: any;
  openOrClose: any;
  deleteItemToSerie: any;
  onChangeCommentsExercise: any;
}

const FormExercise: React.FC<Props> = ({
  exercise,
  addSerie,
  deleteSerie,
  editSerie,
  duplicateSerie,
  addItemToSerie,
  openOrClose,
  deleteItemToSerie,
  onChangeCommentsExercise,
}) => {
  return (
    <FormDiv>
      <CancelButtonDiv>
        <p>{exercise.data.name}</p>
        <CancelRoundedButton onClick={openOrClose} color="#B6B6B6" />
      </CancelButtonDiv>

      <LabelField>Commentaires</LabelField>
      <TextArea
        onChange={(e) => onChangeCommentsExercise(e.target.value)}
        value={exercise.comments}
      />

      <HeightDiv />
      {exercise.works?.map((work: Work, index: number) => (
        <Serie
          key={index}
          number={index + 1}
          work={work}
          length={exercise.works?.length}
          deleteSerie={deleteSerie}
          editSerie={editSerie}
          duplicateSerie={duplicateSerie}
          addItemToSerie={addItemToSerie}
          deleteItemToSerie={deleteItemToSerie}
        />
      ))}
      {exercise.works && exercise.works?.length < 6 ? (
        <AddDiv>
          <AddButton onClick={() => addSerie(exercise.key)} />
        </AddDiv>
      ) : null}
    </FormDiv>
  );
};

export default FormExercise;
