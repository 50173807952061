import { ExtendedFirebaseInstance } from "react-redux-firebase";
import { StripeRole } from "../types";

export const getRole = async (
  firebase: ExtendedFirebaseInstance,
  email: string,
  password: string
) => {
  const userCredentials = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  const tokenResult = await userCredentials?.user?.getIdTokenResult();
  console.log(tokenResult);
  const role: StripeRole = tokenResult?.claims?.role;
  const emailVerified = tokenResult?.claims?.email_verified;
  const userUid = tokenResult?.claims?.user_id;

  return { role, emailVerified, userUid };
};
