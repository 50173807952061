import { RoundedButton } from "../styles";
import React from "react";
import { Props } from "./types";
import FaTrash from "global/components/Icons/FaTrash";

const DeleteButton: React.FC<Props> = ({ onClick }) => {
  return (
    <RoundedButton type="button" onClick={onClick}>
      <FaTrash color="#B6B6B6" />
    </RoundedButton>
  );
};

export default DeleteButton;
