import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";
import { useUploadOnStorage } from "global/hooks/useUploadOnStorage";

export const useAddNutrition = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();
  const uploadOnStorage = useUploadOnStorage();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      const pdfUrl = await uploadOnStorage(values.pdfUrl, "nutritions");

      const keywords: string[] = [];
      values.langs.fr.keywords.map((keyword: any) =>
        keywords.push(keyword.text)
      );

      await firestore.collection("nutritions").add({
        createdBy: firestore.collection("users").doc(uid),
        createdAt: new Date(),
        pdfUrl: pdfUrl,
        langs: {
          fr: {
            name: values.langs.fr.name,
            description: values.langs.fr.description,
            keywords: keywords,
          },
        },
      });

      alert.success(`nutrition created`);

      history.push({
        pathname: "nutritions-list",
      });
    } catch (error) {
      // console.log(error);
      alert.error("Error");
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
