import Seance from "./Seance";

interface Props {
  seances: any[];
  deleteSeanceInDay: any;
  daysArrayIndex: number;
}

const Day: React.FC<Props> = ({
  seances,
  deleteSeanceInDay,
  daysArrayIndex,
}) => {
  return (
    <div className="day">
      {seances.map((seance, index) => (
        <div key={index}>
          <Seance
            seance={seance}
            deleteSeanceInDay={deleteSeanceInDay}
            daysArrayIndex={daysArrayIndex}
          />
        </div>
      ))}
    </div>
  );
};

export default Day;
