import Checkbox from "global/components/Form/Checkbox";
import Input from "global/components/Form/Input";

let physioList = [
  { label: "hot", value: "hot" },
  { label: "cold", value: "cold" },
  { label: "us", value: "us" },
  { label: "vibration", value: "vibration" },
  { label: "photobiomodulation", value: "photobiomodulation" },
  { label: "magnétothérapy", value: "magnétothérapy" },
  { label: "Argile", value: "Argile" },
  { label: "électrothérapy", value: "électrothérapy" },
  { label: "pressothérapy", value: "pressothérapy" },
];

let toolsList = [
  { label: "ventouse", value: "ventouse" },
  { label: "scrapping", value: "scrapping" },
  { label: "crochet", value: "crochet" },
  { label: "kinesiotaping", value: "kinesiotaping" },
  { label: "tape", value: "tape" },
  { label: "band flow", value: "band flow" },
  { label: "foam roll", value: "foam roll" },
];

let technicalList = [
  { label: "manual therapy", value: "manual therapy" },
  { label: "stretching", value: "stretching" },
  { label: "education", value: "education" },
  { label: "active therapy", value: "active therapy" },
  { label: "mobility", value: "mobility" },
  { label: "posturology", value: "posturology" },
  { label: "massage", value: "massage" },
  { label: "recuperation", value: "recuperation" },
  { label: "proprioception", value: "proprioception" },
  { label: "core", value: "core" },
  { label: "passive therapy", value: "passive therapy" },
];

const CareForm = () => {
  return (
    <div>
      <Input
        name="why"
        type="text"
        placeholder="Motif des soins..."
        label="Pourquoi ?"
      />
      <Input
        name="estimatedStop"
        type="number"
        placeholder="Jour(s) d'arrêt estimé(s)"
        label="Temps d'arrêt estimé (jours)"
      />
      <Input
        name="drugs"
        type="text"
        placeholder="Écrire quoi, dose, combien de temps..."
        label="Médicament(s)"
      />
      <Input
        name="appointments"
        type="text"
        placeholder="Écrire quoi, quand, où..."
        label="Examen(s) et rdv"
      />
      <Input
        name="workingAxes"
        type="text"
        placeholder="Décrire axe(s) de travail à effectuer"
        label="Axe(s) de travail"
      />
      <Checkbox label="Physiothérapie" list={physioList} name="physiotherapy" />
      <Checkbox label="Outil(s)" list={toolsList} name="tools" />
      <Checkbox label="Technique(s)" list={technicalList} name="technical" />
    </div>
  );
};

export default CareForm;
