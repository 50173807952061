import { Field } from "formik";

const CGUCheckbox = () => {
  return (
    <div className="cgu">
      <div className="cgu-checkbox">
        <Field type="checkbox" name="acceptCGU" />
      </div>

      <label>
        J'accepte la
        <a
          href="https://enjeux-lab.fr/politique-de-confidentialite-2/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Politique de confidentialité
        </a>{" "}
        d'Enjeux.
      </label>
    </div>
  );
};

export default CGUCheckbox;
