import { DivWidth40 } from "components/CreateSeance/FormExercise/styles";
import AddButton from "global/components/Button/AddButton";
import DeleteButton from "global/components/Button/DeleteRoundedButton";
import { Day } from "schemes/program";
import DayComponent from "./Day";

interface Props {
  days: Day[];
  openOrClose: any;
  setselectedDay: any;
  addDay: any;
  deleteSeanceInDay: any;
  deleteDay: any;
}

const Days: React.FC<Props> = ({
  days,
  openOrClose,
  setselectedDay,
  addDay,
  deleteSeanceInDay,
  deleteDay,
}) => {
  return (
    <div className="days-block">
      {days.map((day, index) => (
        <div className="days">
          <p className="title-day" key={index}>
            Jour {index + 1}{" "}
          </p>
          <DayComponent
            seances={day.seances}
            deleteSeanceInDay={deleteSeanceInDay}
            daysArrayIndex={index}
          />
          <AddButton
            onClick={() => {
              setselectedDay(index);
              openOrClose();
            }}
          />
          <DivWidth40 />
          <DeleteButton onClick={() => deleteDay(index)} />
        </div>
      ))}
      <AddButton onClick={addDay} />
    </div>
  );
};

export default Days;
