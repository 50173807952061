import DeleteArticle from "containers/CreateArticle/delete-article";
import EditArticleContainer from "containers/CreateArticle/edit";
import DeleteRoundedButton from "global/components/Button/DeleteRoundedButton";
import HeaderContainer from "global/components/HeaderContainer";
import { useOpen } from "global/hooks/useOpen";
import { GlobalDiv, StyledContainer } from "global/styles";
import React from "react";

const EditArticle: React.FC<any> = (props) => {
  const { open, openOrClose } = useOpen();

  return (
    <GlobalDiv>
      <StyledContainer>
        <HeaderContainer
          title="Edit Article"
          displayButton
          Button={<DeleteRoundedButton onClick={openOrClose} />}
        />
        <DeleteArticle
          open={open}
          cancelClick={openOrClose}
          seanceRef={props.location.state.articleUid}
        />
        <EditArticleContainer articleUid={props.location.state.articleUid} />
      </StyledContainer>
    </GlobalDiv>
  );
};

export default EditArticle;
