import { HeaderDiv, HeaderTitle } from "./styles";

interface Props {
  title: string;
}

const Header: React.FC<Props> = ({ title }) => {
  return (
    <HeaderDiv>
      <HeaderTitle>{title}</HeaderTitle>
    </HeaderDiv>
  );
};

export default Header;
