import styled from "styled-components";

export const BeautifulTr = styled.tr`
  background: var(--light-blue);
  padding: 0;
  height: 70px;
`;

export const BeautifulTd = styled.td`
  padding: 10px;
  text-align: center;
  color: #fff;
  font-family: "Bebas Neue";
  font-size: 16px;
  text-transform: uppercase;
`;
