import ImageCropContainer from "containers/ImageCrop";
import TextArea from "global/components/Form/Input/TextArea";
import { useState } from "react";
import Title from "./Title";

interface Props {
  label: string;
  setFieldValue: any;
  values: any;
}

const Effort: React.FC<Props> = ({ label, values, setFieldValue }) => {
  const [display, setDisplay] = useState<boolean>(true);
  return (
    <div>
      <Title label={label} display={display} setDisplay={setDisplay} />
      {display ? (
        <div>
          <ImageCropContainer
            croppedImage={values.croppedImageMediumIntensity}
            croppedImageValue="croppedImageMediumIntensity"
            blobImageValue="blobImageMediumIntensity"
            setFieldValue={setFieldValue}
            label="Graphique Intensité Moyenne"
            aspect={4 / 3}
          />
          <ImageCropContainer
            croppedImage={values.croppedImageHighIntensity}
            croppedImageValue="croppedImageHighIntensity"
            blobImageValue="blobImageHighIntensity"
            setFieldValue={setFieldValue}
            label="Graphique Haute intensité"
            aspect={4 / 3}
          />
          <TextArea
            name="intensityComments"
            type="text"
            placeholder="RAS"
            label="Commentaires Intensités"
          />
          <ImageCropContainer
            croppedImage={values.croppedImageDynamism}
            croppedImageValue="croppedImageDynamism"
            blobImageValue="blobImageDynamism"
            setFieldValue={setFieldValue}
            label="Graphique Dynamisme"
            aspect={4 / 3}
          />
          <ImageCropContainer
            croppedImage={values.croppedImagePerceivedDuration}
            croppedImageValue="croppedImagePerceivedDuration"
            blobImageValue="blobImagePerceivedDuration"
            setFieldValue={setFieldValue}
            label="Graphique Durée perçue"
            aspect={4 / 3}
          />
          <ImageCropContainer
            croppedImage={values.croppedImageCondition}
            croppedImageValue="croppedImageCondition"
            blobImageValue="blobImageCondition"
            setFieldValue={setFieldValue}
            label="Graphique Condition"
            aspect={4 / 3}
          />
          <TextArea
            name="effortComments"
            type="text"
            placeholder="RAS"
            label="Commentaires Marqueurs Effort"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Effort;
