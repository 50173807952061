import { Formik } from "formik";
import Form from "./Form";

import { FormikProps } from "./types";

const FormikForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
  edit,
  exercise,
  loading,
}: FormikProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form
          isValid={isValid}
          dirty={dirty}
          values={values}
          setFieldValue={setFieldValue}
          edit={edit}
          exercise={exercise}
          loading={loading}
        />
      )}
    </Formik>
  );
};

export default FormikForm;
