import { LabelField } from "global/components/Form/Input/styles";
import React from "react";
import { InputDiv, StyledInputFile } from "./styles";
import { InputVideoFileProps } from "./types";

const InputVideoFile: React.FC<InputVideoFileProps> = ({ label, onChange }) => {
  return (
    <InputDiv>
      <LabelField>{label}</LabelField>

      <StyledInputFile onChange={onChange} type="file" accept="video/*" />
    </InputDiv>
  );
};

export default InputVideoFile;
