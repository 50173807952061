import ProgramsList from "components/ProgramsList";
import { useAlgoliaSearch } from "global/hooks/useAlgoliaSearch";
import React from "react";
import { InstantSearch, Pagination } from "react-instantsearch-dom";

const ProgramsListContainer: React.FC = () => {
  const { searchClient } = useAlgoliaSearch();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`programs_${process.env.REACT_APP_ENV_NAME}`}
    >
      <ProgramsList />
      <Pagination showLast />
    </InstantSearch>
  );
};

export default ProgramsListContainer;
