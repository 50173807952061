import { Field, Form } from "formik";
import { MyShadows } from "global/styles/box-shadow";
import styled from "styled-components";

export const CenterForm = styled(Form)`
  width: 250px;
  margin: auto;
`;

export const LabelField = styled.p`
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  padding-left: 5px;
  pointer-events: none;
`;

interface InputFieldProps {
  error: boolean;
}

export const InputField = styled(Field)<InputFieldProps>`
  -webkit-appearance: none;
  width: -webkit-fill-available;
  outline: unset;
  display: block;
  height: 40px;
  border-radius: 40px;
  border: 0px;
  box-shadow: ${MyShadows.primary};
  padding: 0 20px;
  border: ${(props) => (props.error ? "solid red 2px" : "none")};
  font-family: "Mulish";

  ::placeholder {
    color: #b6b6b6;
  }

  &.focus {
    border: "solid blue 2px";
  }
`;

export const TextAreaField = styled(Field)<InputFieldProps>`
  resize: inherit;
  font-family: "Mulish";
  -webkit-appearance: none;
  width: -webkit-fill-available;
  outline: unset;
  display: block;
  height: 80px;
  border-radius: 15px;
  border: 0px;
  box-shadow: ${MyShadows.primary};
  padding: 10px 20px;
  border: ${(props) => (props.error ? "solid red 2px" : "none")};

  ::placeholder {
    color: #b6b6b6;
    font-size: 12px;
  }

  &.focus {
    border: "solid blue 2px";
  }
`;

interface StyledErrorMessageProps {
  readonly opacity: number;
}

export const StyledErrorMessage = styled.div<StyledErrorMessageProps>`
  font-size: 12px;
  color: red;
  margin-top: 0.25rem;
  padding-left: 10px;
  &:before {
    content: "❌ ";
    font-size: 10px;
  }
  opacity: ${(props) => props.opacity};
`;
