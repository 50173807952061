import axios from "axios";
import { useEffect } from "react";

const ConfirmationPro = (props: any) => {
  useEffect(() => {
    axios.post("https://hook.eu1.make.com/4jsgmc70f9bp863mdrcbghfjnahri1ac", {
      email: props.match.params.email,
    });
  }, [props.match.params.email]);
  return (
    <div className="signup">
      <div className="div-signup-registration">
        <div className="logo-container">
          <img
            src="/assets/logo-enjeuxfootball-application-3.png"
            alt="logo-enjeux"
          />
        </div>
        <h1>Merci !</h1>
        <h2>Nous allons prendre contact avec toi !</h2>
        <p>
          Tu peux retourner sur l'application et découvrir toutes ses
          fonctionnalités !
        </p>

        {/* <h1>iOS</h1>
        <a
          href="https://apps.apple.com/app/enjeux-football/id1561181378"
          target="_blank"
          rel="noreferrer"
        >
          <button className="button">
            <img
              src="/assets/apple-black-logo.png"
              width="20"
              alt="logo-apple"
            />
          </button>
        </a>
        <h1 style={{ marginTop: 50 }}>Android</h1>
        <a
          href="https://play.google.com/store/apps/details?id=com.enjeuxspm.app"
          target="_blank"
          rel="noreferrer"
        >
          <button className="button">
            <img src="/assets/android-logo.png" width="20" alt="logo-android" />
          </button>
        </a> */}
      </div>
    </div>
  );
};

export default ConfirmationPro;
