import { _activityHourToDate } from "global/functions/activityHourToDate";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { FormValues } from "../utils/initialValues";

export const useAddInjury = (athleteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const uid = useAppSelector((state) => state.firebase.auth.uid);
  const firestore = useFirestore();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await firestore.collection("injuries").add({
        createdBy: firestore.collection("users").doc(uid),
        createdAt: new Date(),
        athlete: firestore.collection("users").doc(athleteId),
        date: _activityHourToDate(values.date, "00:00"),
        injury: values.injury,
        days: values.days,
      });

      alert.success(`Injury created`);

      history.push({
        pathname: "athlete-planning",
        state: {
          athleteId: athleteId,
        },
      });
    } catch (error) {
      // console.log(error);
      alert.error("Error");
    }

    setLoading(false);
  };

  return { handleSubmit, loading };
};
