import DeletePopup from "global/components/DeletePopup";
import React from "react";
import { useDeleteArticle } from "./hooks/useDeleteArticle";

interface Props {
  open: boolean;
  seanceRef: string;
  cancelClick: any;
}

const DeleteArticle: React.FC<Props> = ({ open, seanceRef, cancelClick }) => {
  const { deleteArticle, loading } = useDeleteArticle(seanceRef);
  return (
    <DeletePopup
      open={open}
      validateClick={deleteArticle}
      cancelClick={cancelClick}
      loading={loading}
    />
  );
};

export default DeleteArticle;
