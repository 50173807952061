import { MyShadows } from "global/styles/box-shadow";
import { MyGradients } from "global/styles/colors";
import styled from "styled-components";

export const RedStyledButton = styled.button`
  margin-top: 30px;
  width: 230px;
  height: 40px;
  box-shadow: ${MyShadows.primary};
  background: ${MyGradients.red};

  border-radius: 50px;
  border: 0px;
  box-sizing: border-box;
  outline: unset;

  font-family: "Mulish";
  font-weight: 400;
  font-size: 14px;
`;
