import SeanceDayList from "containers/SeanceDayList";
import TextArea from "global/components/Form/Input/TextArea";

interface Props {
  values: any;
  selectSeance: any;
}

const SeanceForm: React.FC<Props> = ({ values, selectSeance }) => {
  return (
    <div>
      <SeanceDayList
        value={values.seance}
        date={values.date}
        selectSeance={selectSeance}
      />
      <TextArea
        name="organization"
        type="text"
        placeholder="Décrire l'organisation de la séance (ex : gainage 10' dynamique 4 exos, 2 séries, 30sec par exo etc...)"
        label="Organisation de la séance"
      />
    </div>
  );
};

export default SeanceForm;
