import FormikForm from "components/CreateSeance/FormikForm";
import { Exercise } from "schemes/exercise";
import { useEditSeance } from "../hooks/useEditSeance";
import { initialValues } from "../utils/initialValues";
import { validationSchema } from "../utils/yupSchemas";

interface Props {
  exercises: Exercise[];
  seanceRef: string;
}

const FormEditSeanceContainer: React.FC<Props> = ({ exercises, seanceRef }) => {
  const { handleSubmit, seance, loading } = useEditSeance(exercises, seanceRef);
  return (
    <FormikForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      edit={true}
      seance={seance}
      loading={loading}
    />
  );
};

export default FormEditSeanceContainer;
